import {
    DisciplineID,
    LocationID,
    OrganizationID,
    ScheduleID,
    UserID,
    WorkoutID,
} from "@app/entities/uuid";
import { WorkoutStatus } from "@app/entities/workoutStatus";
import { DateTimeRange } from "@app/entities/dateTimeRange";

export type Workout = {
    id: WorkoutID;
    organizationID: OrganizationID;
    locationID: LocationID;
    trainerID: UserID;
    disciplineID: DisciplineID;
    scheduleID?: ScheduleID;
    dateTimeRange: Required<DateTimeRange>;
    studentsCount: number;
    placesLeft: number;
    status: WorkoutStatus;
    createdAt: Date;
    updatedAt: Date;
};

export const workoutAfter = (workout: Workout, date: Date): boolean => {
    return workout.dateTimeRange.right > date;
};

export const sortWorkoutsAsc = (a: Workout, b: Workout) =>
    a.dateTimeRange.left &&
    b.dateTimeRange.left &&
    a.dateTimeRange.left > b.dateTimeRange.left
        ? 1
        : -1;

export const sortWorkoutsDesc = (a: Workout, b: Workout) =>
    a.dateTimeRange.left &&
    b.dateTimeRange.left &&
    a.dateTimeRange.left > b.dateTimeRange.left
        ? -1
        : 1;
