import * as time_range_pb from "proto-api/entity/time_range_pb";
import { TimeRange } from "@app/entities/timeRange";

export const PbToTimeRange = (
    resource: time_range_pb.TimeRange | undefined,
): TimeRange => {
    const val: TimeRange = {
        left: 0,
        right: 0,
    };
    const left = resource?.getLeft();
    if (left) {
        val.left = left;
    }
    const right = resource?.getRight();
    if (right) {
        val.right = right;
    }
    return val;
};

export const TimeRangeToPb = (resource: TimeRange): time_range_pb.TimeRange => {
    const range = new time_range_pb.TimeRange();
    range.setLeft(resource.left);
    range.setRight(resource.right);
    return range;
};
