<template>
  <form-component :form="vueForm">
    <div class="columns-2xs">
      <form-field
        :field="form.name"
        :label="t('Name')"
      >
        <text-input-field :field="form.name" />
      </form-field>
      <form-field
        :field="form.description"
        :label="t('Description')"
      >
        <wysiwyg-input-field
          :field="form.description"
        />
      </form-field>
      <form-field
        :field="form.classes"
        :label="t('Number of lessons')"
      >
        <text-input-field
          :field="form.classes"
          type="number"
        />
      </form-field>
      <form-field
        :field="form.price"
        :label="t('Price')"
      >
        <text-input-field
          :field="form.price"
          type="number"
        />
      </form-field>
      <form-field
        :field="form.days"
        :label="t('Number of days')"
      >
        <text-input-field
          :field="form.days"
          type="number"
        />
      </form-field>
      <form-field
        :field="form.disciplines"
        :label="t('Disciplines')"
      >
        <checkbox-field
          :field="form.disciplines"
          :options="props.disciplinesOptions"
        />
      </form-field>
    </div>
    <submit-button />
  </form-component>
</template>

<script lang="ts" setup>
import { reactive } from "vue";
import { ValidationArgs } from "@vuelidate/core";
import { maxLength, minValue, required } from "@app/services/validators";
import TextInputField from "@app/components/forms/fields/TextInputField.vue";
import FormField from "@app/components/forms/fields/FormField.vue";
import SubmitButton from "@app/components/forms/fields/SubmitButton.vue";
import { appForm } from "@app/services/forms";
import CheckboxField from "@app/components/forms/fields/CheckboxField.vue";
import FormComponent from "@app/components/FormComponent.vue";
import WysiwygInputField from "@app/components/forms/fields/WysiwygInputField.vue";
import { subscriptionTypeUseCase } from "@app/usecase/subscriptionTypeUseCase";
import { SubscriptionType } from "@app/entities/subscriptionType";
import { SelectOption } from "@app/views/models/form";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { StringsToEntityID } from "@app/entities/uuid";
import { useI18n } from "vue-i18n";

interface StoreSubscriptionTypeFormFields {
    name: string;
    description: string;
    days: number;
    price: number;
    classes: number;
    disciplines: string[];
}

const { t } = useI18n();
const props = defineProps<{
    disciplinesOptions: SelectOption<string>[];
}>();

const emits = defineEmits<{
    (e: "on-success", value: SubscriptionType): void;
}>();

const rules: ValidationArgs<StoreSubscriptionTypeFormFields> = {
    name: {
        required: required,
        maxLengthRef: maxLength(1000),
    },
    description: {
        required: required,
        maxLengthRef: maxLength(4000),
    },
    classes: {
        required: required,
        minValueRef: minValue(1),
    },
    price: {
        required: required,
        minValueRef: minValue(0),
    },
    days: {
        required: required,
        minValueRef: minValue(1),
    },
    disciplines: {
        required: required,
    },
};
const formFields = reactive<StoreSubscriptionTypeFormFields>({
    name: "",
    description: "",
    disciplines: [],
    days: 28,
    classes: 8,
    price: 0,
});
const vueForm = appForm<StoreSubscriptionTypeFormFields, void>(
    rules,
    formFields,
    async () => {
        const model = await subscriptionTypeUseCase.store(
            organizationUseCase.getOrganizationID(),
            formFields.name,
            formFields.description,
            formFields.days,
            formFields.price,
            formFields.classes,
            StringsToEntityID(formFields.disciplines),
        );
        emits("on-success", model);
    },
);
const form = vueForm.form;
</script>

<style scoped></style>
