import { PbToUUIDSure } from "@grpc/converters/uuid";
import { DisciplineMessage } from "proto-api/organization/discipline_pb";
import { Discipline } from "@app/entities/discipline";
import { pbToDateSure } from "@grpc/converters/date";

export const PbToDiscipline = (resource: DisciplineMessage): Discipline => {
    return {
        id: PbToUUIDSure(resource.getId()),
        organizationID: PbToUUIDSure(resource.getOrganizationId()),
        name: resource.getName(),
        description: resource.getDescription(),
        createdAt: pbToDateSure(resource.getCreatedAt()),
        updatedAt: pbToDateSure(resource.getUpdatedAt()),
        deletedAt: resource.getDeletedAt()?.toDate(),
    };
};
