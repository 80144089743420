import { RouteRecordRaw } from "vue-router";
import { personalIndexRoute } from "@pages/personal/index/route";
import { personalSubscriptionsRoute } from "@pages/personal/subscriptions/route";
import { personalWorkoutsRoute } from "@pages/personal/workouts/route";

export const personalRoutes: RouteRecordRaw[] = [
    personalIndexRoute,
    personalWorkoutsRoute,
    personalSubscriptionsRoute,
];
