import { Context } from "@app/entities/context";
import { OrganizationUser } from "@app/entities/organzationUser";
import { User } from "@app/entities/user";
import { OrganizationID, OrganizationUserID, UserID } from "@app/entities/uuid";
import { AppError } from "@app/pkg/error/AppError";
import { ContextToPb } from "@grpc/converters/context";
import { PbToAuthResponse } from "@grpc/converters/organizationUser";
import { EntityToUUID } from "@grpc/converters/uuid";
import * as grpcWeb from "grpc-web";
import { AuthServicePromiseClient } from "proto-api/auth/auth_service_grpc_web_pb";
import {
    AdminLoginRequestMessage,
    AuthInfoRequestMessage,
    ForgotPasswordRequestMessage,
    LoginByEmailRequestMessage,
    LoginByPhoneRequestMessage,
    RegisterRequestMessage,
    ResetPasswordRequestMessage,
    UpdateRequestMessage,
} from "proto-api/auth/auth_service_pb";
import { App } from "vue";

let service: AuthServicePromiseClient;

type Config = {
    service: AuthServicePromiseClient;
};
export const initAuthClient = (app: App, options: Config) => {
    service = options.service;
};

export const useAuthClient = () => {
    return authClient;
};

export type AuthResponse = {
    user: User;
    organizationUser: OrganizationUser;
    token: string;
};

const authClient = {
    info: async (ctx: Context): Promise<AuthResponse> => {
        const request = new AuthInfoRequestMessage();
        const response = await service.info(request, ContextToPb(ctx));

        return PbToAuthResponse(
            ctx.token ? ctx.token : "",
            response.getOrganizationUser(),
        );
    },

    forgotPassword: async (
        ctx: Context,
        organizationID: OrganizationID,
        email: string,
    ): Promise<void> => {
        const request = new ForgotPasswordRequestMessage();
        request.setEmail(email);
        request.setOrganizationId(EntityToUUID(organizationID));
        await service.forgotPassword(request, ContextToPb(ctx));
        return;
    },
    resetPassword: async (
        ctx: Context,
        _organizationID: OrganizationID,
        email: string,
        newPassword: string,
        token: string,
    ): Promise<void> => {
        const resetPasswordRequest = new ResetPasswordRequestMessage();
        resetPasswordRequest.setEmail(email);
        resetPasswordRequest.setNewPassword(newPassword);
        resetPasswordRequest.setToken(token);

        await service.resetPassword(resetPasswordRequest, ContextToPb(ctx));
        return;
    },
    loginByEmail: async (
        ctx: Context,
        organizationID: OrganizationID,
        email: string,
        password: string,
    ): Promise<AuthResponse> => {
        const request = new LoginByEmailRequestMessage();
        request.setEmail(email);
        request.setPassword(password);
        request.setOrganizationId(EntityToUUID(organizationID));

        const response = await service
            .loginByEmail(request, ContextToPb(ctx))
            .catch((grpcError: grpcWeb.RpcError) => {
                if (grpcWeb.StatusCode.INVALID_ARGUMENT) {
                    throw new AppError({
                        message: "email or password is wrong"
                    });
                }
                throw grpcError;
            });
        return PbToAuthResponse(
            response.getToken(),
            response.getOrganizationUser(),
        );
    },
    loginByPhone: async (
        ctx: Context,
        organizationID: OrganizationID,
        phone: string,
        password: string,
    ): Promise<AuthResponse> => {
        const request = new LoginByPhoneRequestMessage();
        request.setPhone(phone);
        request.setPassword(password);
        request.setOrganizationId(EntityToUUID(organizationID));

        const response = await service
            .loginByPhone(request, ContextToPb(ctx))
            .catch((grpcError: grpcWeb.RpcError) => {
                if (grpcWeb.StatusCode.INVALID_ARGUMENT) {
                    throw new AppError({
                        message: "phone or password is wrong"
                    });
                }
                throw grpcError;
            });
        return PbToAuthResponse(
            response.getToken(),
            response.getOrganizationUser(),
        );
    },
    adminLogin: async (
        ctx: Context,
        userID: OrganizationUserID,
    ): Promise<AuthResponse> => {
        const request = new AdminLoginRequestMessage();
        request.setUserId(EntityToUUID(userID));

        const response = await service.adminLogin(request, ContextToPb(ctx));
        return PbToAuthResponse(
            response.getToken(),
            response.getOrganizationUser(),
        );
    },
    register: async (
        ctx: Context,
        organizationID: OrganizationID,
        nickname: string,
        firstName: string,
        lastName: string,
        phone: string,
        email: string,
        password: string,
    ): Promise<AuthResponse> => {
        const registerRequest = new RegisterRequestMessage();
        registerRequest.setEmail(email);
        registerRequest.setNickname(nickname);
        registerRequest.setFirstName(firstName);
        registerRequest.setLastName(lastName);
        registerRequest.setOrganizationId(EntityToUUID(organizationID));
        registerRequest.setPhone(phone);
        registerRequest.setPassword(password);
        const response = await service.register(
            registerRequest,
            ContextToPb(ctx),
        );

        return PbToAuthResponse(
            response.getToken(),
            response.getOrganizationUser(),
        );
    },
    update: async (
        ctx: Context,
        id: UserID,
        nickname: string,
        firstName: string,
        lastName: string,
        phone: string,
        email: string,
    ): Promise<void> => {
        const request = new UpdateRequestMessage();
        request.setNickname(nickname);
        request.setFirstName(firstName);
        request.setLastName(lastName);
        request.setUserId(EntityToUUID(id));
        request.setPhone(phone);
        request.setEmail(email);
        await service.update(request, ContextToPb(ctx));

        return;
    },
};
