<template>
  <button class="btn gap-2">
    {{ name ?? t("Save") }}
  </button>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
defineProps<{
    name?: string;
}>();
</script>

<style scoped></style>
