import { PbToUUID, PbToUUIDSure } from "@grpc/converters/uuid";
import { WorkoutMessage } from "proto-api/organization/workout_pb";
import { Workout } from "@app/entities/workout";
import { PbToDateTime } from "@grpc/converters/dateTimeRange";
import { pbToWorkoutStatus } from "@grpc/converters/workoutStatus";
import { pbToDateSure } from "@grpc/converters/date";
import { DateTimeRange } from "@app/entities/dateTimeRange";

export const PbToWorkout = (resource: WorkoutMessage): Workout => {
    return {
        id: PbToUUIDSure(resource.getId()),
        organizationID: PbToUUIDSure(resource.getOrganizationId()),
        locationID: PbToUUIDSure(resource.getLocationId()),
        trainerID: PbToUUIDSure(resource.getTrainerId()),
        disciplineID: PbToUUIDSure(resource.getDisciplineId()),
        scheduleID: PbToUUID(resource.getScheduleId()),
        dateTimeRange: PbToDateTime(
            resource.getDateTimeRange(),
        ) as Required<DateTimeRange>,
        studentsCount: resource.getStudentsCount(),
        placesLeft: resource.getPlacesLeft(),
        status: pbToWorkoutStatus(resource.getStatus()),
        createdAt: pbToDateSure(resource.getCreatedAt()),
        updatedAt: pbToDateSure(resource.getUpdatedAt()),
    };
};
