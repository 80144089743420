<template>
  <page-component
    :links="actionLinks"
    :title="organization.name"
  >
    <p v-if="authView.isOwner">
      {{ t("You are an owner of this organization") }}
    </p>
    <p v-else>
      {{ t("You are not an owner of this organization") }}
    </p>

    <p
      v-for="photo of images"
      :key="EntityIDToString(photo.id)"
    >
      <img
        :src="photo.path"
        alt=""
      >
    </p>
    <div v-if="logoSquare">
      <h3>{{ t("Logo square") }}</h3>
      <img
        :src="logoSquare.path"
        alt=""
      >
    </div>
    <div v-if="logoWide">
      <h3>{{ t("Logo wide") }}</h3>
      <img
        :src="logoWide.path"
        alt=""
      >
    </div>
  </page-component>
</template>

<script lang="ts" setup>
import PageComponent from "@app/components/PageComponent.vue";
import { FileType } from "@app/entities/fileType";
import { Organization } from "@app/entities/organzation";
import { EntityIDToString } from "@app/entities/uuid";
import { PageType } from "@app/pkg/router/pageType";
import {
    authUseCase,
    ENTITIES_LIST,
    useAuthViewData,
} from "@app/usecase/authUseCase";
import { useEntityFilesData } from "@app/usecase/entityFileUseCase";
import { Link, LinkIcons } from "@app/views/models/links";
import { computed, ComputedRef } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const entityFileView = useEntityFilesData();

const props = defineProps<{
    organization: Organization;
}>();

const images = entityFileView.getByEntityType(
    props.organization.id,
    FileType.ORGANIZATION,
);
const logoWide = props.organization.logoWideID
    ? entityFileView.getByID(props.organization.logoWideID)
    : undefined;
const logoSquare = props.organization.logoSquareID
    ? entityFileView.getByID(props.organization.logoSquareID)
    : undefined;

const authView = useAuthViewData();

const actionLinks: ComputedRef<Link[]> = computed(() => [
    {
        text: t("Edit"),
        image: LinkIcons.pencil,
        to: {
            name: PageType.OrganizationEdit,
        },
        disabled: !authUseCase.canEdit(ENTITIES_LIST.ORGANIZATION),
    },
    {
        text: t("Payments"),
        image: LinkIcons.currencyDollar,
        to: {
            name: PageType.Payments,
        },
        disabled: !authUseCase.canEdit(ENTITIES_LIST.ORGANIZATION),
    },
]);
</script>

<style scoped></style>
