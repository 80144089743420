import { LocationID, WorkoutDraftItemID } from "@app/entities/uuid";
import { DateTimeRange } from "@app/entities/dateTimeRange";
import { dateToTimeRange } from "@app/entities/timeRange";
import { WorkoutDraft } from "@app/entities/workoutDraft";
import { Schedule } from "@app/entities/schedule";
import { Workout } from "@app/entities/workout";
import { WorkoutDraftItem } from "@app/entities/workoutDraftItem";
import { storageRepo } from "@app/pkg/storageRepo";
import { WithBackground } from "@app/entities/context";
import { ErrOrganizationNotFound } from "@app/entities/errors";
import { useWorkoutDraftRepo } from "@app/stores/workoutDraftRepo";
import { useWorkoutRepo } from "@app/stores/workoutRepo";
import { useOrganizationRepo } from "@app/stores/organizationRepo";
import { useScheduleRepo } from "@app/stores/scheduleRepo";

export interface WorkoutDaftRepo {
    stop(): void;

    reset(): void;

    storeItem(schedule: Schedule, dateFrom: Date, workouts: Workout[]): void;

    processData(
        processor: (item: WorkoutDraftItem) => Promise<Workout | undefined>,
    ): Promise<void>;

    markAsProblem(id: WorkoutDraftItemID, problem: string): void;
}

const useRepo = (): WorkoutDaftRepo => {
    return useWorkoutDraftRepo();
};

export interface WorkoutDraftUseCase {
    updateData(date: DateTimeRange, locationID: LocationID | undefined): void;

    processData(): void;

    stopProcessData(): void;
}

export const workoutDraftsUseCase: WorkoutDraftUseCase = {
    updateData(
        date: Required<DateTimeRange>,
        locationID: LocationID | undefined,
    ): void {
        const repo = useRepo();
        const workoutsRepo = useWorkoutRepo();
        const scheduleRepo = useScheduleRepo();

        repo.reset();

        const result: { [key: string]: WorkoutDraft } = {};
        const dateFrom = new Date(date.left);
        const dateNextDay = new Date(dateFrom);
        dateNextDay.setDate(dateNextDay.getDate() + 1);

        while (dateFrom < date.right) {
            const schedules = scheduleRepo.getByWeekday(
                dateToTimeRange(dateFrom),
                locationID,
            );

            schedules.forEach((schedule) => {
                if (!result[schedule.id.value]) {
                    result[schedule.id.value] = {
                        id: schedule.id,
                        items: [],
                    };
                }
                const workouts = workoutsRepo.getByScheduleID(
                    schedule.id,
                    dateFrom,
                    dateNextDay,
                );
                repo.storeItem(schedule, dateFrom, workouts);
            });

            dateFrom.setDate(dateFrom.getDate() + 1);
            dateNextDay.setDate(dateNextDay.getDate() + 1);
        }
        return;
    },
    stopProcessData(): void {
        const repo = useRepo();
        repo.stop();
    },
    async processData(): Promise<void> {
        const repo = useRepo();
        const workoutRepo = useWorkoutRepo();
        const organizationRepo = useOrganizationRepo();
        if (!organizationRepo.organization) {
            throw ErrOrganizationNotFound;
        }
        const organizationID = organizationRepo.organization.id;

        await repo.processData(async (model) => {
            return workoutRepo
                .store(
                    storageRepo.withToken(WithBackground()),
                    organizationID,
                    model.trainerID,
                    model.disciplineID,
                    model.locationID,
                    model.studentsCount,
                    model.timeFrom,
                    model.timeTo,
                    model.scheduleID,
                )
                .catch((reason) => {
                    repo.markAsProblem(model.id, reason.message);
                    return undefined;
                });
        });
    },
};

type Statistic = {
    statusDraft: number;
    alreadyExist: number;
    processed: number;
};
export const useWorkoutsDraftViewData = (): {
    List: WorkoutDraft[];
    AllWithProblems: WorkoutDraftItem[];
    Statistic: Statistic;
    IsProcessRun: boolean;
} => {
    return useWorkoutDraftRepo();
};
