import { defineStore } from "pinia";

import { Location } from "@app/entities/location";
import { EntityFile } from "@app/entities/entityFile";
import { Context } from "@app/entities/context";
import { LocationID, OrganizationID } from "@app/entities/uuid";
import { locationClient } from "@grpc/locationClient";
import {
    BaseEntityStorage,
    storeEntities,
    storeGetAll,
    storeGetAllActive,
    storeGetByID,
    storeGetByIDs,
} from "@app/stores/converters/repo";

export interface LocationTODOFIXOLD {
    id: string;
    organization_id: string;
    name: string;
    description: string;
    images: EntityFile[];
    created_at: Date;
    updated_at: Date;
}

type StoreFields = BaseEntityStorage<Location>;

export interface LocationClient {
    loadByOrganizationID(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<Location[]>;

    loadByID(ctx: Context, id: LocationID): Promise<Location | undefined>;

    store(
        ctx: Context,
        organizationID: OrganizationID,
        name: string,
        description: string,
    ): Promise<Location | undefined>;

    update(
        ctx: Context,
        id: LocationID,
        name: string,
        description: string,
    ): Promise<Location | undefined>;

    destroy(ctx: Context, id: LocationID): Promise<Location | undefined>;

    restore(ctx: Context, id: LocationID): Promise<Location | undefined>;
}

const client: LocationClient = locationClient;
export const useLocationRepo = defineStore("location", {
    state: () =>
        ({
            data: [],
            byId: {},
            byPage: {},
            currentPageId: undefined,
            lastUpdate: new Date(),
        }) as StoreFields,
    actions: {
        sync(entities: Location[]) {
            return storeEntities(this, entities);
        },

        async loadByOrganizationID(
            ctx: Context,
            organizationID: OrganizationID,
        ): Promise<Location[]> {
            const data = await client.loadByOrganizationID(ctx, organizationID);
            this.sync(data);
            return data;
        },

        async loadByID(
            ctx: Context,
            id: LocationID,
        ): Promise<Location | undefined> {
            const data = await client.loadByID(ctx, id);
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async store(
            ctx: Context,
            organizationID: OrganizationID,
            name: string,
            description: string,
        ): Promise<Location | undefined> {
            const data = await client.store(
                ctx,
                organizationID,
                name,
                description,
            );
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async update(
            ctx: Context,
            id: LocationID,
            name: string,
            description: string,
        ): Promise<Location | undefined> {
            const data = await client.update(ctx, id, name, description);
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async destroy(
            ctx: Context,
            id: LocationID,
        ): Promise<Location | undefined> {
            const data = await client.destroy(ctx, id);
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async restore(
            ctx: Context,
            id: LocationID,
        ): Promise<Location | undefined> {
            const data = await client.restore(ctx, id);
            if (data) {
                this.sync([data]);
            }
            return data;
        },
    },
    getters: {
        All: (store) => storeGetAll<Location>(store),
        AllActive: (store) => storeGetAllActive<Location>(store),
        getByID: (store) => storeGetByID<Location>(store),
        getByIDs: (store) => storeGetByIDs<Location>(store),
    },
});
