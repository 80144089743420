<template>
  <popup-page-component :title="t('Add workout to the schedule')">
    <schedule-add-form
      :default-day-of-week="dayOfWeek"
      :default-location-id="locationId"
      :discipline-options="disciplineOptions"
      :location-options="locationOptions"
      :trainer-options="trainersOptions"
      @on-success="onSuccess"
    />
  </popup-page-component>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import ScheduleAddForm from "@app/components/forms/ScheduleAddForm.vue";
import PopupPageComponent from "@app/components/PopupPageComponent.vue";
import { LocationID } from "@app/entities/uuid";
import { useDisciplineViewData } from "@app/usecase/disciplineUseCase";
import { useLocationViewData } from "@app/usecase/locationUseCase";
import { useOrganizationUserViewData } from "@app/usecase/organizationUserUseCase";
import { organizationUsersToOptions } from "@app/views/converters/organization_user";
import { locationsToOptions } from "@app/views/converters/location";
import { disciplinesToOptions } from "@app/views/converters/discipline";
import { dayOfWeekById } from "@app/views/models/WeekDay";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

type Props = {
    locationId: LocationID | undefined;
    dateFrom: Date;
};

const { t } = useI18n();
const router = useRouter();

const props = defineProps<Props>();

const disciplineViewData = useDisciplineViewData();
const locationViewData = useLocationViewData();
const oUserViewData = useOrganizationUserViewData();

const dayOfWeek = dayOfWeekById(props.dateFrom.getDay());

const disciplineOptions = computed(() => {
    return disciplinesToOptions(disciplineViewData.AllActive);
});

const trainersOptions = computed(() => {
    return organizationUsersToOptions(oUserViewData.trainers);
});
const locationOptions = computed(() => {
    return locationsToOptions(locationViewData.AllActive);
});

const onSuccess = () => {
    router.back();
};
</script>

<style scoped></style>
