<template>
  <div
    :class="`stats stats-vertical shadow m-0 mt-0 p-1 block overflow-visible`"
  >
    <div class="stat p-2">
      <div class="stat-title text-primary">
        <b v-if="data.location">{{ data.location.name }},</b><b v-if="data.schedule">{{ timeOfWeek(data.schedule.timeRange.left) }}
          -
          {{ timeOfWeek(data.schedule.timeRange.right) }}</b>{{ data.trainer ? `, ${data.trainer?.user.name}` : "" }}
      </div>
      <div
        v-if="data.discipline"
        class="stat-value text-xl text-primary"
      >
        {{ data.discipline.name }}
      </div>
      <div class="stat-buttons">
        <span
          v-for="item of model.items"
          :key="item.id.value"
          :class="{
            'btn-primary':
              item.problem == '' &&
              item.status !== WorkoutStatus.WorkoutStatusDraft,
            'btn-success':
              item.problem == '' &&
              item.status === WorkoutStatus.WorkoutStatusDraft,
            'btn-error': item.problem != '',
          }"
          class="btn btn-outline btn-xs m-0.5 min-w-[8rem]"
        >
          <preloader-component
            :color="
              item.status === WorkoutStatus.WorkoutStatusDraft
                ? DisplayColor.success
                : DisplayColor.primary
            "
            :is-loading="!!item.isProcess"
            :type="PreloaderType.progress"
          >
            {{ d(item.date, DATETIME_DAY_AND_MONTH) }} -
            {{ item.status }}
          </preloader-component>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { timeOfWeek } from "@app/services/format";
import { computed } from "vue";
import { DATETIME_DAY_AND_MONTH } from "@app/i18n/i18n";
import { useI18n } from "vue-i18n";
import { DisplayColor } from "@app/views/models";
import PreloaderComponent from "@app/components/PreloaderComponent.vue";
import { Discipline } from "@app/entities/discipline";
import { Location } from "@app/entities/location";
import { OrganizationUser } from "@app/entities/organzationUser";
import { WorkoutDraft } from "@app/entities/workoutDraft";
import { WorkoutStatus } from "@app/entities/workoutStatus";
import { useDisciplineViewData } from "@app/usecase/disciplineUseCase";
import { useLocationViewData } from "@app/usecase/locationUseCase";
import { useOrganizationUserViewData } from "@app/usecase/organizationUserUseCase";
import { useScheduleViewData } from "@app/usecase/scheduleUseCase";
import { PreloaderType } from "@app/views/entities/preloaderType";

const disciplineView = useDisciplineViewData();
const locationView = useLocationViewData();
const oUserViewData = useOrganizationUserViewData();
const scheduleViewData = useScheduleViewData();
const { d } = useI18n();

const props = defineProps<{
    model: WorkoutDraft;
}>();

const data = computed(() => {
    const schedule = scheduleViewData.getByID(props.model.id);
    let discipline: Discipline | undefined = undefined;
    let location: Location | undefined = undefined;
    let trainer: OrganizationUser | undefined = undefined;
    if (schedule) {
        discipline = disciplineView.getByID(schedule.disciplineID);
        location = locationView.getByID(schedule.locationID);
        trainer = oUserViewData.getByID(schedule.trainerID);
    }
    return {
        schedule: schedule,
        discipline: discipline,
        location: location,
        trainer: trainer,
    };
});
</script>

<style scoped>
.stat {
    display: grid;
}

.stat-value {
    white-space: pre-wrap;
}

.stat-buttons {
    white-space: pre-wrap;
}
</style>
