import { OrganizationID, OrganizationUserID } from "@app/entities/uuid";
import { OrganizationUser } from "@app/entities/organzationUser";
import { storageRepo } from "@app/pkg/storageRepo";
import { Context, WithBackground } from "@app/entities/context";
import { ErrUserNotFound } from "@app/entities/errors";
import { UserRole } from "@app/entities/userRole";
import { useOrganizationUsersRepo } from "@app/stores/organizationUserRepo";

export interface OrganizationUserRepo {
    loadByOrganizationID(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<OrganizationUser[]>;

    loadEmployees(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<OrganizationUser[]>;

    getByID(id: OrganizationUserID): OrganizationUser | undefined;

    loadByID(
        ctx: Context,
        id: OrganizationUserID,
    ): Promise<OrganizationUser | undefined>;

    store(
        ctx: Context,
        organizationID: OrganizationID,
        nickname: string,
        firstName: string,
        lastName: string,
        phone: string,
        email: string,
        password: string,
        role: UserRole,
    ): Promise<OrganizationUser | undefined>;

    update(
        ctx: Context,
        id: OrganizationUserID,
        role: UserRole,
    ): Promise<OrganizationUser | undefined>;

    destroy(
        ctx: Context,
        id: OrganizationUserID,
    ): Promise<OrganizationUser | undefined>;

    restore(
        ctx: Context,
        id: OrganizationUserID,
    ): Promise<OrganizationUser | undefined>;
}

const useRepo = (): OrganizationUserRepo => {
    return useOrganizationUsersRepo();
};

export const organizationUserUseCase = {
    async getByID(
        id: OrganizationUserID,
    ): Promise<OrganizationUser | undefined> {
        const repo = useRepo();
        const model = repo.getByID(id);
        if (model) {
            return model;
        }
        return this.loadByID(id);
    },
    async loadByID(
        id: OrganizationUserID,
    ): Promise<OrganizationUser | undefined> {
        const repo = useRepo();
        return await repo.loadByID(storageRepo.withToken(WithBackground()), id);
    },
    async loadByOrganizationID(
        organizationID: OrganizationID,
    ): Promise<OrganizationUser[]> {
        const repo = useRepo();
        return await repo.loadByOrganizationID(
            storageRepo.withToken(WithBackground()),
            organizationID,
        );
    },

    async loadEmployees(
        organizationID: OrganizationID,
    ): Promise<OrganizationUser[]> {
        const repo = useRepo();
        return await repo.loadEmployees(
            storageRepo.withToken(WithBackground()),
            organizationID,
        );
    },

    async store(
        organizationID: OrganizationID,
        nickname: string,
        firstName: string,
        lastName: string,
        phone: string,
        email: string,
        password: string,
        role: UserRole,
    ): Promise<OrganizationUser> {
        const repo = useRepo();
        const model = await repo.store(
            storageRepo.withToken(),
            organizationID,
            nickname,
            firstName,
            lastName,
            phone,
            email,
            password,
            role,
        );
        if (!model) {
            throw ErrUserNotFound;
        }
        return model;
    },

    async update(
        id: OrganizationUserID,
        role: UserRole,
    ): Promise<OrganizationUser> {
        const repo = useRepo();
        const model = await repo.update(storageRepo.withToken(), id, role);
        if (!model) {
            throw ErrUserNotFound;
        }
        return model;
    },

    async destroy(id: OrganizationUserID): Promise<OrganizationUser> {
        const repo = useRepo();
        const model = await repo.destroy(
            storageRepo.withToken(WithBackground()),
            id,
        );
        if (!model) {
            throw ErrUserNotFound;
        }
        return model;
    },

    async restore(id: OrganizationUserID): Promise<OrganizationUser> {
        const repo = useRepo();
        const model = await repo.restore(
            storageRepo.withToken(WithBackground()),
            id,
        );
        if (!model) {
            throw ErrUserNotFound;
        }
        return model;
    },
};

export const useOrganizationUserViewData = (): {
    All: OrganizationUser[];
    AllActive: OrganizationUser[];
    getByID(id: OrganizationUserID): OrganizationUser | undefined;
    trainers: OrganizationUser[];
} => {
    return useOrganizationUsersRepo();
};
