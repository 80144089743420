import { RouteLocationRaw } from "vue-router";
import { Ref } from "vue";

export const enum LinkIcons {
    error,
    pencil,
    pencilSquare,
    add,
    user,
    userAdd,
    boltSlash,
    bolt,
    calendar,
    trophy,
    presentationChart,
    currencyDollar,
    Google,
    VK,
    phone,
    checkbox,
}

export type LinkAction = () => Promise<object | void>;

export type Link = {
    image: LinkIcons;
    text: string;
    to?: RouteLocationRaw;
    path?: string;
    action?: LinkAction;
    actionConfirm?: string;
    disabled: boolean;
};

export const actionLink = (action: LinkAction, isLoading: Ref<boolean>) => {
    return async () => {
        isLoading.value = true;
        return action().finally(() => {
            isLoading.value = false;
        });
    };
};
