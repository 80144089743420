import { OrganizationUser, oUserName } from "@app/entities/organzationUser";
import { EntityIDToString } from "@app/entities/uuid";
import { SelectOption } from "@app/views/models/form";

export const organizationUsersToOptions = (
    models: OrganizationUser[],
): SelectOption<string>[] => {
    return models
        .map((model): SelectOption<string> => {
            return {
                value: EntityIDToString(model.id),
                displayValue: oUserName(model),
            };
        })
        .sort((a, b) => {
            return a.displayValue && b.displayValue
                ? a.displayValue.toLowerCase() > b.displayValue.toLowerCase()
                    ? 1
                    : -1
                : a.value.toLowerCase() > b.value.toLowerCase()
                  ? 1
                  : -1;
        });
};
