import { LocationID } from "@app/entities/uuid";
import { WorkoutStatus } from "@app/entities/workoutStatus";
import { PageName } from "@app/pkg/router/pageName";
import { PageType } from "@app/pkg/router/pageType";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { getPopupRoutes } from "@app/router/popups";
import {
    parseDateFromQueryString,
    parseUUIDFromQueryString,
} from "@app/router/query";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { userWorkoutsUseCase } from "@app/usecase/userWorkoutUseCase";
import { workoutsUseCase } from "@app/usecase/workoutsUseCase";
import WorkoutsListView from "@pages/workouts/list/WorkoutsListView.vue";
import {
    NavigationGuardWithThis,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";

export type WorkoutsListProps = {
    dateFrom: Date;
    dateTo: Date;
    locationId?: LocationID;
    status?: WorkoutStatus;
};

export const WorkoutsListViewBeforeEnter: NavigationGuardWithThis<
    undefined
> = async (to, from, next) => {
    const props = getFirsLevelProps<WorkoutsListProps>();

    const dateToDefault = new Date();
    dateToDefault.setDate(dateToDefault.getDate() + 7);

    props.dateFrom = parseDateFromQueryString(to.query.date_from, new Date());
    props.dateTo = parseDateFromQueryString(to.query.date_to, dateToDefault);
    props.locationId = parseUUIDFromQueryString(to.query.location_id);
    props.status = to.query.status
        ? (to.query.status as WorkoutStatus)
        : undefined;

    const dateTo = new Date(props.dateTo);
    dateTo.setDate(dateTo.getDate() + 1);

    const wait: Promise<unknown>[] = [];
    wait.push(
        workoutsUseCase.loadByOrganizationID(
            organizationUseCase.getOrganizationID(),
            {
                left: props.dateFrom,
                right: dateTo,
            },
        ),
    );
    wait.push(
        userWorkoutsUseCase
            .loadByOrganizationID(
                organizationUseCase.getOrganizationID(),
                props.dateFrom,
                dateTo,
            )
            .then(async (models) => {
                await userWorkoutsUseCase.sync(models);
            }),
    );
    await Promise.all(wait);

    // didn't find a better way
    to.params = {
        ...to.params,
        ...(props as unknown as RouteParams),
    };
    next();
};

export const workoutsListRoute: RouteRecordRaw = {
    path: "/workouts/list",
    name: PageType.WorkoutsList,
    component: WorkoutsListView,
    props: true,
    beforeEnter: WorkoutsListViewBeforeEnter,
    children: getPopupRoutes(PageType.WorkoutsList, [
        {
            name: PageType.WorkoutsList,
            to: PageName.Workouts,
        },
    ]),
};
