import { LocationID } from "@app/entities/uuid";
import { PageName } from "@app/pkg/router/pageName";
import { PageType } from "@app/pkg/router/pageType";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { getPopupRoutes } from "@app/router/popups";
import { authUseCase } from "@app/usecase/authUseCase";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { userWorkoutsUseCase } from "@app/usecase/userWorkoutUseCase";
import { workoutsUseCase } from "@app/usecase/workoutsUseCase";
import WorkoutsIndexView from "@pages/workouts/index/WorkoutsIndexView.vue";
import {
    NavigationGuardWithThis,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";

type Props = {
    day: string;
    dateFrom: Date;
    dateTo: Date;
    locationId: LocationID;
};

export const WorkoutsIndexViewBeforeEnter: NavigationGuardWithThis<
    undefined
> = async (to, from, next) => {
    let dateFrom = new Date();
    if (to.params.day) {
        dateFrom = new Date(to.params.day as string);
    } else {
        to.params.day = "";
    }

    dateFrom.setHours(0, 0, 0, 0);

    const dateTo = new Date(dateFrom);
    dateTo.setDate(dateFrom.getDate() + 1);

    const props = getFirsLevelProps<Props>();
    props.day = to.params.day as string;
    props.dateFrom = dateFrom;
    props.dateTo = dateTo;
    props.locationId = authUseCase.syncFavoriteLocation(to.query);

    const wait: Promise<unknown>[] = [];
    wait.push(
        workoutsUseCase.loadByOrganizationID(
            organizationUseCase.getOrganizationID(),
            {
                left: dateFrom,
                right: dateTo,
            },
        ),
    );
    userWorkoutsUseCase
        .loadByOrganizationID(
            organizationUseCase.getOrganizationID(),
            dateFrom,
            dateTo,
        )
        .then(async (models) => {
            await userWorkoutsUseCase.sync(models);
        });
    await Promise.all(wait);

    // didn't find a better way
    to.params = {
        ...to.params,
        ...(props as unknown as RouteParams),
    };
    next();
};

export const workoutsIndexRoute: RouteRecordRaw = {
    path: "/workouts/:day?",
    name: PageType.Workouts,
    component: WorkoutsIndexView,
    props: true,
    beforeEnter: WorkoutsIndexViewBeforeEnter,
    children: getPopupRoutes(PageType.Workouts, [
        {
            name: PageName.Workouts,
            to: PageType.Workouts,
        },
    ]),
};
