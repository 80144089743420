<template>
  <dropdown-link
    v-if="props.viewTemplate === 'badge' || props.viewTemplate === 'badge-date'"
    :key="EntityIDToString(model.id)"
    :class="`inline-block cursor-pointer mr-0.5 mb-0.5 h-6 ${css.textStyle}`"
    :links="links"
    type="badge"
  >
    <preloader-component
      :color="statusToColor(model.status)"
      :display="DisplayType.inlineBlock"
      :is-loading="isLoading"
      :type="PreloaderType.progress"
    >
      <span v-if="props.viewTemplate === 'badge' && organizationUser">{{
        oUserName(organizationUser)
      }}</span>

      <span v-else-if="workout">{{
        fullDate(d, workout.dateTimeRange.left)
      }}</span>
      <ActionIcon
        v-if="!isPaid"
        :type="LinkIcons.currencyDollar"
        size="small"
      />
    </preloader-component>
  </dropdown-link>
  <div
    v-else
    :class="`stats stats-vertical shadow m-0 mt-0 mb-1 p-1 block overflow-visible ${css.bg} ${css.textStyle}`"
  >
    <div class="stat p-2">
      <div class="stat-figure w-8 text-center">
        <dropdown-link
          :hide-if-empty="false"
          :links="links"
          type="link"
        >
          <ActionIcon
            :type="css.type"
            size="big"
          />
        </dropdown-link>
      </div>

      
      <div class="max-w-full">
        <dropdown-link
          :hide-if-empty="false"
          :links="links" 
          type="link"
        >
          <div class="break-word">
            <div v-if="workout">
              {{ fullDate(d, workout.dateTimeRange.left) }}
            </div>
            <div v-if="workout">
              <b>{{ timeOfDate(workout.dateTimeRange.left) }}
                -
                {{ timeOfDate(workout.dateTimeRange.right) }}</b>{{ trainer ? `, ${trainer?.user.name}` : "" }}
            </div>
            <div
              v-if="discipline"
              class="stat-value text-2xl"
            >
              {{ discipline.name }}
            </div>
            <div :class="`stat-desc ${css.textStyle}`">
              {{
                t(
                  `workoutStatus.${model.status}`,
                  model.status.toString(),
                )
              }}
            </div>
          </div>
        </dropdown-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ActionIcon from "@app/components/ActionIcon.vue";
import DropdownLink from "@app/components/DropdownLink.vue";
import PreloaderComponent from "@app/components/PreloaderComponent.vue";
import { oUserName } from "@app/entities/organzationUser";
import { EntityIDToString, UserWorkoutID } from "@app/entities/uuid";
import { isFinalStatus, WorkoutStatus } from "@app/entities/workoutStatus";
import { PageType } from "@app/pkg/router/pageType";
import { pupUpRoute } from "@app/router/popups";
import { ActionWatcher } from "@app/services/actionWatcher";
import { getStatusCss, StatusCss } from "@app/services/css";
import { fullDate, timeOfDate } from "@app/services/format";
import {
  authUseCase,
  ENTITIES_LIST,
  useAuthViewData,
} from "@app/usecase/authUseCase";
import { useDisciplineViewData } from "@app/usecase/disciplineUseCase";
import { useOrganizationUserViewData } from "@app/usecase/organizationUserUseCase";
import {
  userWorkoutsUseCase,
  useUserWorkoutViewData,
} from "@app/usecase/userWorkoutUseCase";
import { useWorkoutViewData } from "@app/usecase/workoutsUseCase";
import { CancelUserWorkoutAction } from "@app/userActions/cancelUserWorkout";
import { PreloaderType } from "@app/views/entities/preloaderType";
import { DisplayType, statusToColor } from "@app/views/models";
import { Link, LinkIcons } from "@app/views/models/links";
import { getSubscriptionUpdateLink } from "@app/views/popups/subsctiption-update/route";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const route = useRoute();
const { t, d } = useI18n();

const oUserViewData = useOrganizationUserViewData();
const workoutsViewData = useWorkoutViewData();
const disciplineViewData = useDisciplineViewData();
const authView = useAuthViewData();
const userWorkoutViewData = useUserWorkoutViewData();

const isLoading = ref<boolean>(false);
const watcher = new ActionWatcher();

const props = defineProps<{
  userWorkoutId: UserWorkoutID;
  viewTemplate: "badge" | "badge-date" | "stats";
}>();

const model = computed(() => {
  const model = userWorkoutViewData.getByID(props.userWorkoutId);
  if (!model) {
    throw new Error("Workout didn't find");
  }
  return model;
});

const organizationUser = computed(() => {
  return oUserViewData.getByID(model.value.organizationUserID);
});

const workout = computed(() => workoutsViewData.getByID(model.value.workoutID));
const discipline = computed(() => {
  const w = workout.value;
  return w ? disciplineViewData.getByID(w.disciplineID) : undefined;
});

const trainer = computed(() => {
  const w = workout.value;
  return w ? oUserViewData.getByID(w.trainerID) : undefined;
});

const isPaid = computed(() => userWorkoutsUseCase.isPaid(model.value));

const css = computed<StatusCss>(() => {
  const currentUser = authView.currentUser;
  return getStatusCss(
    model.value.status,
    currentUser &&
      model.value.organizationUserID.value === currentUser.id.value
      ? model.value.status
      : undefined,
    isPaid.value,
  );
});

const cancelUserWorkoutWithoutChangeBalance = async (): Promise<void> => {
  isLoading.value = true;
  return await userWorkoutsUseCase
    .cancel(model.value.id, true)
    .finally(() => {
      isLoading.value = false;
    });
};

const openUserWorkout = async (): Promise<void> => {
  isLoading.value = true;
  return await userWorkoutsUseCase.open(model.value.id).finally(() => {
    isLoading.value = false;
  });
};

const links = computed(() => {
  const currentUser = authView.currentUser;
  const modelUser = oUserViewData.getByID(model.value.organizationUserID);
  const links: Link[] = [];

  const w = workout.value;

  if (currentUser && modelUser && w) {
    const cancelAction = new CancelUserWorkoutAction(
      {
        watcher,
        t,
        currentUser,
        model: model.value,
        modelUser,
        workout: w,
      }
    );
    links.push(cancelAction.getLink());
  }

  if (currentUser && modelUser && w) {
    if (model.value.status === WorkoutStatus.WorkoutStatusOpen) {
      const softCancelMessage = t(
        "Are you sure you want to cancel {user}`s workout without changing of the balance?",
        {
          user: modelUser.user.name,
        },
      );
      let disabled = !authUseCase.canEdit(ENTITIES_LIST.USER_WORKOUT);
      if (currentUser.id.value === modelUser.id.value) {
        disabled = false;
      }

      const tomorrowDate = new Date();
      tomorrowDate.setDate(tomorrowDate.getDate() + 1);

      links.push({
        text: t("Cancel without changing the balance"),
        image: LinkIcons.error,
        actionConfirm: softCancelMessage,
        action: cancelUserWorkoutWithoutChangeBalance,
        disabled:
          disabled ||
          !authView.isEmployee ||
          w.dateTimeRange.right > tomorrowDate,
      });
    } else if (
      model.value.status === WorkoutStatus.WorkoutStatusCancelled
    ) {
      let message = t("Are you sure you want to reopen {user}`s workout?", {
        user: modelUser.user.name,
      });
      let disabled = !authUseCase.canEdit(ENTITIES_LIST.USER_WORKOUT);
      if (currentUser.id.value === modelUser.id.value) {
        message = t("Are you sure you want to reopen your workout?", {
          user: modelUser.user.name,
        });
        disabled = false;
      }
      links.push({
        text: t("Open"),
        image: LinkIcons.add,
        actionConfirm: message,
        action: openUserWorkout,
        disabled: disabled,
      });
    }

    if (
      modelUser.user.phone &&
      EntityIDToString(currentUser.id) !== EntityIDToString(modelUser.id)
    ) {
      links.push({
        text: t("Call"),
        image: LinkIcons.phone,
        path: `tel:${modelUser.user.phone}`,
        disabled: false,
      });
    }
  }

  links.push({
    image: LinkIcons.add,
    text: t("Add subscription"),
    to: pupUpRoute(
      route,
      PageType.UserSubscriptionAdd,
      {},
      {
        userWorkoutId: EntityIDToString(model.value.id),
      },
    ),
    disabled: !authUseCase.canEdit(ENTITIES_LIST.USER_WORKOUT),
  });

  if (isFinalStatus(model.value.status)) {
    return links;
  }
  if (model.value.subscriptionID) {
    links.push(
      getSubscriptionUpdateLink(
        route,
        t("Update subscription"),
        model.value.subscriptionID,
      ),
    );
  } else {
    links.push({
      image: LinkIcons.pencilSquare,
      text: t("Update user workout"),
      to: pupUpRoute(route, PageType.UserWorkoutUpdate, {
        userWorkoutId: EntityIDToString(model.value.id),
      }),
      disabled: !authUseCase.canEdit(ENTITIES_LIST.USER_WORKOUT),
    });
  }

  return links;
});
</script>

<style scoped></style>
