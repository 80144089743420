import { WorkoutStatus } from "@app/entities/workoutStatus";
import { WorkoutStatusMessage } from "proto-api/organization/workout_status_pb";

export const pbToWorkoutStatus = (
    payload: WorkoutStatusMessage,
): WorkoutStatus => {
    switch (payload) {
        case WorkoutStatusMessage.OPEN:
            return WorkoutStatus.WorkoutStatusOpen;
        case WorkoutStatusMessage.CLOSED:
            return WorkoutStatus.WorkoutStatusClosed;
        case WorkoutStatusMessage.CANCELLED:
            return WorkoutStatus.WorkoutStatusCancelled;
        default:
            return WorkoutStatus.WorkoutStatusDraft;
    }
};
