<template>
  <div
    ref="target"
    :class="{
      'dropdown-end': dropdownTypeX === 'end',
      'dropdown-bottom': dropdownTypeY === 'bottom',
      'dropdown-top': dropdownTypeY === 'top',
    }"
    class="dropdown"
  >
    <template v-if="type == 'badge'">
      <label
        v-if="displayLinks.length > 0 || !hideIfEmpty"
        :class="{
          'cursor-pointer': displayLinks.length > 0,
          'badge badge-outline mx-0.5 font-bold': true,
        }"
        tabindex="0"
      >
        <slot />
      </label>
    </template>
    <template v-else-if="type == 'button'">
      <label
        v-if="displayLinks.length > 0 || !hideIfEmpty"
        :class="{
          'cursor-pointer': displayLinks.length > 0,
          button: true,
        }"
        tabindex="0"
      >
        <slot />
      </label>
    </template>
    <template v-else>
      <label
        v-if="displayLinks.length > 0 || !hideIfEmpty"
        :class="{
          'link link-hover cursor-pointer': displayLinks.length > 0,
        }"
        tabindex="0"
      >
        <slot />
      </label>
    </template>
    <ul
      ref="ulElement"
      :class="{
        hidden: displayLinks.length === 0,
      }"
      class="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52 z-10"
      tabindex="0"
    >
      <li
        v-for="link of displayLinks"
        :key="link.text"
        :class="{
          'text-error': link.image === LinkIcons.error,
          'text-accent':
            link.image === LinkIcons.add ||
            link.image === LinkIcons.checkbox,
          'text-primary':
            link.image !== LinkIcons.error &&
            link.image !== LinkIcons.add &&
            link.image !== LinkIcons.checkbox,
        }"
      >
        <a
          :href="link?.path"
          @click.stop.prevent="onClick(link)"
        >
          <ActionIcon :type="link.image" />
          <span class="text-left">{{ link.text }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import ActionIcon from "@app/components/ActionIcon.vue";
import { Link, LinkIcons } from "@app/views/models/links";
import { useWindowSize } from "@vueuse/core";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

type DropDownType = "end" | "left" | "top" | "bottom";

const router = useRouter();

const target = ref<HTMLDivElement | null>(null);
const { width, height } = useWindowSize();

const dropdownTypeX = computed<DropDownType>(() => {
    const rect = target.value?.getBoundingClientRect();
    let right = 0;
    if (rect) {
        right = rect.left + rect.width;
    }
    if (right < width.value / 2) {
        return "left";
    }

    return "end";
});
const dropdownTypeY = computed<DropDownType>(() => {
    const rect = target.value?.getBoundingClientRect();
    let bottom = 0;
    if (rect) {
        bottom = rect.bottom;
    }

    if (bottom < height.value / 2) {
        return "bottom";
    }

    return "top";
});

const props = withDefaults(
    defineProps<{
        links: Link[];
        type: "link" | "button" | "badge";
        hideIfEmpty?: boolean;
    }>(),
    {
        hideIfEmpty: false,
    },
);
const ulElement = ref<HTMLElement>();
const displayLinks = computed(() =>
    props.links.filter((link) => !link.disabled),
);
const onClick = async (link: Link): Promise<void | object> => {
    ulElement.value?.blur();
    if (
        !link.actionConfirm ||
        (link.actionConfirm && confirm(link.actionConfirm))
    ) {
        if (link.action) {
            return link.action();
        } else if (link.path) {
            window.location.href = link.path;
            return Promise.resolve();
        } else if (link.to) {
            return router.push(link.to);
        }
    }
    return Promise.resolve();
};
</script>

<style scoped></style>
