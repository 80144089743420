import { Transaction } from "@app/entities/balance/transaction";
import { pbToDateSure } from "@grpc/converters/date";
import { PbToUUID, PbToUUIDSure } from "@grpc/converters/uuid";
import { Transaction as TransactionPb } from "proto-api/balance/transaction_pb";

export const PbToTransaction = (resource: TransactionPb): Transaction => {
    return {
        id: PbToUUIDSure(resource.getId()),
        fromAccountID: PbToUUID(resource.getFromAccountId()),
        toAccountID: PbToUUID(resource.getToAccountId()),
        confirmedByID: PbToUUIDSure(resource.getConfirmedById()),
        paymentID: PbToUUID(resource.getPaymentId()),
        amount: resource.getAmount(),
        createdAt: pbToDateSure(resource.getCreatedAt()),
        updatedAt: pbToDateSure(resource.getUpdatedAt()),
    };
};
