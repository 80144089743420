import { defineStore } from "pinia";
import {
    BaseEntityStorage,
    storeEntities,
    storeGetAll,
    storeGetAllActive,
    storeGetByID,
    storeGetByIDs,
} from "@app/stores/converters/repo";
import { Discipline } from "@app/entities/discipline";
import { DisciplineID, OrganizationID } from "@app/entities/uuid";
import { Context } from "@app/entities/context";
import { disciplineClient } from "@grpc/disciplineClient";

type StoreFields = BaseEntityStorage<Discipline>;

export interface DisciplineClient {
    loadByOrganizationID(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<Discipline[]>;

    loadByID(ctx: Context, id: DisciplineID): Promise<Discipline | undefined>;

    store(
        ctx: Context,
        organizationID: OrganizationID,
        name: string,
        description: string,
    ): Promise<Discipline | undefined>;

    update(
        ctx: Context,
        id: DisciplineID,
        name: string,
        description: string,
    ): Promise<Discipline | undefined>;

    destroy(ctx: Context, id: DisciplineID): Promise<Discipline | undefined>;

    restore(ctx: Context, id: DisciplineID): Promise<Discipline | undefined>;
}

const client: DisciplineClient = disciplineClient;

export const useDisciplineRepo = defineStore("discipline", {
    state: () =>
        ({
            data: [],
            byId: {},
            byPage: {},
            currentPageId: undefined,
            lastUpdate: new Date(),
        }) as StoreFields,
    actions: {
        sync(entities: Discipline[]) {
            return storeEntities(this, entities);
        },

        async loadByOrganizationID(
            ctx: Context,
            organizationID: OrganizationID,
        ): Promise<Discipline[]> {
            const data = await client.loadByOrganizationID(ctx, organizationID);
            this.sync(data);
            return data;
        },

        async loadByID(
            ctx: Context,
            id: DisciplineID,
        ): Promise<Discipline | undefined> {
            const data = await client.loadByID(ctx, id);
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async store(
            ctx: Context,
            organizationID: OrganizationID,
            name: string,
            description: string,
        ): Promise<Discipline | undefined> {
            const data = await client.store(
                ctx,
                organizationID,
                name,
                description,
            );
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async update(
            ctx: Context,
            id: DisciplineID,
            name: string,
            description: string,
        ): Promise<Discipline | undefined> {
            const data = await client.update(ctx, id, name, description);
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async destroy(
            ctx: Context,
            id: DisciplineID,
        ): Promise<Discipline | undefined> {
            const data = await client.destroy(ctx, id);
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async restore(
            ctx: Context,
            id: DisciplineID,
        ): Promise<Discipline | undefined> {
            const data = await client.restore(ctx, id);
            if (data) {
                this.sync([data]);
            }
            return data;
        },
    },
    getters: {
        All: (store) => storeGetAll<Discipline>(store),
        AllActive: (store) => storeGetAllActive<Discipline>(store),
        getByID: (store) => storeGetByID<Discipline>(store),
        getByIDs: (store) => storeGetByIDs<Discipline>(store),
    },
});
