import { Context } from "@app/entities/context";
import { SubscriptionType } from "@app/entities/subscriptionType";
import {
    DisciplineID,
    OrganizationID,
    SubscriptionTypeID,
} from "@app/entities/uuid";
import { ContextToPb } from "@grpc/converters/context";
import { PbToEntities } from "@grpc/converters/entities";
import { PbToSubscriptionType } from "@grpc/converters/subscriptionType";
import { EntityIDsToUUID, EntityToUUID } from "@grpc/converters/uuid";
import { SubscriptionTypeServicePromiseClient } from "proto-api/organization/subscription_type_service_grpc_web_pb";
import {
    SubscriptionTypeDestroyRequestMessage,
    SubscriptionTypeGetByIDRequestMessage,
    SubscriptionTypeGetByOrganizationIDRequestMessage,
    SubscriptionTypeRestoreRequestMessage,
    SubscriptionTypeStoreRequestMessage,
    SubscriptionTypeUpdateRequestMessage,
} from "proto-api/organization/subscription_type_service_pb";

//"https://grpc-local.fitfrog.ru"
const service = new SubscriptionTypeServicePromiseClient(
    `${import.meta.env.VITE_GRPC_BASE_URL}`,
    null,
);

export const subscriptionTypeClient = {
    loadByOrganizationID: async (
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<SubscriptionType[]> => {
        const request = new SubscriptionTypeGetByOrganizationIDRequestMessage();
        request.setOrganizationId(EntityToUUID(organizationID));

        const response = await service.getByOrganizationID(
            request,
            ContextToPb(ctx),
        );
        return PbToEntities(
            response.getSubscriptiontypesList(),
            PbToSubscriptionType,
        );
    },

    loadByID: async (
        ctx: Context,
        id: SubscriptionTypeID,
    ): Promise<SubscriptionType | undefined> => {
        const request = new SubscriptionTypeGetByIDRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.getByID(request, ContextToPb(ctx));

        const message = response.getSubscriptiontype();
        if (message) {
            return PbToSubscriptionType(message);
        }
        return;
    },

    store: async (
        ctx: Context,
        _organizationID: OrganizationID,
        name: string,
        description: string,
        days: number,
        price: number,
        classes: number,
        disciplineIDs: DisciplineID[],
    ): Promise<SubscriptionType | undefined> => {
        const request = new SubscriptionTypeStoreRequestMessage();
        request.setName(name);
        request.setDescription(description);
        request.setDays(days);
        request.setPrice(price);
        request.setClasses(classes);
        request.setDisciplinesList(EntityIDsToUUID(disciplineIDs));

        const response = await service.store(request, ContextToPb(ctx));

        const message = response.getSubscriptiontype();
        if (message) {
            return PbToSubscriptionType(message);
        }
        return;
    },

    update: async (
        ctx: Context,
        id: SubscriptionTypeID,
        name: string,
        description: string,
        days: number,
        price: number,
        classes: number,
        disciplineIDs: DisciplineID[],
    ): Promise<SubscriptionType | undefined> => {
        const request = new SubscriptionTypeUpdateRequestMessage();
        request.setId(EntityToUUID(id));
        request.setName(name);
        request.setDescription(description);
        request.setDays(days);
        request.setPrice(price);
        request.setClasses(classes);
        request.setDisciplinesList(EntityIDsToUUID(disciplineIDs));

        const response = await service.update(request, ContextToPb(ctx));

        const message = response.getSubscriptiontype();
        if (message) {
            return PbToSubscriptionType(message);
        }
        return;
    },

    destroy: async (
        ctx: Context,
        id: SubscriptionTypeID,
    ): Promise<SubscriptionType | undefined> => {
        const request = new SubscriptionTypeDestroyRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.destroy(request, ContextToPb(ctx));
        const message = response.getSubscriptiontype();
        if (message) {
            return PbToSubscriptionType(message);
        }
        return;
    },

    restore: async (
        ctx: Context,
        id: SubscriptionTypeID,
    ): Promise<SubscriptionType | undefined> => {
        const request = new SubscriptionTypeRestoreRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.restore(request, ContextToPb(ctx));
        const message = response.getSubscriptiontype();
        if (message) {
            return PbToSubscriptionType(message);
        }
        return;
    },
};
