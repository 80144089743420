import { Context, WithBackground } from "@app/entities/context";
import { Discipline } from "@app/entities/discipline";
import { EntityFile } from "@app/entities/entityFile";
import {
    ErrLoadError,
    ErrOrganizationNotFound,
    ErrSomethingWentWrong,
} from "@app/entities/errors";
import { Location } from "@app/entities/location";
import { Organization } from "@app/entities/organzation";
import { Schedule } from "@app/entities/schedule";
import { SubscriptionType } from "@app/entities/subscriptionType";
import { OrganizationID } from "@app/entities/uuid";
import { Workout } from "@app/entities/workout";
import { storageRepo } from "@app/pkg/storageRepo";
import { useDisciplineRepo } from "@app/stores/disciplineRepo";
import { useEntityFileRepo } from "@app/stores/entityFileRepo";
import { useLocationRepo } from "@app/stores/locationRepo";
import { useOrganizationRepo } from "@app/stores/organizationRepo";
import { useScheduleRepo } from "@app/stores/scheduleRepo";
import { useSubscriptionTypeRepo } from "@app/stores/subscriptionTypeRepo";
import { useWorkoutRepo } from "@app/stores/workoutRepo";

export interface OrganizationRepo {
    organization?: Organization;

    info(
        ctx: Context,
        code: string,
    ): Promise<
        | {
              organization: Organization;
              entityFiles: EntityFile[];
              workouts: Workout[];
              subscriptionTypes: SubscriptionType[];
              locations: Location[];
              disciplines: Discipline[];
              schedules: Schedule[];
          }
        | undefined
    >;

    update(
        ctx: Context,
        id: OrganizationID,
        name: string,
    ): Promise<Organization | undefined>;
}

const useRepo = (): OrganizationRepo => {
    return useOrganizationRepo();
};

export const organizationUseCase = {
    info: async (code: string): Promise<Organization> => {
        const repo = useRepo();
        const filesRepo = useEntityFileRepo();
        const workoutRepo = useWorkoutRepo();
        const subscriptionTypesRepo = useSubscriptionTypeRepo();
        const locationsRepo = useLocationRepo();
        const disciplineRepo = useDisciplineRepo();
        const schedulesRepo = useScheduleRepo();

        const data = await repo.info(
            storageRepo.withToken(WithBackground()),
            code,
        );
        if (data) {
            filesRepo.sync(data.entityFiles);
            locationsRepo.sync(data.locations);
            disciplineRepo.sync(data.disciplines);
            subscriptionTypesRepo.sync(data.subscriptionTypes);
            workoutRepo.sync(data.workouts);
            schedulesRepo.sync(data.schedules);
            return data.organization;
        } else {
            throw ErrLoadError;
        }
    },
    async update(id: OrganizationID, name: string): Promise<Organization> {
        const repo = useRepo();
        const model = await repo.update(storageRepo.withToken(), id, name);
        if (model) {
            return model;
        } else {
            throw ErrSomethingWentWrong;
        }
    },
    currentOrganization(): Organization {
        const repo = useRepo();
        const organization = repo.organization;
        if (organization) {
            return organization;
        } else {
            throw ErrOrganizationNotFound;
        }
    },
    getOrganizationID(): OrganizationID {
        const repo = useRepo();
        const organization = repo.organization;
        if (organization) {
            return organization.id;
        } else {
            throw ErrOrganizationNotFound;
        }
    },
};

export const useOrganizationViewData = (): {
    squareLogoPath: string;
    wideLogoPath: string;
} => {
    return useOrganizationRepo();
};
