<template>
  <div class="btm-nav btm-nav-xs bg-white">
    <router-link
      :to="{ name: Workouts }"
      active-class="active bg-primary/5"
      class="text-primary"
      :class="{
        'active bg-primary/5': active === 'workouts',
      }"
    >
      <action-icon :type="LinkIcons.calendar" />
    </router-link>
    <router-link
      :to="{ name: UserSubscriptions }"
      active-class="active bg-primary/5"
      class="text-primary"
      :class="{
        'active bg-primary/5': active === 'personal',
      }"
    >
      <action-icon :type="LinkIcons.user" />
    </router-link>
    <router-link
      v-if="user.isEmployee"
      :to="{ name: Organization }"
      active-class="active bg-primary/5"
      class="text-primary"
      :class="{
        'active bg-primary/5': active === 'organization',
      }"
    >
      <action-icon :type="LinkIcons.presentationChart" />
    </router-link>
  </div>
</template>

<script lang="ts" setup>
import ActionIcon from "@app/components/ActionIcon.vue";
import { PageType } from "@app/pkg/router/pageType";
import { useAuthViewData } from "@app/usecase/authUseCase";
import { LinkIcons } from "@app/views/models/links";
import { computed } from "vue";
import { RouterLink, useRoute } from "vue-router";

const Workouts = PageType.Workouts;
const UserSubscriptions = PageType.UserSubscriptions;
const Organization = PageType.OrganizationStatistic;

const user = useAuthViewData();

const route = useRoute();

const personalRegEx = new RegExp("^/personal", "");
const organizationRegEx = new RegExp("^/organization", "");
const active = computed<"workouts" | "personal" | "organization">(() => {
    switch (true) {
        case personalRegEx.exec(route.path) !== null:
            return "personal";
        case organizationRegEx.exec(route.path) !== null:
            return "organization";
        default:
            return "workouts";
    }
});
</script>

<style scoped></style>
