import { LocationID, OrganizationID } from "@app/entities/uuid";
import {
    ErrLocationNotFound,
    ErrSomethingWentWrong,
} from "@app/entities/errors";
import { storageRepo } from "@app/pkg/storageRepo";
import { Context, WithBackground } from "@app/entities/context";
import { Location } from "@app/entities/location";
import { useLocationRepo } from "@app/stores/locationRepo";
import { organizationUseCase } from "@app/usecase/organizationUseCase";

export interface LocationRepo {
    loadByOrganizationID(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<Location[]>;

    loadByID(ctx: Context, id: LocationID): Promise<Location | undefined>;

    store(
        ctx: Context,
        organizationID: OrganizationID,
        name: string,
        description: string,
    ): Promise<Location | undefined>;

    update(
        ctx: Context,
        id: LocationID,
        name: string,
        description: string,
    ): Promise<Location | undefined>;

    destroy(ctx: Context, id: LocationID): Promise<Location | undefined>;

    restore(ctx: Context, id: LocationID): Promise<Location | undefined>;
}

const useRepo = (): LocationRepo => {
    return useLocationRepo();
};

export interface LocationUseCase {
    getByID(id: LocationID): Promise<Location>;

    getByOrganizationID(organizationID: OrganizationID): Promise<Location[]>;

    store(name: string, description: string): Promise<Location>;

    update(
        id: LocationID,
        name: string,
        description: string,
    ): Promise<Location>;

    destroy(id: LocationID): Promise<Location>;

    restore(id: LocationID): Promise<Location>;
}

export const locationUseCase: LocationUseCase = {
    async getByID(id: LocationID): Promise<Location> {
        const repo = useRepo();
        const model = await repo.loadByID(
            storageRepo.withToken(WithBackground()),
            id,
        );
        if (!model) {
            throw ErrLocationNotFound;
        }
        return model;
    },
    async getByOrganizationID(
        organizationID: OrganizationID,
    ): Promise<Location[]> {
        const repo = useRepo();
        return await repo.loadByOrganizationID(
            storageRepo.withToken(WithBackground()),
            organizationID,
        );
    },
    async store(name: string, description: string): Promise<Location> {
        const repo = useRepo();
        const model = await repo.store(
            storageRepo.withToken(),
            organizationUseCase.getOrganizationID(),
            name,
            description,
        );
        if (!model) {
            throw ErrSomethingWentWrong;
        }
        return model;
    },

    async update(
        id: LocationID,
        name: string,
        description: string,
    ): Promise<Location> {
        const repo = useRepo();
        const model = await repo.update(
            storageRepo.withToken(),
            id,
            name,
            description,
        );
        if (!model) {
            throw ErrSomethingWentWrong;
        }
        return model;
    },

    async destroy(id: LocationID): Promise<Location> {
        const repo = useRepo();
        const model = await repo.destroy(
            storageRepo.withToken(WithBackground()),
            id,
        );
        if (!model) {
            throw ErrSomethingWentWrong;
        }
        return model;
    },

    async restore(id: LocationID): Promise<Location> {
        const repo = useRepo();
        const model = await repo.restore(
            storageRepo.withToken(WithBackground()),
            id,
        );
        if (!model) {
            throw ErrSomethingWentWrong;
        }
        return model;
    },
};

export const useLocationViewData = (): {
    getByID(id: LocationID): Location | undefined;
    getByIDs(ids: LocationID[]): Location[];
    All: Location[];
    AllActive: Location[];
} => {
    return useLocationRepo();
};
