<template>
  <popup-page-component :title="t('Workout update')">
    <workout-update-form
      :discipline-options="disciplineOptions"
      :location-options="locationOptions"
      :trainer-options="trainersOptions"
      :workout="workout"
      @on-success="onSuccess"
    />
  </popup-page-component>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import WorkoutUpdateForm from "@app/components/forms/WorkoutUpdateForm.vue";
import PopupPageComponent from "@app/components/PopupPageComponent.vue";
import { computed } from "vue";
import { useDisciplineViewData } from "@app/usecase/disciplineUseCase";
import { useLocationViewData } from "@app/usecase/locationUseCase";
import { useOrganizationUserViewData } from "@app/usecase/organizationUserUseCase";
import { organizationUsersToOptions } from "@app/views/converters/organization_user";
import { locationsToOptions } from "@app/views/converters/location";
import { disciplinesToOptions } from "@app/views/converters/discipline";
import { useWorkoutViewData } from "@app/usecase/workoutsUseCase";
import { WorkoutID } from "@app/entities/uuid";
import { useI18n } from "vue-i18n";

type Props = {
    workoutId: WorkoutID;
};

const { t } = useI18n();
const props = defineProps<Props>();
const router = useRouter();

const disciplinesStore = useDisciplineViewData();
const locationsStore = useLocationViewData();
const oUserViewData = useOrganizationUserViewData();
const workoutViewData = useWorkoutViewData();

const workout = computed(() => {
    const model = workoutViewData.getByID(props.workoutId);
    if (!model) {
        throw new Error("Workout didn't found");
    }
    return model;
});

const trainersOptions = computed(() => {
    return organizationUsersToOptions(oUserViewData.trainers);
});
const locationOptions = computed(() => {
    return locationsToOptions(locationsStore.AllActive);
});
const disciplineOptions = computed(() => {
    return disciplinesToOptions(disciplinesStore.All);
});

const onSuccess = () => {
    router.back();
};
</script>

<style scoped></style>
