<template>
  <popup-page-component :title="t('Subscription type add')">
    <subscription-type-add-form
      :disciplines-options="disciplineOptions"
      @on-success="onSuccess"
    />
  </popup-page-component>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import SubscriptionTypeAddForm from "@app/components/forms/SubscriptionTypeAddForm.vue";
import PopupPageComponent from "@app/components/PopupPageComponent.vue";
import { useDisciplineViewData } from "@app/usecase/disciplineUseCase";
import { disciplinesToOptions } from "@app/views/converters/discipline";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const disciplineViewData = useDisciplineViewData();

const disciplineOptions = computed(() => {
    return disciplinesToOptions(disciplineViewData.AllActive);
});
const onSuccess = () => {
    router.back();
};
</script>

<style scoped></style>
