import { Account } from "@app/entities/payment/account";
import { pbToDateSure } from "@grpc/converters/date";
import { PbToAccountType } from "@grpc/converters/payment/accountType";
import { PbToUUIDSure } from "@grpc/converters/uuid";
import { Account as AccountPb } from "proto-api/payment/account_pb";

export const PbToAccount = (resource: AccountPb): Account => {
    return {
        id: PbToUUIDSure(resource.getId()),
        organizationID: PbToUUIDSure(resource.getOrganizationId()),
        createdAt: pbToDateSure(resource.getCreatedAt()),
        updatedAt: pbToDateSure(resource.getUpdatedAt()),
        name: resource.getName(),
        type: PbToAccountType(resource.getType()),
    };
};
