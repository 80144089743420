<template>
  <form-component :form="vueForm">
    <list-box-field
      :field="form.location_id"
      :options="locationOptions"
    />
    <div class="max-w-sm">
      <div class="inline-block w-1/2 p-1">
        <form-field
          :field="form.date_from"
          label=""
        >
          <date-input-field
            :field="form.date_from"
          />
        </form-field>
      </div>
      <div class="inline-block w-1/2 p-1">
        <form-field
          :field="form.date_to"
          label=""
        >
          <date-input-field :field="form.date_to" />
        </form-field>
      </div>
      <form-field
        :field="form.status"
        :label="t('Status')"
      >
        <select-box-field
          :field="form.status"
          :options="statusOptions"
        />
      </form-field>
      <div class="inline-block w-1/2 p-1">
        <submit-button :name="t('Search')" />
      </div>
    </div>
  </form-component>
</template>

<script lang="ts" setup>
import FormComponent from "@app/components/FormComponent.vue";
import DateInputField from "@app/components/forms/fields/DateInputField.vue";
import FormField from "@app/components/forms/fields/FormField.vue";
import ListBoxField from "@app/components/forms/fields/ListBoxField.vue";
import SelectBoxField from "@app/components/forms/fields/SelectBoxField.vue";
import SubmitButton from "@app/components/forms/fields/SubmitButton.vue";
import { EntityIDToString } from "@app/entities/uuid";
import { WorkoutStatus } from "@app/entities/workoutStatus";
import { PageType } from "@app/pkg/router/pageType";
import { formatDateISO } from "@app/services/format";
import { appForm } from "@app/services/forms";
import { useLocationViewData } from "@app/usecase/locationUseCase";
import { locationsToOptions } from "@app/views/converters/location";
import { SelectOption } from "@app/views/models/form";
import { WorkoutsListProps } from "@pages/workouts/list/route";
import { ValidationArgs } from "@vuelidate/core";
import { computed, ComputedRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

type ScheduleFilterFormFields = {
    location_id: string;
    date_from: string;
    date_to: string;
    status: string;
};

const locationView = useLocationViewData();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const props = defineProps<WorkoutsListProps>();

const statusOptions: SelectOption<string>[] = [
    {
        value: "",
        displayValue: t("workoutStatus.all"),
    },
    {
        value: WorkoutStatus.WorkoutStatusOpen,
        displayValue: t("workoutStatus.open"),
    },
    {
        value: WorkoutStatus.WorkoutStatusClosed,
        displayValue: t("workoutStatus.closed"),
    },
    {
        value: WorkoutStatus.WorkoutStatusCancelled,
        displayValue: t("workoutStatus.cancelled"),
    },
];

const location = props.locationId
    ? locationView.getByID(props.locationId)
    : undefined;
const formFields: ScheduleFilterFormFields = {
    location_id: location ? EntityIDToString(location.id) : "",
    date_from: formatDateISO(props.dateFrom),
    date_to: formatDateISO(props.dateTo),
    status: props.status ? props.status : "",
};

const rules: ValidationArgs<ScheduleFilterFormFields> = {
    location_id: {},
    date_from: {},
    date_to: {},
    status: {},
};

const locationOptions: ComputedRef<SelectOption<string>[]> = computed(() => {
    return [
        {
            value: "",
            displayValue: t("All locations"),
        },
        ...locationsToOptions(locationView.AllActive),
    ];
});

const vueForm = appForm<ScheduleFilterFormFields, void>(
    rules,
    formFields,
    async () => {
        let query = {
            ...route.query,
            location_id: formFields.location_id,
            date_from: formFields.date_from,
            date_to: formFields.date_to,
            status: formFields.status,
        };
        if (!formFields.location_id) {
            query = Object.assign(query, { location_id: undefined });
        }

        await router.push({
            name: PageType.WorkoutsList,
            query: query,
        });
    },
);

const form = vueForm.form;
</script>

<style scoped></style>
