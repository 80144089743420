import { formatDateISO, onlyDay, weekDay } from "@app/services/format";
import { ComposerDateTimeFormatting } from "vue-i18n";
import { RouteLocationRaw } from "vue-router";
import { DateRange } from "@app/views/models";
import { DayLink } from "@app/views/entities/dayLink";

export const initLinks = (
    today: Date,
    d: ComposerDateTimeFormatting,
    routeNameGenerator: (date: Date, day: string) => RouteLocationRaw,
    activeDayNumber: string,
): DayLink[] => {
    const newDays: DayLink[] = [];

    today.setHours(0, 0, 0, 0);
    activeDayNumber =
        activeDayNumber && activeDayNumber.length
            ? activeDayNumber
            : formatDateISO(today);

    for (let i = 0; i < 7; i++) {
        const day = onlyDay(d, today);
        newDays.push({
            date: new Date(today),
            num: day,
            isDayOf: today.getDay() === 0 || today.getDay() === 6,
            text: weekDay(d, today),
            active: day === activeDayNumber,
            to: routeNameGenerator(today, formatDateISO(today)),
        });
        today.setDate(today.getDate() + 1);
    }
    if (!newDays.find((day) => day.active)) {
        newDays[0].active = true;
    }
    return newDays;
};

export const makeDateRange = (original: Date, days: number): DateRange => {
    const from = new Date(original);
    from.setHours(0, 0, 0, 0);
    const to = new Date(from);
    to.setDate(to.getDate() + days);
    return {
        from,
        to,
    };
};
