import { SubscriptionType } from "@app/entities/subscriptionType";
import { StringToEntityID } from "@app/entities/uuid";
import { PageName } from "@app/pkg/router/pageName";
import { PageType } from "@app/pkg/router/pageType";
import { getFirsLevelProps, getSecondLevelProps } from "@app/pkg/router/props";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { subscriptionTypeUseCase } from "@app/usecase/subscriptionTypeUseCase";
import SubscriptionTypeAddView from "@subscriptionTypes/SubscriptionTypeAddView.vue";
import SubscriptionTypesView from "@subscriptionTypes/SubscriptionTypesView.vue";
import SubscriptionTypeUpdateView from "@subscriptionTypes/SubscriptionTypeUpdateView.vue";
import {
    RouteLocationNormalized,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";

type Props = {
    subscriptionType: SubscriptionType;
};
export const subscriptionTypesRoutes: RouteRecordRaw[] = [
    {
        path: "/subscription-types",
        name: PageType.SubscriptionTypes,
        component: SubscriptionTypesView,
        props: true,
        beforeEnter: async (to: RouteLocationNormalized, from, next) => {
            await subscriptionTypeUseCase.getByOrganizationID(
                organizationUseCase.getOrganizationID(),
            );

            const props = getFirsLevelProps();
            // didn't find a better way
            to.params = {
                ...to.params,
                ...(props as unknown as RouteParams),
            };
            next();
        },
    },
    {
        path: "/subscription-types/add",
        name: PageType.SubscriptionTypesAdd,
        component: SubscriptionTypeAddView,
        props: getSecondLevelProps(),
        meta: {
            breadcrumbs: [
                {
                    name: PageName.SubscriptionTypes,
                    to: PageType.SubscriptionTypes,
                },
            ],
        },
    },
    {
        path: "/subscription-types/:id",
        name: PageType.SubscriptionTypesUpdate,
        component: SubscriptionTypeUpdateView,
        props: true,
        meta: {
            breadcrumbs: [
                {
                    name: PageName.SubscriptionTypes,
                    to: PageType.SubscriptionTypes,
                },
            ],
        },
        beforeEnter: async (to, from, next) => {
            const subscriptionType = await subscriptionTypeUseCase.getByID(
                StringToEntityID(to.params.id as string),
            );

            const props = getFirsLevelProps<Props>();
            props.subscriptionType = subscriptionType;

            // didn't find a better way
            to.params = {
                ...to.params,
                ...(props as unknown as RouteParams),
            };
            next();
        },
    },
];
