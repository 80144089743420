import { defineStore } from "pinia";
import {
    BaseEntityStorage,
    storeEntities,
    storeGetByID,
} from "@app/stores/converters/repo";
import { EntityFile } from "@app/entities/entityFile";
import {
    EntityFileID,
    EntityIDToString,
    OrganizationID,
    UUID,
} from "@app/entities/uuid";
import { Context } from "@app/entities/context";
import { FileType } from "@app/entities/fileType";
import { entityFileClient } from "@grpc/entityFileClient";

type StoreFields = BaseEntityStorage<EntityFile>;

interface EntityFileClient {
    loadByOrganizationID(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<EntityFile[]>;

    loadByID(ctx: Context, id: EntityFileID): Promise<EntityFile | undefined>;

    store(
        ctx: Context,
        entityID: UUID,
        entityType: FileType,
        data: string,
    ): Promise<EntityFile | undefined>;

    destroy(ctx: Context, id: EntityFileID): Promise<EntityFile | undefined>;
}

const client: EntityFileClient = entityFileClient;

export const useEntityFileRepo = defineStore("entityFile", {
    state: () =>
        ({
            data: [],
            byId: {},
            byPage: {},
            currentPageId: undefined,
            lastUpdate: new Date(),
        }) as StoreFields,
    actions: {
        sync(entities: EntityFile[]) {
            return storeEntities(this, entities);
        },

        async loadByOrganizationID(
            ctx: Context,
            organizationID: OrganizationID,
        ): Promise<EntityFile[]> {
            const data = await client.loadByOrganizationID(ctx, organizationID);
            this.sync(data);
            return data;
        },

        async loadByID(
            ctx: Context,
            id: EntityFileID,
        ): Promise<EntityFile | undefined> {
            const data = await client.loadByID(ctx, id);
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async store(
            ctx: Context,
            entityID: UUID,
            entityType: FileType,
            data: string,
        ): Promise<EntityFile | undefined> {
            const model = await client.store(ctx, entityID, entityType, data);
            if (model) {
                this.sync([model]);
            }
            return model;
        },

        async destroy(ctx: Context, id: EntityFileID): Promise<void> {
            await client.destroy(ctx, id);
            delete this.byId[EntityIDToString(id)];
            this.data = Object.values(this.byId);
            return;
        },
    },
    getters: {
        getByID: (store) => storeGetByID<EntityFile>(store),
        getByEntityID: (store) => (entityID: UUID) => {
            return store.data.filter(
                (entity) => entity.entityID.value === entityID.value,
            );
        },
        getOneByEntityType:
            (store) => (entityID: UUID, entityType: FileType) => {
                return store.data.find(
                    (entity) =>
                        entity.entityID.value === entityID.value &&
                        entity.type === entityType,
                );
            },
        getByEntityType: (store) => (entityID: UUID, entityType: FileType) => {
            return store.data.filter(
                (entity) =>
                    entity.entityID.value === entityID.value &&
                    entity.type === entityType,
            );
        },
    },
});
