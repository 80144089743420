import {
    DisciplineID,
    OrganizationID,
    SubscriptionID,
    SubscriptionTypeID,
} from "@app/entities/uuid";
import { Context, WithBackground } from "@app/entities/context";
import { storageRepo } from "@app/pkg/storageRepo";
import { SubscriptionType } from "@app/entities/subscriptionType";
import { useSubscriptionTypeRepo } from "@app/stores/subscriptionTypeRepo";
import { ErrSomethingWentWrong } from "@app/entities/errors";

export interface SubscriptionsTypeRepo {
    getByID(id: SubscriptionTypeID): SubscriptionType | undefined;

    loadByID(
        ctx: Context,
        id: SubscriptionTypeID,
    ): Promise<SubscriptionType | undefined>;

    loadByOrganizationID(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<SubscriptionType[]>;

    store(
        ctx: Context,
        organizationID: OrganizationID,
        name: string,
        description: string,
        days: number,
        price: number,
        classes: number,
        disciplineIDs: DisciplineID[],
    ): Promise<SubscriptionType | undefined>;

    update(
        ctx: Context,
        id: SubscriptionTypeID,
        name: string,
        description: string,
        days: number,
        price: number,
        classes: number,
        disciplineIDs: DisciplineID[],
    ): Promise<SubscriptionType | undefined>;

    destroy(
        ctx: Context,
        id: SubscriptionTypeID,
    ): Promise<SubscriptionType | undefined>;

    restore(
        ctx: Context,
        id: SubscriptionTypeID,
    ): Promise<SubscriptionType | undefined>;
}

const useRepo = (): SubscriptionsTypeRepo => {
    return useSubscriptionTypeRepo();
};

export interface SubscriptionTypeUseCase {
    getByID(id: SubscriptionID): Promise<SubscriptionType>;

    getByOrganizationID(
        organizationID: OrganizationID,
    ): Promise<SubscriptionType[]>;

    store(
        organizationID: OrganizationID,
        name: string,
        description: string,
        days: number,
        price: number,
        classes: number,
        disciplineIDs: DisciplineID[],
    ): Promise<SubscriptionType>;

    update(
        id: SubscriptionTypeID,
        name: string,
        description: string,
        days: number,
        price: number,
        classes: number,
        disciplineIDs: DisciplineID[],
    ): Promise<SubscriptionType>;

    destroy(id: SubscriptionTypeID): Promise<SubscriptionType>;

    restore(id: SubscriptionTypeID): Promise<SubscriptionType>;
}

export const subscriptionTypeUseCase: SubscriptionTypeUseCase = {
    async getByID(id: SubscriptionID): Promise<SubscriptionType> {
        const repo = useRepo();

        let model = repo.getByID(id);
        if (!model) {
            model = await repo.loadByID(
                storageRepo.withToken(WithBackground()),
                id,
            );
        }

        if (!model) {
            throw Error(`Subscription type '${id.value}' has not found`);
        } else {
            return model;
        }
    },

    async getByOrganizationID(
        organizationID: OrganizationID,
    ): Promise<SubscriptionType[]> {
        const repo = useRepo();
        return await repo.loadByOrganizationID(
            storageRepo.withToken(WithBackground()),
            organizationID,
        );
    },

    async store(
        organizationID: OrganizationID,
        name: string,
        description: string,
        days: number,
        price: number,
        classes: number,
        disciplineIDs: DisciplineID[],
    ): Promise<SubscriptionType> {
        const repo = useRepo();

        const model = await repo.store(
            storageRepo.withToken(),
            organizationID,
            name,
            description,
            days,
            price,
            classes,
            disciplineIDs,
        );

        if (!model) {
            throw ErrSomethingWentWrong;
        }

        return model;
    },

    async update(
        id: SubscriptionTypeID,
        name: string,
        description: string,
        days: number,
        price: number,
        classes: number,
        disciplineIDs: DisciplineID[],
    ): Promise<SubscriptionType> {
        const repo = useRepo();

        const model = await repo.update(
            storageRepo.withToken(),
            id,
            name,
            description,
            days,
            price,
            classes,
            disciplineIDs,
        );

        if (!model) {
            throw ErrSomethingWentWrong;
        }

        return model;
    },

    async destroy(id: SubscriptionTypeID): Promise<SubscriptionType> {
        const repo = useRepo();

        const model = await repo.destroy(storageRepo.withToken(), id);
        if (!model) {
            throw ErrSomethingWentWrong;
        }

        return model;
    },

    async restore(id: SubscriptionTypeID): Promise<SubscriptionType> {
        const repo = useRepo();

        const model = await repo.restore(storageRepo.withToken(), id);
        if (!model) {
            throw ErrSomethingWentWrong;
        }

        return model;
    },
};

export const useSubscriptionTypesViewData = (): {
    getByID(id: SubscriptionTypeID): SubscriptionType | undefined;
    getByIDs(ids: SubscriptionTypeID[]): SubscriptionType[];
    All: SubscriptionType[];
    AllActive: SubscriptionType[];
} => {
    return useSubscriptionTypeRepo();
};
