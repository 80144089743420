<template>
  <form-component :form="vueForm">
    <div class="columns-2xs">
      <form-field
        v-if="locationOptions.length > 1"
        :field="form.location_id"
        :label="t('Location')"
      >
        <select-box-field
          :field="form.location_id"
          :options="locationOptions"
        />
      </form-field>
      <form-field
        v-if="disciplineOptions.length > 1"
        :field="form.discipline_id"
        :label="t('Discipline')"
      >
        <select-box-field
          :field="form.discipline_id"
          :options="disciplineOptions"
        />
      </form-field>
      <form-field
        :field="form.trainer_id"
        :label="t('Trainer')"
      >
        <select-box-field
          :field="form.trainer_id"
          :options="trainerOptions"
        />
      </form-field>
      <form-field
        :field="form.students_count"
        :label="t('Students count')"
      >
        <text-input-field
          :field="form.students_count"
        />
      </form-field>
      <div class="break-inside-avoid-column">
        <div class="grid md:grid-cols-2 md:gap-1">
          <form-field
            :field="form.date_from"
            :label="t('Date from')"
          >
            <date-input-field
              :field="form.date_from"
            />
          </form-field>
          <form-field
            :field="form.date_to"
            :label="t('Date to')"
          >
            <date-input-field
              :field="form.date_to"
            />
          </form-field>
        </div>
      </div>
      <div class="break-inside-avoid-column">
        <form-field
          :field="form.day_of_week"
          :label="t('Day of the week')"
        >
          <select-box-field
            :field="form.day_of_week"
            :options="dayOfTheWeeksOptions"
          />
        </form-field>
        <div class="break-inside-avoid-column">
          <div class="grid md:grid-cols-2 md:gap-1">
            <form-field
              :field="form.time_from"
              :label="t('Time from')"
            >
              <text-input-field
                :field="form.time_from"
                type="time"
              />
            </form-field>
            <form-field
              :field="form.time_to"
              :label="t('Time to')"
            >
              <text-input-field
                :field="form.time_to"
                type="time"
              />
            </form-field>
          </div>
        </div>
      </div>
    </div>
    <submit-button />
  </form-component>
</template>

<script lang="ts" setup>
import { reactive } from "vue";
import { ValidationArgs } from "@vuelidate/core";
import { required } from "@app/services/validators";
import FormField from "@app/components/forms/fields/FormField.vue";
import SubmitButton from "@app/components/forms/fields/SubmitButton.vue";
import { appForm } from "@app/services/forms";
import SelectBoxField from "@app/components/forms/fields/SelectBoxField.vue";
import DateInputField from "@app/components/forms/fields/DateInputField.vue";
import TextInputField from "@app/components/forms/fields/TextInputField.vue";
import { formatDateISO, timeOfWeek } from "@app/services/format";
import FormComponent from "@app/components/FormComponent.vue";
import { Schedule } from "@app/entities/schedule";
import { scheduleUseCase } from "@app/usecase/scheduleUseCase";
import { StringToEntityID } from "@app/entities/uuid";
import { SelectOption } from "@app/views/models/form";
import { dayOfWeek, weekDaysOptions } from "@app/views/models/WeekDay";
import { DateTimeRange } from "@app/entities/dateTimeRange";
import { StringToDate, StringToTime } from "@app/views/converters/date";
import { useI18n } from "vue-i18n";

interface UpdateScheduleFormFields {
    location_id: string;
    trainer_id: string;
    discipline_id: string;
    students_count: number;
    date_from: string;
    date_to: string;
    /**
     * Count of seconds for each day
     */
    day_of_week: number;
    time_from: string;
    time_to: string;
}

const { t } = useI18n();
const props = defineProps<{
    schedule: Schedule;
    disciplineOptions: SelectOption<string>[];
    trainerOptions: SelectOption<string>[];
    locationOptions: SelectOption<string>[];
}>();

const dayOfTheWeeksOptions: SelectOption<number>[] = weekDaysOptions();

const emits = defineEmits<{
    (e: "on-success", value: Schedule): void;
}>();

const rules: ValidationArgs<UpdateScheduleFormFields> = {
    trainer_id: {
        required: required,
    },
    discipline_id: {
        required: required,
    },
    location_id: {
        required: required,
    },
    students_count: {
        required: required,
    },
    date_from: {},
    date_to: {},
    day_of_week: {
        required: required,
    },
    time_from: {
        required: required,
    },
    time_to: {
        required: required,
    },
};
const formFields = reactive<UpdateScheduleFormFields>({
    day_of_week: dayOfWeek(props.schedule.timeRange.left).from,
    time_from: timeOfWeek(props.schedule.timeRange.left),
    time_to: timeOfWeek(props.schedule.timeRange.right),
    date_from: props.schedule.dateRange.left
        ? formatDateISO(props.schedule.dateRange.left)
        : "",
    date_to: props.schedule.dateRange.right
        ? formatDateISO(props.schedule.dateRange.right)
        : "",
    location_id:
        props.locationOptions.length === 1
            ? (props.locationOptions[0].value as string)
            : props.schedule.locationID.value,
    students_count: props.schedule.studentsCount,
    discipline_id: props.schedule.disciplineID.value,
    trainer_id: props.schedule.trainerID.value,
});
const vueForm = appForm<UpdateScheduleFormFields, void>(
    rules,
    formFields,
    async () => {
        const dateRange: DateTimeRange = {};
        if (formFields.date_from) {
            dateRange.left = StringToDate(formFields.date_from);
        }
        if (formFields.date_to) {
            dateRange.right = StringToDate(formFields.date_to);
        }

        const model = await scheduleUseCase.update(
            props.schedule.id,
            StringToEntityID(formFields.discipline_id),
            StringToEntityID(formFields.location_id),
            StringToEntityID(formFields.trainer_id),
            formFields.students_count,
            dateRange,
            {
                left: StringToTime(
                    formFields.day_of_week,
                    formFields.time_from,
                ),
                right: StringToTime(formFields.day_of_week, formFields.time_to),
            },
        );
        emits("on-success", model);
    },
);
const form = vueForm.form;
</script>

<style scoped></style>
