import { Account } from "@app/entities/payment/account";
import { EntityIDToString } from "@app/entities/uuid";
import { SelectOption } from "@app/views/models/form";

export const paymentAccountsToOptions = (
    models: Account[],
): SelectOption<string>[] => {
    return models.map((model): SelectOption<string> => {
        return {
            value: EntityIDToString(model.id),
            displayValue: model.name,
        };
    });
};
