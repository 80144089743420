<template>
  <div>
    <message-block
      v-if="errorMessage && errorMessage.length"
      :message="errorMessage"
      template="block"
      type="error"
      class="mb-4"
      @on-close="onCloseError"
    />
    <slot />
  </div>
</template>

<script lang="ts" setup>
/**
 * @see https://flowbite.com/docs/components/alerts/
 */
import { computed, onErrorCaptured, ref } from "vue";
import MessageBlock from "@app/views/components/MessageBlock.vue";
import { useI18n } from "vue-i18n";
import { logError, translateError } from "@app/pkg/error/AppError";
import { useAuthViewData } from "@app/usecase/authUseCase";

const { t } = useI18n();
const props = defineProps<{
    message?: string;
}>();
const authView = useAuthViewData();

const calculatedMessage = ref<undefined | string>(undefined);

const errorMessage = computed<string | undefined>(() =>
    calculatedMessage.value === undefined
        ? props.message
            ? t(props.message)
            : ""
        : calculatedMessage.value,
);

const onCloseError = () => {
    calculatedMessage.value = "";
};

const errorCaptured = (err: Error): boolean => {
    calculatedMessage.value = translateError(err, t, authView.currentUser);
    logError(err);
    return false;
};
onErrorCaptured(errorCaptured);
</script>

<style scoped></style>
