<template>
  <page-component
    :title="
      t('{name} update', {
        name: name,
      })
    "
  >
    <organization-user-update-form
      :organization-user="oUser"
      @on-success="onSuccess"
    />
  </page-component>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import OrganizationUserUpdateForm from "@app/components/forms/OrganizationUserUpdateForm.vue";
import PageComponent from "@app/components/PageComponent.vue";
import { UserUpdateProps } from "@users/update/route";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();

const props = defineProps<UserUpdateProps>();
const name = props.oUser.user.name;
const onSuccess = () => {
    router.back();
};
</script>

<style scoped></style>
