
export const enum FileType {
    ORGANIZATION,
    ORGANIZATION_USER,
    ORGANIZATION_LOGO_WIDE,
    ORGANIZATION_LOGO_SQUARE,
    USER,
    WORKOUT,
    SCHEDULE,
    LOCATION,
    DISCIPLINE,
    SUBSCRIPTION,
    SUBSCRIPTION_TYPE,
    USER_WORKOUT,
}