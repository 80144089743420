import { Context } from "@app/entities/context";
import { OrganizationUser } from "@app/entities/organzationUser";
import { UserRole } from "@app/entities/userRole";
import { OrganizationID, OrganizationUserID } from "@app/entities/uuid";
import { ContextToPb } from "@grpc/converters/context";
import { PbToEntities } from "@grpc/converters/entities";
import { PbToOrganizationUser } from "@grpc/converters/organizationUser";
import { OrganizationUserRoleToPb } from "@grpc/converters/organizationUserRole";
import { EntityToUUID } from "@grpc/converters/uuid";
import { OrganizationUserServicePromiseClient } from "proto-api/auth/organization_user_service_grpc_web_pb";
import {
    GetEmployeesRequest,
    OrganizationUserDestroyRequestMessage,
    OrganizationUserGetByIDRequestMessage,
    OrganizationUserGetByOrganizationIDRequestMessage,
    OrganizationUserRestoreRequestMessage,
    OrganizationUserStoreRequestMessage,
    OrganizationUserUpdateRequestMessage,
} from "proto-api/auth/organization_user_service_pb";
import { App } from "vue";

let service: OrganizationUserServicePromiseClient;

type Config = {
    service: OrganizationUserServicePromiseClient;
};
export const initOUserClient = (app: App, options: Config) => {
    service = options.service;
};

export const useOUserClient = () => {
    return oUserClient;
};

const oUserClient = {
    loadByOrganizationID: async (
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<OrganizationUser[]> => {
        const request = new OrganizationUserGetByOrganizationIDRequestMessage();
        request.setOrganizationId(EntityToUUID(organizationID));

        const response = await service.getByOrganizationID(
            request,
            ContextToPb(ctx),
        );
        return PbToEntities(
            response.getOrganizationUsersList(),
            PbToOrganizationUser,
        );
    },

    loadEmployees: async (
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<OrganizationUser[]> => {
        const request = new GetEmployeesRequest();
        request.setOrganizationId(EntityToUUID(organizationID));

        const response = await service.getEmployees(request, ContextToPb(ctx));
        return PbToEntities(
            response.getOrganizationUsersList(),
            PbToOrganizationUser,
        );
    },

    loadByID: async (
        ctx: Context,
        id: OrganizationUserID,
    ): Promise<OrganizationUser | undefined> => {
        const request = new OrganizationUserGetByIDRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.getByID(request, ContextToPb(ctx));

        const message = response.getOrganizationUser();
        if (message) {
            return PbToOrganizationUser(message);
        }
        return;
    },

    store: async (
        ctx: Context,
        organizationID: OrganizationID,
        nickname: string,
        firstName: string,
        lastName: string,
        phone: string,
        email: string,
        password: string,
        role: UserRole,
    ): Promise<OrganizationUser | undefined> => {
        const request = new OrganizationUserStoreRequestMessage()
            .setNickname(nickname)
            .setOrganizationId(EntityToUUID(organizationID))
            .setFirstName(firstName)
            .setLastName(lastName)
            .setPhone(phone)
            .setEmail(email)
            .setPassword(password)
            .setRole(OrganizationUserRoleToPb(role));

        const response = await service.store(request, ContextToPb(ctx));

        const message = response.getOrganizationUser();
        if (message) {
            return PbToOrganizationUser(message);
        }
        return;
    },

    update: async (
        ctx: Context,
        id: OrganizationUserID,
        role: UserRole,
    ): Promise<OrganizationUser | undefined> => {
        const request = new OrganizationUserUpdateRequestMessage();
        request.setId(EntityToUUID(id));
        request.setRole(OrganizationUserRoleToPb(role));

        const response = await service.update(request, ContextToPb(ctx));

        const message = response.getOrganizationUser();
        if (message) {
            return PbToOrganizationUser(message);
        }
        return;
    },

    destroy: async (
        ctx: Context,
        id: OrganizationUserID,
    ): Promise<OrganizationUser | undefined> => {
        const request = new OrganizationUserDestroyRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.destroy(request, ContextToPb(ctx));

        const message = response.getOrganizationUser();
        if (message) {
            return PbToOrganizationUser(message);
        }
        return;
    },

    restore: async (
        ctx: Context,
        id: OrganizationUserID,
    ): Promise<OrganizationUser | undefined> => {
        const request = new OrganizationUserRestoreRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.restore(request, ContextToPb(ctx));

        const message = response.getOrganizationUser();
        if (message) {
            return PbToOrganizationUser(message);
        }
        return;
    },
};
