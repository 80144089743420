import { ScheduleServicePromiseClient } from "proto-api/organization/schedule_service_grpc_web_pb";
import {
    DisciplineID,
    LocationID,
    OrganizationID,
    OrganizationUserID,
    ScheduleID,
} from "@app/entities/uuid";
import { EntityToUUID } from "@grpc/converters/uuid";
import { ContextToPb } from "@grpc/converters/context";
import { Context } from "@app/entities/context";
import { PbToEntities } from "@grpc/converters/entities";
import { Schedule } from "@app/entities/schedule";
import {
    ScheduleDestroyRequestMessage,
    ScheduleGetByIDRequestMessage,
    ScheduleGetByOrganizationIDRequestMessage,
    ScheduleStoreRequestMessage,
    ScheduleUpdateRequestMessage,
} from "proto-api/organization/schedule_service_pb";
import { PbToSchedule } from "@grpc/converters/schedule";
import { DateTimeRange } from "@app/entities/dateTimeRange";
import { TimeRange } from "@app/entities/timeRange";
import { DateTimeRangeToPb } from "@grpc/converters/dateTimeRange";
import { TimeRangeToPb } from "@grpc/converters/timeRange";

//"https://grpc-local.fitfrog.ru"
const service = new ScheduleServicePromiseClient(
    `${import.meta.env.VITE_GRPC_BASE_URL}`,
    null,
);

export const scheduleClient = {
    loadByOrganizationID: async (
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<Schedule[]> => {
        const request = new ScheduleGetByOrganizationIDRequestMessage();
        request.setOrganizationId(EntityToUUID(organizationID));

        const response = await service.getByOrganizationID(
            request,
            ContextToPb(ctx),
        );
        return PbToEntities(response.getSchedulesList(), PbToSchedule);
    },

    loadByID: async (
        ctx: Context,
        id: ScheduleID,
    ): Promise<Schedule | undefined> => {
        const request = new ScheduleGetByIDRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.getByID(request, ContextToPb(ctx));

        const message = response.getSchedule();
        if (message) {
            return PbToSchedule(message);
        }
        return;
    },

    store: async (
        ctx: Context,
        organizationID: OrganizationID,
        disciplineID: DisciplineID,
        locationID: LocationID,
        trainerID: OrganizationUserID,
        studentsCount: number,
        dateRange: DateTimeRange,
        timeRange: TimeRange,
    ): Promise<Schedule | undefined> => {
        const request = new ScheduleStoreRequestMessage();
        request.setOrganizationId(EntityToUUID(organizationID));
        request.setDisciplineId(EntityToUUID(disciplineID));
        request.setLocationId(EntityToUUID(locationID));
        request.setTrainerId(EntityToUUID(trainerID));
        request.setStudentsCount(studentsCount);
        request.setDateRange(DateTimeRangeToPb(dateRange));
        request.setTimeRange(TimeRangeToPb(timeRange));

        const response = await service.store(request, ContextToPb(ctx));

        const message = response.getSchedule();
        if (message) {
            return PbToSchedule(message);
        }
        return;
    },

    update: async (
        ctx: Context,
        id: ScheduleID,
        disciplineID: DisciplineID,
        locationID: LocationID,
        trainerID: OrganizationUserID,
        studentsCount: number,
        dateRange: DateTimeRange,
        timeRange: TimeRange,
    ): Promise<Schedule | undefined> => {
        const request = new ScheduleUpdateRequestMessage();
        request.setId(EntityToUUID(id));
        request.setDisciplineId(EntityToUUID(disciplineID));
        request.setLocationId(EntityToUUID(locationID));
        request.setTrainerId(EntityToUUID(trainerID));
        request.setStudentsCount(studentsCount);
        request.setDateRange(DateTimeRangeToPb(dateRange));
        request.setTimeRange(TimeRangeToPb(timeRange));

        const response = await service.update(request, ContextToPb(ctx));

        const message = response.getSchedule();
        if (message) {
            return PbToSchedule(message);
        }
        return;
    },

    destroy: async (
        ctx: Context,
        id: ScheduleID,
    ): Promise<Schedule | undefined> => {
        const request = new ScheduleDestroyRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.destroy(request, ContextToPb(ctx));

        const message = response.getSchedule();
        if (message) {
            return PbToSchedule(message);
        }
        return;
    },
};
