import { RouteParams, RouteRecordRaw } from "vue-router";
import { PageType } from "@app/pkg/router/pageType";
import { AppBreadcrumbs } from "@app/router/models";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { StringToEntityID, WorkoutID } from "@app/entities/uuid";
import WorkoutUpdateView from "@app/views/popups/workout-update/WorkoutUpdateView.vue";
import { workoutsUseCase } from "@app/usecase/workoutsUseCase";

type Props = {
    workoutId: WorkoutID;
};

export const workoutUpdateRoute = (
    prefix: string,
    breadcrumbs: AppBreadcrumbs[],
): RouteRecordRaw => {
    return {
        path: "workout-update/:workoutId",
        name: prefix + PageType.WorkoutUpdate,
        meta: {
            breadcrumbs: breadcrumbs,
        },
        components: {
            popup: WorkoutUpdateView,
        },
        props: true,
        beforeEnter: async (to, from, next) => {
            const workoutID = StringToEntityID(to.params.workoutId as string);
            await workoutsUseCase.loadByID(workoutID);

            const props = getFirsLevelProps<Props>();
            props.workoutId = workoutID;

            // didn't find a better way
            to.params = {
                ...to.params,
                ...(props as unknown as RouteParams),
            };
            next();
        },
    };
};
