<template>
  <input
    v-model="fieldRef.$model"
    v-maska
    data-maska="+7 ### ###-##-##"
    data-maske-eager
    :name="field.$path"
    :type="type ? type : 'text'"
    :placeholder="placeholder"
    class="input input-bordered w-full"
    :autocomplete="autocomplete ? autocomplete : 'off'"
    :class="{
      'input-error text-red-500':
        field.$dirty && field.$silentErrors.length,
    }"
    @blur="field.$touch"
    @focusout="onFocusOut"
  >
</template>

<script lang="ts" setup>
/**
 * @see https://beholdr.github.io/maska/
 */
import { ref } from "vue";
import { BaseValidation } from "@vuelidate/core";
import { vMaska } from "maska/vue";

const props = defineProps<{
    field: BaseValidation<string | number>;
    placeholder?: string;
    type?: "text" | "number" | "time" | "email" | "password";
    autocomplete?: string;
}>();
const fieldRef = ref(props.field);
const r = new RegExp("[^+0-9]", "g");
const onFocusOut = () => {
    const v = String(fieldRef.value.$model).replace(r, "");
    if (v.length !== 12) {
        fieldRef.value.$model = "";
    }
};
</script>

<style scoped></style>
