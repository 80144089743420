import { Context, WithBackground } from "@app/entities/context";
import { DateTimeRange } from "@app/entities/dateTimeRange";
import {
    ErrSomethingWentWrong,
    ErrWorkoutNotFound,
} from "@app/entities/errors";
import { UserWorkout } from "@app/entities/userWorkout";
import {
    DisciplineID,
    LocationID,
    OrganizationID,
    ScheduleID,
    UserID,
    WorkoutID,
} from "@app/entities/uuid";
import { Workout } from "@app/entities/workout";
import { WorkoutStatus } from "@app/entities/workoutStatus";
import { storageRepo } from "@app/pkg/storageRepo";
import { useUserWorkoutRepo } from "@app/stores/userWorkoutRepo";
import { useWorkoutRepo } from "@app/stores/workoutRepo";

export interface WorkoutRepo {
    loadByOrganizationID(
        ctx: Context,
        id: OrganizationID,
        dateRange: DateTimeRange,
    ): Promise<Workout[]>;

    loadByID(ctx: Context, id: WorkoutID): Promise<Workout | undefined>;

    loadByIDs(ctx: Context, id: WorkoutID[]): Promise<Workout[]>;

    getByID(id: WorkoutID): Workout | undefined;

    sync(entities: Workout[]): void;

    store(
        ctx: Context,
        organizationID: OrganizationID,
        trainerID: UserID,
        disciplineID: DisciplineID,
        locationID: LocationID,
        studentsCount: number,
        timeFrom: Date,
        timeTo: Date,
        scheduleID?: ScheduleID,
    ): Promise<Workout | undefined>;

    update(
        ctx: Context,
        id: WorkoutID,
        trainerID: UserID,
        disciplineID: DisciplineID,
        locationID: LocationID,
        studentsCount: number,
        timeFrom: Date,
        timeTo: Date,
        scheduleID?: ScheduleID,
    ): Promise<Workout | undefined>;

    getByScheduleID(
        scheduleID: ScheduleID,
        dateFrom?: Date,
        dateTo?: Date,
    ): Workout[];

    cancel(
        ctx: Context,
        id: WorkoutID,
        keepBalance: boolean,
    ): Promise<
        | {
              workout: Workout;
              userWorkouts: UserWorkout[];
          }
        | undefined
    >;

    close(
        ctx: Context,
        id: WorkoutID,
    ): Promise<
        | {
              workout: Workout;
              userWorkouts: UserWorkout[];
          }
        | undefined
    >;
}

const useRepo = (): WorkoutRepo => {
    return useWorkoutRepo();
};

export const workoutsUseCase = {
    async loadByID(id: WorkoutID): Promise<Workout | undefined> {
        const store = useRepo();
        return await store.loadByID(
            storageRepo.withToken(WithBackground()),
            id,
        );
    },
    async loadByIDs(ids: WorkoutID[]): Promise<Workout[]> {
        const store = useRepo();
        return await store.loadByIDs(
            storageRepo.withToken(WithBackground()),
            ids,
        );
    },
    async getByID(id: WorkoutID): Promise<Workout | undefined> {
        const repo = useRepo();
        const workout = repo.getByID(id);
        if (workout) {
            return workout;
        }
        return this.loadByID(id);
    },

    async loadByOrganizationID(
        organizationID: OrganizationID,
        dateRange: DateTimeRange,
    ): Promise<Workout[]> {
        const store = useRepo();
        return await store.loadByOrganizationID(
            storageRepo.withToken(WithBackground()),
            organizationID,
            dateRange,
        );
    },

    async store(
        organizationID: OrganizationID,
        trainerID: UserID,
        disciplineID: DisciplineID,
        locationID: LocationID,
        studentsCount: number,
        timeFrom: Date,
        timeTo: Date,
        scheduleID?: ScheduleID,
    ): Promise<Workout> {
        const store = useRepo();
        const model = await store.store(
            storageRepo.withToken(WithBackground()),
            organizationID,
            trainerID,
            disciplineID,
            locationID,
            studentsCount,
            timeFrom,
            timeTo,
            scheduleID,
        );
        if (!model) {
            throw ErrWorkoutNotFound;
        }
        return model;
    },

    async update(
        id: WorkoutID,
        trainerID: UserID,
        disciplineID: DisciplineID,
        locationID: LocationID,
        studentsCount: number,
        timeFrom: Date,
        timeTo: Date,
        scheduleID?: ScheduleID,
    ): Promise<Workout> {
        const store = useRepo();
        const model = await store.update(
            storageRepo.withToken(WithBackground()),
            id,
            trainerID,
            disciplineID,
            locationID,
            studentsCount,
            timeFrom,
            timeTo,
            scheduleID,
        );
        if (!model) {
            throw ErrWorkoutNotFound;
        }
        return model;
    },

    async close(id: WorkoutID): Promise<{
        workout: Workout;
        userWorkouts: UserWorkout[];
    }> {
        const store = useRepo();
        const userWorkouts = useUserWorkoutRepo();
        const data = await store.close(
            storageRepo.withToken(WithBackground()),
            id,
        );
        if (!data) {
            throw ErrSomethingWentWrong;
        }
        userWorkouts.sync(data.userWorkouts);
        return data;
    },

    async cancel(
        id: WorkoutID,
        keepBalance: boolean,
    ): Promise<{
        workout: Workout;
        userWorkouts: UserWorkout[];
    }> {
        const store = useRepo();
        const userWorkouts = useUserWorkoutRepo();
        const data = await store.cancel(
            storageRepo.withToken(WithBackground()),
            id,
            keepBalance,
        );
        if (!data) {
            throw ErrSomethingWentWrong;
        }
        userWorkouts.sync(data.userWorkouts);
        return data;
    },
};

export const useWorkoutViewData = (): {
    All: Workout[];
    getByID(id: WorkoutID): Workout | undefined;
    getByDate(
        dateFrom: Date,
        dateTo: Date,
        locationId: LocationID | undefined,
        status: WorkoutStatus | undefined,
    ): Workout[];
    getByScheduleID(
        scheduleID: ScheduleID,
        dateFrom?: Date,
        dateTo?: Date,
    ): Workout[];
} => {
    return useWorkoutRepo();
};
