<template>
  <form
    action="#"
    class="space-y-4"
    @submit="onSubmit"
  >
    <preloader-component
      :display="DisplayType.block"
      :is-loading="
        isLoading || routerLoadingState === RouterLoadingState.pageLoad
      "
      :type="PreloaderType.inline"
    >
      <message-block
        :message="errorMsg ? t(errorMsg) : ''"
        template="block"
        type="error"
        @on-close="errorMsg = ''"
      />
      <slot />
    </preloader-component>
  </form>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { AppForm } from "@app/services/forms";
import PreloaderComponent from "@app/components/PreloaderComponent.vue";
import { RouterLoadingState, routerLoadingState } from "@app/router";
import { DisplayType } from "@app/views/models";
import { PreloaderType } from "@app/views/entities/preloaderType";
import MessageBlock from "@app/views/components/MessageBlock.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const errorMsg = ref("");

const props = defineProps<{
    form: AppForm<unknown, unknown>;
}>();

const isLoading = ref<boolean>(false);

const onSubmit = async (ev: Event): Promise<unknown> => {
    ev.preventDefault();
    return props.form.form.value.$validate().then(async (value) => {
        if (value) {
            isLoading.value = true;
            return props.form.onSubmit().finally(() => {
                isLoading.value = false;
            });
        } else {
            console.log(props.form.form.value.$errors);
            errorMsg.value = "Not the all fields are correctly filled";
        }
    });
};
</script>

<style scoped></style>
