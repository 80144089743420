import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { paymentUseCase } from "@app/usecase/paymentUseCase";
import {
    RouteLocationNormalizedLoaded,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";
import { PageType } from "@app/pkg/router/pageType";
import { AppBreadcrumbs } from "@app/router/models";
import { getFirsLevelProps } from "@app/pkg/router/props";
import {
    EntityIDToString,
    OrganizationUserID,
    StringToEntityID,
    UserWorkoutID,
    WorkoutID,
} from "@app/entities/uuid";
import SubscriptionAddView from "@app/views/popups/subscription-add/SubscriptionAddView.vue";
import { Link, LinkIcons } from "@app/views/models/links";
import { pupUpRoute } from "@app/router/popups";
import { authUseCase, ENTITIES_LIST } from "@app/usecase/authUseCase";
import { userWorkoutsUseCase } from "@app/usecase/userWorkoutUseCase";
import { ErrSomethingWentWrong } from "@app/entities/errors";
import { organizationUserUseCase } from "@app/usecase/organizationUserUseCase";
import { workoutsUseCase } from "@app/usecase/workoutsUseCase";

export type SubscriptionAddProps = {
    organizationUserId: OrganizationUserID;
    workoutId?: WorkoutID;
    userWorkoutId?: UserWorkoutID;
};

export const getSubscriptionAddLink = (
    route: RouteLocationNormalizedLoaded,
    text: string,
    userID: OrganizationUserID,
): Link => {
    return {
        image: LinkIcons.add,
        text: text,
        to: pupUpRoute(
            route,
            PageType.UserSubscriptionAdd,
            {},
            {
                organizationUserId: EntityIDToString(userID),
            },
        ),
        disabled: !authUseCase.canEdit(ENTITIES_LIST.SUBSCRIPTION),
    };
};

export const subscriptionAddRoute = (
    prefix: string,
    breadcrumbs: AppBreadcrumbs[],
): RouteRecordRaw => {
    return {
        path: "subscription-add/",
        name: prefix + PageType.UserSubscriptionAdd,
        meta: {
            breadcrumbs: breadcrumbs,
        },
        components: {
            popup: SubscriptionAddView,
        },
        props: true,
        beforeEnter: async (to, from, next) => {
            const props = getFirsLevelProps<SubscriptionAddProps>();
            await paymentUseCase.loadAccounts(
                organizationUseCase.getOrganizationID(),
            );
            if (to.query.organizationUserId) {
                props.organizationUserId = StringToEntityID(
                    to.query.organizationUserId as string,
                );

                const oUser = await organizationUserUseCase.getByID(
                    props.organizationUserId,
                );
                if (!oUser) {
                    throw ErrSomethingWentWrong;
                }
            }
            if (to.query.userWorkoutId) {
                const userWorkoutID = StringToEntityID(
                    to.query.userWorkoutId as string,
                );
                const userWorkout =
                    await userWorkoutsUseCase.getByID(userWorkoutID);
                if (!userWorkout) {
                    throw ErrSomethingWentWrong;
                }
                const workout = await workoutsUseCase.loadByID(
                    userWorkout.workoutID,
                );
                if (!workout) {
                    throw ErrSomethingWentWrong;
                }

                props.workoutId = workout.id;
                props.userWorkoutId = userWorkout.id;
                props.organizationUserId = userWorkout.organizationUserID;
            }

            // didn't find a better way
            to.params = {
                ...to.params,
                ...(props as unknown as RouteParams),
            };
            next();
        },
    };
};
