<template>
  <template v-if="props.isLoading || preloaderErrorMessage">
    <progress
      v-if="props.isLoading && type === PreloaderType.progress"
      :class="{
        'block w-20': display === DisplayType.inlineBlock,
        block: display === DisplayType.block,
        'progress-primary': color === DisplayColor.primary,
        'progress-secondary': color === DisplayColor.secondary,
        'progress-success': color === DisplayColor.success,
        'progress-error': color === DisplayColor.error,
      }"
      class="progress clear-both border-primary-content/20"
    />
    <div
      v-else
      :class="{
        'fixed h-screen bg-white z-50 overflow-hidden':
          type === PreloaderType.popup,
      }"
      class="top-0 left-0 right-0 bottom-0 p-10 w-full flex flex-col items-center justify-center"
    >
      <template v-if="!preloaderErrorMessage">
        <div
          class="loader ease-linear rounded-full border-4 h-12 w-12 mb-4"
        />
        <h2 class="text-center text-xl font-semibold">
          {{ title }}
        </h2>
        <p class="text-center">
          {{ description }}
        </p>
      </template>
      <template v-else>
        <svg
          class="h-12 w-12 text-error"
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h2 class="text-center text-xl font-semibold text-error">
          {{ t("Error") }}
        </h2>
        <div class="text-center text-error">
          {{ preloaderErrorMessage }}
        </div>
      </template>
    </div>
  </template>
  <template v-else>
    <slot />
  </template>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { PreloaderType } from "@app/views/entities/preloaderType";
import { DisplayColor, DisplayType } from "@app/views/models";

interface Props {
    isLoading?: boolean;
    errorMessage?: string;
    type?: PreloaderType;
    display?: DisplayType;
    color?: DisplayColor;
}

const props = withDefaults(defineProps<Props>(), {
    isLoading: false,
    errorMessage: "",
    type: PreloaderType.popup,
    display: DisplayType.block,
    color: DisplayColor.primary,
});
const { t } = useI18n();

// const isLoading = computed(
//     () => props.isLoading || props.errorMessage.length > 0,
// );
const preloaderErrorMessage = computed<string | null>(() =>
    props.errorMessage && props.errorMessage.length ? props.errorMessage : null,
);
const description = computed(() => {
    if (preloaderErrorMessage.value) {
        return preloaderErrorMessage.value;
    }
    return t("This may take a few seconds please dont close this page");
});
const title = computed(() => {
    if (preloaderErrorMessage.value) {
        return t("Error");
    }
    return t("Loading");
});
</script>

<style scoped>
.loader {
    border-top-color: #3498db;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
