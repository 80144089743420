import { ComposerDateTimeFormatting } from "vue-i18n";
import {
    DATETIME_DAY_AND_MONTH,
    DATETIME_ONLY_DAY,
    DATETIME_WEEKDAY,
    DATETIME_YEAR_MONTH_DAY,
} from "@app/i18n/i18n";

const intl = Intl.DateTimeFormat("en-US", { dateStyle: "long" });
const intlISO = Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
});
const intlWeekShort = Intl.DateTimeFormat("en-US", { weekday: "short" });

export const formatWeekShort = (date: Date) => {
    return intlWeekShort.format(date);
};
export const formatDate = (date?: Date): string => {
    return intl.format(date);
};

// https://medium.com/@Saf_Bes/get-current-date-in-format-yyyy-mm-dd-with-javascript-9c898d1d5b22
export const formatDateISO = (date?: Date): string => {
    return intlISO.format(date);
};
export const parseDateISO = (dateString: string): Date | undefined => {
    const dateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
    const match = dateRegex.exec(dateString);
    if (!match) {
        return undefined;
    }
    const year = parseInt(match[1], 10);
    const month = parseInt(match[2], 10) - 1; // subtract 1 because month is zero-indexed
    const day = parseInt(match[3], 10);
    return new Date(year, month, day, 0, 0, 0, 0);
};

export const timeOfWeek = (time: number) => {
    time = time % 86400;
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    return (
        (hours < 10 ? "0" : "") +
        hours +
        ":" +
        (minutes < 10 ? "0" : "") +
        minutes
    );
};

export const addWeekTime = (date: Date, time: number) => {
    time = time % 86400;
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    date.setHours(hours, minutes, 0, 0);
    return date;
};

export const timeOfDate = (date: Date) => {
    return timeOfWeek(
        date.getTime() / 1000 + date.getTimezoneOffset() * 60 * -1,
    );
};

export const onlyDay = (f: ComposerDateTimeFormatting, d: Date): string => {
    return f(d, DATETIME_ONLY_DAY);
};

export const onlyDayMonth = (
    f: ComposerDateTimeFormatting,
    d: Date,
): string => {
    return f(d, DATETIME_DAY_AND_MONTH);
};

export const fullDate = (f: ComposerDateTimeFormatting, d: Date): string => {
    return f(
        d,
        d.getFullYear() != new Date().getFullYear()
            ? DATETIME_YEAR_MONTH_DAY
            : DATETIME_DAY_AND_MONTH,
    );
};

export const weekDay = (f: ComposerDateTimeFormatting, d: Date): string => {
    return f(d, DATETIME_WEEKDAY);
};
