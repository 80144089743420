import { useRegisterSW } from "virtual:pwa-register/vue";
import { personalClient } from "@grpc/personalClient";
import { storageRepo } from "@app/pkg/storageRepo";
import { WithBackground } from "@app/entities/context";
import { EventChannel } from "@app/entities/eventChannel";

export const ServiceWorker = {
    init: async () => {
        const { updateServiceWorker } = useRegisterSW();
        await updateServiceWorker();
    },
    subscribe: async (eventChannels: EventChannel[]) => {
        // Use serviceWorker.ready to ensure that you can subscribe for push
        navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
            const urlBase64ToUint8Array = (base64String: string) => {
                const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
                const base64 = (base64String + padding)
                    .replace(/-/g, "+")
                    .replace(/_/g, "/");

                const rawData = window.atob(base64);
                const outputArray = new Uint8Array(rawData.length);

                for (let i = 0; i < rawData.length; ++i) {
                    outputArray[i] = rawData.charCodeAt(i);
                }
                return outputArray;
            };

            const options = {
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(
                    "BGS6E-WDhQcBE6rK0F8UfB_45r5Bz6uA9fQB4D0-zG83OLbmb5Hxk7aX1bWs76XxMFl1BrBe5L5SAEal9DlL-iE",
                ),
            };
            // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/pushManager
            // WebView
            if (serviceWorkerRegistration.pushManager) {
                serviceWorkerRegistration.pushManager.getSubscription().then(
                    async (pushSubscription) => {
                        if (pushSubscription) {
                            await ServiceWorker.pushSubscriptionSend(
                                eventChannels,
                                pushSubscription,
                            );
                        } else {
                            serviceWorkerRegistration.pushManager
                                .subscribe(options)
                                .then(
                                    async (pushSubscription) => {
                                        await ServiceWorker.pushSubscriptionSend(
                                            eventChannels,
                                            pushSubscription,
                                        );
                                        // The push subscription details needed by the application
                                        // server are now available, and can be sent to it using,
                                        // for example, an XMLHttpRequest.
                                    },
                                    (error) => {
                                        // During development it often helps to log errors to the
                                        // console. In a production environment it might make sense to
                                        // also report information about errors back to the
                                        // application server.
                                        console.log(error);
                                    },
                                );
                        }
                    },
                    (err) => {
                        console.log(err);
                    },
                );
            }
        });
    },
    pushSubscriptionSend: async (
        eventChannels: EventChannel[],
        pushSubscription: PushSubscription,
    ) => {
        const keysValues = pushSubscription.toJSON();
        let authKey = "";
        if (keysValues.keys && keysValues.keys.auth) {
            authKey = keysValues.keys.auth;
        }
        let p256dhKey = "";
        if (keysValues.keys && keysValues.keys.p256dh) {
            p256dhKey = keysValues.keys.p256dh;
        }

        let alreadySubscribed = false;
        const uniqueId = p256dhKey + authKey;
        eventChannels.every((value) => {
            if (value.entityID === uniqueId) {
                alreadySubscribed = true;
                return false;
            } else {
                return true;
            }
        });

        if (!alreadySubscribed) {
            await personalClient.createBrowserEventChannel(
                storageRepo.withToken(WithBackground()),
                pushSubscription.expirationTime
                    ? new Date(pushSubscription.expirationTime)
                    : undefined,
                pushSubscription.endpoint,
                authKey,
                p256dhKey,
            );
        }
    },
};
