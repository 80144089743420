import { EntityIDToString, UUID } from "@app/entities/uuid";

export interface BaseEntityStorage<T> {
    data: T[];
    byId: Record<string, T>;
}

export const storeGetByID = <M>(store: BaseEntityStorage<M>) => {
    return (id: UUID | undefined): M | undefined =>
        id ? store.byId[id.value] : undefined;
};

export const storeGetMissedIDs = <M, T extends UUID>(
    store: BaseEntityStorage<M>,
) => {
    return (ids: T[]): T[] => {
        const data: T[] = [];
        ids.forEach((id) => {
            if (!store.byId[EntityIDToString(id)]) {
                data.push(id);
            }
        });
        return data;
    };
};

interface GetAllProps {
    deletedAt?: Date;
    createdAt: Date;
}

export const storeGetAll = <M extends GetAllProps>(
    store: BaseEntityStorage<M>,
) => {
    return store.data.toSorted((a, b) =>
        a.deletedAt ? 1 : b.deletedAt ? -1 : a.createdAt > b.createdAt ? 1 : -1,
    );
};

interface GetAllActiveProps {
    deletedAt?: Date;
    createdAt: Date;
}

export const storeGetAllActive = <M extends GetAllActiveProps>(
    store: BaseEntityStorage<M>,
) => {
    return store.data
        .filter((model) => !model.deletedAt)
        .sort((a, b) => (a.deletedAt ? 1 : a.createdAt > b.createdAt ? 1 : -1));
};

export const storeGetByIDs = <M>(store: BaseEntityStorage<M>) => {
    return (ids: UUID[]): M[] => {
        const result: M[] = [];
        ids.forEach((id) => {
            const val = store.byId[id.value];
            if (val) {
                result.push(val);
            }
        });
        return result;
    };
};

export type EntityWithID = {
    id: UUID;
};

export type EntityWithCreatedAt = {
    createdAt: Date;
};

export const storeEntities = <T extends EntityWithID>(
    store: BaseEntityStorage<T>,
    entities: T[],
    sortFn?: (a: T, b: T) => number,
) => {
    for (let i = 0; i < entities.length; i++) {
        if (store.byId[entities[i].id.value]) {
            store.byId[entities[i].id.value] = {
                ...entities[i],
            };
        } else {
            store.byId[entities[i].id.value] = entities[i];
        }
    }
    const data = Object.values(store.byId);
    if (sortFn) {
        data.sort(sortFn);
    }
    store.data = data;
};

export const sortByCreatedAt = <T extends EntityWithCreatedAt>(
    store: BaseEntityStorage<T>,
) => {
    store.data.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
};
