import { AccountType } from "@app/entities/payment/accountType";
import { AccountType as AccountTypePb } from "proto-api/payment/account_type_pb";

export const PbToAccountType = (resource: AccountTypePb): AccountType => {
    return resource.valueOf();
};

export const AccountTypeToPb = (resource: AccountType): AccountTypePb => {
    return resource.valueOf();
};
