import {
    NavigationGuardWithThis,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";
import { PageType } from "@app/pkg/router/pageType";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { useAuthViewData } from "@app/usecase/authUseCase";
import PersonalView from "@pages/personal/index/PersonalView.vue";
import { OrganizationUser } from "@app/entities/organzationUser";

export type PersonalIndexProps = {
    user: OrganizationUser;
};

export const PersonalIndexBeforeEnter: NavigationGuardWithThis<undefined> = (
    to,
    from,
    next,
) => {
    const auth = useAuthViewData();

    const props = getFirsLevelProps<PersonalIndexProps>();
    props.user = auth.currentUser;

    // didn't find a better way
    to.params = {
        ...to.params,
        ...(props as unknown as RouteParams),
    };

    next();
};

export const personalIndexRoute: RouteRecordRaw = {
    path: "/personal",
    name: PageType.Personal,
    component: PersonalView,
    props: true,
    beforeEnter: PersonalIndexBeforeEnter,
};
