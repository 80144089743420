import { SelectOption } from "@app/views/models/form";

export interface WeekDay {
    // js day id
    id: number;
    name: string;
    from: number;
    to: number;
}

export const WeekDays: WeekDay[] = [
    { id: 1, name: "Monday", from: 0, to: 86400 },
    { id: 2, name: "Tuesday", from: 86400, to: 86400 * 2 },
    { id: 3, name: "Wednesday", from: 86400 * 2, to: 86400 * 3 },
    { id: 4, name: "Thursday", from: 86400 * 3, to: 86400 * 4 },
    { id: 5, name: "Friday", from: 86400 * 4, to: 86400 * 5 },
    { id: 6, name: "Saturday", from: 86400 * 5, to: 86400 * 6 },
    { id: 0, name: "Sunday", from: 86400 * 6, to: 86400 * 7 },
];

export const weekDaysOptions = (): SelectOption<number>[] => {
    return WeekDays.map((day) => ({
        value: day.from,
        displayValue: day.name,
    }));
};

export const dayOfWeek = (time: number): WeekDay => {
    const dayFrom = Math.floor(time / 86400) * 86400;
    let day = WeekDays.find((value) => value.from === dayFrom);
    if (!day) {
        day = WeekDays[0];
    }
    return day;
};

export const dayOfWeekById = (id: number): WeekDay => {
    let day = WeekDays.find((value) => value.id === id);
    if (!day) {
        day = WeekDays[0];
    }
    return day;
};
