<template>
  <div
    v-if="message.length > 0"
    ref="target"
    class="flex items-center justify-between py-4 px-4"
    :class="{
      rounded: template === 'rounded',
      'bg-red-500 text-white': type == 'error',
      'bg-green-500 text-white': type == 'success',
    }"
  >
    <span class="js-error-summary-field">{{ message }}</span>
    <span
      class="js-error-field-close w-8 h-8 flex items-center justify-center rounded-full transition-colors cursor-pointer hover:bg-[rgba(0,0,0,0.2)]"
      @click="onClose"
    >
      <svg
        class="h-6 w-6"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 18L18 6M6 6l12 12"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useElementVisibility } from "@vueuse/core";

type Template = "rounded" | "block";
type Type = "error" | "success";
type Props = {
    message: string;
    template: Template;
    type: Type;
};

const emits = defineEmits<{
    (e: "on-close", value: void): void;
}>();
const props = defineProps<Props>();

const target = ref<HTMLDivElement | null>(null);
const targetIsVisible = useElementVisibility(target);

watch(props, (p) => {
    scrollTo(p.message);
});
const scrollTo = (m: string) => {
    setTimeout(() => {
        if (m.length) {
            // it's necessary to do this in other stream,
            // because in other case we wouldn't be able to see the error in some cases
            const targetElement = target.value;
            if (targetElement && !targetIsVisible.value) {
                targetElement.scrollIntoView({
                    behavior: "smooth",
                });
            }
        }
    }, 100);
};
scrollTo(props.message);
const onClose = () => {
    emits("on-close");
};
</script>

<style scoped></style>
