<template>
  <page-component
    :links="actionLinks"
    :title="t('Disciplines')"
  >
    <div class="grid grid-cols-1 gap-3">
      <discipline-component
        v-for="discipline in disciplines"
        :key="EntityIDToString(discipline.id)"
        :discipline="discipline"
        :links="links(discipline)"
        template="data-item"
      />
    </div>
  </page-component>
</template>

<script lang="ts" setup>
import PageComponent from "@app/components/PageComponent.vue";
import { Link, LinkIcons } from "@app/views/models/links";
import { useI18n } from "vue-i18n";
import { computed, ComputedRef } from "vue";
import { EntityIDToString } from "@app/entities/uuid";
import {
    disciplineUseCase,
    useDisciplineViewData,
} from "@app/usecase/disciplineUseCase";
import {
    authUseCase,
    ENTITIES_LIST,
    useAuthViewData,
} from "@app/usecase/authUseCase";
import DisciplineComponent from "@app/views/entityComponents/DisciplineComponent.vue";
import { Discipline } from "@app/entities/discipline";

const { t } = useI18n();

const viewData = useDisciplineViewData();
const authViewData = useAuthViewData();

const disciplines = computed(() =>
    authViewData.isManager ? viewData.All : viewData.AllActive,
);

const links = (model: Discipline): Link[] => [
    {
        image: LinkIcons.pencilSquare,
        text: t("Edit"),
        to: {
            name: "DisciplineUpdate",
            params: {
                id: model.id.value,
            },
        },
        disabled:
            !!model.deletedAt ||
            !authUseCase.canEdit(ENTITIES_LIST.DISCIPLINES),
    },
    {
        image: LinkIcons.error,
        text: t("Delete"),
        actionConfirm: t(`Are you sure you want to delete '{name}'?`, {
            name: model.name,
        }),
        action: async () => {
            await disciplineUseCase.destroy(model.id);
        },
        disabled:
            !!model.deletedAt ||
            !authUseCase.canEdit(ENTITIES_LIST.DISCIPLINES),
    },
    {
        image: LinkIcons.add,
        text: t("Restore"),
        actionConfirm: t(`Are you sure you want to restore '{name}'?`, {
            name: model.name,
        }),
        action: async () => {
            await disciplineUseCase.restore(model.id);
        },
        disabled:
            !model.deletedAt || !authUseCase.canEdit(ENTITIES_LIST.DISCIPLINES),
    },
];

const actionLinks: ComputedRef<Link[]> = computed(() => [
    {
        text: t("Add new discipline"),
        image: LinkIcons.add,
        to: {
            name: "DisciplineAdd",
        },
        disabled: !authUseCase.canEdit(ENTITIES_LIST.DISCIPLINES),
    },
]);
</script>

<style scoped></style>
