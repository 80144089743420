<template>
  <input
    v-model="fieldRef.$model"
    :name="field.$path"
    :type="type ? type : 'text'"
    :placeholder="placeholder"
    class="input input-bordered w-full"
    :autocomplete="autocomplete ? autocomplete : 'off'"
    :class="{
      'input-error text-red-500':
        field.$dirty && field.$silentErrors.length,
    }"
    @blur="field.$touch"
  >
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { BaseValidation } from "@vuelidate/core";

const props = defineProps<{
    field: BaseValidation<string | number>;
    placeholder?: string;
    type?: "text" | "number" | "time" | "email" | "password";
    autocomplete?: string;
}>();
const fieldRef = ref(props.field);
</script>

<style scoped></style>
