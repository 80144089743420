import { Organization } from "@app/entities/organzation";
import { PageType } from "@app/pkg/router/pageType";
import { getRoutes } from "@app/router/routes";
import { authUseCase, useAuthViewData } from "@app/usecase/authUseCase";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { organizationUserUseCase } from "@app/usecase/organizationUserUseCase";
import { ref } from "vue";
import {
    createMemoryHistory,
    createRouter,
    RouteRecordRaw,
    RouterHistory,
} from "vue-router";

export type RouteProps = {
    organization?: Organization;
};

let synced = false;

export const createAppRouter = (
    history: RouterHistory,
    r: RouteRecordRaw[],
    watch: boolean,
) => {
    const router = createRouter({
        history: history,
        routes: r,
    });

    if (watch) {
        router.beforeEach(async (to, from, next) => {
            const authView = useAuthViewData();
            let promises: Promise<unknown>[] = [];
            if (!synced) {
                promises.push(
                    authUseCase.sync(),
                    organizationUseCase
                        .info(location.hostname)
                        .then(async (organization) => {
                            await organizationUserUseCase.loadEmployees(
                                organization.id,
                            );
                        }),
                );
            }
            return await Promise.all(promises).then(() => {
                synced = true;
                promises = [];

                if (from.matched.length === 0 || to.matched.length === 0) {
                    routerLoadingState.value = RouterLoadingState.init;
                } else if (
                    to.matched.length > 0 &&
                    to.matched[to.matched.length - 1]?.components?.popup
                ) {
                    routerLoadingState.value = RouterLoadingState.popupLoad;
                } else {
                    routerLoadingState.value = RouterLoadingState.pageLoad;
                }

                if (
                    (to.meta.requiresAuth || !to.meta.isGuest) &&
                    !authView.isAuth
                ) {
                    next({ name: PageType.Login });
                } else if (authView.isAuth && to.meta.isGuest) {
                    next({ name: PageType.Workouts });
                } else {
                    next();
                }
            });
        });

        router.afterEach(() => {
            routerLoadingState.value = RouterLoadingState.loaded;
        });
    }

    return router;
};

export const createAppTestRouter = (
    r?: RouteRecordRaw[],
    watch?: boolean,
    sync?: boolean,
) => {
    synced = sync === true;

    if (!r) {
        r = getRoutes();
    }

    return createAppRouter(createMemoryHistory(), r, watch === true);
};

export const enum RouterLoadingState {
    init,
    pageLoad,
    popupLoad,
    loaded,
}

export const routerLoadingState = ref<RouterLoadingState>(
    RouterLoadingState.init,
);
