import { Context } from "@app/entities/context";
import { EventChannel } from "@app/entities/eventChannel";
import { isAdmin, isEmployee, isManager, isOwner, OrganizationUser } from "@app/entities/organzationUser";
import { User } from "@app/entities/user";
import { UserWorkout } from "@app/entities/userWorkout";
import {
    EntityIDToString,
    OrganizationID,
    OrganizationUserID,
    UserID,
} from "@app/entities/uuid";
import { defaultLocale, LocaleCode } from "@app/i18n/i18n";
import { useAuthClient } from "@grpc/authClient";
import { defineStore } from "pinia";

export interface AuthResponse {
    user: User;
    organizationUser: OrganizationUser;
    token: string;
}

export interface AuthClient {
    register(
        ctx: Context,
        organizationID: OrganizationID,
        nickname: string,
        firstName: string,
        lastName: string,
        phone: string,
        email: string,
        password: string,
    ): Promise<AuthResponse>;

    info(ctx: Context): Promise<AuthResponse>;

    loginByEmail(
        ctx: Context,
        organizationID: OrganizationID,
        email: string,
        password: string,
    ): Promise<AuthResponse>;

    loginByPhone(
        ctx: Context,
        organizationID: OrganizationID,
        phone: string,
        password: string,
    ): Promise<AuthResponse>;

    update(
        ctx: Context,
        id: UserID,
        nickname: string,
        firstName: string,
        lastName: string,
        phone: string,
        email: string,
    ): Promise<void>;

    adminLogin(ctx: Context, userID: OrganizationUserID): Promise<AuthResponse>;

    forgotPassword(
        ctx: Context,
        organizationID: OrganizationID,
        email: string,
    ): Promise<void>;

    resetPassword(
        ctx: Context,
        organizationID: OrganizationID,
        email: string,
        newPassword: string,
        token: string,
    ): Promise<void>;
}

const client: AuthClient = useAuthClient();
export const useAuthRepo = defineStore("auth", {
    state: () => ({
        user: undefined as User | undefined,
        oUser: undefined as OrganizationUser | undefined,
        eventChannels: [] as EventChannel[],
        notifications: [] as string[],
        locale: defaultLocale as LocaleCode,
    }),
    actions: {
        sync(user: OrganizationUser) {
            this.user = user.user;
            this.oUser = user;
        },
        syncEventChannels(channels: EventChannel[]) {
            this.eventChannels = channels;
        },
        sendNotification(message: string) {
            this.notifications.push(message);
        },
        logout() {
            this.$reset();
        },

        async info(ctx: Context) {
            const data = await client.info(ctx);
            this.sync(data.organizationUser);
            return data;
        },

        async register(
            ctx: Context,
            organizationID: OrganizationID,
            nickname: string,
            firstName: string,
            lastName: string,
            phone: string,
            email: string,
            password: string,
        ): Promise<AuthResponse> {
            const data = await client.register(
                ctx,
                organizationID,
                nickname,
                firstName,
                lastName,
                phone,
                email,
                password,
            );
            this.$reset();
            this.sync(data.organizationUser);
            return data;
        },

        async loginByEmail(
            ctx: Context,
            organizationID: OrganizationID,
            email: string,
            password: string,
        ): Promise<AuthResponse> {
            const data = await client.loginByEmail(
                ctx,
                organizationID,
                email,
                password,
            );
            this.$reset();
            this.sync(data.organizationUser);
            return data;
        },

        async loginByPhone(
            ctx: Context,
            organizationID: OrganizationID,
            phone: string,
            password: string,
        ): Promise<AuthResponse> {
            const data = await client.loginByPhone(
                ctx,
                organizationID,
                phone,
                password,
            );
            this.$reset();
            this.sync(data.organizationUser);
            return data;
        },

        async update(
            ctx: Context,
            id: UserID,
            nickname: string,
            firstName: string,
            lastName: string,
            phone: string,
            email: string,
        ): Promise<void> {
            await client.update(
                ctx,
                id,
                nickname,
                firstName,
                lastName,
                phone,
                email,
            );
            if (
                this.user &&
                this.oUser &&
                EntityIDToString(this.user.id) == EntityIDToString(id)
            ) {
                this.user.nickname = nickname;
                this.user.firstName = firstName;
                this.user.lastName = lastName;
                this.user.phone = phone;
                this.user.email = email;
                this.oUser.user = { ...this.user };
            }

            return;
        },

        async adminLogin(
            ctx: Context,
            userID: OrganizationUserID,
        ): Promise<AuthResponse> {
            const data = await client.adminLogin(ctx, userID);
            this.$reset();
            this.sync(data.organizationUser);
            return data;
        },

        async forgotPassword(
            ctx: Context,
            organizationID: OrganizationID,
            email: string,
        ): Promise<void> {
            await client.forgotPassword(ctx, organizationID, email);
            return;
        },

        async resetPassword(
            ctx: Context,
            organizationID: OrganizationID,
            email: string,
            newPassword: string,
            token: string,
        ): Promise<void> {
            await client.resetPassword(
                ctx,
                organizationID,
                email,
                newPassword,
                token,
            );
            return;
        },
    },
    getters: {
        currentUser(): OrganizationUser | undefined {
            return this.oUser;
        },
        isEmployee(): boolean {
            return isEmployee(this.oUser);
        },
        isOwner(): boolean {
            return isOwner(this.oUser)
        },
        isManager(): boolean {
            return isManager(this.oUser)
        },
        isAdmin(): boolean {
            return isAdmin(this.oUser)
        },
        isAuth(): boolean {
            return !!this.oUser;
        },
        hasToPassFinalStep(): boolean {
            return !!(
                this.user &&
                (!this.user.phone ||
                    !this.user.email ||
                    !this.user.firstName ||
                    !this.user.lastName)
            );
        },
        currentLocale(): LocaleCode {
            return this.locale;
        },
        userName(): string {
            return this.user ? this.user.name : "";
        },
        imageUrl(): string {
            return this.user ? this.user.imageUrl : "";
        },
        hasPhone(): boolean {
            return !!(
                this.currentUser && this.currentUser.user.phone.length > 0
            );
        },
        hasEmail(): boolean {
            return !!(
                this.currentUser && this.currentUser.user.email.length > 0
            );
        },
        filterAccessedUserWorkouts() {
            return (models: UserWorkout[]): UserWorkout[] => {
                switch (true) {
                    case this.isEmployee:
                        return models;
                    //TODO Logic for Trainer
                    default:
                        return models.filter(
                            (model) =>
                                this.currentUser &&
                                EntityIDToString(model.organizationUserID) ===
                                    EntityIDToString(this.currentUser.id),
                        );
                }
            };
        },
    },
});
