<template>
  <listbox
    v-model="model"
    @blur="field.touch"
    @update:model-value="onUpdate"
  >
    <div class="relative mt-1">
      <listbox-button
        class="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
      >
        <span class="block truncate">{{
          currentOption
            ? currentOption.displayValue !== undefined
              ? currentOption.displayValue
              : currentOption.value
            : t("Select")
        }}</span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <chevron-up-down-icon
            aria-hidden="true"
            class="h-5 w-5 text-gray-400"
          />
        </span>
      </listbox-button>
      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <listbox-options
          class="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <listbox-option
            v-for="option in options"
            :key="option.value"
            v-slot="{ active, selected }"
            :value="option.value"
            as="template"
          >
            <li
              :class="[
                active
                  ? 'bg-sky-100 text-sky-900'
                  : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-10 pr-4',
              ]"
            >
              <span
                :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate',
                ]"
              >{{
                option.displayValue !== undefined
                  ? option.displayValue
                  : option.value
              }}</span>
              <span
                v-if="selected"
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-sky-600"
              >
                <check-icon
                  aria-hidden="true"
                  class="h-5 w-5"
                />
              </span>
            </li>
          </listbox-option>
        </listbox-options>
      </transition>
    </div>
  </listbox>
</template>

<script lang="ts" setup>
import { SelectOption } from "@app/views/models/form";
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/vue";
import CheckIcon from "@heroicons/vue/24/solid/CheckIcon";
import ChevronUpDownIcon from "@heroicons/vue/24/solid/ChevronUpDownIcon";
import { BaseValidation } from "@vuelidate/core";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps<{
    field: BaseValidation<string>;
    placeholder?: string;
    options: SelectOption<string>[];
}>();

const model = ref(props.field.$model);

const fieldRef = ref(props.field);

const currentOption = ref<SelectOption<string> | undefined>(undefined);
const onUpdate = (fieldValue: string) => {
    const option = props.options.find((value) => value.value === fieldValue);
    if (option) {
        currentOption.value = option;
    } else {
        currentOption.value = props.options.length
            ? props.options[0]
            : undefined;
    }

    fieldRef.value.$model = currentOption.value
        ? currentOption.value.value
        : "";
};
onUpdate(model.value);
</script>

<style scoped></style>
