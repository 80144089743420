import { PbToUUIDSure } from "@grpc/converters/uuid";
import { EventChannelMessage } from "proto-api/organization/event_channel_pb";
import { EventChannel } from "@app/entities/eventChannel";
import { pbToDateSure } from "@grpc/converters/date";
import { pbToEventChannelType } from "@grpc/converters/eventChannelType";

export const PbToEventChannel = (
    resource: EventChannelMessage,
): EventChannel => {
    const pbData = resource.getData();
    let data = "";
    if (pbData) {
        data = pbData.getValue().toString();
    }
    return {
        id: PbToUUIDSure(resource.getId()),
        organizationID: PbToUUIDSure(resource.getOrganizationId()),
        userID: PbToUUIDSure(resource.getUserId()),
        data: data,
        entityID: resource.getEntityId(),
        type: pbToEventChannelType(resource.getType()),
        createdAt: pbToDateSure(resource.getCreatedAt()),
        updatedAt: pbToDateSure(resource.getUpdatedAt()),
    };
};
