<template>
  <popup-page-component
    :title="
      t('Add subscription for {name}', {
        name: organizationUser.user.name,
      })
    "
  >
    <error-boundary-component
      v-if="subscriptionTypeOptions.length === 0"
      :message="t('There are no subscription types for this workout')"
    />
    <user-subscription-add-form
      v-else
      :disciplines-options="disciplineOptions"
      :organization-user="organizationUser"
      :subscription-type-options="subscriptionTypeOptions"
      :user-workout-id="userWorkoutId"
      :payment-accounts="accountOptions"
      @on-success="onSuccess"
    />
  </popup-page-component>
</template>

<script lang="ts" setup>
import ErrorBoundaryComponent from "@app/components/ErrorBoundaryComponent.vue";
import { useAccountsViewData } from "@app/usecase/paymentUseCase";
import { paymentAccountsToOptions } from "@app/views/converters/paymentAccount";
import { useRouter } from "vue-router";
import PopupPageComponent from "@app/components/PopupPageComponent.vue";
import { useI18n } from "vue-i18n";
import { useSubscriptionTypesViewData } from "@app/usecase/subscriptionTypeUseCase";
import { useDisciplineViewData } from "@app/usecase/disciplineUseCase";
import { subscriptionTypeToOptions } from "@app/views/converters/subscriptionType";
import { disciplinesToOptions } from "@app/views/converters/discipline";
import { computed } from "vue";
import UserSubscriptionAddForm from "@app/components/forms/UserSubscriptionAddForm.vue";
import { useOrganizationUserViewData } from "@app/usecase/organizationUserUseCase";
import { SubscriptionAddProps } from "@app/views/popups/subscription-add/route";
import { useWorkoutViewData } from "@app/usecase/workoutsUseCase";
import { EntityIDToString } from "@app/entities/uuid";

const props = defineProps<SubscriptionAddProps>();

const router = useRouter();
const { t } = useI18n();

const subscriptionTypeViewData = useSubscriptionTypesViewData();
const disciplineViewData = useDisciplineViewData();
const userViewData = useOrganizationUserViewData();
const workoutViewData = useWorkoutViewData();
const accountsViewData = useAccountsViewData();

const workout = computed(() =>
    props.workoutId ? workoutViewData.getByID(props.workoutId) : undefined,
);
const organizationUser = computed(() => {
    const user = userViewData.getByID(props.organizationUserId);
    if (!user) {
        throw new Error("User not found");
    }
    return user;
});
const disciplineOptions = computed(() => {
    let options = disciplinesToOptions(disciplineViewData.AllActive);
    const w = workout.value;
    if (w) {
        options = options.map((o) => {
            if (o.value === EntityIDToString(w.disciplineID)) {
                o.disabled = true;
                o.selected = true;
            }
            return o;
        });
    }
    return options;
});
const subscriptionTypeOptions = computed(() => {
    return subscriptionTypeToOptions(
        subscriptionTypeViewData.AllActive.filter((sType) => {
            const w = workout.value;
            return (
                !w ||
                (w &&
                    sType.disciplineIDs.find(
                        (disciplineID) =>
                            EntityIDToString(disciplineID) ===
                            EntityIDToString(w.disciplineID),
                    ))
            );
        }),
    );
});
const accountOptions = computed(() =>
    paymentAccountsToOptions(accountsViewData.All),
);
const onSuccess = () => {
    router.back();
};
</script>

<style scoped></style>
