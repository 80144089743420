import * as google_protobuf_timestamp_pb from "google-protobuf/google/protobuf/timestamp_pb";

export const pbToDateSure = (
    value: google_protobuf_timestamp_pb.Timestamp | undefined,
): Date => {
    return (value as google_protobuf_timestamp_pb.Timestamp).toDate();
};

export const DateToPb = (
    date: Date | undefined,
): google_protobuf_timestamp_pb.Timestamp => {
    const value = new google_protobuf_timestamp_pb.Timestamp();
    if (date) {
        value.fromDate(date);
    }
    return value;
};
