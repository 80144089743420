import { WorkoutStatus } from "@app/entities/workoutStatus";
import { defineStore } from "pinia";
import { Workout } from "@app/entities/workout";
import {
    BaseEntityStorage,
    storeEntities,
    storeGetByID,
    storeGetMissedIDs,
} from "@app/stores/converters/repo";
import {
    DisciplineID,
    LocationID,
    OrganizationID,
    ScheduleID,
    UserID,
    WorkoutID,
} from "@app/entities/uuid";
import { Context } from "@app/entities/context";
import { workoutClient } from "@grpc/workoutClient";
import { UserWorkout } from "@app/entities/userWorkout";
import { DateTimeRange } from "@app/entities/dateTimeRange";
import { removeUndefined } from "@app/services/arrays";

type StoreFields = BaseEntityStorage<Workout>;

export interface WorkoutClient {
    loadByOrganizationID(
        ctx: Context,
        id: OrganizationID,
        dateRange: DateTimeRange,
    ): Promise<Workout[]>;

    loadByID(ctx: Context, id: DisciplineID): Promise<Workout | undefined>;

    store(
        ctx: Context,
        organizationID: OrganizationID,
        trainerID: UserID,
        disciplineID: DisciplineID,
        locationID: LocationID,
        studentsCount: number,
        timeFrom: Date,
        timeTo: Date,
        scheduleID?: ScheduleID,
    ): Promise<Workout | undefined>;

    update(
        ctx: Context,
        id: WorkoutID,
        trainerID: UserID,
        disciplineID: DisciplineID,
        locationID: LocationID,
        studentsCount: number,
        timeFrom: Date,
        timeTo: Date,
        scheduleID?: ScheduleID,
    ): Promise<Workout | undefined>;

    close(
        ctx: Context,
        id: WorkoutID,
    ): Promise<
        | {
              workout: Workout;
              userWorkouts: UserWorkout[];
          }
        | undefined
    >;

    cancel(
        ctx: Context,
        id: WorkoutID,
        keepBalance: boolean,
    ): Promise<
        | {
              workout: Workout;
              userWorkouts: UserWorkout[];
          }
        | undefined
    >;
}

const client: WorkoutClient = workoutClient;

export const useWorkoutRepo = defineStore("workout", {
    state: () =>
        ({
            data: [],
            byId: {},
        }) as StoreFields,
    actions: {
        sync(entities: Workout[]) {
            return storeEntities(this, entities);
        },
        async loadByOrganizationID(
            ctx: Context,
            id: OrganizationID,
            dateRange: DateTimeRange,
        ): Promise<Workout[]> {
            const data = await client.loadByOrganizationID(ctx, id, dateRange);
            this.sync(data);
            return data;
        },
        async loadByID(
            ctx: Context,
            id: DisciplineID,
        ): Promise<Workout | undefined> {
            const data = await client.loadByID(ctx, id);
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async loadByIDs(
            ctx: Context,
            entitiesIDs: WorkoutID[],
        ): Promise<Workout[]> {
            const waits: Promise<Workout | undefined>[] = [];
            entitiesIDs.forEach((value) => {
                waits.push(this.loadByID(ctx, value));
            });
            const data = await Promise.all(waits);
            return removeUndefined(data);
        },
        async store(
            ctx: Context,
            organizationID: OrganizationID,
            trainerID: UserID,
            disciplineID: DisciplineID,
            locationID: LocationID,
            studentsCount: number,
            timeFrom: Date,
            timeTo: Date,
            scheduleID?: ScheduleID,
        ): Promise<Workout | undefined> {
            const model = await client.store(
                ctx,
                organizationID,
                trainerID,
                disciplineID,
                locationID,
                studentsCount,
                timeFrom,
                timeTo,
                scheduleID,
            );
            if (model) {
                this.sync([model]);
                return model;
            }

            return;
        },

        async update(
            ctx: Context,
            id: WorkoutID,
            trainerID: UserID,
            disciplineID: DisciplineID,
            locationID: LocationID,
            studentsCount: number,
            timeFrom: Date,
            timeTo: Date,
            scheduleID?: ScheduleID,
        ): Promise<Workout | undefined> {
            const model = await client.update(
                ctx,
                id,
                trainerID,
                disciplineID,
                locationID,
                studentsCount,
                timeFrom,
                timeTo,
                scheduleID,
            );
            if (model) {
                this.sync([model]);
                return model;
            }

            return;
        },

        async close(
            ctx: Context,
            id: WorkoutID,
        ): Promise<
            | {
                  workout: Workout;
                  userWorkouts: UserWorkout[];
              }
            | undefined
        > {
            const data = await client.close(ctx, id);
            if (data) {
                this.sync([data.workout]);
            }
            return data;
        },

        async cancel(
            ctx: Context,
            id: WorkoutID,
            keepBalance: boolean,
        ): Promise<
            | {
                  workout: Workout;
                  userWorkouts: UserWorkout[];
              }
            | undefined
        > {
            const data = await client.cancel(ctx, id, keepBalance);
            if (data) {
                this.sync([data.workout]);
            }
            return data;
        },
    },
    getters: {
        All: (store): Workout[] => store.data,
        getByID: (store) => storeGetByID<Workout>(store),
        getMissedIDs: (store) => storeGetMissedIDs<Workout, WorkoutID>(store),
        getByScheduleID() {
            return (
                scheduleID: ScheduleID,
                dateFrom?: Date,
                dateTo?: Date,
            ): Workout[] => {
                return this.data
                    .filter(
                        (value: Workout) =>
                            value.scheduleID &&
                            value.scheduleID.value === scheduleID.value,
                    )
                    .filter(
                        (value) =>
                            !dateFrom ||
                            !dateTo ||
                            value.dateTimeRange.left == undefined ||
                            (value.dateTimeRange.left >= dateFrom &&
                                value.dateTimeRange.left < dateTo),
                    )
                    .sort((a, b) =>
                        a.dateTimeRange.left &&
                        b.dateTimeRange.left &&
                        a.dateTimeRange.left > b.dateTimeRange.left
                            ? 1
                            : -1,
                    );
            };
        },
        getByDate() {
            return (
                dateFrom: Date,
                dateTo: Date,
                locationId: LocationID | undefined,
                status: WorkoutStatus | undefined,
            ): Workout[] => {
                return this.data
                    .filter(
                        (value: Workout) =>
                            (value.dateTimeRange.left == undefined ||
                                (value.dateTimeRange.left >= dateFrom &&
                                    value.dateTimeRange.left < dateTo)) &&
                            (!locationId ||
                                locationId.value === value.locationID.value) &&
                            (!status || status === value.status),
                    )
                    .sort((a, b) =>
                        a.dateTimeRange.left &&
                        b.dateTimeRange.left &&
                        a.dateTimeRange.left > b.dateTimeRange.left
                            ? 1
                            : -1,
                    );
            };
        },
    },
});
