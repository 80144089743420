<template>
  <PageComponent title="Form example">
    <form-component :form="vueForm">
      <div class="columns-2xs">
        <form-field
          :field="form.masked"
          label="Masked"
        >
          <phone-input-field :field="form.masked" />
        </form-field>
        <form-field
          :field="form.name"
          label="Organization name"
        >
          <text-input-field :field="form.name" />
        </form-field>
        <form-field
          :field="form.textareaTest"
          label="Organization description"
        >
          <text-area-input-field
            :field="form.textareaTest"
            placeholder="Describe you company in a couple of words"
          />
        </form-field>
        <form-field
          :field="form.selectTest"
          label="Test select box"
        >
          <select-box-field
            :field="form.selectTest"
            :options="[
              {
                value: '',
                displayValue: 'Please, select option',
                disabled: true,
              },
              { value: 'one select' },
              { value: 'two select' },
              { value: 'three select' },
            ]"
          />
        </form-field>
        <form-field
          :field="form.selectTestList"
          label="Test select box"
        >
          <list-box-field
            :field="form.selectTestList"
            :options="[
              {
                value: '',
                displayValue: 'Please, select option',
                disabled: true,
              },
              { value: 'one select' },
              { value: 'two select' },
              { value: 'three select' },
            ]"
          />
        </form-field>
        <form-field
          :field="form.fileInput"
          label="Organization logo"
        >
          <file-input-field
            :field="form.fileInput"
            :multiple="true"
          />
        </form-field>
        <form-field
          :field="form.radioTest"
          label="Test radio button"
        >
          <radio-button-field
            :field="form.radioTest"
            :options="[
              { value: 'one' },
              { value: 'two' },
              { value: 'three' },
            ]"
          />
        </form-field>
        <form-field
          :field="form.checkboxTest"
          label="Test checkbox button"
        >
          <checkbox-field
            :field="form.checkboxTest"
            :options="[
              { value: 'one check' },
              { value: 'two check' },
              { value: 'three check' },
            ]"
          />
        </form-field>
        <form-field
          :field="form.toggleTest"
          bottom-label="bottom label!"
          label="Group we needed!"
        >
          <toggle-field
            :field="form.toggleTest"
            label="test option 1"
          />
          <toggle-field
            :field="form.toggleTest2"
            label="Second option"
          />
        </form-field>
        <form-field
          :field="form.dateInput"
          label="Date"
        >
          <date-input-field
            :field="form.dateInput"
          />
        </form-field>
      </div>
      <button class="btn gap-2">
        Button
        <svg
          class="h-6 w-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </svg>
      </button>
    </form-component>
  </PageComponent>
</template>

<script lang="ts" setup>
import PageComponent from "@app/components/PageComponent.vue";
import { reactive } from "vue";
import { ValidationArgs } from "@vuelidate/core";
import { required } from "@app/services/validators";
import CheckboxField from "@app/components/forms/fields/CheckboxField.vue";
import FormField from "@app/components/forms/fields/FormField.vue";
import FileInputField from "@app/components/forms/fields/FileInputField.vue";
import RadioButtonField from "@app/components/forms/fields/RadioButtonField.vue";
import TextAreaInputField from "@app/components/forms/fields/TextAreaInputField.vue";
import SelectBoxField from "@app/components/forms/fields/SelectBoxField.vue";
import ToggleField from "@app/components/forms/fields/ToggleField.vue";
import TextInputField from "@app/components/forms/fields/TextInputField.vue";
import { appForm } from "@app/services/forms";
import DateInputField from "@app/components/forms/fields/DateInputField.vue";
import FormComponent from "@app/components/FormComponent.vue";
import ListBoxField from "@app/components/forms/fields/ListBoxField.vue";
import PhoneInputField from "@app/components/forms/fields/PhoneInputField.vue";

interface OrganizationFormFields {
    name: string;
    masked: string;
    radioTest: string[];
    checkboxTest: string[];
    selectTest: string;
    selectTestList: string;
    textareaTest: string;
    toggleTest: boolean;
    toggleTest2: boolean;
    fileInput: string;
    dateInput: string;
}

const formFields = reactive<OrganizationFormFields>({
    name: "Title",
    masked: "",
    radioTest: ["two"],
    checkboxTest: ["one check", "three check"],
    selectTest: "",
    selectTestList: "",
    textareaTest: "",
    toggleTest: true,
    toggleTest2: false,
    fileInput: "",
    dateInput: "",
});

const rules: ValidationArgs<OrganizationFormFields> = {
    name: {
        required: required,
    },
    masked: {},
    radioTest: { required },
    checkboxTest: { required: required },
    selectTest: { required },
    textareaTest: { required },
    toggleTest: {},
    toggleTest2: {},
    fileInput: {},
    dateInput: {},
    selectTestList: {},
};

const vueForm = appForm<OrganizationFormFields, void>(
    rules,
    formFields,
    async () => {
        console.info(formFields);
        console.info("success");
    },
);
const form = vueForm.form;
</script>

<style scoped></style>
