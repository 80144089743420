<template>
  <form-component :form="vueForm">
    <div class="columns-1xs">
      <form-field
        :field="form.name"
        :label="t('Name')"
      >
        <text-input-field :field="form.name" />
      </form-field>
      <form-field
        :field="form.description"
        :label="t('Description')"
      >
        <wysiwyg-input-field
          :field="form.description"
        />
      </form-field>
      <form-field
        :field="form.images"
        :label="t('Images')"
      >
        <file-input-field
          :field="form.images"
          :multiple="true"
        />
      </form-field>
    </div>
    <submit-button />
  </form-component>
</template>

<script lang="ts" setup>
import { reactive } from "vue";
import { ValidationArgs } from "@vuelidate/core";
import { maxLength, required } from "@app/services/validators";
import TextInputField from "@app/components/forms/fields/TextInputField.vue";
import FormField from "@app/components/forms/fields/FormField.vue";
import SubmitButton from "@app/components/forms/fields/SubmitButton.vue";
import FileInputField from "@app/components/forms/fields/FileInputField.vue";
import { appForm } from "@app/services/forms";
import FormComponent from "@app/components/FormComponent.vue";
import WysiwygInputField from "@app/components/forms/fields/WysiwygInputField.vue";
import { disciplineUseCase } from "@app/usecase/disciplineUseCase";
import { Discipline } from "@app/entities/discipline";
import { entityFileUseCase } from "@app/usecase/entityFileUseCase";
import { FileType } from "@app/entities/fileType";
import { useI18n } from "vue-i18n";

interface StoreDisciplineFormFields {
    name: string;
    description: string;
    images: string[];
}

const { t } = useI18n();
const emits = defineEmits<{
    (e: "on-success", value: Discipline): void;
}>();

const formFields = reactive<StoreDisciplineFormFields>({
    name: "",
    description: "",
    images: [],
});

const rules: ValidationArgs<StoreDisciplineFormFields> = {
    name: {
        required: required,
        maxLengthRef: maxLength(1000),
    },
    description: {
        required: required,
        maxLengthRef: maxLength(4000),
    },
    images: {},
};

const vueForm = appForm<StoreDisciplineFormFields, void>(
    rules,
    formFields,
    async () => {
        const discipline = await disciplineUseCase.store(
            formFields.name,
            formFields.description,
        );

        const promises = entityFileUseCase.syncImages(
            discipline.id,
            FileType.DISCIPLINE,
            formFields.images,
            [],
        );

        await Promise.all(promises);

        emits("on-success", discipline);
    },
);
const form = vueForm.form;
</script>

<style scoped></style>
