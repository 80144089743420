<template>
  <template v-if="template == 'data-item'">
    <data-item
      v-if="entity"
      :links="links"
      :model="entity"
    >
      <h3
        :class="{ 'line-through': !!entity.deletedAt }"
        class="text-xl pb-2 header"
      >
        {{ entity.name }}
      </h3>
      <div v-if="!entity.deletedAt">
        <wysiwyg-content
          :content="entity.description"
        />
        <div
          v-for="image of filesData.getByEntityID(entity.id)"
          :key="EntityIDToString(image.id)"
        >
          <img
            :src="image.path"
            alt=""
            class="lg:max-w-sm max-w-full"
          >
        </div>
      </div>
    </data-item>
  </template>
  <template v-if="template == 'inline'">
    <span>{{ entity ? entity.name : "" }}</span>
  </template>
</template>

<script lang="ts" setup>
import { useEntityFilesData } from "@app/usecase/entityFileUseCase";
import { EntityIDToString, LocationID } from "@app/entities/uuid";
import WysiwygContent from "@app/components/WysiwygContent.vue";
import { Link } from "@app/views/models/links";
import DataItem from "@app/components/DataItem.vue";
import { useLocationViewData } from "@app/usecase/locationUseCase";
import { computed } from "vue";

const filesData = useEntityFilesData();

interface Props {
    entityId: LocationID;
    template: "data-item" | "inline";
    links?: Link[];
}

const props = withDefaults(defineProps<Props>(), {
    links: () => [],
});

const locationView = useLocationViewData();

const entity = computed(() => locationView.getByID(props.entityId));
</script>

<style scoped></style>
