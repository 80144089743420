import { Context } from "@app/entities/context";
import { Discipline } from "@app/entities/discipline";
import { EntityFile } from "@app/entities/entityFile";
import { Location } from "@app/entities/location";
import { Organization } from "@app/entities/organzation";
import { Schedule } from "@app/entities/schedule";
import { SubscriptionType } from "@app/entities/subscriptionType";
import { OrganizationID } from "@app/entities/uuid";
import { Workout } from "@app/entities/workout";
import { useEntityFilesData } from "@app/usecase/entityFileUseCase";
import { organizationClient } from "@grpc/organizationClient";
import { defineStore } from "pinia";

type StoreFields = {
    organization?: Organization;
};

export interface OrganizationClient {
    update(
        ctx: Context,
        id: OrganizationID,
        name: string,
    ): Promise<Organization | undefined>;

    info(
        ctx: Context,
        code: string,
    ): Promise<
        | {
              organization: Organization;
              entityFiles: EntityFile[];
              workouts: Workout[];
              subscriptionTypes: SubscriptionType[];
              locations: Location[];
              disciplines: Discipline[];
              schedules: Schedule[];
          }
        | undefined
    >;
}

const client: OrganizationClient = organizationClient;

export const useOrganizationRepo = defineStore("organization", {
    state: () =>
        ({
            organization: undefined,
        }) as StoreFields,
    actions: {
        sync(data: Organization) {
            this.organization = data;
        },

        async info(
            ctx: Context,
            code: string,
        ): Promise<
            | {
                  organization: Organization;
                  entityFiles: EntityFile[];
                  workouts: Workout[];
                  subscriptionTypes: SubscriptionType[];
                  locations: Location[];
                  disciplines: Discipline[];
                  schedules: Schedule[];
              }
            | undefined
        > {
            const data = await client.info(ctx, code);
            if (data) {
                this.sync(data.organization);
                return data;
            }

            return;
        },

        async update(
            ctx: Context,
            id: OrganizationID,
            name: string,
        ): Promise<Organization | undefined> {
            const data = await client.update(ctx, id, name);
            if (data) {
                this.sync(data);
                return data;
            }

            return;
        },
    },
    getters: {
        wideLogoPath(state) {
            const entityFilesStore = useEntityFilesData();
            const file = state.organization?.logoWideID
                ? entityFilesStore.getByID(state.organization.logoWideID)
                : undefined;
            return file ? file.path : "/src/assets/logo.svg";
        },
        squareLogoPath(state) {
            const entityFilesStore = useEntityFilesData();
            const file = state.organization?.logoSquareID
                ? entityFilesStore.getByID(state.organization.logoSquareID)
                : undefined;
            return file
                ? file.path
                : "https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=500";
        },
    },
});
