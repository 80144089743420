import {
    RouteLocationNormalizedLoaded,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";
import { PageType } from "@app/pkg/router/pageType";
import { AppBreadcrumbs } from "@app/router/models";
import { getFirsLevelProps } from "@app/pkg/router/props";
import {
    EntityIDToString,
    ScheduleID,
    StringToEntityID,
} from "@app/entities/uuid";
import { Link, LinkIcons } from "@app/views/models/links";
import { pupUpRoute } from "@app/router/popups";
import { authUseCase, ENTITIES_LIST } from "@app/usecase/authUseCase";
import ScheduleUpdateView from "@app/views/popups/schedule-update/ScheduleUpdateView.vue";
import { scheduleUseCase } from "@app/usecase/scheduleUseCase";

type Props = {
    scheduleId: ScheduleID;
};

export const getScheduleUpdateLink = (
    route: RouteLocationNormalizedLoaded,
    text: string,
    scheduleID: ScheduleID,
): Link => {
    return {
        image: LinkIcons.pencil,
        text: text,
        to: pupUpRoute(route, PageType.ScheduleUpdate, {
            ...route.params,
            scheduleId: EntityIDToString(scheduleID),
        }),
        disabled: !authUseCase.canEdit(ENTITIES_LIST.SUBSCRIPTION),
    };
};

export const scheduleUpdateRoute = (
    prefix: string,
    breadcrumbs: AppBreadcrumbs[],
): RouteRecordRaw => {
    return {
        path: "schedule-update/:scheduleId",
        name: prefix + PageType.ScheduleUpdate,
        meta: {
            breadcrumbs: breadcrumbs,
        },
        components: {
            popup: ScheduleUpdateView,
        },
        props: true,
        beforeEnter: async (to, from, next) => {
            const scheduleId = StringToEntityID(to.params.scheduleId as string);

            await scheduleUseCase.loadByID(scheduleId);

            const props = getFirsLevelProps<Props>();
            props.scheduleId = scheduleId;

            // didn't find a better way
            to.params = {
                ...to.params,
                ...(props as unknown as RouteParams),
            };
            next();
        },
    };
};
