import { defineStore } from "pinia";
import { BaseEntityStorage, storeEntities } from "@app/stores/converters/repo";
import { WorkoutDraft } from "@app/entities/workoutDraft";
import { WorkoutStatus } from "@app/entities/workoutStatus";
import { Schedule } from "@app/entities/schedule";
import { Workout } from "@app/entities/workout";
import { addWeekTime } from "@app/services/format";
import { WorkoutDraftItem } from "@app/entities/workoutDraftItem";
import { EntityIDToString, WorkoutDraftItemID } from "@app/entities/uuid";

type Statistic = {
    statusDraft: number;
    alreadyExist: number;
    processed: number;
};

type StoreFields = BaseEntityStorage<WorkoutDraft> & {
    statistic: Statistic;
    isProcessRun: boolean;
    counter: number;
    byDraftId: Record<number, WorkoutDraftItem>;
};

export const useWorkoutDraftRepo = defineStore("workoutDraft", {
    state: () =>
        ({
            counter: 0,
            byId: {},
            byDraftId: {},
            data: [],
            statistic: {
                statusDraft: 0,
                alreadyExist: 0,
                processed: 0,
            },
            isProcessRun: false,
        }) as StoreFields,
    actions: {
        store(entities: WorkoutDraft[]) {
            storeEntities(this, entities);
            this.statistic = {
                statusDraft: 0,
                alreadyExist: 0,
                processed: 0,
            };
            this.data.forEach((row) => {
                row.items.forEach((item) => {
                    if (item.status === WorkoutStatus.WorkoutStatusDraft) {
                        this.statistic.statusDraft++;
                    } else {
                        this.statistic.alreadyExist++;
                    }
                });
            });
            return;
        },
        storeItem(
            schedule: Schedule,
            dateFrom: Date,
            workouts: Workout[],
        ): void {
            const scheduleIDValue = EntityIDToString(schedule.id);
            if (!this.byId[scheduleIDValue]) {
                this.byId[scheduleIDValue] = {
                    id: schedule.id,
                    items: [],
                };
            }
            const id = this.counter++;

            let item: WorkoutDraftItem = {
                id: { value: id },
                status: WorkoutStatus.WorkoutStatusDraft,
                locationID: schedule.locationID,
                trainerID: schedule.trainerID,
                disciplineID: schedule.disciplineID,
                scheduleID: schedule.id,
                date: new Date(dateFrom),
                timeFrom: addWeekTime(
                    new Date(dateFrom),
                    schedule.timeRange.left,
                ),
                timeTo: addWeekTime(
                    new Date(dateFrom),
                    schedule.timeRange.right,
                ),
                studentsCount: schedule.studentsCount,
                problem: "",
            };

            if (workouts.length) {
                const workout = workouts[0];
                const dateTimeLeft = workout.dateTimeRange.left;
                const dateTimeRight = workout.dateTimeRange.right;
                if (dateTimeLeft && dateTimeRight) {
                    item = {
                        id: { value: id },
                        status: workout.status,
                        locationID: workout.locationID,
                        trainerID: workout.trainerID,
                        disciplineID: workout.disciplineID,
                        scheduleID: schedule.id,
                        date: dateTimeLeft,
                        timeFrom: dateTimeLeft,
                        timeTo: dateTimeRight,
                        studentsCount: workout.studentsCount,
                        problem: "",
                    };
                }
            }

            if (item.status === WorkoutStatus.WorkoutStatusDraft) {
                this.statistic.statusDraft++;
            } else {
                this.statistic.alreadyExist++;
            }
            this.byId[scheduleIDValue].items.push(item);
            this.byDraftId[id] = item;
            this.data = Object.values(this.byId);
            return;
        },
        markAsProblem(id: WorkoutDraftItemID, problem: string): void {
            this.byDraftId[id.value].problem = problem;
        },

        async processData(
            processor: (item: WorkoutDraftItem) => Promise<Workout | undefined>,
        ): Promise<void> {
            this.isProcessRun = true;
            for (const item of this.data) {
                for (const workoutDraftItem of item.items) {
                    if (
                        workoutDraftItem.status ===
                            WorkoutStatus.WorkoutStatusDraft &&
                        this.isProcessRun
                    ) {
                        workoutDraftItem.isProcess = true;
                        await processor(workoutDraftItem)
                            .then((workout) => {
                                if (workout) {
                                    workoutDraftItem.workoutID = workout.id;
                                    workoutDraftItem.status = workout.status;
                                }
                            })
                            .finally(() => {
                                this.statistic = {
                                    ...this.statistic,
                                    processed: this.statistic.processed + 1,
                                    statusDraft: this.statistic.statusDraft - 1,
                                };
                                workoutDraftItem.isProcess = false;
                            });
                    }
                    if (!this.isProcessRun) {
                        return;
                    }
                }
            }
            this.isProcessRun = false;
            return;
        },
        stop(): void {
            this.isProcessRun = false;
        },
        reset(): void {
            this.$reset();
        },
    },
    getters: {
        List(): WorkoutDraft[] {
            return this.data;
        },
        AllWithProblems(store): WorkoutDraftItem[] {
            return Object.values(store.byDraftId).filter(
                (value) => value.problem != "",
            );
        },
        Statistic(): Statistic {
            return this.statistic;
        },
        IsProcessRun(): boolean {
            return this.isProcessRun;
        },
    },
});
