import { defineStore } from "pinia";
import {
    BaseEntityStorage,
    storeEntities,
    storeGetAll,
    storeGetAllActive,
    storeGetByID,
    storeGetByIDs,
} from "@app/stores/converters/repo";
import { SubscriptionType } from "@app/entities/subscriptionType";
import {
    DisciplineID,
    OrganizationID,
    SubscriptionTypeID,
} from "@app/entities/uuid";
import { Context } from "@app/entities/context";
import { subscriptionTypeClient } from "@grpc/subscriptionTypeClient";

type StoreFields = BaseEntityStorage<SubscriptionType>;

export interface SubscriptionTypeClient {
    loadByOrganizationID(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<SubscriptionType[]>;

    loadByID(
        ctx: Context,
        id: SubscriptionTypeID,
    ): Promise<SubscriptionType | undefined>;

    store(
        ctx: Context,
        organizationID: OrganizationID,
        name: string,
        description: string,
        days: number,
        price: number,
        classes: number,
        disciplineIDs: DisciplineID[],
    ): Promise<SubscriptionType | undefined>;

    update(
        ctx: Context,
        id: SubscriptionTypeID,
        name: string,
        description: string,
        days: number,
        price: number,
        classes: number,
        disciplineIDs: DisciplineID[],
    ): Promise<SubscriptionType | undefined>;

    destroy(
        ctx: Context,
        id: SubscriptionTypeID,
    ): Promise<SubscriptionType | undefined>;

    restore(
        ctx: Context,
        id: SubscriptionTypeID,
    ): Promise<SubscriptionType | undefined>;
}

const client: SubscriptionTypeClient = subscriptionTypeClient;

export const useSubscriptionTypeRepo = defineStore("subscriptionType", {
    state: () =>
        ({
            data: [],
            byId: {},
            byPage: {},
            currentPageId: undefined,
            lastUpdate: new Date(),
        }) as StoreFields,
    actions: {
        sync(entities: SubscriptionType[]) {
            return storeEntities(this, entities);
        },

        async loadByOrganizationID(
            ctx: Context,
            organizationID: OrganizationID,
        ): Promise<SubscriptionType[]> {
            const data = await client.loadByOrganizationID(ctx, organizationID);
            this.sync(data);
            return data;
        },

        async loadByID(
            ctx: Context,
            id: SubscriptionTypeID,
        ): Promise<SubscriptionType | undefined> {
            const data = await client.loadByID(ctx, id);
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async store(
            ctx: Context,
            organizationID: OrganizationID,
            name: string,
            description: string,
            days: number,
            price: number,
            classes: number,
            disciplineIDs: DisciplineID[],
        ): Promise<SubscriptionType | undefined> {
            const data = await client.store(
                ctx,
                organizationID,
                name,
                description,
                days,
                price,
                classes,
                disciplineIDs,
            );
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async update(
            ctx: Context,
            id: SubscriptionTypeID,
            name: string,
            description: string,
            days: number,
            price: number,
            classes: number,
            disciplineIDs: DisciplineID[],
        ): Promise<SubscriptionType | undefined> {
            const data = await client.update(
                ctx,
                id,
                name,
                description,
                days,
                price,
                classes,
                disciplineIDs,
            );
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async destroy(
            ctx: Context,
            id: SubscriptionTypeID,
        ): Promise<SubscriptionType | undefined> {
            const data = await client.destroy(ctx, id);
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async restore(
            ctx: Context,
            id: SubscriptionTypeID,
        ): Promise<SubscriptionType | undefined> {
            const data = await client.restore(ctx, id);
            if (data) {
                this.sync([data]);
            }
            return data;
        },
    },
    getters: {
        All: (store): SubscriptionType[] => storeGetAll(store),
        AllActive: (store) => storeGetAllActive<SubscriptionType>(store),
        getByID: (store) => storeGetByID<SubscriptionType>(store),
        getByIDs: (store) => storeGetByIDs<SubscriptionType>(store),
    },
});
