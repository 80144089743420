<template>
  <form-component :form="vueForm">
    <div class="columns-1xs">
      <form-field
        :field="form.name"
        :label="t('Name')"
      >
        <text-input-field :field="form.name" />
      </form-field>
      <form-field
        :field="form.description"
        :label="t('Description')"
      >
        <wysiwyg-input-field
          :field="form.description"
        />
      </form-field>
      <form-field
        :field="form.images"
        :label="t('Images')"
      >
        <file-input-field
          :field="form.images"
          :multiple="true"
        />
      </form-field>
    </div>
    <submit-button />
  </form-component>
</template>

<script lang="ts" setup>
import { reactive } from "vue";
import { ValidationArgs } from "@vuelidate/core";
import { maxLength, required } from "@app/services/validators";
import TextInputField from "@app/components/forms/fields/TextInputField.vue";
import FormField from "@app/components/forms/fields/FormField.vue";
import SubmitButton from "@app/components/forms/fields/SubmitButton.vue";
import { appForm } from "@app/services/forms";
import FormComponent from "@app/components/FormComponent.vue";
import WysiwygInputField from "@app/components/forms/fields/WysiwygInputField.vue";
import FileInputField from "@app/components/forms/fields/FileInputField.vue";
import { locationUseCase } from "@app/usecase/locationUseCase";
import { Location } from "@app/entities/location";
import { entityFileUseCase } from "@app/usecase/entityFileUseCase";
import { FileType } from "@app/entities/fileType";
import { useI18n } from "vue-i18n";

interface StoreLocationFormFields {
    name: string;
    description: string;
    images: string[];
}

const { t } = useI18n();
const emits = defineEmits<{
    (e: "on-success", value: Location): void;
}>();

const rules: ValidationArgs<StoreLocationFormFields> = {
    name: {
        required: required,
        maxLengthRef: maxLength(1000),
    },
    description: {
        required: required,
        maxLengthRef: maxLength(4000),
    },
    images: {},
};
const formFields = reactive<StoreLocationFormFields>({
    name: "",
    description: "",
    images: [],
});
const vueForm = appForm<StoreLocationFormFields, void>(
    rules,
    formFields,
    async () => {
        const location = await locationUseCase.store(
            formFields.name,
            formFields.description,
        );

        const promises = entityFileUseCase.syncImages(
            location.id,
            FileType.LOCATION,
            formFields.images,
            [],
        );

        await Promise.all(promises);
        emits("on-success", location);
    },
);
const form = vueForm.form;
</script>

<style scoped></style>
