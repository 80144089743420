<template>
  <page-component
    :is-loading="isLoading"
    :links="actionLinks"
    title="Users"
  >
    <div class="grid grid-cols-1 gap-3">
      <organization-user-component
        v-for="organizationUser in users"
        :key="EntityIDToString(organizationUser.id)"
        :entity-id="organizationUser.id"
        template="data-item"
      >
        <subscription-component
          v-for="subscription in subscriptionsViewData.getByOrganizationUserID(
            organizationUser.id,
          )"
          :key="EntityIDToString(subscription.id)"
          :model-id="subscription.id"
        />
      </organization-user-component>
    </div>
    <!--        TODO -->
    <!--        <navigation-component-->
    <!--            v-if="organizationUsersStore.currentPage"-->
    <!--            :links="organizationUsersStore.currentPage.links"-->
    <!--            @navigation="getForPage"-->
    <!--        ></navigation-component>-->
  </page-component>
</template>

<script lang="ts" setup>
import PageComponent from "@app/components/PageComponent.vue";
import { EntityIDToString } from "@app/entities/uuid";
import { authUseCase, ENTITIES_LIST } from "@app/usecase/authUseCase";
import { useOrganizationUserViewData } from "@app/usecase/organizationUserUseCase";
import { useSubscriptionsViewData } from "@app/usecase/subscriptionUseCase";
import OrganizationUserComponent from "@app/views/entityComponents/OrganizationUserComponent.vue";
import SubscriptionComponent from "@app/views/entityComponents/SubscriptionComponent.vue";
import { Link, LinkIcons } from "@app/views/models/links";
import { computed, ComputedRef, ref } from "vue";
import { useI18n } from "vue-i18n";

const organizationUserView = useOrganizationUserViewData();
const subscriptionsViewData = useSubscriptionsViewData();
const { t } = useI18n();

const isLoading = ref<boolean>(false);

const users = computed(() => {
    const data = organizationUserView.All;
    return data.sort((a, b) => {
        const aName =
            (a.isDeleted ? "1" : "0") +
            (a.user.firstName
                ? a.user.firstName
                : a.user.lastName
                  ? a.user.lastName
                  : a.user.nickname);
        const bName =
            (b.isDeleted ? "1" : "0") +
            (b.user.firstName
                ? b.user.firstName
                : b.user.lastName
                  ? b.user.lastName
                  : b.user.nickname);
        return aName > bName ? 1 : -1;
    });
});

// const data = computed(() => {
//     const oUserIDs = organizationUserView.All.map((oUser) => oUser.id);
//     console.log(oUserIDs);
//     // subscriptionsUseCase.getByID();
//     // organizationUserView.All.map((value) => {
//     //     subscriptionsUseCase.getByOrganizationUserID(value.id);
//     // });
//     return organizationUserView.All;
// });

// TODO
// interface NavigationLink {
//     url: string;
//     pageNumber: number;
//     active: boolean;
//     label: string;
// }

// TODO
// const getForPage = async (event: Event, link: NavigationLink) => {
//     event.preventDefault();
//     if (!link.url || link.active) {
//         return;
//     }
//
//     await router.push({
//         name: ROUTES.Users,
//         query: {
//             pageNumber: link.pageNumber,
//         },
//     });
// };

const actionLinks: ComputedRef<Link[]> = computed(() => [
    {
        text: t("Add new user"),
        image: LinkIcons.add,
        to: {
            name: "UserAdd",
        },
        disabled: !authUseCase.canEdit(ENTITIES_LIST.ORGANIZATION_USER),
    },
]);
// onBeforeRouteUpdate(usersListGuard);
</script>

<style scoped></style>
