import { Account } from "@app/entities/balance/account";
import { Context } from "@app/entities/context";

import { BalanceAccountID } from "@app/entities/uuid";
import {
    BaseEntityStorage,
    storeEntities,
    storeGetByID,
    storeGetMissedIDs,
} from "@app/stores/converters/repo";
import { useBalanceAccountClient } from "@grpc/balanceAccountClient";
import { defineStore } from "pinia";

type StoreFields = BaseEntityStorage<Account>;

export interface BalanceAccountClient {
    loadByID(ctx: Context, id: BalanceAccountID): Promise<Account | undefined>;
}

const client: BalanceAccountClient = useBalanceAccountClient();

export const useBalanceAccountsRepo = defineStore("balanceAccounts", {
    state: () =>
        ({
            data: [],
            byId: {},
        }) as StoreFields,
    actions: {
        sync(entities: Account[]) {
            return storeEntities(this, entities);
        },
        async loadByID(
            ctx: Context,
            id: BalanceAccountID,
        ): Promise<Account | undefined> {
            const data = await client.loadByID(ctx, id);
            if (data) {
                this.sync([data]);
            }
            return data;
        },
        async loadByIDs(
            ctx: Context,
            ids: BalanceAccountID[],
        ): Promise<Account[]> {
            return (
                await Promise.all(ids.map((id) => this.loadByID(ctx, id)))
            ).filter((value): value is Account => !!value);
        },
    },
    getters: {
        All: (store): Account[] => store.data,
        getByID: (store) => storeGetByID<Account>(store),
        getMissedIDs: (store) =>
            storeGetMissedIDs<Account, BalanceAccountID>(store),
    },
});
