import { UserRole } from "@app/entities/userRole";
import { RoleMessage } from "proto-api/auth/user_role_pb";

export const pbToOrganizationUserRole = (resource: RoleMessage): UserRole => {
    return resource.valueOf();
};

export const OrganizationUserRoleToPb = (resource: UserRole): RoleMessage => {
    return resource.valueOf();
};
