import { UserRole } from "@app/entities/userRole";
import { SelectOption } from "@app/views/models/form";
import { useI18n } from "vue-i18n";
import { ValidationRuleCollection } from "@vuelidate/core";

export const UserRoleToString = (entity: UserRole) => {
    switch (entity) {
        case UserRole.MANAGER:
            return "Manager";
        case UserRole.OWNER:
            return "Owner";
        case UserRole.STUDENT:
            return "Student";
        case UserRole.TEACHER:
            return "Teacher";
        default:
            return "Unknown";
    }
};

export const getAllRoles = () => {
    return [
        UserRole.STUDENT,
        UserRole.TEACHER,
        UserRole.MANAGER,
        UserRole.OWNER,
    ];
};
export const UserRolesToOptions = (): SelectOption<number>[] => {
    return getAllRoles().map((value) => {
        const { t } = useI18n();
        return {
            value: value,
            displayValue: t(UserRoleToString(value)),
        };
    });
};

export const getUserRoleValidator = (
    possibleRolesOptions: UserRole[],
): ValidationRuleCollection<UserRole> => {
    return {
        inArray: (value) => {
            return (
                possibleRolesOptions.find((option) => option === value) !=
                undefined
            );
        },
    };
};
