import { ErrInternalMessage } from "@app/entities/errors";
import * as grpcWeb from "grpc-web";
import * as Sentry from "@sentry/vue";
import { isAdmin, isEmployee, isManager, OrganizationUser, oUserName } from "@app/entities/organzationUser";

export interface IAppError  {
    Error(t: Translator, user?: OrganizationUser): string
}

type Translator = (
    m: string,
    params?: Record<string, string>,
    fallback?: string,
) => string;

type AppErrorParams = {
    message: string
    adminMessage?: string
    teacherMessage?: string
    managerMessage?: string
    organizationUser?: OrganizationUser
    params?: Record<string, string>
}
export class AppError extends Error implements IAppError {
    params?: Record<string, string>;
    organizationUser?: OrganizationUser;

    message: string
    adminMessage?: string
    managerMessage?: string
    teacherMessage?: string

    constructor( params: AppErrorParams) {
        super(params.message);
        this.params = params.params;
        this.adminMessage = params?.adminMessage;
        this.managerMessage = params?.managerMessage;
        this.teacherMessage = params?.teacherMessage;
        this.message = params.message;
        this.organizationUser = params.organizationUser;

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, AppError.prototype);
    }

    Error(t: Translator, currentUser?: OrganizationUser): string {
        const message = this.choseMessage(currentUser);

        return t("errors." + message, {
            ...this.params,
            user: oUserName(currentUser)
        }, message)
    }

    choseMessage(currentUser?: OrganizationUser): string {
        switch (true) {
            case !!this.adminMessage && isAdmin(currentUser):
                return this.adminMessage;
            case !!this.managerMessage && isManager(currentUser):
                return this.managerMessage;
            case !!this.teacherMessage && isEmployee(currentUser):
                return this.teacherMessage;
            default:
                return this.message;
        }
    }
}

export const translateError = (err: Error, t: Translator, currentUser?: OrganizationUser): string => {
    if (err instanceof grpcWeb.RpcError) {
        const e = new AppError({
            message: "Internal",
            adminMessage: err.message + "\n" + err.stack
        })
        return e.Error(t, currentUser);
    }

    if (err instanceof AppError) {
        return err.Error(t, currentUser);
    }
    
    const e = new AppError({
        message: ErrInternalMessage,
        adminMessage: err.message + "\n" + err.stack
    })
    return e.Error(t, currentUser);
};

export const logError = (err: Error): void => {
    if (!(err instanceof AppError)) {
        console.warn(err);
        Sentry.captureException(err);
    }
};
