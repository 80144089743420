import { Organization } from "@app/entities/organzation";
import { PageType } from "@app/pkg/router/pageType";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { useAuthViewData } from "@app/usecase/authUseCase";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { paymentUseCase } from "@app/usecase/paymentUseCase";
import { infoRoutes } from "@organizationView/info/route";
import OrganizationStatisticView from "@organizationView/OrganizationStatisticView.vue";
import { paymentsIndexRoute } from "@organizationView/payments/route";
import {
    NavigationGuardWithThis,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";

type Props = {
    organization: Organization;
};

export const OrganizationStatisticViewBeforeEnter: NavigationGuardWithThis<
    undefined
> = async (to, from, next) => {
    const authView = useAuthViewData();

    const props = getFirsLevelProps<Props>();
    props.organization = organizationUseCase.currentOrganization();

    if (authView.isManager) {
        await paymentUseCase.loadAccounts(
            organizationUseCase.getOrganizationID(),
        );
        await paymentUseCase
            .loadTransactions(organizationUseCase.getOrganizationID())
            .then(async (models) => {
                await paymentUseCase.sync(models);
            });
    }

    // didn't find a better way
    to.params = {
        ...to.params,
        ...(props as unknown as RouteParams),
    };

    next();
};

export const organizationRoutes: RouteRecordRaw[] = [
    {
        path: "/organization",
        children: [
            {
                path: "",
                name: PageType.OrganizationStatistic,
                component: OrganizationStatisticView,
                props: true,
                beforeEnter: OrganizationStatisticViewBeforeEnter,
            },
            infoRoutes,
            paymentsIndexRoute,
        ],
    },
];
