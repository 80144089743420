import { LocationID } from "@app/entities/uuid";

/**
 * @see https://router.vuejs.org/guide/advanced/meta.html
 */
declare module "vue-router" {
    interface RouteMeta {
        // if this route need to be in the breadcrumbs
        breadcrumbs?: AppBreadcrumbs[];

        requiresAuth?: boolean;

        isGuest?: boolean;

        title?: string;

        filter?: WorkoutsFilter;

        workoutsListFilter?: WorkoutsListFilter;

        usersListFilter?: UsersListFilter;
    }
}

export type WorkoutsFilter = {
    currentLocationId: LocationID | undefined;
    dateFrom: Date;
    dateTo: Date;
};

export type WorkoutsListFilter = {
    currentLocationId: string | undefined;
    dateFrom: Date | undefined;
    dateTo: Date | undefined;
    pageNumber: number;
};

export type UsersListFilter = {
    pageNumber: number;
};

export const enum POPUP_ROUTES {
    Organization = "Organization",
    WorkoutUpdate = "WorkoutUpdate",
    SchedulePlan = "SchedulePlan",
    Schedule = "Schedule",
    Workouts = "Workouts",
}

export const enum ROUTES {
    Organization = "Organization",
    Users = "Users",
    UserWorkouts = "UserWorkouts",
    WorkoutsList = "WorkoutsList",
    UserSubscriptions = "UserSubscriptions",
}

export interface AppBreadcrumbs {
    name: string;
    to: string;
}
