import { dateToTimeRange, TimeRange } from "@app/entities/timeRange";
import { LocationID } from "@app/entities/uuid";
import { PageName } from "@app/pkg/router/pageName";
import { PageType } from "@app/pkg/router/pageType";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { getPopupRoutes } from "@app/router/popups";
import { authUseCase } from "@app/usecase/authUseCase";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { userWorkoutsUseCase } from "@app/usecase/userWorkoutUseCase";
import { workoutsUseCase } from "@app/usecase/workoutsUseCase";
import ScheduleIndexView from "@pages/schedule/index/ScheduleIndexView.vue";
import {
    NavigationGuardWithThis,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";

type Props = {
    day: string;
    timeRange: TimeRange;
    locationId: LocationID;
};

export const ScheduleIndexBeforeEnter: NavigationGuardWithThis<
    undefined
> = async (to, from, next) => {
    let dateFrom = new Date();
    if (to.params.day) {
        dateFrom = new Date(to.params.day as string);
    } else {
        to.params.day = "";
    }
    dateFrom.setHours(0, 0, 0, 0);

    const dateTo = new Date(dateFrom);
    dateTo.setDate(dateFrom.getDate() + 1);

    const props = getFirsLevelProps<Props>();
    props.day = to.params.day as string;
    props.timeRange = dateToTimeRange(dateFrom);
    props.locationId = authUseCase.syncFavoriteLocation(to.query);

    const wait: Promise<unknown>[] = [];
    for (let i = 0; i < 4; i++) {
        const from = new Date(dateFrom);
        from.setDate(from.getDate() + 7 * i);
        const to = new Date(dateTo);
        to.setDate(to.getDate() + 7 * i);
        wait.push(
            workoutsUseCase.loadByOrganizationID(
                organizationUseCase.getOrganizationID(),
                {
                    left: from,
                    right: to,
                },
            ),
        );
        wait.push(
            userWorkoutsUseCase
                .loadByOrganizationID(
                    organizationUseCase.getOrganizationID(),
                    from,
                    to,
                )
                .then(async (models) => {
                    await userWorkoutsUseCase.sync(models);
                }),
        );
    }

    await Promise.all(wait);

    // didn't find a better way
    to.params = {
        ...to.params,
        ...(props as unknown as RouteParams),
    };

    next();
};

export const scheduleIndexRoute: RouteRecordRaw = {
    path: "/schedule/:day?",
    name: PageType.Schedule,
    component: ScheduleIndexView,
    props: true,
    beforeEnter: ScheduleIndexBeforeEnter,
    children: getPopupRoutes(PageType.Schedule, [
        {
            name: PageType.Schedule,
            to: PageName.Schedule,
        },
    ]),
};
