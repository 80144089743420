import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { paymentUseCase } from "@app/usecase/paymentUseCase";
import { RouteParams, RouteRecordRaw } from "vue-router";
import { PageType } from "@app/pkg/router/pageType";
import { AppBreadcrumbs } from "@app/router/models";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { StringToEntityID, UserWorkoutID } from "@app/entities/uuid";
import { userWorkoutsUseCase } from "@app/usecase/userWorkoutUseCase";
import { subscriptionsUseCase } from "@app/usecase/subscriptionUseCase";
import UserWorkoutUpdateView from "@app/views/popups/user-workout-update/UserWorkoutUpdateView.vue";

type Props = {
    userWorkoutId: UserWorkoutID;
};
export const userWorkoutUpdateRoute = (
    prefix: string,
    breadcrumbs: AppBreadcrumbs[],
): RouteRecordRaw => {
    return {
        path: "user-workout/:userWorkoutId/update",
        name: prefix + PageType.UserWorkoutUpdate,
        meta: {
            breadcrumbs: breadcrumbs,
        },
        components: {
            popup: UserWorkoutUpdateView,
        },
        props: true,
        beforeEnter: async (to, from, next) => {
            await paymentUseCase.loadAccounts(
                organizationUseCase.getOrganizationID(),
            );

            const userWorkoutID = StringToEntityID(
                to.params.userWorkoutId as string,
            );
            const userWorkout =
                await userWorkoutsUseCase.loadByID(userWorkoutID);
            if (userWorkout) {
                await subscriptionsUseCase.loadByOrganizationUserID(
                    userWorkout.organizationUserID,
                );
            }

            const props = getFirsLevelProps<Props>();
            props.userWorkoutId = userWorkoutID;

            // didn't find a better way
            to.params = {
                ...to.params,
                ...(props as unknown as RouteParams),
            };
            next();
        },
    };
};
