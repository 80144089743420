import {
    NavigationGuardWithThis,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";
import { PageType } from "@app/pkg/router/pageType";
import { PageName } from "@app/pkg/router/pageName";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { useAuthViewData } from "@app/usecase/authUseCase";
import { OrganizationUser } from "@app/entities/organzationUser";
import { getPopupRoutes } from "@app/router/popups";
import PersonalSubscriptionsView from "@pages/personal/subscriptions/PersonalSubscriptionsView.vue";
import { subscriptionsUseCase } from "@app/usecase/subscriptionUseCase";

export type PersonalIndexProps = {
    user: OrganizationUser;
};

export const PersonalSubscriptionsBeforeEnter: NavigationGuardWithThis<
    undefined
> = async (to, from, next) => {
    const auth = useAuthViewData();

    const props = getFirsLevelProps<PersonalIndexProps>();
    props.user = auth.currentUser;

    if (auth.currentUser) {
        await subscriptionsUseCase
            .loadByOrganizationUserID(auth.currentUser.id)
            .then(async (models) => {
                await subscriptionsUseCase.sync(models);
            });
    }

    // didn't find a better way
    to.params = {
        ...to.params,
        ...(props as unknown as RouteParams),
    };

    next();
};

export const personalSubscriptionsRoute: RouteRecordRaw = {
    path: "/personal/subscriptions",
    name: PageType.UserSubscriptions,
    component: PersonalSubscriptionsView,
    props: true,
    beforeEnter: PersonalSubscriptionsBeforeEnter,
    children: getPopupRoutes(PageType.UserSubscriptions, [
        {
            name: PageName.UserSubscriptions,
            to: PageType.UserSubscriptions,
        },
    ]),
};
