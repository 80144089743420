import { PbToUUIDSure } from "@grpc/converters/uuid";
import { UserMessage } from "proto-api/auth/user_pb";
import { User } from "@app/entities/user";

export const PbToUser = (resource: UserMessage): User => {
    return {
        id: PbToUUIDSure(resource.getId()),
        email: resource.getEmail(),
        isAdmin: resource.getIsAdmin(),
        name: resource.getNickname(),
        nickname: resource.getNickname(),
        firstName: resource.getFirstName(),
        lastName: resource.getLastName(),
        phone: resource.getPhone(),
        imageUrl: "",
    };
};
