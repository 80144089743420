import { PbToUUIDSure } from "@grpc/converters/uuid";
import { PbToDateTime } from "@grpc/converters/dateTimeRange";
import { pbToDateSure } from "@grpc/converters/date";
import { ScheduleMessage } from "proto-api/organization/schedule_pb";
import { Schedule } from "@app/entities/schedule";
import { PbToTimeRange } from "@grpc/converters/timeRange";

export const PbToSchedule = (resource: ScheduleMessage): Schedule => {
    return {
        id: PbToUUIDSure(resource.getId()),
        organizationID: PbToUUIDSure(resource.getOrganizationId()),
        locationID: PbToUUIDSure(resource.getLocationId()),
        trainerID: PbToUUIDSure(resource.getTrainerId()),
        disciplineID: PbToUUIDSure(resource.getDisciplineId()),
        dateRange: PbToDateTime(resource.getDateRange()),
        timeRange: PbToTimeRange(resource.getTimeRange()),
        studentsCount: resource.getStudentsCount(),
        createdAt: pbToDateSure(resource.getCreatedAt()),
        updatedAt: pbToDateSure(resource.getUpdatedAt()),
        deletedAt: resource.getDeletedAt()?.toDate(),
    };
};
