export declare type UUID = {
    value: string;
};
export declare type EntityFileID = {
    value: string;
};
export declare type OrganizationID = {
    value: string;
};
export declare type WorkoutID = {
    value: string;
};
export declare type UserID = {
    value: string;
};
export declare type OrganizationUserID = {
    value: string;
};
export declare type DisciplineID = {
    value: string;
};
export declare type ScheduleID = {
    value: string;
};
export declare type LocationID = {
    value: string;
};
export declare type EventID = {
    value: string;
};
export declare type EventChannelID = {
    value: string;
};
export declare type EventMessageID = {
    value: string;
};
export declare type UserWorkoutID = {
    value: string;
};
export declare type SubscriptionID = {
    value: string;
};
export declare type PaymentID = {
    value: string;
};
export declare type SubscriptionTypeID = {
    value: string;
};
export declare type BalanceAccountID = {
    value: string;
};
export declare type BalanceTransactionID = {
    value: string;
};
export declare type WorkoutDraftItemID = {
    value: number;
};
export declare type PaymentAccountID = {
    value: string;
};
export declare type PaymentTransactionID = {
    value: string;
};

export const StringToEntityID = (val: string): UUID => {
    return {
        value: val,
    };
};

export const StringPossibleToEntityID = (val: string): UUID | undefined => {
    if (val) {
        return {
            value: val,
        };
    }
    return;
};

export const StringsToEntityID = (val: string[]): UUID[] => {
    return val.map(StringToEntityID);
};

export const EntityIDToString = (val?: UUID): string => {
    return val ? val.value : "";
};

export const EntityIDsToString = (val: UUID[]): string[] => {
    return val.map(EntityIDToString);
};

export const listIDsUnique = <T extends UUID>(ids: (T | undefined)[]): T[] => {
    const res: Record<string, T> = {};
    ids.forEach((id) => {
        if (id !== undefined) {
            res[EntityIDToString(id)] = id;
        }
    });
    return Object.values(res);
};
