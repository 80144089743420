import { Workout } from "@app/entities/workout";
import { WorkoutServicePromiseClient } from "proto-api/organization/workout_service_grpc_web_pb";
import {
    WorkoutCancelRequestMessage,
    WorkoutCloseRequestMessage,
    WorkoutGetByIDRequestMessage,
    WorkoutGetByOrganizationIDRequestMessage,
    WorkoutStoreRequestMessage,
    WorkoutUpdateRequestMessage,
} from "proto-api/organization/workout_service_pb";
import { Context } from "@app/entities/context";
import { PbToEntities } from "@grpc/converters/entities";
import { PbToWorkout } from "@grpc/converters/workout";
import {
    DisciplineID,
    LocationID,
    OrganizationID,
    ScheduleID,
    UserID,
    WorkoutID,
} from "@app/entities/uuid";
import { EntityToUUID } from "@grpc/converters/uuid";
import { DateTimeRangeToPb } from "@grpc/converters/dateTimeRange";
import { ContextToPb } from "@grpc/converters/context";
import { UserWorkout } from "@app/entities/userWorkout";
import { PbToUserWorkout } from "@grpc/converters/userWorkout";
import { DateTimeRange } from "@app/entities/dateTimeRange";

//"https://grpc-local.fitfrog.ru"
const service = new WorkoutServicePromiseClient(
    `${import.meta.env.VITE_GRPC_BASE_URL}`,
    null,
);

export const workoutClient = {
    async loadByOrganizationID(
        ctx: Context,
        id: OrganizationID,
        dateRange: DateTimeRange,
    ): Promise<Workout[]> {
        const request = new WorkoutGetByOrganizationIDRequestMessage();
        request.setOrganizationId(EntityToUUID(id));
        request.setDateRange(DateTimeRangeToPb(dateRange));

        const response = await service.getByOrganizationID(
            request,
            ContextToPb(ctx),
        );

        return PbToEntities(response.getWorkoutsList(), PbToWorkout);
    },

    loadByID: async (
        ctx: Context,
        id: WorkoutID,
    ): Promise<Workout | undefined> => {
        const request = new WorkoutGetByIDRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.getByID(request, ContextToPb(ctx));

        const message = response.getWorkout();
        if (message) {
            return PbToWorkout(message);
        }
        return;
    },

    async store(
        ctx: Context,
        organizationID: OrganizationID,
        trainerID: UserID,
        disciplineID: DisciplineID,
        locationID: LocationID,
        studentsCount: number,
        timeFrom: Date,
        timeTo: Date,
        scheduleID?: ScheduleID,
    ): Promise<Workout | undefined> {
        const request = new WorkoutStoreRequestMessage();
        if (scheduleID) {
            request.setScheduleId(EntityToUUID(scheduleID));
        }
        request.setOrganizationId(EntityToUUID(organizationID));
        request.setDisciplineId(EntityToUUID(disciplineID));
        request.setLocationId(EntityToUUID(locationID));
        request.setTrainerId(EntityToUUID(trainerID));
        request.setDateTimeRange(
            DateTimeRangeToPb({
                left: timeFrom,
                right: timeTo,
            }),
        );
        request.setStudentsCount(studentsCount);

        const response = await service.store(request, ContextToPb(ctx));

        const workoutMessage = response.getWorkout();
        if (workoutMessage) {
            return PbToWorkout(workoutMessage);
        }

        return;
    },

    update: async (
        ctx: Context,
        id: WorkoutID,
        trainerID: UserID,
        disciplineID: DisciplineID,
        locationID: LocationID,
        studentsCount: number,
        timeFrom: Date,
        timeTo: Date,
        scheduleID?: ScheduleID,
    ): Promise<Workout | undefined> => {
        const request = new WorkoutUpdateRequestMessage();
        request.setId(EntityToUUID(id));
        request.setDisciplineId(EntityToUUID(disciplineID));
        request.setLocationId(EntityToUUID(locationID));
        request.setTrainerId(EntityToUUID(trainerID));
        request.setDateTimeRange(
            DateTimeRangeToPb({
                left: timeFrom,
                right: timeTo,
            }),
        );
        request.setStudentsCount(studentsCount);
        if (scheduleID) {
            request.setScheduleId(EntityToUUID(scheduleID));
        }

        const response = await service.update(request, ContextToPb(ctx));

        const workoutMessage = response.getWorkout();
        if (workoutMessage) {
            return PbToWorkout(workoutMessage);
        }

        return;
    },

    close: async (
        ctx: Context,
        id: WorkoutID,
    ): Promise<
        | {
              workout: Workout;
              userWorkouts: UserWorkout[];
          }
        | undefined
    > => {
        const request = new WorkoutCloseRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.close(request, ContextToPb(ctx));

        const message = response.getWorkout();
        if (message) {
            return {
                userWorkouts: PbToEntities(
                    response.getUserWorkoutsList(),
                    PbToUserWorkout,
                ),
                workout: PbToWorkout(message),
            };
        }
        return;
    },

    cancel: async (
        ctx: Context,
        id: WorkoutID,
        keepBalance: boolean,
    ): Promise<
        | {
              workout: Workout;
              userWorkouts: UserWorkout[];
          }
        | undefined
    > => {
        const request = new WorkoutCancelRequestMessage();
        request.setId(EntityToUUID(id));

        request.setKeepBalance(keepBalance);

        const response = await service.cancel(request, ContextToPb(ctx));

        const message = response.getWorkout();
        if (message) {
            return {
                userWorkouts: PbToEntities(
                    response.getUserWorkoutsList(),
                    PbToUserWorkout,
                ),
                workout: PbToWorkout(message),
            };
        }
        return;
    },
};
