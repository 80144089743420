<template>
  <div
    class="tabs tabs-lifted font-mono tabs-sm pb-4"
    role="tablist"
  >
    <router-link
      v-for="day of days"
      :key="day.num"
      v-slot="{ href, navigate, isActive }"
      :to="day.to"
      custom
    >
      <a
        :class="{
          'tab leading-none h-10': true,
          'tab-active font-bold': isActive,
          '': !isActive,
          'day-off': day.isDayOf,
        }"
        :href="href"
        role="tab"
        v-bind="$attrs"
        @click="navigate"
      >
        <div
          :class="{
            hidden: type === 'weekday',
          }"
          class="w-full"
        >
          {{ day.num }}
        </div>
        <div class="w-full capitalize">{{ day.text }}</div>
      </a>
    </router-link>
  </div>
</template>

<script lang="ts" setup>
import { touchStart } from "@app/directives/touchstart";
import { PageType } from "@app/pkg/router/pageType";
import { formatDateISO } from "@app/services/format";
import { DayLink } from "@app/views/entities/dayLink";
import { initLinks } from "@app/views/entities/weekday";
import { computed, ComputedRef, onBeforeMount, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const props = defineProps<{
    activeDate: Date;
    leftDate?: Date;
    rightDate?: Date;
    routeName: PageType;
    type: "weekday" | "calendar";
}>();

const { d } = useI18n();
const route = useRoute();
const router = useRouter();

const startDay = computed(() => {
    const left = new Date();
    const right = new Date();
    right.setDate(right.getDate() + 6);
    if (props.activeDate < left) {
        return new Date(props.activeDate);
    }
    if (props.activeDate > right) {
        const r = new Date(props.activeDate);
        r.setDate(r.getDate() - 6);
        return r;
    }
    return left;
});

const days: ComputedRef<DayLink[]> = computed(() => {
    const now = formatDateISO(new Date());
    return initLinks(
        startDay.value,
        d,
        (today: Date, day: string) => {
            return {
                name: props.routeName ? props.routeName : undefined,
                params: {
                    day: now === day ? "" : day,
                },
                query: {
                    ...route.query,
                },
            };
        },
        route.params.day as string,
    );
});

const pushDay = async (day: Date) => {
    const dayISO = formatDateISO(day);

    // We have to be exactly at the same page
    if (route.name === props.routeName) {
        await router.push({
            name: route.name ? route.name : undefined,
            params:
                dayISO === formatDateISO(new Date())
                    ? {}
                    : {
                          day: dayISO,
                      },
            query: {
                ...route.query,
            },
        });
    }
};
const touchEvent = touchStart(
    async () => {
        const nextDay = new Date(props.activeDate);
        nextDay.setDate(nextDay.getDate() + 1);
        nextDay.setHours(0, 0, 0, 0);
        if (!props.rightDate || props.rightDate > nextDay) {
            await pushDay(nextDay);
        }
    },
    async () => {
        const prevDay = new Date(props.activeDate);
        prevDay.setDate(prevDay.getDate() - 1);
        prevDay.setHours(0, 0, 0, 0);
        if (!props.leftDate || props.leftDate <= prevDay) {
            await pushDay(prevDay);
        }
    },
);
onBeforeMount(() => {
    addEventListener("touchstart", touchEvent);
});
onBeforeUnmount(() => {
    removeEventListener("touchstart", touchEvent);
});
</script>

<style scoped>
.tabs .tab.day-off {
    @apply text-red-600 !important;
}

.tab-lifted.tab-active {
    padding-left: 0;
    padding-right: 0;
    --tab-padding: 0;
}
</style>
