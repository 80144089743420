<template>
  <form-component
    :form="vueForm"
    class="mt-8 space-y-6"
  >
    <div class="rounded-md shadow-sm -space-y-px">
      <form-field
        :field="form.password"
        class="block relative w-full"
      >
        <text-input-field
          :field="form.password"
          :placeholder="t('Password')"
          type="password"
        />
      </form-field>
      <form-field
        :field="form.password_confirmation"
        class="block relative w-full"
      >
        <text-input-field
          :field="form.password_confirmation"
          :placeholder="t('Password confirm')"
          type="password"
        />
      </form-field>
    </div>

    <div class="flex items-center justify-between">
      <div class="flex items-center" />

      <div class="text-sm">
        <router-link
          :to="{ name: 'Login' }"
          class="font-medium text-indigo-600 hover:text-indigo-500"
        >
          {{ t("Login") }}
        </router-link>
      </div>
    </div>

    <div>
      <button
        class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        type="submit"
      >
        {{ t("Reset password") }}
      </button>
    </div>
  </form-component>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from "vue-router";
import { reactive } from "vue";
import { ValidationArgs } from "@vuelidate/core";
import { email, required } from "@app/services/validators";
import { appForm } from "@app/services/forms";
import FormComponent from "@app/components/FormComponent.vue";
import TextInputField from "@app/components/forms/fields/TextInputField.vue";
import FormField from "@app/components/forms/fields/FormField.vue";
import { useI18n } from "vue-i18n";
import { authUseCase } from "@app/usecase/authUseCase";
import { organizationUseCase } from "@app/usecase/organizationUseCase";

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const organization = organizationUseCase.currentOrganization();

interface PasswordResetFormFields {
    email: string;
    token: string;
    password: string;
    password_confirmation: string;
}

const formFields = reactive<PasswordResetFormFields>({
    email: route.query.email as string,
    token: route.query.token as string,
    password: "",
    password_confirmation: "",
});

const rules: ValidationArgs<PasswordResetFormFields> = {
    email: { required, email },
    token: { required },
    password: { required },
    password_confirmation: { required },
};

const vueForm = appForm<PasswordResetFormFields, void>(
    rules,
    formFields,
    async () => {
        await authUseCase.resetPassword(
            organization.id,
            formFields.email,
            formFields.password,
            formFields.token,
        );
        await router.push({
            name: "Login",
        });
        return;
    },
);
const form = vueForm.form;
</script>

<style scoped></style>
