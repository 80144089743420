import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { paymentUseCase } from "@app/usecase/paymentUseCase";
import {
    RouteLocationNormalizedLoaded,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";
import { PageType } from "@app/pkg/router/pageType";
import { AppBreadcrumbs } from "@app/router/models";
import { getFirsLevelProps } from "@app/pkg/router/props";
import {
    EntityIDToString,
    StringToEntityID,
    SubscriptionID,
} from "@app/entities/uuid";
import { Link, LinkIcons } from "@app/views/models/links";
import { pupUpRoute } from "@app/router/popups";
import { authUseCase, ENTITIES_LIST } from "@app/usecase/authUseCase";
import { subscriptionsUseCase } from "@app/usecase/subscriptionUseCase";
import SubscriptionUpdateView from "@app/views/popups/subsctiption-update/SubscriptionUpdateView.vue";

interface Props {
    subscriptionId: SubscriptionID;
}

export const getSubscriptionUpdateLink = (
    route: RouteLocationNormalizedLoaded,
    text: string,
    subscriptionID: SubscriptionID,
): Link => {
    return {
        image: LinkIcons.pencil,
        text: text,
        to: pupUpRoute(route, PageType.UserSubscriptionUpdate, {
            subscriptionId: EntityIDToString(subscriptionID),
        }),
        disabled: !authUseCase.canEdit(ENTITIES_LIST.SUBSCRIPTION),
    };
};

export const subscriptionUpdateRoute = (
    prefix: string,
    breadcrumbs: AppBreadcrumbs[],
): RouteRecordRaw => {
    return {
        path: "subscription-update/:subscriptionId",
        name: prefix + PageType.UserSubscriptionUpdate,
        meta: {
            breadcrumbs: breadcrumbs,
        },
        components: {
            popup: SubscriptionUpdateView,
        },
        props: true,
        beforeEnter: async (to, from, next) => {
            const id = StringToEntityID(to.params.subscriptionId as string);
            await paymentUseCase.loadAccounts(
                organizationUseCase.getOrganizationID(),
            );

            await subscriptionsUseCase.loadByID(id);

            const props = getFirsLevelProps<Props>();
            props.subscriptionId = id;

            // didn't find a better way
            to.params = {
                ...to.params,
                ...(props as unknown as RouteParams),
            };
            next();
        },
    };
};
