import LocationsView from "@locationsView/LocationsView.vue";
import { getFirsLevelProps } from "@app/pkg/router/props";
import LocationAddView from "@locationsView/LocationAddView.vue";
import LocationUpdateView from "@locationsView/LocationUpdateView.vue";
import {
    RouteLocationNormalized,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { locationUseCase } from "@app/usecase/locationUseCase";
import { PageType } from "@app/pkg/router/pageType";
import { PageName } from "@app/pkg/router/pageName";
import { StringToEntityID } from "@app/entities/uuid";
import { Location } from "@app/entities/location";

type Props = {
    location: Location;
};
export const locationsRoutes: RouteRecordRaw[] = [
    {
        path: "/locations",
        name: PageType.Locations,
        component: LocationsView,
        props: true,
        beforeEnter: async (to: RouteLocationNormalized, from, next) => {
            await locationUseCase.getByOrganizationID(
                organizationUseCase.getOrganizationID(),
            );

            const props = getFirsLevelProps();
            // didn't find a better way
            to.params = {
                ...to.params,
                ...(props as unknown as RouteParams),
            };
            next();
        },
    },
    {
        path: "/locations/add",
        name: PageType.LocationAdd,
        component: LocationAddView,
        props: getFirsLevelProps(),
        meta: {
            breadcrumbs: [
                {
                    name: PageName.Locations,
                    to: PageType.Locations,
                },
            ],
        },
    },
    {
        path: "/locations/:id",
        name: PageType.LocationUpdate,
        component: LocationUpdateView,
        props: true,
        meta: {
            breadcrumbs: [
                {
                    name: PageName.Locations,
                    to: PageType.Locations,
                },
            ],
        },
        beforeEnter: async (to, from, next) => {
            const location = await locationUseCase.getByID(
                StringToEntityID(to.params.id as string),
            );

            const props = getFirsLevelProps<Props>();
            props.location = location;

            // didn't find a better way
            to.params = {
                ...to.params,
                ...(props as unknown as RouteParams),
            };
            next();
        },
    },
];
