export const ErrInternalMessage = "Internal";
export const ErrLoadError = new Error(
    "Something went wrong while loading data",
);

export const ErrForbiddenMessage = "forbidden";

export const ErrOrganizationNotFound = new Error("Organization not found");

export const ErrWorkoutNotFound = "Workout not found";
export const ErrUserWorkoutNotFound = "User's workout not found";

export const ErrUserNotFound = new Error("user not found");

export const ErrDisciplineNotFound = new Error("Discipline not found");

export const ErrLocationNotFound = new Error("Location not found");

export const ErrPaymentError = new Error("Couldn't pay");

export const ErrSomethingWentWrong = new Error("Something went wrong");

export const ErrAuthorizeError = "User authorize error";
