import { WorkoutStatus } from "@app/entities/workoutStatus";

export interface DateRange {
    from: Date;
    to: Date;
}

export interface DateRangePartial {
    from: Date | null;
    to: Date | null;
}

export const enum DisplayType {
    block,
    inlineBlock,
}

export const enum DisplayColor {
    primary,
    secondary,
    error,
    success,
}

export function statusToColor(status: WorkoutStatus): DisplayColor {
    switch (status) {
        case WorkoutStatus.WorkoutStatusOpen:
        case WorkoutStatus.WorkoutStatusClosed:
            return DisplayColor.success;
        case WorkoutStatus.WorkoutStatusCancelled:
            return DisplayColor.error;
        default:
            return DisplayColor.primary;
    }
}
