<template>
  <!--
This example requires updating your template:

```
<html class="h-full bg-gray-100">
<body class="h-full">
```
-->
  <div class="h-full">
    <Disclosure
      v-slot="{ open, close }"
      as="nav"
      class="bg-gray-800"
    >
      <div class="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between">
          <div class="flex grow items-center">
            <div
              v-if="organizationView.squareLogoPath"
              class="flex-shrink-0"
            >
              <img
                :src="organizationView.squareLogoPath"
                alt="Workflow"
                class="h-16"
              >
            </div>
            <div class="hidden md:block">
              <div class="ml-10 flex items-baseline space-x-4">
                <router-link
                  v-for="item in navigation"
                  :key="item.name"
                  :class="[
                    item.name === route.name
                      ? ''
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'px-3 py-2 rounded-md text-sm font-medium',
                  ]"
                  :to="item.to as RouteLocationRaw"
                  active-class="bg-gray-900 text-white"
                >
                  {{ item.name }}
                </router-link>
              </div>
            </div>
          </div>
          <div class="hidden md:block">
            <div class="ml-4 flex items-center md:ml-6">
              <!-- Profile dropdown -->
              <router-link
                :to="{ name: PageType.Personal }"
                class="flex max-w-xs items-center rounded-full bg-gray-800 text-sm"
              >
                <UserCircleIcon class="h-8 w-8 text-gray-500" />
                <span class="text-left leading-4 pl-1 pr-2">
                  <span class="text-white">{{
                    authView.userName
                  }}</span><br>
                  <span
                    v-if="user"
                    class="text-gray-300"
                  >{{
                    user.user.email
                  }}</span>
                </span>
              </router-link>
              <Menu
                as="div"
                class="relative ml-3 z-20"
              >
                <div>
                  <MenuButton
                    class="flex max-w-xs items-center rounded-full bg-gray-800 text-sm"
                  >
                    <span
                      class="inline-flex items-center justify-center rounded-md bg-gray-800 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <Bars4Icon
                        v-if="!open"
                        aria-hidden="true"
                        class="block h-6 w-6"
                      />
                      <XMarkIcon
                        v-else
                        aria-hidden="true"
                        class="block h-6 w-6"
                      />
                    </span>
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                  name="Menu"
                >
                  <MenuItems
                    class="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <MenuItem
                      v-for="item in userNavigation"
                      :key="item.name"
                    >
                      <router-link
                        v-if="item.to"
                        :class="[
                          route.name === item.name
                            ? 'bg-gray-100'
                            : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        ]"
                        :to="item.to"
                        @click="close()"
                      >
                        {{ item.name }}
                      </router-link>
                      <a
                        v-else-if="item.action"
                        :class="[
                          route.name === item.name
                            ? 'bg-gray-100'
                            : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        ]"
                        class="cursor-pointer"
                        @click="
                          close();
                          item.action &&
                            item.action();
                        "
                      >{{ item.name }}</a>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
          <div class="flex md:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="inline-flex items-center justify-center rounded-md bg-gray-800 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
            >
              <span class="sr-only">Open main menu</span>
              <Bars4Icon
                v-if="!open"
                aria-hidden="true"
                class="block h-6 w-6"
              />
              <XMarkIcon
                v-else
                aria-hidden="true"
                class="block h-6 w-6"
              />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel class="md:hidden">
        <div class="space-y-1 px-2 pt-2 pb-3 sm:px-3">
          <router-link
            v-for="item in navigation"
            :key="item.name"
            :aria-current="
              item.name === route.name ? 'page' : undefined
            "
            :class="[
              item.name === route.name
                ? 'bg-gray-900 text-white'
                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
              'block px-3 py-2 rounded-md text-base font-medium',
            ]"
            :to="item.to as RouteLocationRaw"
            @click="close()"
          >
            {{ item.name }}
          </router-link>
        </div>
        <div class="border-t border-gray-700 pt-4 pb-3">
          <div class="flex items-center px-5">
            <div class="flex-shrink-0">
              <!--                            <img-->
              <!--                                v-if="user"-->
              <!--                                :src="user.user.imageUrl"-->
              <!--                                alt=""-->
              <!--                                class="h-10 w-10 rounded-full"-->
              <!--                            />-->
              <UserCircleIcon class="h-10 w-10 text-gray-500" />
            </div>
            <div class="ml-3">
              <router-link
                :to="{ name: PageType.Personal }"
                @click="close()"
              >
                <div
                  class="text-base font-medium leading-none text-white"
                >
                  {{ authView.userName }}
                </div>
                <div
                  v-if="user"
                  class="text-sm font-medium leading-none text-gray-400"
                >
                  {{ user.user.email }}
                </div>
              </router-link>
            </div>
            <button
              class="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
              type="button"
            >
              <span class="sr-only">View notifications</span>
              <BellIcon
                aria-hidden="true"
                class="h-6 w-6"
              />
            </button>
          </div>
          <div class="mt-3 space-y-1 px-2">
            <div
              v-for="item in userNavigation"
              :key="item.name"
            >
              <router-link
                v-if="item.to"
                :class="[
                  item.name === route.name
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'block px-3 py-2 rounded-md text-base font-medium',
                ]"
                :to="item.to as RouteLocationRaw"
                @click="close()"
              >
                {{ item.name }}
              </router-link>
              <a
                v-else
                :class="[
                  item.name === route.name
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'block px-3 py-2 rounded-md text-base font-medium',
                ]"
                class="cursor-pointer"
                @click="item.action && item.action()"
              >{{ item.name }}</a>
            </div>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>

    <user-final-step-view
      v-if="authView.currentUser && authView.hasToPassFinalStep"
      :user="authView.currentUser"
    />
    <div
      v-else
      class="pb-16"
    >
      <router-view />
      <bottom-navigation />
    </div>
  </div>
</template>

<script lang="ts" setup>
import BottomNavigation from "@app/layouts/shared/BottomNavigation.vue";
import { PageType } from "@app/pkg/router/pageType";
import { ROUTES } from "@app/router/models";
import { useAuthViewData } from "@app/usecase/authUseCase";
import { useOrganizationViewData } from "@app/usecase/organizationUseCase";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from "@headlessui/vue";
import {
    Bars4Icon,
    BellIcon,
    UserCircleIcon,
    XMarkIcon,
} from "@heroicons/vue/24/outline";
import UserFinalStepView from "@pages/UserFinalStepView.vue";
import { computed, ComputedRef } from "vue";
import { useI18n } from "vue-i18n";
import { RouteLocationRaw, RouterLink, useRoute, useRouter } from "vue-router";

interface NavigationItem {
    name: string;
    to?: RouteLocationRaw;
    action?: () => void;

    disabled: boolean;
}

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const authView = useAuthViewData();
const organizationView = useOrganizationViewData();

const user = computed(() => {
    return authView.currentUser;
});
const navigation: NavigationItem[] = [
    { name: t("Workouts"), to: { name: PageType.Workouts }, disabled: false },
];

const logout = async () => {
    await router.push({
        name: PageType.Logout,
    });
};

const userNavigation: ComputedRef<NavigationItem[]> = computed(() =>
    [
        {
            name: t("My workouts"),
            to: { name: ROUTES.UserWorkouts },
            disabled: !authView.isAuth,
        },
        {
            name: t("My subscriptions"),
            to: { name: ROUTES.UserSubscriptions },
            disabled: !authView.isAuth,
        },
        {
            name: t("Organization"),
            to: { name: PageType.OrganizationStatistic },
            disabled: !authView.isEmployee,
        },
        {
            name: t("Users"),
            to: { name: "Users" },
            disabled: !authView.isManager,
        },
        {
            name: t("Locations"),
            to: { name: "Locations" },
            disabled: !authView.isManager,
        },
        {
            name: t("Disciplines"),
            to: { name: "Disciplines" },
            disabled: !authView.isManager,
        },
        {
            name: t("Subscription types"),
            to: { name: "SubscriptionTypes" },
            disabled: !authView.isManager,
        },
        { name: t("Sign out"), action: logout, disabled: !authView.isAuth },
    ].filter((value) => !value.disabled),
);
</script>

<style scoped></style>
