<template>
  <page-component
    :links="actionLinks"
    :title="t('Workouts')"
  >
    <location-filter-form class="mb-2" />
    <weekday-navigation
      :left-date="leftDate"
      :right-date="rightDate"
      :active-date="dateFrom"
      :route-name="PageType.Workouts"
      type="calendar"
    />
    <error-boundary-component
      v-for="workout of workouts"
      :key="EntityIDToString(workout.id)"
    >
      <workout-item-component
        :model="workout"
        :show-date="false"
        :show-location="!locationId"
      />
    </error-boundary-component>
  </page-component>
</template>

<script lang="ts" setup>
import PageComponent from "@app/components/PageComponent.vue";
import { Link, LinkIcons } from "@app/views/models/links";
import { useI18n } from "vue-i18n";
import WeekdayNavigation from "@app/components/WeekdayNavigation.vue";
import { computed, ComputedRef } from "vue";
import { authUseCase, ENTITIES_LIST } from "@app/usecase/authUseCase";
import { PageType } from "@app/pkg/router/pageType";
import { PageName } from "@app/pkg/router/pageName";
import { EntityIDToString, LocationID } from "@app/entities/uuid";
import ErrorBoundaryComponent from "@app/components/ErrorBoundaryComponent.vue";
import { onBeforeRouteUpdate, useRoute } from "vue-router";
import LocationFilterForm from "@app/components/forms/LocationFilterForm.vue";
import { pupUpRoute } from "@app/router/popups";
import { useWorkoutViewData } from "@app/usecase/workoutsUseCase";
import { WorkoutsIndexViewBeforeEnter } from "@pages/workouts/index/route";
import WorkoutItemComponent from "@app/views/entityComponents/WorkoutItemComponent.vue";

type Props = {
    day: string;
    dateFrom: Date;
    dateTo: Date;
    locationId?: LocationID;
};
const { t } = useI18n();
const route = useRoute();
const viewData = useWorkoutViewData();

const leftDate = new Date();
leftDate.setHours(0, 0, 0, 0);
const rightDate = new Date(leftDate);

leftDate.setDate(leftDate.getDate() - 31);
rightDate.setDate(rightDate.getDate() + 14);

const props = defineProps<Props>();

const workouts = computed(() =>
    viewData.getByDate(
        props.dateFrom,
        props.dateTo,
        props.locationId,
        undefined,
    ),
);

const actionLinks: ComputedRef<Link[]> = computed(() => [
    {
        text: t("Add workout"),
        image: LinkIcons.add,
        to: pupUpRoute(route, PageType.WorkoutAdd, {}),
        disabled: !authUseCase.canEdit(ENTITIES_LIST.SCHEDULE),
    },
    {
        text: t(PageName.Schedule),
        image: LinkIcons.calendar,
        to: {
            name: PageType.Schedule,
        },
        disabled: !authUseCase.canView(ENTITIES_LIST.SCHEDULE),
    },
    {
        text: t("Workouts list"),
        image: LinkIcons.calendar,
        to: {
            name: PageType.WorkoutsList,
        },
        disabled: !authUseCase.canEdit(ENTITIES_LIST.WORKOUT),
    },
    {
        text: t("Schedule plan"),
        image: LinkIcons.presentationChart,
        to: {
            name: PageType.SchedulePlan,
        },
        disabled: !authUseCase.canEdit(ENTITIES_LIST.SCHEDULE),
    },
]);
onBeforeRouteUpdate(WorkoutsIndexViewBeforeEnter);
</script>

<style scoped></style>
