<template>
  <form-component :form="vueForm">
    <div class="columns-2xs">
      <form-field
        :field="form.disciplines"
        :label="t('Disciplines')"
      >
        <checkbox-field
          :field="form.disciplines"
          :options="props.disciplinesOptions"
        />
      </form-field>
      <form-field
        :field="form.start_date"
        :label="t('Start date')"
      >
        <date-input-field :field="form.start_date" />
      </form-field>
      <form-field
        :field="form.expire_date"
        :label="t('Expire date')"
      >
        <date-input-field :field="form.expire_date" />
      </form-field>
      <toggle-field
        :field="form.is_change_balance"
        :label="t('Is change balance')"
      />
      <form-field
        v-if="formFields.is_change_balance === true"
        :field="form.balance_operation"
        label="Operation"
      >
        <select-box-field
          :field="form.balance_operation"
          :options="balanceOperations"
        />
      </form-field>
      <form-field
        v-if="formFields.is_change_balance === true"
        :field="form.balance_amount"
        label="Amount of workouts"
      >
        <text-input-field
          :field="form.balance_amount"
        />
      </form-field>
    </div>
    <submit-button />
  </form-component>
</template>

<script lang="ts" setup>
import FormComponent from "@app/components/FormComponent.vue";
import CheckboxField from "@app/components/forms/fields/CheckboxField.vue";
import DateInputField from "@app/components/forms/fields/DateInputField.vue";
import FormField from "@app/components/forms/fields/FormField.vue";
import SelectBoxField from "@app/components/forms/fields/SelectBoxField.vue";
import SubmitButton from "@app/components/forms/fields/SubmitButton.vue";
import TextInputField from "@app/components/forms/fields/TextInputField.vue";
import ToggleField from "@app/components/forms/fields/ToggleField.vue";
import { Subscription } from "@app/entities/subscription";
import { EntityIDsToString, StringsToEntityID } from "@app/entities/uuid";
import { formatDateISO } from "@app/services/format";
import { appForm } from "@app/services/forms";
import { required } from "@app/services/validators";
import { balanceUseCase } from "@app/usecase/balanceUseCase";
import { subscriptionsUseCase } from "@app/usecase/subscriptionUseCase";
import { StringToDate } from "@app/views/converters/date";
import { SelectOption } from "@app/views/models/form";
import { ValidationArgs } from "@vuelidate/core";
import { reactive } from "vue";
import { useI18n } from "vue-i18n";

interface UpdateSubscriptionFormFields {
    start_date: string;
    expire_date: string;
    disciplines: string[];
    is_change_balance: boolean;
    balance_amount: number;
    balance_operation: string;
}

const props = defineProps<{
    subscription: Subscription;
    subscriptionTypeOptions: SelectOption<string>[];
    disciplinesOptions: SelectOption<string>[];
    paymentAccounts: SelectOption<string>[];
}>();

const emits = defineEmits<{
    (e: "on-success", value: Subscription): void;
}>();
const { t } = useI18n();

const balanceOperationAdd = "add";
const balanceOperationTake = "take";
const balanceOperations: SelectOption<string | number>[] = [
    {
        value: balanceOperationAdd,
        displayValue: t("Add to Balance"),
    },
    {
        value: balanceOperationTake,
        displayValue: t("Take from Balance"),
    },
];

const rules: ValidationArgs<UpdateSubscriptionFormFields> = {
    disciplines: {
        required: required,
    },
    start_date: {
        required: required,
    },
    expire_date: {
        required: required,
    },
    is_change_balance: {},
    balance_amount: {},
    balance_operation: {},
};
const formFields = reactive<UpdateSubscriptionFormFields>({
    disciplines: EntityIDsToString(props.subscription.disciplineIDs),
    start_date: formatDateISO(props.subscription.startDate),
    expire_date: formatDateISO(props.subscription.displayExpireDate),
    is_change_balance: false,
    balance_amount: 0,
    balance_operation: "add",
});

const vueForm = appForm<UpdateSubscriptionFormFields, void>(
    rules,
    formFields,
    async () => {
        const dateTo = StringToDate(formFields.expire_date);
        dateTo.setDate(dateTo.getDate() + 1);

        const model = await subscriptionsUseCase.update(
            props.subscription.id,
            StringsToEntityID(formFields.disciplines),
            StringToDate(formFields.start_date),
            dateTo,
        );

        if (!model) {
            throw Error("Something went wrong");
        }

        if (formFields.is_change_balance && formFields.balance_amount) {
            if (formFields.balance_operation == balanceOperationAdd) {
                await balanceUseCase.storeTransaction(
                    undefined,
                    model.balanceID,
                    Number(formFields.balance_amount),
                    undefined,
                );
            } else {
                await balanceUseCase.storeTransaction(
                    model.balanceID,
                    undefined,
                    Number(formFields.balance_amount),
                    undefined,
                );
            }
        }

        emits("on-success", model);
    },
);
const form = vueForm.form;
</script>

<style scoped></style>
