import { PersonalServicePromiseClient } from "proto-api/organization/personal_service_grpc_web_pb";
import {
    CreateBrowserEventChannelRequestMessage,
    InfoRequestMessage,
} from "proto-api/organization/personal_service_pb";
import { Context } from "@app/entities/context";
import { PbToEntities } from "@grpc/converters/entities";
import { PbToEventChannel } from "@grpc/converters/eventChannel";
import { EventChannel } from "@app/entities/eventChannel";
import { ContextToPb } from "@grpc/converters/context";
import {
    BrowserEvenChannelDataKeyMessage,
    BrowserEvenChannelDataMessage,
} from "proto-api/organization/events_pb";
import { DateToPb } from "@grpc/converters/date";

//"https://grpc-local.fitfrog.ru"
const service = new PersonalServicePromiseClient(
    `${import.meta.env.VITE_GRPC_BASE_URL}`,
    null,
);

export const personalClient = {
    createBrowserEventChannel: async (
        ctx: Context,
        expirationTime: Date | undefined,
        endpoint: string,
        authKey: string,
        p256dhKey: string,
    ): Promise<void> => {
        const keys = new BrowserEvenChannelDataKeyMessage();
        if (authKey) {
            keys.setAuth(authKey);
        }
        if (p256dhKey) {
            keys.setP256dh(p256dhKey);
        }

        const data = new BrowserEvenChannelDataMessage();
        data.setEndpoint(endpoint);
        data.setExpirationtime(DateToPb(expirationTime));
        data.setKeys(keys);

        const request = new CreateBrowserEventChannelRequestMessage();
        request.setData(data);

        await service.createBrowserEventChannel(request, ContextToPb(ctx));
        return;
    },
    info: async (
        ctx: Context,
    ): Promise<
        | {
              eventChannels: EventChannel[];
          }
        | undefined
    > => {
        const request = new InfoRequestMessage();

        const data = await service.info(request, ContextToPb(ctx));

        const eventChannels = PbToEntities(
            data.getEventchannelsList(),
            PbToEventChannel,
        );

        return {
            eventChannels: eventChannels,
        };
    },
};
