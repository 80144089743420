import { Account } from "@app/entities/balance/account";
import { Context } from "@app/entities/context";
import { BalanceAccountID } from "@app/entities/uuid";
import { PbToAccount } from "@grpc/converters/balance/account";
import { ContextToPb } from "@grpc/converters/context";
import { EntityToUUID } from "@grpc/converters/uuid";
import { AccountServicePromiseClient } from "proto-api/balance/account_service_grpc_web_pb";
import { GetAccountByIDRequest } from "proto-api/balance/account_service_pb";
import { App } from "vue";

let service: AccountServicePromiseClient;

type Config = {
    service: AccountServicePromiseClient;
};
export const initBalanceAccountClient = (app: App, options: Config) => {
    service = options.service;
};

export const useBalanceAccountClient = () => {
    return balanceAccountClient;
};

const balanceAccountClient = {
    loadByID: async (
        ctx: Context,
        id: BalanceAccountID,
    ): Promise<Account | undefined> => {
        const request = new GetAccountByIDRequest();
        request.setAccountId(EntityToUUID(id));
        const response = await service.getByID(request, ContextToPb(ctx));
        const account = response.getAccount();
        if (account) {
            return PbToAccount(account);
        } else {
            return undefined;
        }
    },
};
