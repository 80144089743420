import { PbToUUIDSure } from "@grpc/converters/uuid";
import { OrganizationUserMessage } from "proto-api/auth/organization_user_pb";
import { OrganizationUser } from "@app/entities/organzationUser";
import { pbToOrganizationUserRole } from "@grpc/converters/organizationUserRole";
import { PbToUser } from "@grpc/converters/user";
import { UserMessage } from "proto-api/auth/user_pb";
import { AuthResponse } from "@grpc/authClient";

export const PbToOrganizationUser = (
    resource: OrganizationUserMessage,
): OrganizationUser => {
    return {
        id: PbToUUIDSure(resource.getId()),
        createdAt: resource.getCreatedAt()?.toDate(),
        updatedAt: resource.getUpdatedAt()?.toDate(),
        role: pbToOrganizationUserRole(resource.getRole()),
        user: PbToUser(resource.getUser() as UserMessage),
        userID: PbToUUIDSure(resource.getUserId()),
        deletedAt: resource.getDeletedAt()?.toDate(),
        isDeleted: !!resource.getDeletedAt()?.toDate(),
    };
};

export const PbToAuthResponse = (
    token: string,
    resource?: OrganizationUserMessage,
): AuthResponse => {
    if (!resource) {
        console.error("Organization user not found");
        throw new Error("User authorize error");
    }
    const user = resource.getUser();
    if (!user) {
        console.error("Organization user not found", resource);
        throw new Error("User authorize error");
    }

    return {
        token: token,
        user: PbToUser(user),
        organizationUser: PbToOrganizationUser(resource),
    };
};
