<template>
  <form-component :form="vueForm">
    <div class="columns-2xs">
      <form-field
        :field="form.name"
        :label="t('Name')"
      >
        <text-input-field :field="form.name" />
      </form-field>
      <form-field
        :field="form.images"
        :label="t('New images')"
      >
        <file-input-field
          :field="form.images"
          :multiple="true"
        />
      </form-field>
      <form-field
        v-if="images.length"
        :field="form.deleteImages"
        :label="t('Delete images')"
      >
        <files-delete-field
          :field="form.deleteImages"
          :options="images"
        />
      </form-field>
      <form-field
        :field="form.logo_wide"
        :label="t('Logo wide')"
      >
        <file-input-field
          :actual-files="logoWide ? [logoWide.path] : []"
          :field="form.logo_wide"
          :multiple="false"
        />
      </form-field>
      <form-field
        :field="form.logo_square"
        :label="t('Logo square')"
      >
        <file-input-field
          :actual-files="logoSquare ? [logoSquare.path] : []"
          :field="form.logo_square"
          :multiple="false"
        />
      </form-field>
    </div>
    <submit-button />
  </form-component>
</template>

<script lang="ts" setup>
import { reactive } from "vue";
import { ValidationArgs } from "@vuelidate/core";
import { required } from "@app/services/validators";
import TextInputField from "@app/components/forms/fields/TextInputField.vue";
import FormField from "@app/components/forms/fields/FormField.vue";
import SubmitButton from "@app/components/forms/fields/SubmitButton.vue";
import FileInputField from "@app/components/forms/fields/FileInputField.vue";
import { appForm } from "@app/services/forms";
import FormComponent from "@app/components/FormComponent.vue";
import { Organization } from "@app/entities/organzation";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import FilesDeleteField from "@app/components/forms/fields/FilesDeleteField.vue";
import {
    entityFileUseCase,
    useEntityFilesData,
} from "@app/usecase/entityFileUseCase";
import { FileType } from "@app/entities/fileType";
import { UUID } from "@app/entities/uuid";
import { useI18n } from "vue-i18n";

interface OrganizationFormFields {
    name: string;
    images: string[];
    deleteImages: UUID[];
    logo_wide: string;
    logo_square: string;
}

const { t } = useI18n();
const entityFileView = useEntityFilesData();

const props = defineProps<{
    organization: Organization;
}>();

const images = entityFileView.getByEntityType(
    props.organization.id,
    FileType.ORGANIZATION,
);
const logoWide = props.organization.logoWideID
    ? entityFileView.getByID(props.organization.logoWideID)
    : undefined;
const logoSquare = props.organization.logoSquareID
    ? entityFileView.getByID(props.organization.logoSquareID)
    : undefined;

const emits = defineEmits<{
    (e: "on-success", value: Organization): void;
}>();

const rules: ValidationArgs<OrganizationFormFields> = {
    name: {
        required: required,
    },
    deleteImages: {},
    images: {},
    logo_wide: {},
    logo_square: {},
};
const formFields = reactive<OrganizationFormFields>({
    name: props.organization.name,
    images: [],
    deleteImages: [],
    logo_square: "",
    logo_wide: "",
});
const vueForm = appForm<OrganizationFormFields, void>(
    rules,
    formFields,
    async () => {
        const promises = entityFileUseCase.syncImages(
            props.organization.id,
            FileType.ORGANIZATION,
            formFields.images,
            formFields.deleteImages,
        );

        if (formFields.logo_wide) {
            if (props.organization.logoWideID) {
                promises.push(
                    entityFileUseCase.destroy(props.organization.logoWideID),
                );
            }
            promises.push(
                entityFileUseCase.store(
                    props.organization.id,
                    FileType.ORGANIZATION_LOGO_WIDE,
                    formFields.logo_wide,
                ),
            );
        }
        if (formFields.logo_square) {
            if (props.organization.logoSquareID) {
                promises.push(
                    entityFileUseCase.destroy(props.organization.logoSquareID),
                );
            }
            promises.push(
                entityFileUseCase.store(
                    props.organization.id,
                    FileType.ORGANIZATION_LOGO_SQUARE,
                    formFields.logo_square,
                ),
            );
        }

        await Promise.all(promises);
        const model = await organizationUseCase.update(
            props.organization.id,
            formFields.name,
        );
        emits("on-success", model);
    },
);
const form = vueForm.form;
</script>

<style scoped></style>
