import { LocationServicePromiseClient } from "proto-api/organization/location_service_grpc_web_pb";
import { LocationID, OrganizationID } from "@app/entities/uuid";
import { EntityToUUID } from "@grpc/converters/uuid";
import { ContextToPb } from "@grpc/converters/context";
import { Context } from "@app/entities/context";
import { PbToEntities } from "@grpc/converters/entities";
import { Location } from "@app/entities/location";
import {
    LocationDestroyRequestMessage,
    LocationGetByIDRequestMessage,
    LocationGetByOrganizationIDRequestMessage,
    LocationRestoreRequestMessage,
    LocationStoreRequestMessage,
    LocationUpdateRequestMessage,
} from "proto-api/organization/location_service_pb";
import { PbToLocation } from "@grpc/converters/location";

//"https://grpc-local.fitfrog.ru"
const service = new LocationServicePromiseClient(
    `${import.meta.env.VITE_GRPC_BASE_URL}`,
    null,
);

export const locationClient = {
    loadByOrganizationID: async (
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<Location[]> => {
        const request = new LocationGetByOrganizationIDRequestMessage();
        request.setOrganizationId(EntityToUUID(organizationID));

        const response = await service.getByOrganizationID(
            request,
            ContextToPb(ctx),
        );
        return PbToEntities(response.getLocationsList(), PbToLocation);
    },

    loadByID: async (
        ctx: Context,
        id: LocationID,
    ): Promise<Location | undefined> => {
        const request = new LocationGetByIDRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.getByID(request, ContextToPb(ctx));

        const message = response.getLocation();
        if (message) {
            return PbToLocation(message);
        }
        return;
    },

    store: async (
        ctx: Context,
        organizationID: OrganizationID,
        name: string,
        description: string,
    ): Promise<Location | undefined> => {
        const request = new LocationStoreRequestMessage();
        request.setOrganizationId(EntityToUUID(organizationID));
        request.setName(name);
        request.setDescription(description);

        const response = await service.store(request, ContextToPb(ctx));

        const message = response.getLocation();
        if (message) {
            return PbToLocation(message);
        }
        return;
    },

    update: async (
        ctx: Context,
        id: LocationID,
        name: string,
        description: string,
    ): Promise<Location | undefined> => {
        const request = new LocationUpdateRequestMessage();
        request.setId(EntityToUUID(id));
        request.setName(name);
        request.setDescription(description);

        const response = await service.update(request, ContextToPb(ctx));

        const message = response.getLocation();
        if (message) {
            return PbToLocation(message);
        }
        return;
    },

    destroy: async (
        ctx: Context,
        id: LocationID,
    ): Promise<Location | undefined> => {
        const request = new LocationDestroyRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.destroy(request, ContextToPb(ctx));

        const message = response.getLocation();
        if (message) {
            return PbToLocation(message);
        }
        return;
    },

    restore: async (
        ctx: Context,
        id: LocationID,
    ): Promise<Location | undefined> => {
        const request = new LocationRestoreRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.restore(request, ContextToPb(ctx));

        const message = response.getLocation();
        if (message) {
            return PbToLocation(message);
        }
        return;
    },
};
