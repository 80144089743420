<template>
  <page-component
    :links="actionLinks"
    :title="t('Schedule planning')"
  >
    <div class="alert alert-warning shadow-lg mb-2">
      <div>
        <svg
          class="stroke-current flex-shrink-0 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </svg>
        <div>
          <h3 class="text-xl font-bold">
            {{ t("Attention") }}!
          </h3>
          <div class="text-sm">
            <span><b>{{ t("Already exists") }}</b>:
              {{
                t(
                  "workout",
                  draftsViewData.Statistic.alreadyExist,
                )
              }}</span>
            <span><br>
              <b>{{ t("Will be added") }}:</b>
              {{
                t(
                  "workout",
                  draftsViewData.Statistic.statusDraft,
                )
              }}</span>
            <span v-if="draftsViewData.Statistic.processed">
              <br>
              <b>{{ t("Processed") }}:</b>
              {{
                t("workout", draftsViewData.Statistic.processed)
              }}</span>
          </div>
        </div>
      </div>
      <div class="flex-none">
        <button
          class="btn btn-sm"
          @click="process"
        >
          {{ draftsViewData.IsProcessRun ? "Stop" : "Run" }}
        </button>
      </div>
    </div>
    <error-boundary-component
      v-for="model of draftsViewData.AllWithProblems"
      :key="model.id.value"
      :message="model.problem"
    />
    <workout-draft-item-component
      v-for="model of draftsViewData.List"
      :key="model.id.value"
      :model="model"
    />
  </page-component>
</template>

<script lang="ts" setup>
import PageComponent from "@app/components/PageComponent.vue";
import { Link, LinkIcons } from "@app/views/models/links";
import { computed, ComputedRef } from "vue";
import { onBeforeRouteUpdate } from "vue-router";
import { useI18n } from "vue-i18n";
import {
    useWorkoutsDraftViewData,
    workoutDraftsUseCase,
} from "@app/usecase/workoutDraftUseCase";
import { authUseCase, ENTITIES_LIST } from "@app/usecase/authUseCase";
import { SchedulePlanBeforeEnter } from "@pages/schedule/plan/route";
import ErrorBoundaryComponent from "@app/components/ErrorBoundaryComponent.vue";
import WorkoutDraftItemComponent from "@app/views/entityComponents/WorkoutDraftItemComponent.vue";
import { PageType } from "@app/pkg/router/pageType";

const draftsViewData = useWorkoutsDraftViewData();
const { t } = useI18n();

const process = () => {
    if (draftsViewData.IsProcessRun) {
        return workoutDraftsUseCase.stopProcessData();
    } else {
        return workoutDraftsUseCase.processData();
    }
};

const actionLinks: ComputedRef<Link[]> = computed(() => [
    {
        text: t("Workouts"),
        image: LinkIcons.trophy,
        to: {
            name: PageType.Workouts,
        },
        disabled: !authUseCase.canView(ENTITIES_LIST.WORKOUT),
    },
    {
        text: t("Workouts list"),
        image: LinkIcons.calendar,
        to: {
            name: PageType.WorkoutsList,
        },
        disabled: !authUseCase.canEdit(ENTITIES_LIST.WORKOUT),
    },
    {
        text: t("Schedule"),
        image: LinkIcons.calendar,
        to: {
            name: PageType.Schedule,
        },
        disabled: !authUseCase.canView(ENTITIES_LIST.SCHEDULE),
    },
]);

onBeforeRouteUpdate(SchedulePlanBeforeEnter);
</script>

<style scoped></style>
