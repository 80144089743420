<template>
  <form-component :form="vueForm">
    <div class="columns-1xs">
      <form-field
        :field="form.name"
        :label="t('Name')"
      >
        <text-input-field :field="form.name" />
      </form-field>
    </div>
    <submit-button />
  </form-component>
</template>

<script lang="ts" setup>
import FormComponent from "@app/components/FormComponent.vue";
import FormField from "@app/components/forms/fields/FormField.vue";
import SubmitButton from "@app/components/forms/fields/SubmitButton.vue";
import TextInputField from "@app/components/forms/fields/TextInputField.vue";
import { Account } from "@app/entities/payment/account";
import { AccountType } from "@app/entities/payment/accountType";
import { OrganizationID } from "@app/entities/uuid";
import { appForm } from "@app/services/forms";
import { maxLength, required } from "@app/services/validators";
import { paymentUseCase } from "@app/usecase/paymentUseCase";
import { ValidationArgs } from "@vuelidate/core";
import { reactive } from "vue";
import { useI18n } from "vue-i18n";

interface StoreDisciplineFormFields {
    name: string;
}

const { t } = useI18n();
const props = defineProps<{
    organizationId: OrganizationID;
}>();
const emits = defineEmits<{
    (e: "on-success", value: Account): void;
}>();

const formFields = reactive<StoreDisciplineFormFields>({
    name: "",
});

const rules: ValidationArgs<StoreDisciplineFormFields> = {
    name: {
        required: required,
        maxLengthRef: maxLength(100),
    },
};

const vueForm = appForm<StoreDisciplineFormFields, void>(
    rules,
    formFields,
    async () => {
        const account = await paymentUseCase.store(
            props.organizationId,
            formFields.name,
            AccountType.AT_CASH,
        );
        if (account) {
            emits("on-success", account);
        }
    },
);
const form = vueForm.form;
</script>

<style scoped></style>
