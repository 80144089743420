<template>
  <page-component :title="t('Discipline add')">
    <discipline-add-form @on-success="onSuccess" />
  </page-component>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import PageComponent from "@app/components/PageComponent.vue";
import DisciplineAddForm from "@app/components/forms/DisciplineAddForm.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();

const onSuccess = () => {
    router.back();
};
</script>

<style scoped></style>
