import {
    NavigationGuardWithThis,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";
import { PageType } from "@app/pkg/router/pageType";
import { PageName } from "@app/pkg/router/pageName";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { organizationUserUseCase } from "@app/usecase/organizationUserUseCase";
import { StringToEntityID } from "@app/entities/uuid";
import UserUpdateView from "@users/update/UserUpdateView.vue";
import { OrganizationUser } from "@app/entities/organzationUser";
import { ErrLoadError } from "@app/entities/errors";

export interface UserUpdateProps {
    oUser: OrganizationUser;
}

export const UserUpdateBeforeEnter: NavigationGuardWithThis<undefined> = async (
    to,
    from,
    next,
) => {
    const oUser = await organizationUserUseCase.getByID(
        StringToEntityID(to.params.id as string),
    );

    if (!oUser) {
        throw ErrLoadError;
    }

    const props = getFirsLevelProps<UserUpdateProps>();
    props.oUser = oUser;

    // didn't find a better way
    to.params = {
        ...to.params,
        ...(props as unknown as RouteParams),
    };
    next();
};

export const usersUpdateRoute: RouteRecordRaw = {
    path: "/users/:id",
    name: PageType.UserUpdate,
    component: UserUpdateView,
    props: true,
    meta: {
        breadcrumbs: [
            {
                name: PageName.Users,
                to: PageType.Users,
            },
        ],
    },
    beforeEnter: UserUpdateBeforeEnter,
};
