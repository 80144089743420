<template>
  <form-component :form="vueForm">
    <list-box-field
      :field="form.location_id"
      :options="locationOptions"
    />
  </form-component>
</template>

<script lang="ts" setup>
import FormComponent from "@app/components/FormComponent.vue";
import ListBoxField from "@app/components/forms/fields/ListBoxField.vue";
import { EntityIDToString, StringToEntityID } from "@app/entities/uuid";
import { storageRepo } from "@app/pkg/storageRepo";
import { appForm } from "@app/services/forms";
import { useLocationViewData } from "@app/usecase/locationUseCase";
import { SelectOption } from "@app/views/models/form";
import { ValidationArgs } from "@vuelidate/core";
import { computed, ComputedRef, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { RouteRecordName, useRoute, useRouter } from "vue-router";

interface FormFields {
    location_id: string;
}

const locationView = useLocationViewData();
const route = useRoute();
const { t } = useI18n();
const router = useRouter();

const rules: ValidationArgs<FormFields> = {
    location_id: {},
};

const locationOptions: ComputedRef<SelectOption<string>[]> = computed(() => {
    return [
        {
            value: "",
            displayValue: t("All locations"),
        },
        ...locationView.AllActive.map((location) => ({
            value: EntityIDToString(location.id),
            displayValue: location.name,
        })),
    ];
});

const formFields = reactive<FormFields>({
    location_id: EntityIDToString(storageRepo.getFavoriteLocationID()),
});

watch(formFields, (newValues) => {
    const query: Partial<FormFields> = {
        ...route.query,
        location_id: newValues.location_id,
    };
    if (newValues.location_id) {
        query.location_id = newValues.location_id;
        storageRepo.setFavoriteLocationID(
            StringToEntityID(newValues.location_id),
        );
    } else {
        storageRepo.setFavoriteLocationID(undefined);
        delete query.location_id;
    }
    router.push({
        name: route.name as RouteRecordName,
        params: {
            ...route.params,
        },
        query: {
            ...query,
        },
    });
});

const vueForm = appForm<FormFields, void>(rules, formFields, async () => {});

const form = vueForm.form;
</script>

<style scoped></style>
