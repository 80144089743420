<template>
  <popup-page-component :title="t('Update')">
    <user-subscription-update-form
      :disciplines-options="disciplineOptions"
      :payment-accounts="accountOptions"
      :subscription="subscription"
      :subscription-type-options="subscriptionTypeOptions"
      @on-success="onSuccess"
    />
  </popup-page-component>
</template>

<script lang="ts" setup>
import UserSubscriptionUpdateForm from "@app/components/forms/UserSubscriptionUpdateForm.vue";
import PopupPageComponent from "@app/components/PopupPageComponent.vue";
import { SubscriptionID } from "@app/entities/uuid";
import { useDisciplineViewData } from "@app/usecase/disciplineUseCase";
import { useAccountsViewData } from "@app/usecase/paymentUseCase";
import { useSubscriptionTypesViewData } from "@app/usecase/subscriptionTypeUseCase";
import { useSubscriptionsViewData } from "@app/usecase/subscriptionUseCase";
import { disciplinesToOptions } from "@app/views/converters/discipline";
import { paymentAccountsToOptions } from "@app/views/converters/paymentAccount";
import { subscriptionTypeToOptions } from "@app/views/converters/subscriptionType";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

interface Props {
    subscriptionId: SubscriptionID;
}

const props = defineProps<Props>();
const router = useRouter();
const { t } = useI18n();

const subscriptionTypeViewData = useSubscriptionTypesViewData();
const disciplineViewData = useDisciplineViewData();
const subscriptionsViewData = useSubscriptionsViewData();

const accountsViewData = useAccountsViewData();

const subscription = computed(() => {
    const model = subscriptionsViewData.getByID(props.subscriptionId);
    if (!model) {
        throw Error("Not found");
    }
    return model;
});

const disciplineOptions = computed(() => {
    return disciplinesToOptions(disciplineViewData.AllActive);
});
const subscriptionTypeOptions = computed(() => {
    return subscriptionTypeToOptions(subscriptionTypeViewData.AllActive);
});
const accountOptions = computed(() =>
    paymentAccountsToOptions(accountsViewData.All),
);
const onSuccess = () => {
    router.back();
};
</script>

<style scoped></style>
