export interface TimeRange {
    left: number;
    right: number;
}

export const dateToTimeRange = (date: Date): TimeRange => {
    let day = date.getDay();
    if (day == 0) {
        day = 6;
    } else {
        day--;
    }
    // Sunday - Saturday : 0 - 6

    return {
        left: 86400 * day,
        right: 86400 * (day + 1),
    };
};
