<template>
  <form-component :form="vueForm">
    <div class="columns-2xs">
      <form-field
        v-if="locationOptions.length > 1"
        :field="form.location_id"
        :label="t('Location')"
      >
        <select-box-field
          :field="form.location_id"
          :options="locationOptions"
        />
      </form-field>
      <form-field
        :field="form.discipline_id"
        :label="t('Discipline')"
      >
        <select-box-field
          :field="form.discipline_id"
          :options="disciplineOptions"
        />
      </form-field>
      <form-field
        :field="form.trainer_id"
        :label="t('Trainer')"
      >
        <select-box-field
          :field="form.trainer_id"
          :options="trainerOptions"
        />
      </form-field>
      <div class="break-inside-avoid-column">
        <form-field
          :field="form.students_count"
          :label="t('Students count')"
        >
          <text-input-field
            :field="form.students_count"
          />
        </form-field>
        <form-field
          :field="form.date"
          :label="t('Date')"
        >
          <date-input-field :field="form.date" />
        </form-field>
        <div class="break-inside-avoid-column">
          <div class="grid md:grid-cols-2 md:gap-1">
            <form-field
              :field="form.time_from"
              :label="t('Time from')"
            >
              <text-input-field
                :field="form.time_from"
                type="time"
              />
            </form-field>
            <form-field
              :field="form.time_to"
              :label="t('Time to')"
            >
              <text-input-field
                :field="form.time_to"
                type="time"
              />
            </form-field>
          </div>
        </div>
      </div>
    </div>
    <submit-button />
  </form-component>
</template>

<script lang="ts" setup>
import { reactive } from "vue";
import { ValidationArgs } from "@vuelidate/core";
import { required } from "@app/services/validators";
import FormField from "@app/components/forms/fields/FormField.vue";
import SubmitButton from "@app/components/forms/fields/SubmitButton.vue";
import { appForm } from "@app/services/forms";
import SelectBoxField from "@app/components/forms/fields/SelectBoxField.vue";
import DateInputField from "@app/components/forms/fields/DateInputField.vue";
import TextInputField from "@app/components/forms/fields/TextInputField.vue";
import { formatDateISO, timeOfDate } from "@app/services/format";
import FormComponent from "@app/components/FormComponent.vue";
import { workoutsUseCase } from "@app/usecase/workoutsUseCase";
import { EntityIDToString, StringToEntityID } from "@app/entities/uuid";
import { Workout } from "@app/entities/workout";
import { WorkoutStatus } from "@app/entities/workoutStatus";
import { SelectOption } from "@app/views/models/form";
import { StringToDate } from "@app/views/converters/date";
import { useI18n } from "vue-i18n";

interface UpdateWorkoutFormFields {
    location_id: string;
    trainer_id: string;
    discipline_id: string;
    date: string;
    time_from: string;
    time_to: string;
    students_count: number;
    status: WorkoutStatus;
}

const { t } = useI18n();
const props = defineProps<{
    workout: Workout;
    disciplineOptions: SelectOption<string>[];
    trainerOptions: SelectOption<string>[];
    locationOptions: SelectOption<string>[];
}>();

const emits = defineEmits<{
    (e: "on-success", value: Workout): void;
}>();

const rules: ValidationArgs<UpdateWorkoutFormFields> = {
    trainer_id: {
        required: required,
    },
    discipline_id: {
        required: required,
    },
    location_id: {
        required: required,
    },
    date: {
        required: required,
    },
    students_count: {
        required: required,
    },
    time_from: {
        required: required,
    },
    time_to: {
        required: required,
    },
    status: {},
};

const formFields = reactive<UpdateWorkoutFormFields>({
    time_from: timeOfDate(props.workout.dateTimeRange.left),
    time_to: timeOfDate(props.workout.dateTimeRange.right),
    location_id: EntityIDToString(props.workout.locationID),
    discipline_id: EntityIDToString(props.workout.disciplineID),
    trainer_id: EntityIDToString(props.workout.trainerID),
    students_count: props.workout.studentsCount,
    date: formatDateISO(props.workout.dateTimeRange.left),
    status: props.workout.status,
});

const vueForm = appForm<UpdateWorkoutFormFields, void>(
    rules,
    formFields,
    async () => {
        const leftDate = StringToDate(formFields.date, formFields.time_from);
        const model = await workoutsUseCase.update(
            props.workout.id,
            StringToEntityID(formFields.trainer_id),
            StringToEntityID(formFields.discipline_id),
            StringToEntityID(formFields.location_id),
            formFields.students_count,
            leftDate,
            StringToDate(formFields.date, formFields.time_to, leftDate),
            props.workout.scheduleID,
        );
        emits("on-success", model);
    },
);
const form = vueForm.form;
</script>

<style scoped></style>
