import { LocationID } from "@app/entities/uuid";
import { PageName } from "@app/pkg/router/pageName";
import { PageType } from "@app/pkg/router/pageType";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { getPopupRoutes } from "@app/router/popups";
import { authUseCase } from "@app/usecase/authUseCase";
import IndexView from "@pages/index/IndexView.vue";
import {
    NavigationGuardWithThis,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";

export type WorkoutsFilter = {
    currentLocationId: LocationID | undefined;
    dateFrom: Date;
    dateTo: Date;
};
type Props = {
    day: string;
    dateFrom: Date;
    dateTo: Date;
    locationId: LocationID;
};

export const IndexViewBeforeEnter: NavigationGuardWithThis<undefined> = (
    to,
    from,
    next,
) => {
    let dateFrom = new Date();
    if (to.params.day) {
        dateFrom = new Date(to.params.day as string);
    } else {
        to.params.day = "";
    }
    dateFrom.setHours(0, 0, 0, 0);

    const dateTo = new Date(dateFrom);
    dateTo.setDate(dateFrom.getDate() + 1);

    const props = getFirsLevelProps<Props>();
    props.day = to.params.day as string;
    props.dateFrom = dateFrom;
    props.dateTo = dateTo;
    props.locationId = authUseCase.syncFavoriteLocation(to.query);

    // didn't find a better way
    to.params = {
        ...to.params,
        ...(props as unknown as RouteParams),
    };
    next();
};

export const indexRoute: RouteRecordRaw = {
    path: "/:day?",
    alias: [""],
    name: PageType.Index,
    component: IndexView,
    props: true,
    meta: {
        title: "Welcome",
        isGuest: true,
    },
    beforeEnter: IndexViewBeforeEnter,
    children: getPopupRoutes(PageType.Index, [
        {
            name: PageName.Index,
            to: PageType.Index,
        },
    ]),
};
