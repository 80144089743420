<template>
  <select
    v-model="fieldRef.$model"
    :name="field.$path"
    class="select select-bordered"
    @blur="field.$touch"
  >
    <option
      v-for="option of options"
      :key="option.value"
      :value="option.value"
      :disabled="option.disabled === true"
      :selected="field.$model === option.value"
    >
      {{
        option.displayValue !== undefined
          ? option.displayValue
          : option.value
      }}
    </option>
  </select>
</template>

<script lang="ts" setup>
import { BaseValidation } from "@vuelidate/core";
import { ref } from "vue";
import { SelectOption } from "@app/views/models/form";

const props = defineProps<{
    field: BaseValidation;
    placeholder?: string;
    options: SelectOption<string | number>[];
}>();
const fieldRef = ref(props.field);
</script>

<style scoped></style>
