import { Context, WithBackground } from "@app/entities/context";
import { filterSubscriptionIDs } from "@app/entities/subscription";
import { filterUserWorkoutIDs, UserWorkout } from "@app/entities/userWorkout";
import {
    EntityIDToString,
    OrganizationID,
    OrganizationUserID,
    PaymentAccountID,
    SubscriptionID,
    UserWorkoutID,
    WorkoutID,
} from "@app/entities/uuid";
import { storageRepo } from "@app/pkg/storageRepo";
import { useBalanceAccountsRepo } from "@app/stores/balanceAccountsRepo";
import { useOrganizationUsersRepo } from "@app/stores/organizationUserRepo";
import { useSubscriptionRepo } from "@app/stores/subscriptionRepo";
import { useUserWorkoutRepo } from "@app/stores/userWorkoutRepo";
import { useWorkoutRepo } from "@app/stores/workoutRepo";
import { useAuthViewData } from "@app/usecase/authUseCase";
import { useWorkoutViewData } from "@app/usecase/workoutsUseCase";
import { ref } from "vue";

export interface UserWorkoutRepo {
    sync(entities: UserWorkout[]): void;

    loadByID(ctx: Context, id: UserWorkoutID): Promise<UserWorkout | undefined>;

    getByID(id: UserWorkoutID): UserWorkout | undefined;

    loadByOrganizationID(
        ctx: Context,
        organizationID: OrganizationID,
        dateFrom: Date,
        dateTo: Date,
    ): Promise<UserWorkout[]>;

    loadByOrganizationUserID(
        ctx: Context,
        organizationUserID: OrganizationUserID,
    ): Promise<UserWorkout[]>;

    loadBySubscriptionIDs(
        ctx: Context,
        ids: SubscriptionID[],
    ): Promise<UserWorkout[]>;

    store(
        ctx: Context,
        workoutID: WorkoutID,
        organizationUserID: OrganizationUserID,
        subscriptionID?: SubscriptionID,
    ): Promise<UserWorkout | undefined>;

    open(
        ctx: Context,
        workoutID: UserWorkoutID,
    ): Promise<UserWorkout | undefined>;

    cancel(
        ctx: Context,
        workoutID: UserWorkoutID,
        keepBalance: boolean,
    ): Promise<UserWorkout | undefined>;

    pay(
        ctx: Context,
        workoutID: UserWorkoutID,
        accountID: PaymentAccountID,
        sum: number,
    ): Promise<UserWorkout | undefined>;

    addToSubscription(
        ctx: Context,
        workoutID: UserWorkoutID,
        subscriptionID: SubscriptionID,
    ): Promise<UserWorkout | undefined>;
}

const useRepo = (): UserWorkoutRepo => {
    return useUserWorkoutRepo();
};

export const userWorkoutsUseCase = {
    isLoading: ref<boolean>(false),

    async loadByID(id: UserWorkoutID): Promise<UserWorkout | undefined> {
        const repo = useRepo();

        return await repo.loadByID(storageRepo.withToken(WithBackground()), id);
    },

    async getByID(id: UserWorkoutID): Promise<UserWorkout | undefined> {
        const repo = useRepo();
        const workout = repo.getByID(id);
        if (workout) {
            return workout;
        }
        return this.loadByID(id);
    },

    async loadByOrganizationID(
        organizationID: OrganizationID,
        dateFrom: Date,
        dateTo: Date,
    ): Promise<UserWorkout[]> {
        const repo = useRepo();
        const auth = useAuthViewData();

        if (!auth.isEmployee) {
            const forAllLeft = new Date();
            forAllLeft.setHours(0, 0, 0, 0);
            const forAllRight = new Date(forAllLeft);
            forAllRight.setDate(forAllRight.getDate() + 7);

            if (!auth.isAuth || dateFrom < forAllLeft || dateTo > forAllRight) {
                return [];
            }
        }

        return await repo.loadByOrganizationID(
            storageRepo.withToken(WithBackground()),
            organizationID,
            dateFrom,
            dateTo,
        );
    },

    async loadByOrganizationUserID(
        organizationUserID: OrganizationUserID,
    ): Promise<UserWorkout[]> {
        const repo = useRepo();

        return await repo.loadByOrganizationUserID(
            storageRepo.withToken(WithBackground()),
            organizationUserID,
        );
    },

    async loadBySubscriptionIDs(ids: SubscriptionID[]): Promise<UserWorkout[]> {
        const repo = useRepo();

        return await repo.loadBySubscriptionIDs(
            storageRepo.withToken(WithBackground()),
            ids,
        );
    },
    sortByWorkoutDate(data: UserWorkout[]): void {
        const workoutViewData = useWorkoutViewData();
        data.sort((a, b) => {
            const aWorkout = workoutViewData.getByID(a.workoutID);
            const bWorkout = workoutViewData.getByID(b.workoutID);

            return aWorkout &&
                bWorkout &&
                aWorkout.dateTimeRange.left &&
                bWorkout.dateTimeRange.left &&
                aWorkout.dateTimeRange.left < bWorkout.dateTimeRange.left
                ? 1
                : -1;
        });
    },

    async store(
        workoutID: WorkoutID,
        organizationUserID: OrganizationUserID,
        subscriptionID?: SubscriptionID,
    ): Promise<void> {
        const repo = useRepo();

        const uWorkout = await repo.store(
            storageRepo.withToken(WithBackground()),
            workoutID,
            organizationUserID,
            subscriptionID,
        );
        await this.reloadWorkout(uWorkout);
        return;
    },

    async open(workoutID: UserWorkoutID): Promise<void> {
        const repo = useRepo();

        const uWorkout = await repo.open(
            storageRepo.withToken(WithBackground()),
            workoutID,
        );
        await this.reloadWorkout(uWorkout);
        return;
    },

    async cancel(
        workoutID: UserWorkoutID,
        keepBalance: boolean,
    ): Promise<void> {
        const repo = useRepo();

        const uWorkout = await repo.cancel(
            storageRepo.withToken(WithBackground()),
            workoutID,
            keepBalance,
        );

        if (uWorkout && !keepBalance) {
            await this.reloadWorkout(uWorkout);
        }

        return;
    },

    async pay(
        workoutID: UserWorkoutID,
        accountID: PaymentAccountID,
        sum: number,
    ): Promise<UserWorkout | undefined> {
        const repo = useRepo();

        const uWorkout = await repo.pay(
            storageRepo.withToken(WithBackground()),
            workoutID,
            accountID,
            sum,
        );
        await this.reloadWorkout(uWorkout);
        return uWorkout;
    },

    async addToSubscription(
        workoutID: UserWorkoutID,
        subscriptionID: SubscriptionID,
    ): Promise<UserWorkout | undefined> {
        const repo = useRepo();
        return await repo.addToSubscription(
            storageRepo.withToken(WithBackground()),
            workoutID,
            subscriptionID,
        );
    },
    isPaid(model: UserWorkout): boolean {
        // We don't tell unemployed person which workout is unpaid
        const auth = useAuthViewData();
        if (
            !auth.isEmployee &&
            EntityIDToString(model.organizationUserID) !==
                EntityIDToString(auth.currentUser?.id)
        ) {
            return true;
        }

        return !!(model.paymentID || model.subscriptionID);
    },

    async sync(models: UserWorkout[]): Promise<void> {
        const authView = useAuthViewData();
        const oUserRepo = useOrganizationUsersRepo();
        const subscriptionRepo = useSubscriptionRepo();
        const workoutRepo = useWorkoutRepo();
        const balanceAccountRepo = useBalanceAccountsRepo();
        const promises: Promise<unknown>[] = [];
        const ctx = storageRepo.withToken(WithBackground());

        let ids = filterUserWorkoutIDs(models);
        const oUserRepoIDs = oUserRepo.getMissedIDs(ids.organizationUserIDs);
        if (oUserRepoIDs.length > 0) {
            promises.push(oUserRepo.loadByIDs(ctx, oUserRepoIDs));
        }

        models = authView.filterAccessedUserWorkouts(models);
        ids = filterUserWorkoutIDs(models);

        const workoutRepoIDs = workoutRepo.getMissedIDs(ids.workoutIDs);
        if (workoutRepoIDs.length > 0) {
            promises.push(workoutRepo.loadByIDs(ctx, workoutRepoIDs));
        }

        const subscriptionRepoIDs = subscriptionRepo.getMissedIDs(
            ids.subscriptionIDs,
        );
        if (subscriptionRepoIDs.length > 0) {
            promises.push(
                subscriptionRepo
                    .loadByIDs(ctx, subscriptionRepoIDs)
                    .then(async (s) => {
                        const ids = filterSubscriptionIDs(s);
                        await balanceAccountRepo.loadByIDs(ctx, ids.balanceIDs);
                        return s;
                    }),
            );
        }

        await Promise.all(promises);
    },

    async reloadWorkout(uWorkout: UserWorkout | undefined) {
        if (uWorkout) {
            const workoutsRepo = useWorkoutRepo();
            await workoutsRepo.loadByID(
                storageRepo.withToken(WithBackground()),
                uWorkout.workoutID,
            );
        }
    },
};

export const useUserWorkoutViewData = (): {
    getByID(id: UserWorkoutID): UserWorkout | undefined;
    getByOrganizationUserID(
        organizationUserID: OrganizationUserID,
    ): UserWorkout[];
    getBySubscriptionID(id: SubscriptionID): UserWorkout[];
    getByWorkoutID(id: WorkoutID): UserWorkout[];
} => {
    return useUserWorkoutRepo();
};
