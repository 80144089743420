<template>
  <template v-if="template == 'data-item'">
    <data-item :links="links">
      <h3
        class="text-xl pb-2 header"
        :class="{ 'line-through': !!entity.deletedAt }"
      >
        {{ entity.name }}
      </h3>
      <div v-if="!entity.deletedAt">
        <ul>
          <li>
            <span class="font-bold pr-1">Price:</span>{{ entity.price }}
          </li>
          <li>
            <span class="font-bold pr-1">Days:</span>{{ entity.days }}
          </li>
          <li>
            <span class="font-bold pr-1">Classes:</span>{{ entity.classes }}<br>
          </li>
          <li v-if="disciplines.length">
            <span class="font-bold pr-1">Disciplines:</span>
            {{
              disciplines
                .map((discipline) => discipline.name)
                .join(", ")
            }}
          </li>
        </ul>
        <h3 class="font-bold pr-1">
          {{ t("Description") }}:
        </h3>
        <hr>
        <wysiwyg-content :content="entity.description" />
        <hr>
      </div>
    </data-item>
  </template>
</template>

<script lang="ts" setup>
import { Link, LinkIcons } from "@app/views/models/links";
import DataItem from "@app/components/DataItem.vue";
import { computed, ComputedRef } from "vue";
import { useI18n } from "vue-i18n";
import WysiwygContent from "@app/components/WysiwygContent.vue";
import { SubscriptionType } from "@app/entities/subscriptionType";
import { subscriptionTypeUseCase } from "@app/usecase/subscriptionTypeUseCase";
import { useDisciplineViewData } from "@app/usecase/disciplineUseCase";
import { authUseCase, ENTITIES_LIST } from "@app/usecase/authUseCase";
import { PageType } from "@app/pkg/router/pageType";
import { EntityIDToString } from "@app/entities/uuid";

const { t } = useI18n();

const disciplinesViewData = useDisciplineViewData();

const props = defineProps<{
    entity: SubscriptionType;
    template: "data-item" | "inline";
}>();

const disciplines = disciplinesViewData.getByIDs(props.entity.disciplineIDs);

const subscriptionTypeDelete = async () => {
    await subscriptionTypeUseCase.destroy(props.entity.id);
};

const subscriptionTypeRestore = async () => {
    await subscriptionTypeUseCase.restore(props.entity.id);
};

const links: ComputedRef<Link[]> = computed(() => [
    {
        image: LinkIcons.pencilSquare,
        text: t("Edit"),
        to: {
            name: PageType.SubscriptionTypesUpdate,
            params: {
                id: EntityIDToString(props.entity.id),
            },
        },
        disabled:
            !!props.entity.deletedAt ||
            !authUseCase.canEdit(ENTITIES_LIST.SUBSCRIPTION_TYPE),
    },
    {
        image: LinkIcons.error,
        text: t("Delete"),
        actionConfirm: t(
            `Are you sure you want to delete this subscription type "{name}"`,
            {
                name: props.entity.name,
            },
        ),
        action: subscriptionTypeDelete,
        disabled:
            !!props.entity.deletedAt ||
            !authUseCase.canEdit(ENTITIES_LIST.SUBSCRIPTION_TYPE),
    },
    {
        image: LinkIcons.error,
        text: t("Restore"),
        actionConfirm: t(
            `Are you sure you want to restore this subscription type "{name}"`,
            {
                name: props.entity.name,
            },
        ),
        action: subscriptionTypeRestore,
        disabled:
            !props.entity.deletedAt ||
            !authUseCase.canEdit(ENTITIES_LIST.SUBSCRIPTION_TYPE),
    },
]);
</script>

<style scoped></style>
