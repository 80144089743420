<template>
  <!-- Breadcrumb -->
  <nav
    v-if="breadcrumbs.length"
    class="flex"
    aria-label="Breadcrumb"
  >
    <ol class="inline-flex items-center space-x-1 md:space-x-3">
      <li class="inline-flex items-center">
        <router-link
          class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900"
          :to="{ name: 'Schedule' }"
        >
          <svg
            class="w-4 h-4 mr-2"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
            />
          </svg>
          Home
        </router-link>
      </li>
      <li
        v-for="breadcrumb in breadcrumbs"
        :key="breadcrumb.name"
      >
        <div class="flex items-center">
          <svg
            class="w-6 h-6 text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
          <router-link
            class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2"
            :to="{ name: breadcrumb.to }"
          >
            {{ t(breadcrumb.name) }}
          </router-link>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script lang="ts" setup>
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

const route = useRoute();
const { t } = useI18n();

const breadcrumbs =
    route.meta && route.meta.breadcrumbs ? route.meta.breadcrumbs : [];
</script>

<style scoped></style>
