import { Context, WithBackground } from "@app/entities/context";
import {
    EntityIDToString,
    LocationID,
    StringToEntityID,
} from "@app/entities/uuid";
import { useLocationRepo } from "@app/stores/locationRepo";

const FAVORITE_LOCATION_ID = "FAVORITE_LOCATION_ID";
const TOKEN = "TOKEN";

export const storageRepo = {
    setToken(token: string): void {
        localStorage.setItem(TOKEN, token);
    },

    getToken(): string | undefined {
        const token = localStorage.getItem(TOKEN);
        return token ? token : undefined;
    },

    getFavoriteLocationID(): LocationID | undefined {
        const id = localStorage.getItem(FAVORITE_LOCATION_ID);
        if (id) {
            const repo = useLocationRepo();
            const locationID = StringToEntityID(id);
            const location = repo.getByID(locationID);
            if (location) {
                return location.id;
            } else {
                this.setFavoriteLocationID(undefined);
            }
        }
        return undefined;
    },

    setFavoriteLocationID(id: LocationID | undefined): LocationID | undefined {
        const repo = useLocationRepo();
        const location = repo.getByID(id);
        if (id && location) {
            localStorage.setItem(
                FAVORITE_LOCATION_ID,
                EntityIDToString(location.id),
            );
            return location.id;
        } else {
            localStorage.removeItem(FAVORITE_LOCATION_ID);
        }
        return undefined;
    },

    withToken(ctx?: Context): Context {
        if (!ctx) {
            ctx = WithBackground();
        }
        ctx.token = this.getToken();
        return ctx;
    },

    logout(): void {
        sessionStorage.clear();
        localStorage.clear();
    },
};
