<template>
  <popup-page-component :title="t('{name} update', { name: location.name })">
    <location-update-form
      :location="location"
      @on-success="onSuccess"
    />
  </popup-page-component>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import LocationUpdateForm from "@app/components/forms/LocationUpdateForm.vue";
import PopupPageComponent from "@app/components/PopupPageComponent.vue";
import { useI18n } from "vue-i18n";
import { Location } from "@app/entities/location";

const router = useRouter();
const { t } = useI18n();

defineProps<{
    location: Location;
}>();
const onSuccess = () => {
    router.back();
};
</script>

<style scoped></style>
