import { Transaction } from "@app/entities/payment/transaction";
import { pbToDateSure } from "@grpc/converters/date";
import { PbToTransactionStatus } from "@grpc/converters/payment/transactionStatus";
import { PbToUUIDSure } from "@grpc/converters/uuid";
import { Transaction as TransactionPb } from "proto-api/payment/transaction_pb";

export const PbToTransaction = (resource: TransactionPb): Transaction => {
    return {
        id: PbToUUIDSure(resource.getId()),
        organizationID: PbToUUIDSure(resource.getOrganizationId()),
        confirmedByID: PbToUUIDSure(resource.getConfirmedById()),
        organizationUserID: PbToUUIDSure(resource.getOrganizationUserId()),
        createdAt: pbToDateSure(resource.getCreatedAt()),
        updatedAt: pbToDateSure(resource.getUpdatedAt()),
        accountID: PbToUUIDSure(resource.getAccountId()),
        amount: resource.getAmount(),
        status: PbToTransactionStatus(resource.getStatus()),
    };
};
