<template>
  <div
    v-for="option of options"
    :key="option.value"
    :class="{
      'input-error text-red-500':
        field.$dirty && field.$silentErrors.length,
    }"
  >
    <label class="label cursor-pointer">
      <input
        v-model="fieldRef.$model"
        type="radio"
        :value="option.value"
        :name="field.$path"
        class="radio"
      >
      <span class="flex-[0_0_100%] ml-2">
        {{
          option.displayValue !== undefined
            ? option.displayValue
            : option.value
        }}
      </span>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { BaseValidation } from "@vuelidate/core";
import { ref } from "vue";

interface RadioOption {
    value: string | number;
    displayValue?: string | number;
}

const props = defineProps<{
    field: BaseValidation;
    options: RadioOption[];
}>();
const fieldRef = ref(props.field);
</script>

<style scoped></style>
