import { RouteRecordRaw } from "vue-router";
import { usersIndexRoute } from "@users/index/route";
import { usersAddRoute } from "@users/add/route";
import { usersUpdateRoute } from "@users/update/route";

export const usersRoutes: RouteRecordRaw[] = [
    usersIndexRoute,
    usersAddRoute,
    usersUpdateRoute,
];
