import {
    listIDsUnique,
    OrganizationID,
    OrganizationUserID,
    PaymentTransactionID,
    SubscriptionID,
    UserWorkoutID,
    WorkoutID,
} from "@app/entities/uuid";
import { WorkoutStatus } from "@app/entities/workoutStatus";

export type UserWorkout = {
    id: UserWorkoutID;
    organizationID: OrganizationID;
    subscriptionID?: SubscriptionID;
    workoutID: WorkoutID;
    organizationUserID: OrganizationUserID;
    paymentID?: PaymentTransactionID;
    status: WorkoutStatus;
    createdAt: Date;
};

export const filterUserWorkoutIDs = (
    uWorkouts: UserWorkout[],
): {
    subscriptionIDs: SubscriptionID[];
    workoutIDs: WorkoutID[];
    paymentIDs: PaymentTransactionID[];
    organizationUserIDs: OrganizationUserID[];
} => {
    return {
        subscriptionIDs: listIDsUnique(
            uWorkouts.map((uWorkout) => uWorkout.subscriptionID),
        ),
        workoutIDs: listIDsUnique(
            uWorkouts.map((uWorkout) => uWorkout.workoutID),
        ),
        paymentIDs: listIDsUnique(
            uWorkouts.map((uWorkout) => uWorkout.paymentID),
        ),
        organizationUserIDs: listIDsUnique(
            uWorkouts.map((uWorkout) => uWorkout.organizationUserID),
        ),
    };
};
