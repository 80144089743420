<template>
  <template v-if="template == 'data-item' && oUser">
    <div
      :class="{
        'bg-secondary/5 text-secondary': oUser.isDeleted,
        'bg-white': !oUser.isDeleted,
      }"
      class="block rounded-lg mb-2 p-6 shadow-lg"
    >
      <data-item
        :links="[]"
        :model="oUser"
      >
        <dropdown-link
          :links="entityLinks"
          class="pr-2"
          type="link"
        >
          <b class="pr-2">{{ oUserName(oUser) }}</b>
          <i>{{ oUser.user.email }}</i>
        </dropdown-link>
        <br>
        <a
          v-if="oUser.user.phone.length > 0"
          :href="'tel:+' + oUser.user.phone.replace(/\D/g, '')"
          class="pr-2"
        >{{ oUser.user.phone }}</a>
        <span class="app-badge app-badge-role pr-2">{{
          oUser.user.isAdmin
            ? t("Administrator")
            : t(UserRoleToString(oUser.role))
        }}</span>
        <slot />
      </data-item>
    </div>
  </template>
</template>

<script lang="ts" setup>
import DataItem from "@app/components/DataItem.vue";
import DropdownLink from "@app/components/DropdownLink.vue";
import { ErrUserWorkoutNotFound } from "@app/entities/errors";
import { oUserName } from "@app/entities/organzationUser";
import { EntityIDToString, OrganizationUserID } from "@app/entities/uuid";
import { PageType } from "@app/pkg/router/pageType";
import {
    authUseCase,
    ENTITIES_LIST,
    useAuthViewData,
} from "@app/usecase/authUseCase";
import {
    organizationUserUseCase,
    useOrganizationUserViewData,
} from "@app/usecase/organizationUserUseCase";
import { UserRoleToString } from "@app/views/converters/userRole";
import { Link, LinkIcons } from "@app/views/models/links";
import { getSubscriptionAddLink } from "@app/views/popups/subscription-add/route";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const authView = useAuthViewData();
const organizationUserView = useOrganizationUserViewData();

interface Props {
    entityId: OrganizationUserID;
    template: "data-item" | "inline";
    links?: Link[];
}

const oUser = computed(() => {
    const model = organizationUserView.getByID(props.entityId);
    if (!model) {
        throw Error(ErrUserWorkoutNotFound);
    }
    return model;
});

const props = withDefaults(defineProps<Props>(), {
    links: () => [],
});
const entityLinks = computed((): Link[] => {
    if (oUser.value.isDeleted) {
        return [
            ...(props.links ? props.links : []),
            {
                image: LinkIcons.add,
                text: t("Restore"),
                action: async () => {
                    await organizationUserUseCase.restore(oUser.value.id);
                },
                actionConfirm: t("Are you sure you want to restore {user}?", {
                    user: oUser.value.user.name,
                }),
                disabled:
                    !authUseCase.canEdit(ENTITIES_LIST.ORGANIZATION_USER) ||
                    !oUser.value.isDeleted,
            },
        ];
    } else {
        return [
            ...(props.links ? props.links : []),
            getSubscriptionAddLink(
                route,
                t("Add subscription"),
                oUser.value.id,
            ),
            {
                image: LinkIcons.pencilSquare,
                text: t("Change role"),
                to: {
                    name: PageType.UserUpdate,
                    params: {
                        id: EntityIDToString(oUser.value.id),
                    },
                },
                disabled: !authUseCase.canEdit(ENTITIES_LIST.ORGANIZATION_USER),
            },
            {
                image: LinkIcons.error,
                text: t("Block"),
                action: async () => {
                    await organizationUserUseCase.destroy(oUser.value.id);
                },
                actionConfirm: t("Are you sure you want to block {user}?", {
                    user: oUser.value.user.name,
                }),
                disabled: !authUseCase.canEdit(ENTITIES_LIST.ORGANIZATION_USER),
            },
            {
                image: LinkIcons.user,
                text: t("Auth"),
                action: async () => {
                    await authUseCase.adminLogin(oUser.value.user.id);
                    await router.push({
                        path: "/",
                    });
                },
                disabled: !authView.isAdmin,
            },
        ];
    }
});
</script>

<style scoped></style>
