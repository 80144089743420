<template>
  <textarea
    v-model="fieldRef.$model"
    class="textarea textarea-bordered h-20 max-h-48"
    :class="{
      'textarea-error text-red-500':
        field.$dirty && field.$silentErrors.length,
    }"
    :placeholder="placeholder"
    @blur="field.$touch"
  />
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { BaseValidation } from "@vuelidate/core";

const props = defineProps<{
    field: BaseValidation<string>;
    placeholder?: string;
}>();
const fieldRef = ref(props.field);
</script>

<style scoped></style>
