import { SubscriptionTypeMessage } from "proto-api/organization/subscription_type_pb";
import { SubscriptionType } from "@app/entities/subscriptionType";
import { pbToDateSure } from "@grpc/converters/date";
import { PbToUUIDSure } from "@grpc/converters/uuid";

export const PbToSubscriptionType = (
    resource: SubscriptionTypeMessage,
): SubscriptionType => {
    const deletedAt = resource.getDeletedAt();
    return {
        id: PbToUUIDSure(resource.getId()),
        organizationID: PbToUUIDSure(resource.getOrganizationId()),
        disciplineIDs: resource
            .getDisciplineIdsList()
            .map((value) => PbToUUIDSure(value)),
        name: resource.getName(),
        description: resource.getDescription(),
        price: resource.getPrice(),
        days: resource.getDays(),
        classes: resource.getClasses(),
        createdAt: pbToDateSure(resource.getCreatedAt()),
        updatedAt: pbToDateSure(resource.getUpdatedAt()),
        deletedAt: deletedAt?.toDate(),
    };
};
