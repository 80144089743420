import { EntityIDToString } from "@app/entities/uuid";
import { Discipline } from "@app/entities/discipline";
import { SelectOption } from "@app/views/models/form";

export const disciplinesToOptions = (
    models: Discipline[],
): SelectOption<string>[] => {
    return models.map((model): SelectOption<string> => {
        return {
            value: EntityIDToString(model.id),
            displayValue: model.name,
        };
    });
};
