<template>
  <form-component
    :form="vueForm"
    class="mt-8 space-y-6"
  >
    <div class="rounded-md shadow-sm -space-y-px">
      <form-field
        :field="form.email"
        class="block relative w-full"
      >
        <text-input-field
          :field="form.email"
          :placeholder="t('Email')"
          autocomplete="current-email"
          type="email"
        />
      </form-field>
    </div>

    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <router-link
          :to="{ name: PageType.SignUp }"
          class="font-medium text-indigo-600 hover:text-indigo-500"
        >
          {{ t(PageName.SignUp) }}
        </router-link>
      </div>

      <div class="text-sm">
        <router-link
          :to="{ name: PageType.Login }"
          class="font-medium text-indigo-600 hover:text-indigo-500"
        >
          {{ t(PageName.Login) }}
        </router-link>
      </div>
    </div>

    <div>
      <button
        class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        type="submit"
      >
        {{ t("Send me reset a link") }}
      </button>
    </div>
  </form-component>
</template>

<script lang="ts" setup>
import { PageType } from "@app/pkg/router/pageType";
import { PageName } from "@app/pkg/router/pageName";
import { useRouter } from "vue-router";
import { reactive } from "vue";
import { ValidationArgs } from "@vuelidate/core";
import { email, required } from "@app/services/validators";
import { appForm } from "@app/services/forms";
import FormComponent from "@app/components/FormComponent.vue";
import TextInputField from "@app/components/forms/fields/TextInputField.vue";
import FormField from "@app/components/forms/fields/FormField.vue";
import { useI18n } from "vue-i18n";
import { authUseCase } from "@app/usecase/authUseCase";
import { organizationUseCase } from "@app/usecase/organizationUseCase";

const router = useRouter();
const { t } = useI18n();

interface ForgotPasswordFormFields {
    email: string;
}

const organization = organizationUseCase.currentOrganization();

const formFields = reactive<ForgotPasswordFormFields>({
    email: "",
});

const rules: ValidationArgs<ForgotPasswordFormFields> = {
    email: { required, email }, // Matches state.email
};

const vueForm = appForm<ForgotPasswordFormFields, void>(
    rules,
    formFields,
    async () => {
        return authUseCase
            .forgot(organization.id, formFields.email)
            .then(() => {
                router.push({
                    name: "Login",
                });
                return;
            });
    },
);
const form = vueForm.form;
</script>

<style scoped></style>
