<template>
  <h1 class="text-xl p-2 text-center">
    Заработано денег
  </h1>
  <div>
    <canvas ref="income" />
  </div>
</template>

<script lang="ts" setup>
import { EntityIDToString, StringToEntityID } from "@app/entities/uuid";
import { DATETIME_YEAR_MONTH } from "@app/i18n/i18n";
import {
    useAccountsViewData,
    useTransactionsViewData,
} from "@app/usecase/paymentUseCase";
import Chart from "chart.js/auto";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const income = ref<HTMLCanvasElement>();
const { d } = useI18n();
const transactionsView = useTransactionsViewData();
const accountsView = useAccountsViewData();

onMounted(() => {
    const fields: Record<string, Record<string, number>> = {};
    const mFields: Record<string, number> = {};

    const date = new Date();
    let endDate = new Date();
    endDate.setMonth(date.getMonth() - 12);

    let payments = transactionsView.All;

    payments = payments.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));

    endDate = payments.length ? payments[0].createdAt : endDate;

    while (date > endDate) {
        const m = d(date, DATETIME_YEAR_MONTH);
        mFields[m] = 0;
        date.setMonth(date.getMonth() - 1);
    }

    payments.forEach((value) => {
        const date = d(value.createdAt, DATETIME_YEAR_MONTH);
        const aID = EntityIDToString(value.accountID);
        if (!fields[aID]) {
            fields[aID] = {};
            for (const key in mFields) {
                fields[aID][key] = 0;
            }
        }
        if (fields[aID][date] != undefined) {
            fields[aID][date] += value.amount;
        }
    });

    const incomeVal = income.value;
    if (incomeVal) {
        new Chart(incomeVal, {
            type: "bar",
            data: {
                labels: Object.keys(mFields),
                datasets: Object.keys(fields).map((value) => {
                    const account = accountsView.getByID(
                        StringToEntityID(value),
                    );
                    return {
                        type: "bar",
                        label: account ? account.name : value,
                        data: Object.values(fields[value]),
                        order: 0,
                    };
                }),
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 1,
                indexAxis: "y",
                scales: {
                    x: {
                        position: "top",
                        ticks: {
                            callback: function (value) {
                                return value + " Р.";
                            },
                        },
                    },
                    y: {
                        stacked: true,
                        position: "right",
                    },
                },
            },
        });
    }
});
</script>

<style scoped></style>
