import { Context } from "@app/entities/context";
import { Discipline } from "@app/entities/discipline";
import { EntityFile } from "@app/entities/entityFile";
import { Location } from "@app/entities/location";
import { Organization } from "@app/entities/organzation";
import { Schedule } from "@app/entities/schedule";
import { SubscriptionType } from "@app/entities/subscriptionType";
import { OrganizationID } from "@app/entities/uuid";
import { Workout } from "@app/entities/workout";
import { ContextToPb } from "@grpc/converters/context";
import { PbToDiscipline } from "@grpc/converters/discipline";
import { PbToEntities } from "@grpc/converters/entities";
import { PbToEntityFile } from "@grpc/converters/entityFile";
import { PbToLocation } from "@grpc/converters/location";
import { PbToOrganization } from "@grpc/converters/organization";
import { PbToSchedule } from "@grpc/converters/schedule";
import { PbToSubscriptionType } from "@grpc/converters/subscriptionType";
import { EntityToUUID } from "@grpc/converters/uuid";
import { PbToWorkout } from "@grpc/converters/workout";
import { OrganizationServicePromiseClient } from "proto-api/organization/organization_service_grpc_web_pb";
import {
    OrganizationInfoRequestMessage,
    OrganizationUpdateRequestMessage,
} from "proto-api/organization/organization_service_pb";

const service = new OrganizationServicePromiseClient(
    `${import.meta.env.VITE_GRPC_BASE_URL}`,
    null,
);

export const organizationClient = {
    update: async (
        ctx: Context,
        id: OrganizationID,
        name: string,
    ): Promise<Organization | undefined> => {
        const request = new OrganizationUpdateRequestMessage();
        request.setName(name);
        request.setId(EntityToUUID(id));

        const data = await service.update(request, ContextToPb(ctx));
        const pbOrganization = data.getOrganization();
        if (pbOrganization) {
            return PbToOrganization(pbOrganization);
        }
        return;
    },
    info: async (
        ctx: Context,
        code: string,
    ): Promise<
        | {
              organization: Organization;
              entityFiles: EntityFile[];
              workouts: Workout[];
              subscriptionTypes: SubscriptionType[];
              disciplines: Discipline[];
              locations: Location[];
              schedules: Schedule[];
          }
        | undefined
    > => {
        const request = new OrganizationInfoRequestMessage();
        request.setCode(code);

        const data = await service
            .info(request, ContextToPb(ctx))
            .catch((reason) => {
                throw reason;
            });
        const pbOrganization = data.getOrganization();
        if (!pbOrganization) {
            throw new Error("couldn't find organization");
        }
        const organization = PbToOrganization(pbOrganization);

        const entityFiles = PbToEntities(data.getImagesList(), PbToEntityFile);

        const workouts = PbToEntities(data.getWorkoutsList(), PbToWorkout);

        const schedules = PbToEntities(data.getSchedulesList(), PbToSchedule);

        const subscriptionTypes = PbToEntities(
            data.getSubscriptiontypesList(),
            PbToSubscriptionType,
        );

        const disciplines = PbToEntities(
            data.getDisciplinesList(),
            PbToDiscipline,
        );

        const locations = PbToEntities(data.getLocationsList(), PbToLocation);

        return {
            organization: organization,
            entityFiles: entityFiles,
            workouts: workouts,
            subscriptionTypes: subscriptionTypes,
            disciplines: disciplines,
            locations: locations,
            schedules: schedules,
        };
    },
};
