import {
    DisciplineID,
    LocationID,
    OrganizationID,
    OrganizationUserID,
    ScheduleID,
} from "@app/entities/uuid";
import { Context, WithBackground } from "@app/entities/context";
import { Schedule } from "@app/entities/schedule";
import { DateTimeRange } from "@app/entities/dateTimeRange";
import { TimeRange } from "@app/entities/timeRange";
import { useScheduleRepo } from "@app/stores/scheduleRepo";
import { storageRepo } from "@app/pkg/storageRepo";
import { ErrSomethingWentWrong } from "@app/entities/errors";

export interface ScheduleRepo {
    loadByOrganizationID(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<Schedule[]>;

    loadByID(ctx: Context, id: ScheduleID): Promise<Schedule | undefined>;

    store(
        ctx: Context,
        organizationID: OrganizationID,
        disciplineID: DisciplineID,
        locationID: LocationID,
        trainerID: OrganizationUserID,
        studentsCount: number,
        dateRange: Partial<DateTimeRange>,
        timeRange: TimeRange,
    ): Promise<Schedule | undefined>;

    update(
        ctx: Context,
        id: ScheduleID,
        disciplineID: DisciplineID,
        locationID: LocationID,
        trainerID: OrganizationUserID,
        studentsCount: number,
        dateRange: Partial<DateTimeRange>,
        timeRange: TimeRange,
    ): Promise<Schedule | undefined>;

    destroy(ctx: Context, id: ScheduleID): Promise<Schedule | undefined>;
}

const useRepo = (): ScheduleRepo => {
    return useScheduleRepo();
};

export interface ScheduleUseCase {
    loadByOrganizationID(organizationID: OrganizationID): Promise<Schedule[]>;

    loadByID(id: ScheduleID): Promise<Schedule>;

    store(
        organizationID: OrganizationID,
        disciplineID: DisciplineID,
        locationID: LocationID,
        trainerID: OrganizationUserID,
        studentsCount: number,
        dateRange: Partial<DateTimeRange>,
        timeRange: TimeRange,
    ): Promise<Schedule>;

    update(
        id: ScheduleID,
        disciplineID: DisciplineID,
        locationID: LocationID,
        trainerID: OrganizationUserID,
        studentsCount: number,
        dateRange: Partial<DateTimeRange>,
        timeRange: TimeRange,
    ): Promise<Schedule>;

    destroy(id: ScheduleID): Promise<Schedule>;
}

export const scheduleUseCase: ScheduleUseCase = {
    async loadByOrganizationID(
        organizationID: OrganizationID,
    ): Promise<Schedule[]> {
        const repo = useRepo();
        return await repo.loadByOrganizationID(
            storageRepo.withToken(),
            organizationID,
        );
    },
    async loadByID(id: ScheduleID): Promise<Schedule> {
        const repo = useRepo();
        const model = await repo.loadByID(
            storageRepo.withToken(WithBackground()),
            id,
        );
        if (!model) {
            throw ErrSomethingWentWrong;
        }
        return model;
    },
    async store(
        organizationID: OrganizationID,
        disciplineID: DisciplineID,
        locationID: LocationID,
        trainerID: OrganizationUserID,
        studentsCount: number,
        dateRange: DateTimeRange,
        timeRange: TimeRange,
    ): Promise<Schedule> {
        const repo = useRepo();
        const model = await repo.store(
            storageRepo.withToken(),
            organizationID,
            disciplineID,
            locationID,
            trainerID,
            studentsCount,
            dateRange,
            timeRange,
        );
        if (!model) {
            throw ErrSomethingWentWrong;
        }
        return model;
    },

    async update(
        id: ScheduleID,
        disciplineID: DisciplineID,
        locationID: LocationID,
        trainerID: OrganizationUserID,
        studentsCount: number,
        dateRange: DateTimeRange,
        timeRange: TimeRange,
    ): Promise<Schedule> {
        const repo = useRepo();
        const model = await repo.update(
            storageRepo.withToken(),
            id,
            disciplineID,
            locationID,
            trainerID,
            studentsCount,
            dateRange,
            timeRange,
        );
        if (!model) {
            throw ErrSomethingWentWrong;
        }
        return model;
    },

    async destroy(id: ScheduleID): Promise<Schedule> {
        const repo = useRepo();
        const model = await repo.destroy(
            storageRepo.withToken(WithBackground()),
            id,
        );
        if (!model) {
            throw ErrSomethingWentWrong;
        }
        return model;
    },
};

export const useScheduleViewData = (): {
    getByID(id: ScheduleID): Schedule | undefined;
    getByIDs(ids: ScheduleID[]): Schedule[];
    getByWeekday(timeRange: TimeRange, locationID?: LocationID): Schedule[];
    All: Schedule[];
} => {
    return useScheduleRepo();
};
