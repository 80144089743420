import { Context } from "@app/entities/context";
import { Transaction } from "@app/entities/payment/transaction";
import { OrganizationID, PaymentTransactionID } from "@app/entities/uuid";
import {
    BaseEntityStorage,
    storeEntities,
    storeGetByID,
    storeGetMissedIDs,
} from "@app/stores/converters/repo";
import { usePaymentClient } from "@grpc/paymentClient";
import { defineStore } from "pinia";

type StoreFields = BaseEntityStorage<Transaction>;

export interface PaymentTransactionsClient {
    loadTransactions(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<Transaction[]>;

    loadTransactionsByIDs(
        ctx: Context,
        ids: PaymentTransactionID[],
    ): Promise<Transaction[]>;
}

const client: PaymentTransactionsClient = usePaymentClient();

export const usePaymentTransactionsRepo = defineStore("paymentTransactions", {
    state: () =>
        ({
            data: [],
            byId: {},
        }) as StoreFields,
    actions: {
        sync(entities: Transaction[]) {
            return storeEntities(this, entities);
        },
        async loadByOrganizationID(
            ctx: Context,
            id: OrganizationID,
        ): Promise<Transaction[]> {
            const data = await client.loadTransactions(ctx, id);
            this.sync(data);
            return data;
        },
        async loadByIDs(
            ctx: Context,
            ids: PaymentTransactionID[],
        ): Promise<Transaction[]> {
            const data = await client.loadTransactionsByIDs(ctx, ids);
            this.sync(data);
            return data;
        },
    },
    getters: {
        All: (store): Transaction[] => store.data,
        getByID: (store) => storeGetByID<Transaction>(store),
        getMissedIDs: (store) =>
            storeGetMissedIDs<Transaction, PaymentTransactionID>(store),
    },
});
