
export interface IActionWatcher {
    begin(): void
    end(): void
}

export class ActionWatcher implements IActionWatcher {

    counter: number = 0;
    constructor() {

    }

    begin(): void {
        this.counter++
    }

    end(): void {
        this.counter--
    }
}