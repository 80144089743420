<template>
  <page-component
    :links="actionLinks"
    :title="t('Workouts list')"
  >
    <workouts-list-filter-form
      :location-id="locationId"
      :date-from="dateFrom"
      :date-to="dateTo"
      :status="status"
    />
    <error-boundary-component
      v-for="workout of workouts"
      :key="EntityIDToString(workout.id)"
    >
      <workout-item-component
        :model="workout"
        :show-date="true"
        :show-location="!locationId"
      />
    </error-boundary-component>
  </page-component>
</template>

<script lang="ts" setup>
import ErrorBoundaryComponent from "@app/components/ErrorBoundaryComponent.vue";
import PageComponent from "@app/components/PageComponent.vue";
import { EntityIDToString } from "@app/entities/uuid";
import { sortWorkoutsAsc } from "@app/entities/workout";
import { PageName } from "@app/pkg/router/pageName";
import { PageType } from "@app/pkg/router/pageType";
import { authUseCase, ENTITIES_LIST } from "@app/usecase/authUseCase";
import { useWorkoutViewData } from "@app/usecase/workoutsUseCase";
import WorkoutItemComponent from "@app/views/entityComponents/WorkoutItemComponent.vue";
import { Link, LinkIcons } from "@app/views/models/links";
import {
    WorkoutsListProps,
    WorkoutsListViewBeforeEnter,
} from "@pages/workouts/list/route";
import WorkoutsListFilterForm from "@pages/workouts/list/WorkoutsListFilterForm.vue";
import { computed, ComputedRef } from "vue";
import { useI18n } from "vue-i18n";
import { onBeforeRouteUpdate } from "vue-router";

const workoutsViewData = useWorkoutViewData();
const { t } = useI18n();
const props = defineProps<WorkoutsListProps>();

const workouts = computed(() => {
    const dateTo = new Date(props.dateTo);
    dateTo.setDate(dateTo.getDate() + 1);

    return workoutsViewData
        .getByDate(props.dateFrom, dateTo, props.locationId, props.status)
        .sort(sortWorkoutsAsc);
});

const actionLinks: ComputedRef<Link[]> = computed(() => [
    {
        text: t("Workouts"),
        image: LinkIcons.trophy,
        to: {
            name: PageType.Workouts,
        },
        disabled: !authUseCase.canView(ENTITIES_LIST.WORKOUT),
    },
    {
        text: t(PageName.Schedule),
        image: LinkIcons.calendar,
        to: {
            name: PageType.Schedule,
        },
        disabled: !authUseCase.canView(ENTITIES_LIST.SCHEDULE),
    },
    {
        text: t("Schedule plan"),
        image: LinkIcons.presentationChart,
        to: {
            name: PageType.SchedulePlan,
        },
        disabled: !authUseCase.canEdit(ENTITIES_LIST.SCHEDULE),
    },
]);

onBeforeRouteUpdate(WorkoutsListViewBeforeEnter);
</script>

<style scoped></style>
