import { DisciplineID, OrganizationID } from "@app/entities/uuid";
import { Discipline } from "@app/entities/discipline";
import {
    ErrDisciplineNotFound,
    ErrSomethingWentWrong,
} from "@app/entities/errors";
import { storageRepo } from "@app/pkg/storageRepo";
import { Context, WithBackground } from "@app/entities/context";
import { useDisciplineRepo } from "@app/stores/disciplineRepo";
import { organizationUseCase } from "@app/usecase/organizationUseCase";

export interface DisciplineRepo {
    loadByOrganizationID(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<Discipline[]>;

    loadByID(ctx: Context, id: DisciplineID): Promise<Discipline | undefined>;

    store(
        ctx: Context,
        organizationID: OrganizationID,
        name: string,
        description: string,
    ): Promise<Discipline | undefined>;

    update(
        ctx: Context,
        id: DisciplineID,
        name: string,
        description: string,
    ): Promise<Discipline | undefined>;

    destroy(ctx: Context, id: DisciplineID): Promise<Discipline | undefined>;

    restore(ctx: Context, id: DisciplineID): Promise<Discipline | undefined>;
}

const useRepo = (): DisciplineRepo => {
    return useDisciplineRepo();
};

export interface DisciplineUseCase {
    getByID(id: DisciplineID): Promise<Discipline>;

    getByOrganizationID(id: OrganizationID): Promise<Discipline[]>;

    store(name: string, description: string): Promise<Discipline>;

    update(
        id: DisciplineID,
        name: string,
        description: string,
    ): Promise<Discipline>;

    destroy(id: DisciplineID): Promise<Discipline>;

    restore(id: DisciplineID): Promise<Discipline>;
}

export const disciplineUseCase: DisciplineUseCase = {
    async getByID(id: DisciplineID): Promise<Discipline> {
        const repo = useRepo();
        const model = await repo.loadByID(
            storageRepo.withToken(WithBackground()),
            id,
        );
        if (!model) {
            throw ErrDisciplineNotFound;
        }
        return model;
    },
    async getByOrganizationID(
        organizationID: OrganizationID,
    ): Promise<Discipline[]> {
        const repo = useRepo();
        return (
            await repo.loadByOrganizationID(
                storageRepo.withToken(WithBackground()),
                organizationID,
            )
        ).sort((a) => (a.deletedAt ? 1 : -1));
    },
    async store(name: string, description: string): Promise<Discipline> {
        const repo = useRepo();
        const model = await repo.store(
            storageRepo.withToken(WithBackground()),
            organizationUseCase.getOrganizationID(),
            name,
            description,
        );
        if (!model) {
            throw ErrSomethingWentWrong;
        }
        return model;
    },
    async update(
        id: DisciplineID,
        name: string,
        description: string,
    ): Promise<Discipline> {
        const repo = useRepo();
        const model = await repo.update(
            storageRepo.withToken(WithBackground()),
            id,
            name,
            description,
        );
        if (!model) {
            throw ErrSomethingWentWrong;
        }
        return model;
    },

    async destroy(id: DisciplineID): Promise<Discipline> {
        const repo = useRepo();
        const model = await repo.destroy(
            storageRepo.withToken(WithBackground()),
            id,
        );
        if (!model) {
            throw ErrDisciplineNotFound;
        }
        return model;
    },

    async restore(id: DisciplineID): Promise<Discipline> {
        const repo = useRepo();
        const model = await repo.restore(
            storageRepo.withToken(WithBackground()),
            id,
        );
        if (!model) {
            throw ErrDisciplineNotFound;
        }
        return model;
    },
};

export const useDisciplineViewData = (): {
    getByID(id: DisciplineID): Discipline | undefined;
    getByIDs(ids: DisciplineID[]): Discipline[];
    All: Discipline[];
    AllActive: Discipline[];
} => {
    return useDisciplineRepo();
};
