<template>
  <form-component :form="vueForm">
    <div class="columns-1xs">
      <form-field
        :field="form.nickname"
        :label="t('Nickname')"
      >
        <text-input-field :field="form.nickname" />
      </form-field>
      <form-field
        :field="form.firstName"
        :label="t('First name')"
      >
        <text-input-field :field="form.firstName" />
      </form-field>
      <form-field
        :field="form.lastName"
        :label="t('Last name')"
      >
        <text-input-field :field="form.lastName" />
      </form-field>
      <form-field
        :field="form.nickname"
        :label="t('Nickname')"
      >
        <text-input-field :field="form.nickname" />
      </form-field>
      <form-field
        :field="form.phone"
        :label="t('User\'s phone')"
      >
        <phone-input-field :field="form.phone" />
      </form-field>
      <form-field
        :field="form.email"
        :label="t('Email address')"
      >
        <text-input-field
          :field="form.email"
          type="email"
        />
      </form-field>
      <form-field
        :field="form.password"
        :label="t('Password')"
      >
        <text-input-field :field="form.password" />
      </form-field>
      <form-field
        :field="form.role"
        :label="t('Role')"
      >
        <select-box-field
          :field="form.role"
          :options="possibleRolesOptions"
        />
      </form-field>
    </div>
    <submit-button />
  </form-component>
</template>

<script lang="ts" setup>
import { reactive } from "vue";
import { ValidationArgs } from "@vuelidate/core";
import { email, required } from "@app/services/validators";
import TextInputField from "@app/components/forms/fields/TextInputField.vue";
import FormField from "@app/components/forms/fields/FormField.vue";
import SelectBoxField from "@app/components/forms/fields/SelectBoxField.vue";
import SubmitButton from "@app/components/forms/fields/SubmitButton.vue";
import { appForm } from "@app/services/forms";
import FormComponent from "@app/components/FormComponent.vue";
import { UserRole } from "@app/entities/userRole";
import { organizationUserUseCase } from "@app/usecase/organizationUserUseCase";
import { OrganizationUser } from "@app/entities/organzationUser";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import {
    getAllRoles,
    getUserRoleValidator,
    UserRolesToOptions,
} from "@app/views/converters/userRole";
import PhoneInputField from "@app/components/forms/fields/PhoneInputField.vue";
import { useI18n } from "vue-i18n";

interface StoreUserFormFields {
    nickname: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    password: string;
    role: UserRole;
}

const { t } = useI18n();
const emits = defineEmits<{
    (e: "on-success", value: OrganizationUser): void;
}>();
const possibleRolesOptions = UserRolesToOptions();
const rules: ValidationArgs<StoreUserFormFields> = {
    nickname: {
        required: required,
    },
    firstName: {
        required: required,
    },
    lastName: {
        required: required,
    },
    phone: {
        required: required,
    },
    email: {
        email: email,
    },
    password: {
        required: required,
    },
    role: getUserRoleValidator(getAllRoles()),
};
const formFields = reactive<StoreUserFormFields>({
    nickname: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    role: UserRole.STUDENT,
});
const vueForm = appForm<StoreUserFormFields, void>(
    rules,
    formFields,
    async () => {
        const model = await organizationUserUseCase.store(
            organizationUseCase.getOrganizationID(),
            formFields.nickname,
            formFields.firstName,
            formFields.lastName,
            formFields.phone,
            formFields.email,
            formFields.password,
            formFields.role,
        );
        emits("on-success", model);
    },
);
const form = vueForm.form;
</script>

<style scoped></style>
