<template>
  <div class="block rounded-lg bg-white mb-2 p-6 shadow-lg">
    <div class="mb-2 font-medium leading-tight text-primary">
      <dropdown-link
        :links="scheduleLinks"
        type="link"
      >
        <b v-if="showLocation && location">{{ location.name }}, </b>
        <b>{{ timeOfWeek(model.timeRange.left) }} -
          {{ timeOfWeek(model.timeRange.right) }}</b><span v-if="trainer">, {{ trainer.user.name }}</span>
        <div
          v-if="discipline"
          class="text-xl"
        >
          {{ discipline.name }}
        </div>
      </dropdown-link>
    </div>
    <div class="pb-2">
      <workout-item-component
        v-for="workout of workouts"
        :key="EntityIDToString(workout.id)"
        :model="workout"
        :show-location="false"
        :show-date="true"
      >
        <slot>
          <div
            v-if="discipline"
            class="stat-value text-2xl"
          >
            {{ onlyDayMonth(d, workout.dateTimeRange.left) }}
          </div>
        </slot>
      </workout-item-component>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Link, LinkIcons } from "@app/views/models/links";
import DropdownLink from "@app/components/DropdownLink.vue";
import { computed, ComputedRef } from "vue";
import { useRoute } from "vue-router";
import { pupUpRoute } from "@app/router/popups";
import { useI18n } from "vue-i18n";
import { Schedule } from "@app/entities/schedule";
import { scheduleUseCase } from "@app/usecase/scheduleUseCase";
import { EntityIDToString } from "@app/entities/uuid";
import { authUseCase, ENTITIES_LIST } from "@app/usecase/authUseCase";
import { useDisciplineViewData } from "@app/usecase/disciplineUseCase";
import { useLocationViewData } from "@app/usecase/locationUseCase";
import { useOrganizationUserViewData } from "@app/usecase/organizationUserUseCase";
import { useWorkoutViewData } from "@app/usecase/workoutsUseCase";
import { PageType } from "@app/pkg/router/pageType";
import { onlyDayMonth, timeOfWeek } from "@app/services/format";
import { getScheduleUpdateLink } from "@app/views/popups/schedule-update/route";
import WorkoutItemComponent from "@app/views/entityComponents/WorkoutItemComponent.vue";

const locationView = useLocationViewData();
const disciplineView = useDisciplineViewData();
const organizationUserView = useOrganizationUserViewData();
const workoutViewData = useWorkoutViewData();
const route = useRoute();
const { t, d } = useI18n();

const dateFrom = new Date();
dateFrom.setHours(0, 0, 0);
const dateTo = new Date(dateFrom);
dateTo.setMonth(dateTo.getMonth() + 1);

const props = defineProps<{
    model: Schedule;
    showLocation?: boolean;
}>();

const discipline = disciplineView.getByID(props.model.disciplineID);
const trainer = organizationUserView.getByID(props.model.trainerID);
const location = locationView.getByID(props.model.locationID);
const workouts = computed(() => {
    return workoutViewData.getByScheduleID(props.model.id, dateFrom, dateTo);
});

const scheduleDelete = async () => {
    await scheduleUseCase.destroy(props.model.id);
};

const scheduleLinks: ComputedRef<Link[]> = computed<Link[]>(() => {
    const links: Link[] = [];
    links.push(getScheduleUpdateLink(route, t("Edit"), props.model.id));
    links.push({
        text: "Add workout",
        image: LinkIcons.add,
        to: pupUpRoute(route, PageType.WorkoutAdd, {
            scheduleId: EntityIDToString(props.model.id),
        }),
        disabled: !authUseCase.canEdit(ENTITIES_LIST.WORKOUT),
    });
    if (!props.model.deletedAt) {
        links.push({
            image: LinkIcons.error,
            text: t("Delete"),
            actionConfirm: `Are you sure you want to delete this schedule item`,
            action: scheduleDelete,
            disabled: !authUseCase.canEdit(ENTITIES_LIST.SCHEDULE),
        });
    }
    return links;
});
</script>

<style scoped></style>
