<template>
  <PageComponent
    :links="actionLinks"
    :title="t('Subscription types')"
  >
    <div class="grid grid-cols-1 gap-3">
      <subscription-type-component
        v-for="subscriptionType in sTypes"
        :key="EntityIDToString(subscriptionType.id)"
        :entity="subscriptionType"
        template="data-item"
      />
    </div>
  </PageComponent>
</template>

<script lang="ts" setup>
import PageComponent from "@app/components/PageComponent.vue";
import { Link, LinkIcons } from "@app/views/models/links";
import { useI18n } from "vue-i18n";
import { computed, ComputedRef } from "vue";
import { EntityIDToString } from "@app/entities/uuid";
import {
    authUseCase,
    ENTITIES_LIST,
    useAuthViewData,
} from "@app/usecase/authUseCase";
import { useSubscriptionTypesViewData } from "@app/usecase/subscriptionTypeUseCase";
import SubscriptionTypeComponent from "@app/views/entityComponents/SubscriptionTypeComponent.vue";
import { PageType } from "@app/pkg/router/pageType";

const { t } = useI18n();

const viewData = useSubscriptionTypesViewData();
const authViewData = useAuthViewData();

const sTypes = computed(() =>
    authViewData.isManager ? viewData.All : viewData.AllActive,
);
const actionLinks: ComputedRef<Link[]> = computed(() => [
    {
        text: t("Add new subscription type"),
        image: LinkIcons.add,
        to: {
            name: PageType.SubscriptionTypesAdd,
        },
        disabled: !authUseCase.canEdit(ENTITIES_LIST.SUBSCRIPTION_TYPE),
    },
]);
</script>

<style scoped></style>
