<template>
  <page-component
    :is-loading="isLoading"
    :links="actionLinks"
    :title="t('My subscriptions')"
  >
    <div v-if="subscriptions.length">
      <subscription-component
        v-for="subscription in subscriptions"
        :key="EntityIDToString(subscription.id)"
        :model-id="subscription.id"
      />
    </div>
    <p v-else>
      {{
        t(
          "You have no subscriptions yet. But you can subscribe for your first workout right now",
        )
      }}
      <br>
      <router-link
        :to="{ name: PageType.Workouts }"
        class="link link-primary"
      >
        Go
      </router-link>
    </p>
  </page-component>
</template>

<script lang="ts" setup>
import PageComponent from "@app/components/PageComponent.vue";
import { Link, LinkIcons } from "@app/views/models/links";
import { useI18n } from "vue-i18n";
import { computed, ComputedRef, ref } from "vue";
import { authUseCase } from "@app/usecase/authUseCase";
import { EntityIDToString } from "@app/entities/uuid";
import { useSubscriptionsViewData } from "@app/usecase/subscriptionUseCase";
import SubscriptionComponent from "@app/views/entityComponents/SubscriptionComponent.vue";
import { PageType } from "@app/pkg/router/pageType";

const { t } = useI18n();
const viewData = useSubscriptionsViewData();

const user = authUseCase.currentUser();
const isLoading = ref<boolean>(false);

const subscriptions = computed(() => {
    return viewData.getByOrganizationUserID(user.id);
});

const actionLinks: ComputedRef<Link[]> = computed(() => [
    {
        text: t("Personal"),
        image: LinkIcons.user,
        to: {
            name: PageType.Personal,
        },
        disabled: false,
    },
    {
        text: t("My workouts"),
        image: LinkIcons.trophy,
        to: {
            name: PageType.UserWorkouts,
        },
        disabled: false,
    },
]);
</script>

<style scoped></style>
