import { PbToUUIDSure } from "@grpc/converters/uuid";
import { pbToDateSure } from "@grpc/converters/date";
import { LocationMessage } from "proto-api/organization/location_pb";
import { Location } from "@app/entities/location";

export const PbToLocation = (resource: LocationMessage): Location => {
    return {
        id: PbToUUIDSure(resource.getId()),
        organizationID: PbToUUIDSure(resource.getOrganizationId()),
        name: resource.getName(),
        description: resource.getDescription(),
        createdAt: pbToDateSure(resource.getCreatedAt()),
        updatedAt: pbToDateSure(resource.getUpdatedAt()),
        deletedAt: resource.getDeletedAt()?.toDate(),
    };
};
