import { Organization } from "@app/entities/organzation";
import { Discipline } from "@app/entities/discipline";
import { Location } from "@app/entities/location";
import { SubscriptionType } from "@app/entities/subscriptionType";
import { OrganizationUser } from "@app/entities/organzationUser";
import { Workout } from "@app/entities/workout";
import {
    LocationID,
    OrganizationUserID,
    UserWorkoutID,
    WorkoutID,
} from "@app/entities/uuid";

export type RouteProps = {
    organization?: Organization;
    discipline?: Discipline;
    disciplines?: Discipline[];
    location?: Location;
    subscriptionType?: SubscriptionType;
    oUser?: OrganizationUser;
    filter?: unknown;
    workouts?: Workout[];
    showLocation?: boolean;
    day?: string;
    locationId?: LocationID;
    dateFrom?: Date;
    dateTo?: Date;
    workoutId?: WorkoutID;
    userWorkoutId?: UserWorkoutID;
    organizationUserId?: OrganizationUserID;
};

export const getFirsLevelProps = <T>(def: Partial<T> = {}): Partial<T> => {
    return {
        ...def,
    };
};

export const getSecondLevelProps = (
    def: Partial<RouteProps> = {},
): {
    default: RouteProps;
    popup: RouteProps;
} => {
    return {
        default: {
            ...def,
        },
        popup: {
            ...def,
        },
    };
};
