<template>
  <popup-page-component :title="t('User workout edit')">
    <user-workout-update-form
      :model="userWorkout"
      :payment-accounts="accountOptions"
      :subscription-options="usersOptions"
      @on-success="onSuccess"
    />
  </popup-page-component>
</template>

<script lang="ts" setup>
import UserWorkoutUpdateForm from "@app/components/forms/UserWorkoutUpdateForm.vue";
import PopupPageComponent from "@app/components/PopupPageComponent.vue";
import { UserWorkoutID } from "@app/entities/uuid";
import { WorkoutStatus } from "@app/entities/workoutStatus";
import { useBalanceAccountsViewData } from "@app/usecase/balanceUseCase";
import { useAccountsViewData } from "@app/usecase/paymentUseCase";
import { useSubscriptionsViewData } from "@app/usecase/subscriptionUseCase";
import { useUserWorkoutViewData } from "@app/usecase/userWorkoutUseCase";
import { paymentAccountsToOptions } from "@app/views/converters/paymentAccount";
import { subscriptionsToOptions } from "@app/views/converters/subscription";
import { SelectOption } from "@app/views/models/form";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

interface Props {
    userWorkoutId: UserWorkoutID;
}

const props = defineProps<Props>();
const { t, d } = useI18n();

const router = useRouter();
const userWorkoutViewData = useUserWorkoutViewData();
const accountsViewData = useAccountsViewData();

const userWorkout = computed(() => {
    const model = userWorkoutViewData.getByID(props.userWorkoutId);
    if (!model) {
        throw Error("Not found");
    }
    return model;
});

const subscriptionsViewData = useSubscriptionsViewData();

const usersOptions = computed<SelectOption<string>[]>(() => {
    const now = new Date();
    const subscriptions = subscriptionsViewData
        .getByOrganizationUserID(userWorkout.value.organizationUserID)
        .filter((subscription) => {
            const userWorkoutViewData = useUserWorkoutViewData();
            const uWorkouts = userWorkoutViewData.getBySubscriptionID(
                subscription.id,
            );
            const openWorkouts = uWorkouts.filter(
                (value) => value.status === WorkoutStatus.WorkoutStatusOpen,
            );

            const balanceView = useBalanceAccountsViewData();
            const balance = balanceView.getByID(subscription.balanceID);

            return (
                (!subscription.expireDate || subscription.expireDate > now) &&
                balance &&
                balance.balance > openWorkouts.length
            );
        });
    return subscriptionsToOptions(subscriptions, t, d);
});

const accountOptions = computed(() =>
    paymentAccountsToOptions(accountsViewData.All),
);

const onSuccess = () => {
    router.back();
};
</script>

<style scoped></style>
