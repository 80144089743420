import { File } from "proto-api/file/file_pb";
import { PbToUUIDSure } from "@grpc/converters/uuid";
import { EntityFile } from "@app/entities/entityFile";
import { PbToFileType } from "@grpc/converters/fileType";

export const PbToEntityFile = (resource: File): EntityFile => {
    return {
        id: PbToUUIDSure(resource.getId()),
        entityID: PbToUUIDSure(resource.getEntityId()),
        path: resource.getPath(),
        type: PbToFileType(resource.getEntityType()),
    };
};
