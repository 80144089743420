<template>
  <PageComponent
    :has-popup="false"
    :links="links"
    :title="t(SignUpPageName)"
  >
    <form-component
      :form="vueForm"
      class="mt-8 space-y-6"
    >
      <div class="rounded-md shadow-sm -space-y-px">
        <form-field
          :field="form.nickname"
          :label="t('Nickname')"
          class="block relative w-full"
        >
          <text-input-field
            :field="form.nickname"
            :placeholder="t('Nickname')"
            autocomplete="current-name"
            type="text"
          />
        </form-field>
        <form-field
          :field="form.firstName"
          :label="t('First name')"
        >
          <text-input-field
            :field="form.firstName"
          />
        </form-field>
        <form-field
          :field="form.lastName"
          :label="t('Last name')"
        >
          <text-input-field :field="form.lastName" />
        </form-field>
        <form-field
          :field="form.phone"
          :label="t('Phone')"
        >
          <phone-input-field
            :field="form.phone"
            :placeholder="t('Phone')"
            autocomplete="current-phone"
          />
        </form-field>
        <form-field
          :field="form.email"
          :label="t('Email')"
          class="block relative w-full"
        >
          <text-input-field
            :field="form.email"
            :placeholder="t('Email')"
            autocomplete="current-email"
            type="email"
          />
        </form-field>
        <form-field
          :field="form.password"
          :label="t('Password')"
        >
          <text-input-field
            :field="form.password"
            :placeholder="t('Password')"
            type="password"
          />
        </form-field>
        <form-field
          :field="form.password_confirmation"
          :label="t('Password confirmation')"
        >
          <text-input-field
            :field="form.password_confirmation"
            :placeholder="t('Password confirmation')"
            type="password"
          />
        </form-field>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <input
            id="remember"
            v-model="formFields.remember"
            class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            name="remember"
            type="checkbox"
          >
          <label
            class="ml-2 block text-sm text-gray-900"
            for="remember"
          >
            {{ t("Remember me") }}
          </label>
        </div>

        <div class="text-sm">
          <router-link
            :to="{ name: 'ForgotPassword' }"
            class="font-medium text-indigo-600 hover:text-indigo-500"
          >
            {{ t("Forgot your password") }}?
          </router-link>
        </div>
      </div>
      <div class="flex items-center justify-between">
        <div class="flex items-center" />

        <div class="text-sm">
          <router-link
            :to="{ name: 'Login' }"
            class="font-medium text-indigo-600 hover:text-indigo-500"
          >
            {{ t("Login") }}
          </router-link>
        </div>
      </div>

      <div>
        <button
          class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          type="submit"
        >
          {{ t("Sign up") }}
        </button>
      </div>
      <hr>
      <social-links />
    </form-component>
  </PageComponent>
</template>

<script lang="ts" setup>
import FormComponent from "@app/components/FormComponent.vue";
import FormField from "@app/components/forms/fields/FormField.vue";
import PhoneInputField from "@app/components/forms/fields/PhoneInputField.vue";
import TextInputField from "@app/components/forms/fields/TextInputField.vue";
import PageComponent from "@app/components/PageComponent.vue";
import { PageType } from "@app/pkg/router/pageType";
import { PageName } from "@app/pkg/router/pageName";
import { appForm } from "@app/services/forms";
import { email, required } from "@app/services/validators";
import { authUseCase } from "@app/usecase/authUseCase";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import SocialLinks from "@app/views/components/SocialLinks.vue";
import { Link, LinkIcons } from "@app/views/models/links";
import { ValidationArgs } from "@vuelidate/core";
import { reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const SignUpPageName = PageName.SignUp;
const router = useRouter();
const { t } = useI18n();
const organization = organizationUseCase.currentOrganization();
interface LoginFormFields {
    nickname: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    password: string;
    password_confirmation: string;
    remember: boolean;
}

const formFields = reactive<LoginFormFields>({
    nickname: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    password_confirmation: "",
    remember: false,
});

const rules: ValidationArgs<LoginFormFields> = {
    nickname: { required },
    firstName: { required },
    lastName: { required },
    phone: { required },
    email: { required, email },
    password: { required },
    password_confirmation: { required },
    remember: {},
};

const links: Link[] = [
    {
        disabled: false,
        image: LinkIcons.calendar,
        text: t("Workouts"),
        to: { name: PageType.Index },
    },
];

const vueForm = appForm<LoginFormFields, void>(rules, formFields, async () => {
    await authUseCase.signUp(
        organization.id,
        formFields.nickname,
        formFields.firstName,
        formFields.lastName,
        formFields.phone,
        formFields.email,
        formFields.password,
    );
    await router.push({
        name: PageType.Workouts,
    });
});
const form = vueForm.form;
</script>

<style scoped></style>
