<template>
  <div class="flex items-center justify-center md:px-4 sm:px-6 lg:px-8">
    <div class="pb-12 max-w-md w-full space-y-2">
      <div class="flex justify-center">
        <img
          :src="organizationViewData.wideLogoPath"
          alt=""
          class="fill-primary text-primary max-h-32"
        >
      </div>
      <div class="px-2">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useOrganizationViewData } from "@app/usecase/organizationUseCase";

const organizationViewData = useOrganizationViewData();
</script>

<style scoped></style>
