import { DateTimeRange as DateTimeRangeMessage } from "proto-api/entity/date_time_range_pb";
import { DateTimeRange } from "@app/entities/dateTimeRange";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";

export const PbToDateTime = (
    resource: DateTimeRangeMessage | undefined,
): DateTimeRange => {
    const val: DateTimeRange = {
        left: undefined,
        right: undefined,
    };
    const left = resource?.getLeft();
    if (left) {
        val.left = left.toDate();
    }
    const right = resource?.getRight();
    if (right) {
        val.right = right.toDate();
    }
    return val;
};

export const DateTimeRangeToPb = (
    entity: DateTimeRange,
): DateTimeRangeMessage => {
    const message = new DateTimeRangeMessage();

    if (entity.left) {
        message.setLeft(Timestamp.fromDate(entity.left));
    }
    if (entity.right) {
        message.setRight(Timestamp.fromDate(entity.right));
    }

    return message;
};
