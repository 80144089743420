import { i18n } from "@app/i18n/i18n";
import { getRoutes } from "@app/router/routes";
import { initAuthClient } from "@grpc/authClient";
import { initBalanceAccountClient } from "@grpc/balanceAccountClient";
import { initBalanceTransactionClient } from "@grpc/balanceTransactionClient";
import { initOUserClient } from "@grpc/organizationUserClient";
import { initPaymentClient } from "@grpc/paymentClient";
import { initSubscriptionClient } from "@grpc/subscriptionClient";
import { initUserWorkoutClient } from "@grpc/userWorkoutClient";
import * as Sentry from "@sentry/vue";
import { createPinia } from "pinia";
import { AuthServicePromiseClient } from "proto-api/auth/auth_service_grpc_web_pb";
import { OrganizationUserServicePromiseClient } from "proto-api/auth/organization_user_service_grpc_web_pb";
import { AccountServicePromiseClient } from "proto-api/balance/account_service_grpc_web_pb";
import { TransactionServicePromiseClient as BalanceTransactionServicePromiseClient } from "proto-api/balance/transaction_service_grpc_web_pb";
import { SubscriptionServicePromiseClient } from "proto-api/organization/subscription_service_grpc_web_pb";
import { UserWorkoutServicePromiseClient } from "proto-api/organization/user_workout_service_grpc_web_pb";
import { PaymentServicePromiseClient } from "proto-api/payment/service_grpc_web_pb";
import { createApp } from "vue";
import { createWebHistory } from "vue-router";
import { initYandexMetrika } from "yandex-metrika-vue3";
import App from "./App.vue";
import { createAppRouter } from "./router";

// without it the css styles will not be loaded
import "./index.css";

const pinia = createPinia();

const app = createApp(App);
const router = createAppRouter(createWebHistory(), getRoutes(), true);

// @see vite.config.ts
declare const __APP_VERSION__: string;
if (import.meta.env.MODE === "production") {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_API_BASE_SENTRY_DNS,
        integrations: [Sentry.browserTracingIntegration({ router })],
        tracePropagationTargets: [
            "localhost",
            import.meta.env.VITE_API_BASE_DOMAIN,
        ],
        maxBreadcrumbs: 50,
        environment: import.meta.env.MODE,
        release: __APP_VERSION__,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}
app.use(initYandexMetrika, {
    id: import.meta.env.VITE_YANDEX_COUNTER,
    router: router,
    env: import.meta.env.MODE,
    scriptSrc: "https://mc.yandex.ru/metrika/tag.js",
});

//"https://grpc-local.fitfrog.ru"
app.use(initPaymentClient, {
    service: new PaymentServicePromiseClient(
        `${import.meta.env.VITE_PAYMENTS_BASE_URL}`,
        null,
    ),
});
app.use(initSubscriptionClient, {
    service: new SubscriptionServicePromiseClient(
        `${import.meta.env.VITE_GRPC_BASE_URL}`,
        null,
    ),
});
app.use(initUserWorkoutClient, {
    service: new UserWorkoutServicePromiseClient(
        `${import.meta.env.VITE_GRPC_BASE_URL}`,
        null,
    ),
});
app.use(initAuthClient, {
    service: new AuthServicePromiseClient(
        `${import.meta.env.VITE_AUTH_BASE_URL}`,
        null,
    ),
});
app.use(initOUserClient, {
    service: new OrganizationUserServicePromiseClient(
        `${import.meta.env.VITE_AUTH_BASE_URL}`,
        null,
    ),
});
app.use(initBalanceAccountClient, {
    service: new AccountServicePromiseClient(
        `${import.meta.env.VITE_BALANCE_BASE_URL}`,
        null,
    ),
});
app.use(initBalanceTransactionClient, {
    service: new BalanceTransactionServicePromiseClient(
        `${import.meta.env.VITE_BALANCE_BASE_URL}`,
        null,
    ),
});

app.use(pinia).use(i18n).use(router).mount("#app");
