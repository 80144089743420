<template>
  <div
    v-for="option of options"
    :key="EntityIDToString(option.id)"
    :class="{
      'input-error text-red-500':
        field.$dirty && field.$silentErrors.length,
    }"
  >
    <label class="label text-left cursor-pointer">
      <input
        v-model="fieldRef.$model"
        :name="field.$path + '[]'"
        :value="option.id"
        class="checkbox"
        type="checkbox"
      >
      <span class="flex-1 ml-2">
        <img
          :src="option.path"
          alt=""
          class="mw-100"
        >
      </span>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { BaseValidation } from "@vuelidate/core";
import { ref } from "vue";
import { EntityFile } from "@app/entities/entityFile";
import { EntityIDToString, UUID } from "@app/entities/uuid";

const props = defineProps<{
    field: BaseValidation<UUID[]>;
    options: EntityFile[];
}>();
const fieldRef = ref(props.field);
</script>

<style scoped></style>
