<template>
  <div
    class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
  >
    <div class="space-y-1 text-center">
      <template v-if="imageUrls.length > 0">
        <img
          v-for="imageUrl in imageUrls"
          :key="imageUrl"
          :src="imageUrl"
          alt="It's not an image file"
          class="text-center"
        >
      </template>
      <img
        v-for="actualFile in actualFiles"
        v-else-if="props.actualFiles && props.actualFiles.length"
        :key="actualFile"
        :src="actualFile"
        class="mx-auto"
        alt=""
      >
      <svg
        v-else
        aria-hidden="true"
        class="mx-auto h-12 w-12"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 48 48"
      >
        <path
          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </svg>
      <div class="flex text-sm">
        <label
          class="cursor-pointer relative rounded-md font-medium text-indigo-600 hover:text-indigo-500"
          for="file-upload"
        >
          <input
            :class="{
              'input-error text-red-500':
                field.$dirty && field.$silentErrors.length,
            }"
            :multiple="multiple"
            class="text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
            type="file"
            @blur="field.$touch"
            @change="uploadFile"
          >
        </label>
      </div>
      <p class="text-xs text-gray-500">
        PNG, JPG, GIF up to 10MB
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { BaseValidation } from "@vuelidate/core";

const props = defineProps<{
    field: BaseValidation;
    placeholder?: string;
    imageUrls?: string[];
    multiple: boolean;
    actualFiles?: string[];
}>();

const imageUrls = ref<string[]>(props.imageUrls ?? []);
const fieldRef = ref<BaseValidation>(props.field);

const uploadFile = (event: Event) => {
    const target = event.target as HTMLInputElement;
    imageUrls.value = [];
    fieldRef.value.$model = props.multiple
        ? imageUrls.value
        : imageUrls.value[0];
    if (target.files) {
        for (let i = 0; i < target.files.length; i++) {
            const file = target.files[i];
            if (file) {
                const reader = new FileReader();
                reader.onload = (ev) => {
                    imageUrls.value = [
                        ...imageUrls.value,
                        (ev.target?.result ?? "") as string,
                    ]; // ArrayBuffer can be here
                    fieldRef.value.$model = props.multiple
                        ? imageUrls.value
                        : imageUrls.value[0];
                };
                reader.readAsDataURL(file);
            }
        }
    }
};
</script>

<style scoped></style>
