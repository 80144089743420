import { Account } from "@app/entities/balance/account";
import { Context } from "@app/entities/context";
import { Transaction } from "@app/entities/balance/transaction";
import { BalanceAccountID, PaymentTransactionID } from "@app/entities/uuid";
import { storageRepo } from "@app/pkg/storageRepo";
import { useBalanceAccountsRepo } from "@app/stores/balanceAccountsRepo";
import { useBalanceTransactionsRepo } from "@app/stores/balanceTransactionsRepo";

export interface BalanceTransactionsRepo {
    storeTransaction(
        ctx: Context,
        fromAccountID: BalanceAccountID | undefined,
        toAccountID: BalanceAccountID | undefined,
        amount: number,
        paymentTransactionID: PaymentTransactionID | undefined,
    ): Promise<Transaction | undefined>;
}

const useTransactionsRepo = (): BalanceTransactionsRepo => {
    return useBalanceTransactionsRepo();
};

export const balanceUseCase = {
    async storeTransaction(
        fromAccountID: BalanceAccountID | undefined,
        toAccountID: BalanceAccountID | undefined,
        amount: number,
        paymentTransactionID: PaymentTransactionID | undefined,
    ): Promise<Transaction | undefined> {
        const repo = useTransactionsRepo();
        const balanceRepo = useBalanceAccountsRepo();
        const model = await repo.storeTransaction(
            storageRepo.withToken(),
            fromAccountID,
            toAccountID,
            amount,
            paymentTransactionID,
        );
        if (fromAccountID) {
            await balanceRepo.loadByID(storageRepo.withToken(), fromAccountID);
        }
        if (toAccountID) {
            await balanceRepo.loadByID(storageRepo.withToken(), toAccountID);
        }
        return model;
    },
};

export const useBalanceAccountsViewData = (): {
    getByID(id: BalanceAccountID): Account | undefined;
    All: Account[];
} => {
    return useBalanceAccountsRepo();
};
