import { RouteRecordRaw } from "vue-router";
import FormView from "@app/views/papes/FormView.vue";
import HtmlView from "@app/views/papes/HtmlView.vue";
import LoginView from "@app/views/papes/LoginView.vue";
import { getFirsLevelProps } from "@app/pkg/router/props";
import ForgotPasswordView from "@app/views/papes/ForgotPasswordView.vue";
import PasswordResetView from "@app/views/papes/PasswordResetView.vue";
import SignUpView from "@app/views/papes/SignUpView.vue";
import { organizationRoutes } from "@app/views/papes/organization/routes";
import { disciplinesRoutes } from "@disciplinesView/routes";
import { locationsRoutes } from "@locationsView/routes";
import { subscriptionTypesRoutes } from "@subscriptionTypes/routes";
import { usersRoutes } from "@users/routes";
import { workoutRoutes } from "@pages/workouts/routes";
import { scheduleRoutes } from "@pages/schedule/routes";
import { indexRoute } from "@pages/index/route";
import { personalRoutes } from "@pages/personal/routes";
import { PageType } from "@app/pkg/router/pageType";
import { authUseCase } from "@app/usecase/authUseCase";

export const getRoutes = (): RouteRecordRaw[] => {
    return [
        ...scheduleRoutes,
        ...workoutRoutes,
        ...organizationRoutes,
        ...locationsRoutes,
        ...disciplinesRoutes,
        ...subscriptionTypesRoutes,
        ...usersRoutes,
        ...personalRoutes,
        {
            path: "/form",
            name: "Form",
            component: FormView,
            props: getFirsLevelProps(),
            meta: {
                title: "Form",
                isGuest: false,
                requiresAuth: false,
            },
        },
        {
            path: "/html",
            name: "Html",
            component: HtmlView,
            meta: {
                title: "Schedule",
                isGuest: false,
                requiresAuth: false,
            },
        },
        {
            path: "/login",
            name: PageType.Login,
            component: LoginView,
            meta: {
                title: "Sign in to your account",
                isGuest: true,
                requiresAuth: false,
            },
        },
        {
            path: "/logout",
            name: PageType.Logout,
            meta: {
                isGuest: false,
                requiresAuth: true,
            },
            redirect: () => {
                authUseCase.logout();
                return {
                    name: PageType.Index,
                };
            },
        },
        {
            path: "/auth-success/:token",
            name: "AuthSuccess",
            meta: {
                isGuest: true,
                requiresAuth: false,
            },
            redirect: (to) => {
                if (to.params.token) {
                    localStorage.setItem("TOKEN", to.params.token as string);
                    return {
                        name: PageType.Workouts,
                    };
                } else {
                    return {
                        name: PageType.Login,
                    };
                }
            },
        },
        {
            path: "/forgot-password",
            name: "ForgotPassword",
            component: ForgotPasswordView,
            meta: {
                title: "Password recovery",
                isGuest: true,
                requiresAuth: false,
            },
        },
        {
            path: "/password-reset",
            name: "PasswordReset",
            component: PasswordResetView,
            meta: {
                title: "Reset password",
                isGuest: true,
                requiresAuth: false,
            },
        },
        {
            path: "/sign-up",
            name: "SignUp",
            component: SignUpView,
            meta: {
                title: "Sign up",
                isGuest: true,
                requiresAuth: false,
            },
        },
        indexRoute,
    ];
};
