<template>
  <label
    v-for="option of options"
    :key="option.value"
    class="label cursor-pointer"
    :class="{
      'input-error text-red-500':
        field.$dirty && field.$silentErrors.length,
    }"
  >
    <input
      v-model="fieldRef.$model"
      :name="field.$path + '[]'"
      :value="option.value"
      class="checkbox"
      type="checkbox"
      :disabled="option.disabled"
    >
    <span class="grow ml-2">
      {{
        option.displayValue !== undefined
          ? option.displayValue
          : option.value
      }}
    </span>
  </label>
</template>

<script lang="ts" setup>
import { BaseValidation } from "@vuelidate/core";
import { ref } from "vue";
import { SelectOption } from "@app/views/models/form";

const props = defineProps<{
    field: BaseValidation<string[]>;
    options: SelectOption<string>[];
}>();
const fieldRef = ref(props.field);
</script>

<style scoped></style>
