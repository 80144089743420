<template>
  <page-component
    :title="t('Settings')"
    :links="actionLinks"
  >
    <message-block
      :message="msg"
      template="block"
      type="success"
      @on-close="msg = ''"
    />
    <user-update-form
      :organization-user="user"
      @on-success="onSuccess"
    />
  </page-component>
</template>

<script lang="ts" setup>
import PageComponent from "@app/components/PageComponent.vue";
import UserUpdateForm from "@app/components/forms/UserUpdateForm.vue";
import {
    PersonalIndexBeforeEnter,
    PersonalIndexProps,
} from "@pages/personal/index/route";
import { Link, LinkIcons } from "@app/views/models/links";
import { useI18n } from "vue-i18n";
import { computed, ComputedRef, ref } from "vue";
import { PageType } from "@app/pkg/router/pageType";
import MessageBlock from "@app/views/components/MessageBlock.vue";
import { onBeforeRouteUpdate } from "vue-router";

const { t } = useI18n();

defineProps<PersonalIndexProps>();
const msg = ref<string>("");
const onSuccess = () => {
    msg.value = t("Successfully updated");
};

const actionLinks: ComputedRef<Link[]> = computed(() => [
    {
        text: t("My workouts"),
        image: LinkIcons.calendar,
        to: {
            name: PageType.UserWorkouts,
        },
        disabled: false,
    },
    {
        text: t("My subscriptions"),
        image: LinkIcons.trophy,
        to: {
            name: PageType.UserSubscriptions,
        },
        disabled: false,
    },
]);
onBeforeRouteUpdate(PersonalIndexBeforeEnter);
</script>

<style scoped></style>
