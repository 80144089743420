<template>
  <page-component :title="t(`{name} update`, { name: discipline.name })">
    <discipline-update-form
      :discipline="discipline"
      @on-success="onSuccess"
    />
  </page-component>
</template>

<script lang="ts" setup>
import DisciplineUpdateForm from "@app/components/forms/DisciplineUpdateForm.vue";
import { useI18n } from "vue-i18n";
import { Discipline } from "@app/entities/discipline";
import PageComponent from "@app/components/PageComponent.vue";
import { useRouter } from "vue-router";

const { t } = useI18n();
const router = useRouter();

defineProps<{
    discipline: Discipline;
}>();

const onSuccess = () => {
    router.back();
};
</script>

<style scoped></style>
