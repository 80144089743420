import { EntityIDToString } from "@app/entities/uuid";
import { Subscription } from "@app/entities/subscription";
import { subscriptionsUseCase } from "@app/usecase/subscriptionUseCase";
import { SelectOption } from "@app/views/models/form";
import { ComposerDateTimeFormatting, ComposerTranslation } from "vue-i18n";

export const subscriptionsToOptions = (
    models: Subscription[],
    tt: ComposerTranslation,
    dd: ComposerDateTimeFormatting,
): SelectOption<string>[] => {
    return models.map((model): SelectOption<string> => {
        return {
            value: EntityIDToString(model.id),
            displayValue: subscriptionsUseCase.modelName(model, tt, dd),
        };
    });
};
