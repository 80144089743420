import { Ref, ref, UnwrapNestedRefs } from "vue";
import useVuelidate, { Validation, ValidationArgs } from "@vuelidate/core";

export interface AppForm<T, F> {
    form: Ref<Validation<ValidationArgs<T>, UnwrapNestedRefs<T>>>;
    onSubmit: () => Promise<F>;
    $externalResults: Ref<unknown>;
}

export const appForm = <T, F>(
    rules: ValidationArgs<T>,
    formFields: T,
    onSubmit: () => Promise<F>,
): AppForm<T, F> => {
    const $externalResults = ref({});
    const form = useVuelidate(rules, formFields, {
        $externalResults,
    });

    return { form, onSubmit, $externalResults } as AppForm<T, F>;
};
