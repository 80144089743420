import { SelectOption } from "@app/views/models/form";
import { EntityIDToString } from "@app/entities/uuid";
import { SubscriptionType } from "@app/entities/subscriptionType";

export const subscriptionTypeToOptions = (
    models: SubscriptionType[],
): SelectOption<string>[] => {
    return models.map((model): SelectOption<string> => {
        return {
            value: EntityIDToString(model.id),
            displayValue: model.name,
        };
    });
};

// const convertDate = (date: string, time_from: string, time_to: string) => {
//     const timeFrom = time_from.split(":");
//     const timeTo = time_to.split(":");
//
//     const dateFrom = new Date(date);
//     dateFrom.setHours(parseInt(timeFrom[0]), parseInt(timeFrom[1]), 0, 0);
//
//     const dateTo = new Date(date);
//     dateTo.setHours(parseInt(timeTo[0]), parseInt(timeTo[1]), 0, 0);
//
//     return {
//         dateFrom,
//         dateTo,
//     };
// };

// const convertFormFieldsToStoreRequestFields = (
//     data: StoreWorkoutFormFields,
// ): StoreWorkoutFields => {
//     const date = convertDate(data.date, data.time_from, data.time_to);
//
//     return {
//         discipline_id: data.discipline_id,
//         location_id: data.location_id,
//         trainer_id: data.trainer_id,
//         schedule_id: data.schedule_id,
//         date_time_range:
//             '["' +
//             date.dateFrom.toISOString() +
//             '","' +
//             date.dateTo.toISOString() +
//             '")',
//         students_count: data.students_count,
//     };
// };

// TODO
// const convertFormFieldsToUpdateRequestFields = (
//     data: UpdateWorkoutFormFields,
// ): UpdateWorkoutFields => {
//     const date = convertDate(data.date, data.time_from, data.time_to);
//
//     return {
//         discipline_id: data.discipline_id,
//         location_id: data.location_id,
//         trainer_id: data.trainer_id,
//         schedule_id: data.schedule_id,
//         date_time_range:
//             '["' +
//             date.dateFrom.toISOString() +
//             '","' +
//             date.dateTo.toISOString() +
//             '")',
//         students_count: data.students_count,
//         status: WorkoutStatus.open,
//     };
// };
