export const StringToDate = (
    ymd: string,
    t?: string,
    relativeDate?: Date,
): Date => {
    const d = new Date(ymd);
    d.setHours(0, 0, 0, 0);
    if (t) {
        const time = t.split(":");
        d.setHours(parseInt(time[0]), parseInt(time[1]), 0, 0);
        if (relativeDate && relativeDate > d) {
            d.setDate(d.getDate() + 1);
        }
    }
    return d;
};

export const StringToTime = (from: number, t: string): number => {
    const timeFrom = t.split(":");
    return from + parseInt(timeFrom[0]) * 3600 + parseInt(timeFrom[1]) * 60;
};
