<template>
  <popup-page-component :title="t('Schedule update')">
    <schedule-update-form
      :discipline-options="disciplineOptions"
      :location-options="locationOptions"
      :schedule="schedule"
      :trainer-options="trainersOptions"
      @on-success="onSuccess"
    />
  </popup-page-component>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import ScheduleUpdateForm from "@app/components/forms/ScheduleUpdateForm.vue";
import PopupPageComponent from "@app/components/PopupPageComponent.vue";
import { computed } from "vue";
import { useDisciplineViewData } from "@app/usecase/disciplineUseCase";
import { useLocationViewData } from "@app/usecase/locationUseCase";
import { useOrganizationUserViewData } from "@app/usecase/organizationUserUseCase";
import { disciplinesToOptions } from "@app/views/converters/discipline";
import { organizationUsersToOptions } from "@app/views/converters/organization_user";
import { locationsToOptions } from "@app/views/converters/location";
import { ScheduleID } from "@app/entities/uuid";
import { useScheduleViewData } from "@app/usecase/scheduleUseCase";
import { useI18n } from "vue-i18n";

type Props = {
    scheduleId: ScheduleID;
};
const { t } = useI18n();
const router = useRouter();

const props = defineProps<Props>();

const disciplineViewData = useDisciplineViewData();
const scheduleViewData = useScheduleViewData();
const locationViewData = useLocationViewData();
const oUserViewData = useOrganizationUserViewData();

const schedule = computed(() => {
    const model = scheduleViewData.getByID(props.scheduleId);
    if (!model) {
        throw Error("Not found");
    }
    return model;
});
const disciplineOptions = computed(() => {
    return disciplinesToOptions(disciplineViewData.AllActive);
});
const trainersOptions = computed(() => {
    return organizationUsersToOptions(oUserViewData.trainers);
});
const locationOptions = computed(() => {
    return locationsToOptions(locationViewData.AllActive);
});
const onSuccess = () => {
    router.back();
};
</script>

<style scoped></style>
