<template>
  <page-component
    :has-popup="false"
    :links="links"
    :title="t('Workouts')"
  >
    <location-filter-form class="mb-2" />
    <weekday-navigation
      :active-date="dateFrom"
      :left-date="leftDate"
      :right-date="rightDate"
      :route-name="PageType.Index"
      type="calendar"
    />
    <error-boundary-component
      v-for="workout of workouts"
      :key="EntityIDToString(workout.id)"
    >
      <workout-item-component
        :model="workout"
        :show-date="false"
        :show-location="!locationId"
      />
    </error-boundary-component>
  </page-component>
</template>

<script lang="ts" setup>
import ErrorBoundaryComponent from "@app/components/ErrorBoundaryComponent.vue";
import LocationFilterForm from "@app/components/forms/LocationFilterForm.vue";
import PageComponent from "@app/components/PageComponent.vue";
import WeekdayNavigation from "@app/components/WeekdayNavigation.vue";
import { EntityIDToString, LocationID } from "@app/entities/uuid";
import { PageType } from "@app/pkg/router/pageType";
import { PageName } from "@app/pkg/router/pageName";
import { useWorkoutViewData } from "@app/usecase/workoutsUseCase";
import WorkoutItemComponent from "@app/views/entityComponents/WorkoutItemComponent.vue";
import { Link, LinkIcons } from "@app/views/models/links";
import { IndexViewBeforeEnter } from "@pages/index/route";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { onBeforeRouteUpdate } from "vue-router";

type Props = {
    day: string;
    dateFrom: Date;
    dateTo: Date;
    locationId?: LocationID;
};

const props = defineProps<Props>();
const viewData = useWorkoutViewData();
const { t } = useI18n();

const leftDate = new Date();
leftDate.setHours(0, 0, 0, 0);
const rightDate = new Date(leftDate);
rightDate.setDate(rightDate.getDate() + 7);

const links: Link[] = [
    {
        disabled: false,
        image: LinkIcons.userAdd,
        text: t(PageName.SignUp),
        to: { name: PageType.SignUp },
    },
    {
        disabled: false,
        image: LinkIcons.user,
        text: t(PageName.Login),
        to: { name: PageType.Login },
    },
];

const workouts = computed(() =>
    viewData.getByDate(
        props.dateFrom,
        props.dateTo,
        props.locationId,
        undefined,
    ),
);

onBeforeRouteUpdate(IndexViewBeforeEnter);
</script>

<style scoped></style>
