import { Link, LinkIcons } from "@app/views/models/links";
import { IUserAction } from "./interface";
import { ComposerTranslation } from "vue-i18n";
import { OrganizationUser } from "@app/entities/organzationUser";
import { ActionWatcher } from "@app/services/actionWatcher";
import { UserWorkout } from "@app/entities/userWorkout";
import { userWorkoutsUseCase } from "@app/usecase/userWorkoutUseCase";
import { authUseCase, ENTITIES_LIST } from "@app/usecase/authUseCase";
import { Workout } from "@app/entities/workout";
import { WorkoutStatus } from "@app/entities/workoutStatus";

export type CancelUserWorkoutActionParams = {
    watcher: ActionWatcher
    t: ComposerTranslation
    workout: Workout
    model: UserWorkout
    modelUser: OrganizationUser
    currentUser: OrganizationUser
    now?: Date
}
export class CancelUserWorkoutAction implements IUserAction {

    now: Date
    watcher: ActionWatcher
    t: ComposerTranslation
    currentUser: OrganizationUser
    workout: Workout
    userWorkout: UserWorkout
    modelUser: OrganizationUser

    constructor(params: CancelUserWorkoutActionParams) {
        this.t = params.t;
        this.watcher = params.watcher;
        this.currentUser = params.currentUser;
        this.workout = params.workout;
        this.userWorkout = params.model;
        this.modelUser = params.modelUser;
        this.now = params.now ? params.now : new Date();
    }

    getID(): string {
        return "CancelUserWorkoutAction"
    }

    async cancelUserWorkout(): Promise<void> {
        this.watcher.begin();
        return await userWorkoutsUseCase
            .cancel(this.userWorkout.id, false)
            .finally(() => {
                this.watcher.end();
            });
    };

    getLink(): Link {
        return {
            text: this.t("Cancel"),
            image: LinkIcons.error,
            actionConfirm: this.getConfirmMessage(),
            action: () => this.cancelUserWorkout(),
            disabled: !this.isAllowed(),
        }
    }

    getConfirmMessage(): string {
        const tomorrowDate = new Date(this.now);
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);

        const isSelf = this.currentUser.id.value === this.modelUser.id.value;
        const isTooLate = this.workout.dateTimeRange.right < tomorrowDate;

        switch (true) {
            case isTooLate && isSelf:
                return this.t("You cancel the workout less than 24 hours, unfortunately, the workout will be deducted from your subscription. Are you sure you want to cancel your workout?");
            case isTooLate:
                return this.t("You cancel the workout less than 24 hours, unfortunately, the workout will be deducted from your subscription. Are you sure you want to cancel {user}`s workout?", {
                    user: this.modelUser.user.name,
                });
            case isSelf:
                return this.t("Are you sure you want to cancel your workout?");
            default:
                return this.t("Are you sure you want to cancel {user}`s workout?", {
                    user: this.modelUser.user.name,
                });
        }
    }

    isAllowed(): boolean {
        return this.workout.status === WorkoutStatus.WorkoutStatusOpen &&
            this.userWorkout.status === WorkoutStatus.WorkoutStatusOpen &&
            (authUseCase.canEdit(ENTITIES_LIST.USER_WORKOUT) || this.currentUser.id.value === this.modelUser.id.value);
    }

}