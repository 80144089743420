<template>
  <div
    :class="{
      [`form-control-${field.$path}`]: true,
    }"
  >
    <label class="label flex flex-auto cursor-pointer gap-2">
      <span class="label-text grow text-right">{{ labelLeft }}</span>

      <input
        v-model="fieldRef.$model"
        :name="field.$path"
        :value="true"
        class="toggle bg-primary border-primary"
        type="checkbox"
        @blur="field.$touch"
      >

      <span class="label-text grow">{{ labelRight }}</span>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { BaseValidation } from "@vuelidate/core";
import { ref } from "vue";

const props = defineProps<{
    field: BaseValidation;
    labelLeft: string;
    labelRight: string;
}>();
const fieldRef = ref(props.field);
</script>

<style scoped></style>
