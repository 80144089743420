<template>
  <page-component
    :links="actionLinks"
    :title="organization.name"
  >
    <earned-money-chart />
  </page-component>
</template>

<script lang="ts" setup>
import PageComponent from "@app/components/PageComponent.vue";
import { Organization } from "@app/entities/organzation";
import { PageType } from "@app/pkg/router/pageType";
import { authUseCase, ENTITIES_LIST } from "@app/usecase/authUseCase";
import EarnedMoneyChart from "@app/views/charts/EarnedMoneyChart.vue";
import { Link, LinkIcons } from "@app/views/models/links";
import { OrganizationStatisticViewBeforeEnter } from "@organizationView/routes";
import { computed, ComputedRef } from "vue";
import { useI18n } from "vue-i18n";
import { onBeforeRouteUpdate } from "vue-router";

const { t } = useI18n();

defineProps<{
    organization: Organization;
}>();

const actionLinks: ComputedRef<Link[]> = computed(() => [
    {
        text: t("Info"),
        image: LinkIcons.user,
        to: {
            name: PageType.OrganizationInfo,
        },
        disabled: !authUseCase.canEdit(ENTITIES_LIST.ORGANIZATION),
    },
    {
        text: t("Payments"),
        image: LinkIcons.currencyDollar,
        to: {
            name: PageType.Payments,
        },
        disabled: !authUseCase.canEdit(ENTITIES_LIST.ORGANIZATION),
    },
]);
onBeforeRouteUpdate(OrganizationStatisticViewBeforeEnter);
</script>

<style scoped></style>
