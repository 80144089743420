<template>
  <PageComponent
    :has-popup="false"
    :links="links"
    :title="t('Login')"
  >
    <form-component
      :form="vueForm"
      class="mt-8 space-y-6"
    >
      <div class="rounded-md shadow-sm -space-y-px">
        <choose-field
          :field="form.byPhone"
          label-left="Email"
          label-right="Phone"
        />

        <form-field
          v-if="!form.byPhone.$model"
          :field="form.email"
          :label="t('Email')"
          class="block relative w-full"
        >
          <text-input-field
            :field="form.email"
            :placeholder="t('Email')"
            autocomplete="current-email"
            type="email"
          />
        </form-field>
        <form-field
          v-if="form.byPhone.$model"
          :field="form.phone"
          :label="t('Phone')"
          class="block relative w-full"
        >
          <phone-input-field
            :field="form.phone"
            :placeholder="t('Phone')"
            autocomplete="current-phone"
          />
        </form-field>

        <form-field
          :field="form.password"
          :label="t('Password')"
        >
          <text-input-field
            :field="form.password"
            :placeholder="t('Password')"
            autocomplete="current-password"
            type="password"
          />
        </form-field>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <input
            id="remember"
            v-model="formFields.remember"
            class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            name="remember"
            type="checkbox"
          >
          <label
            class="ml-2 block text-sm text-gray-900"
            for="remember"
          >
            {{ t("Remember me") }}
          </label>
        </div>

        <div class="text-sm">
          <router-link
            :to="{ name: 'ForgotPassword' }"
            class="font-medium text-indigo-600 hover:text-indigo-500"
          >
            {{ t("Forgot your password") }}?
          </router-link>
        </div>
      </div>
      <div class="flex items-center justify-between">
        <div class="flex items-center" />

        <div class="text-sm">
          <router-link
            :to="{ name: 'SignUp' }"
            class="font-medium text-indigo-600 hover:text-indigo-500"
          >
            {{ t("Sign up") }}
          </router-link>
        </div>
      </div>

      <div>
        <button
          class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          type="submit"
        >
          {{ t("Sign in") }}
        </button>
      </div>

      <hr>

      <social-links />
    </form-component>
  </PageComponent>
</template>

<script lang="ts" setup>
import FormComponent from "@app/components/FormComponent.vue";
import ChooseField from "@app/components/forms/fields/ChooseField.vue";
import FormField from "@app/components/forms/fields/FormField.vue";
import PhoneInputField from "@app/components/forms/fields/PhoneInputField.vue";
import TextInputField from "@app/components/forms/fields/TextInputField.vue";
import PageComponent from "@app/components/PageComponent.vue";
import { PageType } from "@app/pkg/router/pageType";
import { appForm } from "@app/services/forms";
import { email, required, requiredIf } from "@app/services/validators";
import { authUseCase } from "@app/usecase/authUseCase";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import SocialLinks from "@app/views/components/SocialLinks.vue";
import { Link, LinkIcons } from "@app/views/models/links";
import { ValidationArgs } from "@vuelidate/core";
import { reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

interface LoginFormFields {
    byPhone: boolean;
    email: string;
    phone: string;
    password: string;
    remember: boolean;
}

const router = useRouter();
const { t } = useI18n();
const organization = organizationUseCase.currentOrganization();

const links: Link[] = [
    {
        disabled: false,
        image: LinkIcons.calendar,
        text: t("Workouts"),
        to: { name: PageType.Index },
    },
];

const formFields = reactive<LoginFormFields>({
    byPhone: false,
    email: "",
    phone: "",
    password: "",
    remember: false,
});

const rules: ValidationArgs<LoginFormFields> = {
    byPhone: {},
    email: {
        requiredIf: requiredIf(() => {
            return !formFields.byPhone;
        }),
        email,
    },
    phone: {
        requiredIf: requiredIf(() => {
            return formFields.byPhone;
        }),
    },
    password: { required }, // Matches state.password
    remember: {},
};

const vueForm = appForm<LoginFormFields, void>(rules, formFields, async () => {
    if (formFields.byPhone) {
        await authUseCase.loginByPhone(
            organization.id,
            formFields.phone,
            formFields.password,
        );
    } else {
        await authUseCase.loginByEmail(
            organization.id,
            formFields.email,
            formFields.password,
        );
    }

    await router.push({
        name: PageType.Workouts,
    });
});
const form = vueForm.form;
</script>

<style scoped></style>
