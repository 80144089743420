<template>
  <page-component :title="t('Payment account add')">
    <payment-account-add-form
      :organization-id="organization.id"
      @on-success="onSuccess"
    />
  </page-component>
</template>

<script lang="ts" setup>
import PaymentAccountAddForm from "@app/components/forms/PaymentAccountAddForm.vue";
import PageComponent from "@app/components/PageComponent.vue";
import { PageType } from "@app/pkg/router/pageType";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();
const router = useRouter();

const organization = organizationUseCase.currentOrganization();

const onSuccess = () => {
    router.push({ name: PageType.Payments });
};
</script>

<style scoped></style>
