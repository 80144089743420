import { Context, WithBackground } from "@app/entities/context";
import { oUserName } from "@app/entities/organzationUser";
import {
    filterSubscriptionIDs,
    Subscription,
} from "@app/entities/subscription";
import {
    DisciplineID,
    OrganizationID,
    OrganizationUserID,
    PaymentAccountID,
    SubscriptionID,
    SubscriptionTypeID,
} from "@app/entities/uuid";
import { DATETIME_DAY_AND_MONTH } from "@app/i18n/i18n";
import { storageRepo } from "@app/pkg/storageRepo";
import { useBalanceAccountsRepo } from "@app/stores/balanceAccountsRepo";
import { useSubscriptionRepo } from "@app/stores/subscriptionRepo";
import { useUserWorkoutRepo } from "@app/stores/userWorkoutRepo";
import { useOrganizationUserViewData } from "@app/usecase/organizationUserUseCase";
import { ComposerDateTimeFormatting, ComposerTranslation } from "vue-i18n";

export interface SubscriptionRepo {
    getByID(id: SubscriptionID): Subscription | undefined;

    getByOrganizationUserID(
        organizationUserID: OrganizationUserID,
    ): Subscription[];

    store(
        ctx: Context,
        oUserID: OrganizationUserID,
        typeID: SubscriptionTypeID,
        disciplineIDs: DisciplineID[],
        start?: Date,
        expire?: Date,
        days?: number,
    ): Promise<Subscription | undefined>;

    update(
        ctx: Context,
        id: SubscriptionID,
        disciplineIDs: DisciplineID[],
        start: Date,
        expire: Date,
    ): Promise<Subscription | undefined>;

    loadByID(
        ctx: Context,
        id: SubscriptionID,
    ): Promise<Subscription | undefined>;

    loadByOrganizationID(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<Subscription[]>;

    loadByOrganizationUserID(
        ctx: Context,
        organizationUserID: OrganizationUserID,
    ): Promise<Subscription[]>;

    cancel(ctx: Context, id: SubscriptionID): Promise<Subscription | undefined>;

    open(ctx: Context, id: SubscriptionID): Promise<Subscription | undefined>;

    pay(
        ctx: Context,
        id: SubscriptionID,
        accountID: PaymentAccountID,
        sum: number,
        count: number,
    ): Promise<Subscription | undefined>;
}

const useRepo = (): SubscriptionRepo => {
    return useSubscriptionRepo();
};

export const subscriptionsUseCase = {
    async store(
        oUserID: OrganizationUserID,
        typeID: SubscriptionTypeID,
        disciplineIDs: DisciplineID[],
        start?: Date,
        expire?: Date,
        days?: number,
    ): Promise<Subscription | undefined> {
        const repo = useRepo();
        return await repo.store(
            storageRepo.withToken(WithBackground()),
            oUserID,
            typeID,
            disciplineIDs,
            start,
            expire,
            days,
        );
    },

    async update(
        id: SubscriptionID,
        disciplineIDs: DisciplineID[],
        start: Date,
        expire: Date,
    ): Promise<Subscription | undefined> {
        const repo = useRepo();
        return await repo.update(
            storageRepo.withToken(WithBackground()),
            id,
            disciplineIDs,
            start,
            expire,
        );
    },

    async cancel(id: SubscriptionID): Promise<Subscription | undefined> {
        const repo = useRepo();
        return await repo.cancel(storageRepo.withToken(WithBackground()), id);
    },

    async open(id: SubscriptionID): Promise<Subscription | undefined> {
        const repo = useRepo();
        return await repo.open(storageRepo.withToken(WithBackground()), id);
    },

    async pay(
        id: SubscriptionID,
        accountID: PaymentAccountID,
        sum: number,
        count: number,
    ): Promise<Subscription | undefined> {
        const repo = useRepo();
        const subscription = await repo.pay(
            storageRepo.withToken(WithBackground()),
            id,
            accountID,
            sum,
            count,
        );
        const balanceRepo = useBalanceAccountsRepo();
        if (subscription && subscription.balanceID) {
            await balanceRepo.loadByID(
                storageRepo.withToken(WithBackground()),
                subscription.balanceID,
            );
        }
        return subscription;
    },

    async loadByID(id: SubscriptionID): Promise<Subscription> {
        const repo = useRepo();
        let model = repo.getByID(id);

        if (!model) {
            model = await repo.loadByID(
                storageRepo.withToken(WithBackground()),
                id,
            );
        }

        if (!model) {
            throw Error(`Subscription '${id.value}' has not found`);
        } else {
            return model;
        }
    },
    async loadByOrganizationUserID(
        organizationUserID: OrganizationUserID,
    ): Promise<Subscription[]> {
        const store = useRepo();
        return await store.loadByOrganizationUserID(
            storageRepo.withToken(WithBackground()),
            organizationUserID,
        );
    },
    async loadByOrganizationID(
        organizationID: OrganizationID,
    ): Promise<Subscription[]> {
        const store = useRepo();
        return await store.loadByOrganizationID(
            storageRepo.withToken(WithBackground()),
            organizationID,
        );
    },
    modelName(
        model: Subscription,
        t: ComposerTranslation,
        d: ComposerDateTimeFormatting,
    ): string {
        const organizationUsersRepo = useOrganizationUserViewData();

        const user = organizationUsersRepo.getByID(model.organizationUserID);
        const untilMessage = model.displayExpireDate
            ? t("until") +
              " " +
              d(model.displayExpireDate, DATETIME_DAY_AND_MONTH)
            : t("open date");
        if (user) {
            return oUserName(user) + ", " + untilMessage;
        } else {
            return untilMessage;
        }
    },
    getForWorkoutID(
        organizationUserID: OrganizationUserID,
        disciplineID: DisciplineID,
        date: Date,
    ): Subscription[] {
        const viewData = useSubscriptionsViewData();
        const userWorkoutsRepo = useUserWorkoutRepo();
        const balanceRepo = useBalanceAccountsRepo();

        const subscriptions = viewData.getByFilter(
            organizationUserID,
            disciplineID,
            date,
        );

        return subscriptions.filter((s) => {
            const balance = balanceRepo.getByID(s.balanceID);
            const workouts = userWorkoutsRepo.getOpenBySubscriptionID(s.id);
            return balance && balance.balance > workouts.length;
        });
    },
    async sync(models: Subscription[]): Promise<void> {
        const ids = filterSubscriptionIDs(models);
        const balanceRepo = useBalanceAccountsRepo();
        await Promise.all(
            ids.balanceIDs.map((id) =>
                balanceRepo.loadByID(storageRepo.withToken(), id),
            ),
        );
        return;
    },
};

export const useSubscriptionsViewData = (): {
    getByID(id: SubscriptionID): Subscription | undefined;
    getByOrganizationUserID(id: OrganizationUserID): Subscription[];
    getByFilter(
        organizationUserID: OrganizationUserID,
        disciplineID: DisciplineID,
        date: Date,
    ): Subscription[];
    All: Subscription[];
    active: Subscription[];
} => {
    return useSubscriptionRepo();
};
