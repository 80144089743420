import { PbToUUID, PbToUUIDSure } from "@grpc/converters/uuid";
import { UserWorkoutMessage } from "proto-api/organization/user_workout_pb";
import { pbToWorkoutStatus } from "@grpc/converters/workoutStatus";
import { UserWorkout } from "@app/entities/userWorkout";

export const PbToUserWorkout = (resource: UserWorkoutMessage): UserWorkout => {
    return {
        id: PbToUUIDSure(resource.getId()),
        organizationID: PbToUUIDSure(resource.getOrganizationId()),
        status: pbToWorkoutStatus(resource.getStatus()),
        organizationUserID: PbToUUIDSure(resource.getOrganizationUserId()),
        paymentID: PbToUUID(resource.getPaymentId()),
        subscriptionID: PbToUUID(resource.getSubscriptionId()),
        workoutID: PbToUUIDSure(resource.getWorkoutId()),
        createdAt: new Date(),
    };
};
