import {
    UserWorkoutAddToSubscriptionRequestMessage,
    UserWorkoutCancelRequestMessage,
    UserWorkoutGetByIDRequestMessage,
    UserWorkoutGetByOrganizationIDRequestMessage,
    UserWorkoutGetByOrganizationUserIDRequestMessage,
    UserWorkoutGetBySubscriptionIDsRequestMessage,
    UserWorkoutOpenRequestMessage,
    UserWorkoutPayRequestMessage,
    UserWorkoutStoreRequestMessage,
} from "proto-api/organization/user_workout_service_pb";
import { Context } from "@app/entities/context";
import { PbToUserWorkout } from "@grpc/converters/userWorkout";
import { UserWorkout } from "@app/entities/userWorkout";
import {
    OrganizationID,
    OrganizationUserID,
    PaymentAccountID,
    SubscriptionID,
    UserWorkoutID,
    WorkoutID,
} from "@app/entities/uuid";
import { EntityIDsToUUID, EntityToUUID } from "@grpc/converters/uuid";
import { ContextToPb } from "@grpc/converters/context";
import { UserWorkoutServicePromiseClient } from "proto-api/organization/user_workout_service_grpc_web_pb";
import { DateTimeRangeToPb } from "@grpc/converters/dateTimeRange";
import { PbToEntities } from "@grpc/converters/entities";
import { App } from "vue";
import { RpcError } from "grpc-web";
import { AppError } from "../error/AppError";

let service: UserWorkoutServicePromiseClient;

type Config = {
    service: UserWorkoutServicePromiseClient;
};
export const initUserWorkoutClient = (app: App, options: Config) => {
    service = options.service;
};

export const useUserWorkoutClient = () => {
    return userWorkoutClient;
};

const userWorkoutClient = {
    async loadByID(
        ctx: Context,
        id: UserWorkoutID,
    ): Promise<UserWorkout | undefined> {
        const request = new UserWorkoutGetByIDRequestMessage();
        request.setId(EntityToUUID(id));

        const data = await service.getByID(request, ContextToPb(ctx));
        const model = data.getUserWorkout();
        if (model) {
            return PbToUserWorkout(model);
        }
        return;
    },

    async loadByOrganizationID(
        ctx: Context,
        organizationID: OrganizationID,
        dateFrom: Date,
        dateTo: Date,
    ): Promise<UserWorkout[]> {
        const request = new UserWorkoutGetByOrganizationIDRequestMessage();
        request.setOrganizationId(EntityToUUID(organizationID));
        request.setDate(
            DateTimeRangeToPb({
                left: dateFrom,
                right: dateTo,
            }),
        );

        const data = await service.getByOrganizationID(
            request,
            ContextToPb(ctx),
        );

        return PbToEntities(data.getUserWorkoutsList(), PbToUserWorkout);
    },

    async loadByOrganizationUserID(
        ctx: Context,
        organizationUserID: OrganizationUserID,
    ): Promise<UserWorkout[]> {
        const request = new UserWorkoutGetByOrganizationUserIDRequestMessage();
        request.setOrganizationUserId(EntityToUUID(organizationUserID));

        const data = await service.getByOrganizationUserID(
            request,
            ContextToPb(ctx),
        );

        return PbToEntities(data.getUserWorkoutsList(), PbToUserWorkout);
    },

    async loadBySubscriptionIDs(
        ctx: Context,
        ids: SubscriptionID[],
    ): Promise<UserWorkout[]> {
        const request = new UserWorkoutGetBySubscriptionIDsRequestMessage();
        request.setIdsList(EntityIDsToUUID(ids));

        const data = await service.getBySubscriptionIDs(
            request,
            ContextToPb(ctx),
        );

        return PbToEntities(data.getUserWorkoutsList(), PbToUserWorkout);
    },

    async store(
        ctx: Context,
        workoutID: WorkoutID,
        organizationUserID: OrganizationUserID,
        subscriptionID?: SubscriptionID,
    ): Promise<UserWorkout | undefined> {
        const request = new UserWorkoutStoreRequestMessage();
        request.setWorkoutId(EntityToUUID(workoutID));
        request.setOrganizationUserId(EntityToUUID(organizationUserID));
        if (subscriptionID) {
            request.setSubscriptionId(EntityToUUID(subscriptionID));
        }

        return await service.store(request, ContextToPb(ctx)).then((data) => {
            const pbWorkout = data.getUserWorkout();
            if (pbWorkout) {
                return PbToUserWorkout(pbWorkout);
            }

            return undefined;
        }).catch((e: RpcError) => {
            switch (e.message) {
                case "user already have unpaid workout":
                    throw new AppError({
                        message: "You already have an unpaid workout. You should pay it or buy a subscription",
                        teacherMessage: "{user} already has an unpaid workout. {user} should pay it or buy a subscription"
                    });
            default:
                throw e;
            }
        });
    },
    async open(
        ctx: Context,
        workoutID: UserWorkoutID,
    ): Promise<UserWorkout | undefined> {
        const request = new UserWorkoutOpenRequestMessage();
        request.setId(EntityToUUID(workoutID));

        return await service.open(request, ContextToPb(ctx)).then((data) => {
            const pbWorkout = data.getUserWorkout();
            if (pbWorkout) {
                return PbToUserWorkout(pbWorkout);
            }

            return undefined;
        });
    },
    async cancel(
        ctx: Context,
        workoutID: UserWorkoutID,
        keepBalance: boolean,
    ): Promise<UserWorkout | undefined> {
        const request = new UserWorkoutCancelRequestMessage();
        request.setId(EntityToUUID(workoutID));

        request.setKeepBalance(keepBalance);

        const data = await service.cancel(request, ContextToPb(ctx));

        const pbWorkout = data.getUserWorkout();
        if (pbWorkout) {
            return PbToUserWorkout(pbWorkout);
        }

        return undefined;
    },
    async pay(
        ctx: Context,
        workoutID: UserWorkoutID,
        accountID: PaymentAccountID,
        sum: number,
    ): Promise<UserWorkout | undefined> {
        const request = new UserWorkoutPayRequestMessage();
        request.setUserWorkoutId(EntityToUUID(workoutID));
        request.setPaymentSum(sum);
        request.setPaymentAccountId(EntityToUUID(accountID));

        const data = await service.pay(request, ContextToPb(ctx));

        const pbWorkout = data.getUserWorkout();
        if (pbWorkout) {
            return PbToUserWorkout(pbWorkout);
        }

        return;
    },
    async addToSubscription(
        ctx: Context,
        workoutID: UserWorkoutID,
        subscriptionID: SubscriptionID,
    ): Promise<UserWorkout | undefined> {
        const request = new UserWorkoutAddToSubscriptionRequestMessage();
        request.setUserWorkoutId(EntityToUUID(workoutID));
        request.setSubscriptionId(EntityToUUID(subscriptionID));

        const data = await service.addToSubscription(request, ContextToPb(ctx));

        const pbWorkout = data.getUserWorkout();
        if (pbWorkout) {
            return PbToUserWorkout(pbWorkout);
        }

        return;
    },
};
