import { Link, LinkIcons } from "@app/views/models/links";
import { IUserAction } from "./interface";
import { ComposerTranslation } from "vue-i18n";
import { UserWorkout } from "@app/entities/userWorkout";
import { userWorkoutsUseCase } from "@app/usecase/userWorkoutUseCase";
import { Workout } from "@app/entities/workout";
import { WorkoutStatus } from "@app/entities/workoutStatus";
import { subscriptionsUseCase } from "@app/usecase/subscriptionUseCase";
import { OrganizationUserID } from "@app/entities/uuid";
import { useAuthRepo } from "@app/stores/authRepo";

export type MakeAnAppointmentParams = {
    t: ComposerTranslation
    workout: Workout
    existWorkout?: UserWorkout
    oUserID?: OrganizationUserID
}
export class MakeAnAppointmentAction implements IUserAction {

    t: ComposerTranslation
    workout: Workout
    existWorkout?: UserWorkout
    oUserID?: OrganizationUserID

    constructor(params: MakeAnAppointmentParams) {
        this.t = params.t;
        this.existWorkout = params.existWorkout;
        this.oUserID = params.oUserID;
        this.workout = params.workout;
    }

    getID(): string {
        return "MakeAnAppointmentAction"
    }

    async makeAnAppointment(): Promise<void> {
        if (!this.oUserID) {
            return;
        }
        if (this.existWorkout) {
            return userWorkoutsUseCase.open(this.existWorkout.id);
        }
        
        const subscriptions = subscriptionsUseCase.getForWorkoutID(
            this.oUserID,
            this.workout.disciplineID,
            this.workout.dateTimeRange.left,
        );
        return userWorkoutsUseCase
            .store(
                this.workout.id,
                this.oUserID,
                subscriptions.length ? subscriptions[0].id : undefined,
            ).catch((e: Error) => {
                console.log(e);
                throw e;
            });    
    }

    getLink(): Link {
        return {
            text: this.t("Make an appointment!"),
            image: LinkIcons.add,
            actionConfirm: this.getConfirmMessage(),
            action: () => this.makeAnAppointment(),
            disabled: !this.isAllowed(),
        }
    }

    getConfirmMessage(): string {
        return "";
    }

    isAllowed(): boolean {
        const authRepo = useAuthRepo();
        return this.workout.status === WorkoutStatus.WorkoutStatusOpen &&
            (!this.existWorkout || this.existWorkout.status === WorkoutStatus.WorkoutStatusCancelled) &&
            authRepo.isAuth;
    }
}