<template>
  <form-component :form="vueForm">
    <div class="columns-1xs">
      <form-field
        :field="form.name"
        :label="t('Name')"
      >
        <text-input-field :field="form.name" />
      </form-field>
      <form-field
        :field="form.description"
        :label="t('Description')"
      >
        <wysiwyg-input-field
          :field="form.description"
        />
      </form-field>
      <form-field
        :field="form.images"
        :label="t('Images')"
      >
        <file-input-field
          :field="form.images"
          :multiple="true"
        />
      </form-field>
      <form-field
        v-if="images.length"
        :field="form.deleteImages"
        :label="t('Delete images')"
      >
        <files-delete-field
          :field="form.deleteImages"
          :options="images"
        />
      </form-field>
    </div>
    <submit-button />
  </form-component>
</template>

<script lang="ts" setup>
import { reactive } from "vue";
import { ValidationArgs } from "@vuelidate/core";
import { maxLength, required } from "@app/services/validators";
import TextInputField from "@app/components/forms/fields/TextInputField.vue";
import FormField from "@app/components/forms/fields/FormField.vue";
import SubmitButton from "@app/components/forms/fields/SubmitButton.vue";
import { appForm } from "@app/services/forms";
import FormComponent from "@app/components/FormComponent.vue";
import WysiwygInputField from "@app/components/forms/fields/WysiwygInputField.vue";
import FileInputField from "@app/components/forms/fields/FileInputField.vue";
import { locationUseCase } from "@app/usecase/locationUseCase";
import { Location } from "@app/entities/location";
import { UUID } from "@app/entities/uuid";
import {
    entityFileUseCase,
    useEntityFilesData,
} from "@app/usecase/entityFileUseCase";
import { FileType } from "@app/entities/fileType";
import FilesDeleteField from "@app/components/forms/fields/FilesDeleteField.vue";
import { useI18n } from "vue-i18n";

interface UpdateLocationFormFields {
    name: string;
    description: string;
    images: string[];
    deleteImages: UUID[];
}

const { t } = useI18n();
const entityFileView = useEntityFilesData();

const props = defineProps<{
    location: Location;
}>();
const images = entityFileView.getByEntityType(
    props.location.id,
    FileType.LOCATION,
);

const emits = defineEmits<{
    (e: "on-success", value: Location): void;
}>();

const rules: ValidationArgs<UpdateLocationFormFields> = {
    name: {
        required: required,
        maxLengthRef: maxLength(1000),
    },
    description: {
        required: required,
        maxLengthRef: maxLength(4000),
    },
    images: {},
    deleteImages: {},
};
const formFields = reactive<UpdateLocationFormFields>({
    name: props.location.name,
    description: props.location.description,
    images: [],
    deleteImages: [],
});
const vueForm = appForm<UpdateLocationFormFields, void>(
    rules,
    formFields,
    async () => {
        const promises = entityFileUseCase.syncImages(
            props.location.id,
            FileType.LOCATION,
            formFields.images,
            formFields.deleteImages,
        );
        promises.push(
            locationUseCase
                .update(
                    props.location.id,
                    formFields.name,
                    formFields.description,
                )
                .then((discipline) => {
                    emits("on-success", discipline);
                }),
        );
        await Promise.all(promises);
    },
);
const form = vueForm.form;
</script>

<style scoped></style>
