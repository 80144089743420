<template>
  <PlusIcon
    v-if="type === LinkIcons.add"
    :class="addClass"
  />
  <ExclamationTriangleIcon
    v-if="type === LinkIcons.error"
    :class="addClass"
  />
  <PencilIcon
    v-if="type === LinkIcons.pencil"
    :class="addClass"
  />
  <PencilSquareIcon
    v-if="type === LinkIcons.pencilSquare"
    :class="addClass"
  />
  <PhoneIcon
    v-if="type === LinkIcons.phone"
    :class="addClass"
  />
  <UserIcon
    v-if="type === LinkIcons.user"
    :class="addClass"
  />
  <UserPlusIcon
    v-if="type === LinkIcons.userAdd"
    :class="addClass"
  />
  <BoltSlashIcon
    v-if="type === LinkIcons.boltSlash"
    :class="addClass"
  />
  <BoltIcon
    v-if="type === LinkIcons.bolt"
    :class="addClass"
  />
  <CalendarDaysIcon
    v-if="type === LinkIcons.calendar"
    :class="addClass"
  />
  <TrophyIcon
    v-if="type === LinkIcons.trophy"
    :class="addClass"
  />
  <CheckBadgeIcon
    v-if="type === LinkIcons.checkbox"
    :class="addClass"
  />
  <PresentationChartLineIcon
    v-if="type === LinkIcons.presentationChart"
    :class="addClass"
  />
  <CurrencyDollarIcon
    v-if="type === LinkIcons.currencyDollar"
    :class="addClass"
  />
  <svg
    v-if="type === LinkIcons.Google"
    :class="addClass"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M21.456 10.154c.123.659.19 1.348.19 2.067 0 5.624-3.764 9.623-9.449 9.623A9.841 9.841 0 0 1 2.353 12a9.841 9.841 0 0 1 9.844-9.844c2.658 0 4.879.978 6.583 2.566l-2.775 2.775V7.49c-1.033-.984-2.344-1.489-3.808-1.489-3.248 0-5.888 2.744-5.888 5.993 0 3.248 2.64 5.998 5.888 5.998 2.947 0 4.953-1.685 5.365-3.999h-5.365v-3.839h9.26Z"
    />
  </svg>
  <svg
    v-if="type === LinkIcons.VK"
    :class="addClass"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M23.45 5.948c.166-.546 0-.948-.795-.948H20.03c-.668 0-.976.347-1.143.73 0 0-1.335 3.196-3.226 5.272-.612.602-.89.793-1.224.793-.167 0-.418-.191-.418-.738V5.948c0-.656-.184-.948-.74-.948H9.151c-.417 0-.668.304-.668.593 0 .621.946.765 1.043 2.513v3.798c0 .833-.153.984-.487.984-.89 0-3.055-3.211-4.34-6.885C4.45 5.288 4.198 5 3.527 5H.9c-.75 0-.9.347-.9.73 0 .682.89 4.07 4.145 8.551C6.315 17.341 9.37 19 12.153 19c1.669 0 1.875-.368 1.875-1.003v-2.313c0-.737.158-.884.687-.884.39 0 1.057.192 2.615 1.667C19.11 18.216 19.403 19 20.405 19h2.625c.75 0 1.126-.368.91-1.096-.238-.724-1.088-1.775-2.215-3.022-.612-.71-1.53-1.475-1.809-1.858-.389-.491-.278-.71 0-1.147 0 0 3.2-4.426 3.533-5.929Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script lang="ts" setup>
/**
 * VK and Google
 *  @see https://vue-icons.kalimah-apps.com/
 *
 */

import {
    BoltIcon,
    BoltSlashIcon,
    CalendarDaysIcon,
    CurrencyDollarIcon,
    ExclamationTriangleIcon,
    PencilIcon,
    PencilSquareIcon,
    PlusIcon,
    PresentationChartLineIcon,
    TrophyIcon,
    UserIcon,
    UserPlusIcon,
    PhoneIcon,
    CheckBadgeIcon,
} from "@heroicons/vue/24/outline";

import { computed } from "vue";
import { LinkIcons } from "@app/views/models/links";

const props = withDefaults(
    defineProps<{
        type: LinkIcons;
        view?: "block" | "inline-block";
        size?: "big" | "medium" | "small";
    }>(),
    {
        view: "inline-block",
        size: "medium",
    },
);

const addClass = computed(() => {
    return {
        "w-4 h-4": props.size === "small",
        "w-6 h-6": props.size === "medium",
        "w-8 h-8": props.size === "big",
        "inline-block stroke-current": props.view === "inline-block",
    };
});
</script>

<style scoped></style>
