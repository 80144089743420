<template>
  <preloader-component
    :is-loading="isLoading"
    :type="pType"
  >
    <div class="block rounded-lg bg-white mb-2 p-6 shadow-lg">
      <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-800">
        <dropdown-link
          :links="subscriptionLinks"
          type="link"
        >
          <span v-if="subscriptionType">{{ subscriptionType.name }}, </span>{{
            model.displayExpireDate
              ? t("Until") +
                "\xa0" +
                fullDate(d, model.displayExpireDate).replace(
                  r,
                  "\xa0",
                )
              : t("open date")
          }}
        </dropdown-link>
      </h5>
      <p class="mb-4 text-base">
        <span
          v-for="discipline in disciplines"
          :key="EntityIDToString(discipline.id)"
          class="m-0.5"
        >{{ discipline.name }}</span><br>
        <span v-if="balance">{{
          t("Left workouts: {leftWorkouts}", {
            leftWorkouts: balance.balance,
          })
        }}</span>
      </p>
      <div>
        <user-workout-component
          v-for="workout in workouts"
          :key="EntityIDToString(workout.id)"
          :user-workout-id="workout.id"
          view-template="badge-date"
        />
      </div>
    </div>
  </preloader-component>
</template>

<script lang="ts" setup>
import DropdownLink from "@app/components/DropdownLink.vue";
import PreloaderComponent from "@app/components/PreloaderComponent.vue";
import { Discipline } from "@app/entities/discipline";
import { EntityIDToString, SubscriptionID } from "@app/entities/uuid";
import { fullDate } from "@app/services/format";
import { useBalanceAccountsViewData } from "@app/usecase/balanceUseCase";
import { useDisciplineViewData } from "@app/usecase/disciplineUseCase";
import { useSubscriptionTypesViewData } from "@app/usecase/subscriptionTypeUseCase";
import { useSubscriptionsViewData } from "@app/usecase/subscriptionUseCase";
import {
    userWorkoutsUseCase,
    useUserWorkoutViewData,
} from "@app/usecase/userWorkoutUseCase";
import { PreloaderType } from "@app/views/entities/preloaderType";
import UserWorkoutComponent from "@app/views/entityComponents/UserWorkoutComponent.vue";
import { Link } from "@app/views/models/links";
import { getSubscriptionUpdateLink } from "@app/views/popups/subsctiption-update/route";
import { computed, ComputedRef, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const route = useRoute();
const { t, d } = useI18n();
const disciplineViewData = useDisciplineViewData();
const subscriptionTypeViewData = useSubscriptionTypesViewData();
const subscriptionsViewData = useSubscriptionsViewData();
const userWorkoutsView = useUserWorkoutViewData();
const balanceAccountView = useBalanceAccountsViewData();

const isLoading = ref(false);

const pType = PreloaderType.inline;

const props = defineProps<{
    modelId: SubscriptionID;
}>();

const r = new RegExp(" ", "g");

const model = computed(() => {
    const model = subscriptionsViewData.getByID(props.modelId);
    if (!model) {
        throw Error("Something went wrong");
    }
    return model;
});

const workouts = computed(() => {
    const data = userWorkoutsView.getBySubscriptionID(model.value.id);
    userWorkoutsUseCase.sortByWorkoutDate(data);
    return data;
});

const balance = computed(() => {
    return balanceAccountView.getByID(model.value.balanceID);
});

const subscriptionType = subscriptionTypeViewData.getByID(
    model.value.subscriptionTypeID,
);
const disciplines = computed(() => {
    return model.value.disciplineIDs
        .map((value) => disciplineViewData.getByID(value))
        .filter((x): x is Discipline => x !== undefined);
});

const subscriptionLinks: ComputedRef<Link[]> = computed(() => [
    getSubscriptionUpdateLink(route, t("Update subscription"), model.value.id),
]);
</script>

<style scoped></style>
