<template>
  <page-component
    :links="actionLinks"
    :title="t('Locations')"
  >
    <div class="grid grid-cols-1 gap-3">
      <location-component
        v-for="entity in locations"
        :key="EntityIDToString(entity.id)"
        :entity-id="entity.id"
        :links="links(entity)"
        template="data-item"
      />
    </div>
  </page-component>
</template>

<script lang="ts" setup>
import PageComponent from "@app/components/PageComponent.vue";
import { Link, LinkIcons } from "@app/views/models/links";
import { useI18n } from "vue-i18n";
import { computed, ComputedRef } from "vue";
import { Location } from "@app/entities/location";
import { EntityIDToString } from "@app/entities/uuid";
import {
    locationUseCase,
    useLocationViewData,
} from "@app/usecase/locationUseCase";
import {
    authUseCase,
    ENTITIES_LIST,
    useAuthViewData,
} from "@app/usecase/authUseCase";
import LocationComponent from "@app/views/entityComponents/LocationComponent.vue";

const locationView = useLocationViewData();
const { t } = useI18n();
const authViewData = useAuthViewData();

const locations = computed(() =>
    authViewData.isManager ? locationView.All : locationView.AllActive,
);

const links = (model: Location): Link[] => {
    return [
        {
            image: LinkIcons.pencilSquare,
            text: t("Edit"),
            to: {
                name: "LocationUpdate",
                params: {
                    id: EntityIDToString(model.id),
                },
            },
            disabled:
                !!model.deletedAt ||
                !authUseCase.canEdit(ENTITIES_LIST.DISCIPLINES),
        },
        {
            image: LinkIcons.error,
            text: t("Delete"),
            actionConfirm: t(`Are you sure you want to delete '{name}'?`, {
                name: model.name,
            }),
            action: async () => {
                await locationUseCase.destroy(model.id);
            },
            disabled:
                !!model.deletedAt ||
                !authUseCase.canEdit(ENTITIES_LIST.DISCIPLINES),
        },
        {
            image: LinkIcons.add,
            text: t("Restore"),
            actionConfirm: t(`Are you sure you want to restore '{name}'?`, {
                name: model.name,
            }),
            action: async () => {
                await locationUseCase.restore(model.id);
            },
            disabled:
                !model.deletedAt ||
                !authUseCase.canEdit(ENTITIES_LIST.DISCIPLINES),
        },
    ];
};

const actionLinks: ComputedRef<Link[]> = computed(() => [
    {
        text: t("Add New Location"),
        image: LinkIcons.add,
        to: {
            name: "LocationAdd",
        },
        disabled: !authUseCase.canEdit(ENTITIES_LIST.LOCATIONS),
    },
]);
</script>

<style scoped></style>
