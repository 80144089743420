import { Transaction } from "@app/entities/balance/transaction";
import { Context } from "@app/entities/context";
import { BalanceAccountID, PaymentTransactionID } from "@app/entities/uuid";
import {
    BaseEntityStorage,
    storeEntities,
    storeGetByID,
} from "@app/stores/converters/repo";
import { useBalanceTransactionClient } from "@grpc/balanceTransactionClient";
import { defineStore } from "pinia";

type StoreFields = BaseEntityStorage<Transaction>;

export interface BalanceTransactionsClient {
    storeTransaction(
        ctx: Context,
        fromAccountID: BalanceAccountID | undefined,
        toAccountID: BalanceAccountID | undefined,
        amount: number,
        paymentTransactionID: PaymentTransactionID | undefined,
    ): Promise<Transaction | undefined>;
}

const client: BalanceTransactionsClient = useBalanceTransactionClient();

export const useBalanceTransactionsRepo = defineStore("balanceTransactions", {
    state: () =>
        ({
            data: [],
            byId: {},
        }) as StoreFields,
    actions: {
        sync(entities: Transaction[]) {
            return storeEntities(this, entities);
        },
        async storeTransaction(
            ctx: Context,
            fromAccountID: BalanceAccountID | undefined,
            toAccountID: BalanceAccountID | undefined,
            amount: number,
            paymentTransactionID: PaymentTransactionID | undefined,
        ): Promise<Transaction | undefined> {
            const data = await client.storeTransaction(
                ctx,
                fromAccountID,
                toAccountID,
                amount,
                paymentTransactionID,
            );
            if (data) {
                this.sync([data]);
            }
            return data;
        },
    },
    getters: {
        All: (store): Transaction[] => store.data,
        getByID: (store) => storeGetByID<Transaction>(store),
    },
});
