import { FileServicePromiseClient } from "proto-api/file/file_service_grpc_web_pb";
import { EntityFileID, OrganizationID, UUID } from "@app/entities/uuid";
import { EntityToUUID } from "@grpc/converters/uuid";
import { ContextToPb } from "@grpc/converters/context";
import { Context } from "@app/entities/context";
import { PbToEntities } from "@grpc/converters/entities";
import { EntityFile } from "@app/entities/entityFile";
import { FileType } from "@app/entities/fileType";
import {
    FileDestroyRequestMessage,
    FileGetByIDRequestMessage,
    FileGetByOrganizationIDRequestMessage,
    FileStoreRequestMessage,
} from "proto-api/file/file_service_pb";
import { PbToEntityFile } from "@grpc/converters/entityFile";
import { FileTypeToPb } from "@grpc/converters/fileType";

//"https://grpc-local.fitfrog.ru"
const service = new FileServicePromiseClient(
    `${import.meta.env.VITE_GRPC_BASE_URL}`,
    null,
);

export const entityFileClient = {
    loadByOrganizationID: async (
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<EntityFile[]> => {
        const request = new FileGetByOrganizationIDRequestMessage();
        request.setOrganizationId(EntityToUUID(organizationID));

        const response = await service.getByOrganizationID(
            request,
            ContextToPb(ctx),
        );
        return PbToEntities(response.getFilesList(), PbToEntityFile);
    },

    loadByID: async (
        ctx: Context,
        id: EntityFileID,
    ): Promise<EntityFile | undefined> => {
        const request = new FileGetByIDRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.getByID(request, ContextToPb(ctx));

        const message = response.getFile();
        if (message) {
            return PbToEntityFile(message);
        }
        return;
    },

    store: async (
        ctx: Context,
        entityID: UUID,
        entityType: FileType,
        data: string,
    ): Promise<EntityFile | undefined> => {
        const request = new FileStoreRequestMessage();
        request.setEntityId(EntityToUUID(entityID));
        request.setEntityType(FileTypeToPb(entityType));

        const enc = new TextEncoder(); // always utf-8
        request.setData(enc.encode(data));

        const response = await service.store(request, ContextToPb(ctx));

        const message = response.getFile();
        if (message) {
            return PbToEntityFile(message);
        }
        return;
    },

    destroy: async (ctx: Context, id: EntityFileID): Promise<undefined> => {
        const request = new FileDestroyRequestMessage();
        request.setId(EntityToUUID(id));

        await service.destroy(request, ContextToPb(ctx));
        return;
    },
};
