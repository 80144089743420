import { Context } from "@app/entities/context";

import { Account } from "@app/entities/payment/account";
import { AccountType } from "@app/entities/payment/accountType";
import {
    filterTransactionIDs,
    Transaction,
} from "@app/entities/payment/transaction";
import {
    OrganizationID,
    PaymentAccountID,
    PaymentTransactionID,
} from "@app/entities/uuid";
import { storageRepo } from "@app/pkg/storageRepo";
import { useOrganizationUsersRepo } from "@app/stores/organizationUserRepo";
import { usePaymentAccountsRepo } from "@app/stores/paymentAccountsRepo";
import { usePaymentTransactionsRepo } from "@app/stores/paymentTransactionsRepo";

export interface PaymentAccountsRepo {
    loadByOrganizationID(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<Account[]>;

    store(
        ctx: Context,
        organizationID: OrganizationID,
        name: string,
        type: AccountType,
    ): Promise<Account | undefined>;

    update(
        ctx: Context,
        accountID: PaymentAccountID,
        name: string,
    ): Promise<Account | undefined>;
}

const useAccountsRepo = (): PaymentAccountsRepo => {
    return usePaymentAccountsRepo();
};

export interface PaymentTransactionsRepo {
    loadByOrganizationID(
        ctx: Context,
        id: OrganizationID,
    ): Promise<Transaction[]>;

    loadByIDs(
        ctx: Context,
        ids: PaymentTransactionID[],
    ): Promise<Transaction[]>;
}

const useTransactionsRepo = (): PaymentTransactionsRepo => {
    return usePaymentTransactionsRepo();
};

export const paymentUseCase = {
    async loadAccounts(organizationID: OrganizationID): Promise<Account[]> {
        const repo = useAccountsRepo();
        return await repo.loadByOrganizationID(
            storageRepo.withToken(),
            organizationID,
        );
    },
    async store(
        organizationID: OrganizationID,
        name: string,
        type: AccountType,
    ): Promise<Account | undefined> {
        const repo = useAccountsRepo();
        return await repo.store(
            storageRepo.withToken(),
            organizationID,
            name,
            type,
        );
    },
    async update(
        accountID: PaymentAccountID,
        name: string,
    ): Promise<Account | undefined> {
        const repo = useAccountsRepo();
        return await repo.update(storageRepo.withToken(), accountID, name);
    },

    async loadTransactions(
        organizationID: OrganizationID,
    ): Promise<Transaction[]> {
        const repo = useTransactionsRepo();
        return await repo.loadByOrganizationID(
            storageRepo.withToken(),
            organizationID,
        );
    },

    async loadTransactionsByIDs(
        transactionIDs: PaymentTransactionID[],
    ): Promise<Transaction[]> {
        const repo = useTransactionsRepo();
        return await repo.loadByIDs(storageRepo.withToken(), transactionIDs);
    },
    async sync(models: Transaction[]): Promise<void> {
        const oUserRepo = useOrganizationUsersRepo();

        const ids = filterTransactionIDs(models);

        await oUserRepo.loadByIDs(
            storageRepo.withToken(),
            oUserRepo.getMissedIDs(ids.organizationUserIDs),
        );
    },
};

export const useAccountsViewData = (): {
    getByID(id: PaymentAccountID): Account | undefined;
    All: Account[];
} => {
    return usePaymentAccountsRepo();
};

export const useTransactionsViewData = (): {
    getByID(id: PaymentTransactionID): Transaction | undefined;
    All: Transaction[];
} => {
    return usePaymentTransactionsRepo();
};
