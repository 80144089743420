<template>
  <popup-page-component :title="t('Add user')">
    <user-add-form @on-success="onSuccess" />
  </popup-page-component>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import UserAddForm from "@app/components/forms/UserAddForm.vue";
import PopupPageComponent from "@app/components/PopupPageComponent.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();

const onSuccess = () => {
    router.back();
};
</script>

<style scoped></style>
