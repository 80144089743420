import { DisciplineServicePromiseClient } from "proto-api/organization/discipline_service_grpc_web_pb";
import { DisciplineID, OrganizationID } from "@app/entities/uuid";
import { EntityToUUID } from "@grpc/converters/uuid";
import { ContextToPb } from "@grpc/converters/context";
import { Context } from "@app/entities/context";
import { Discipline } from "@app/entities/discipline";
import {
    DisciplineDestroyRequestMessage,
    DisciplineGetByIDRequestMessage,
    DisciplineGetByOrganizationIDRequestMessage,
    DisciplineRestoreRequestMessage,
    DisciplineStoreRequestMessage,
    DisciplineUpdateRequestMessage,
} from "proto-api/organization/discipline_service_pb";
import { PbToDiscipline } from "@grpc/converters/discipline";
import { PbToEntities } from "@grpc/converters/entities";

//"https://grpc-local.fitfrog.ru"
const service = new DisciplineServicePromiseClient(
    `${import.meta.env.VITE_GRPC_BASE_URL}`,
    null,
);

export const disciplineClient = {
    loadByOrganizationID: async (
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<Discipline[]> => {
        const request = new DisciplineGetByOrganizationIDRequestMessage();
        request.setOrganizationId(EntityToUUID(organizationID));

        const response = await service.getByOrganizationID(
            request,
            ContextToPb(ctx),
        );
        return PbToEntities(response.getDisciplinesList(), PbToDiscipline);
    },

    loadByID: async (
        ctx: Context,
        id: DisciplineID,
    ): Promise<Discipline | undefined> => {
        const request = new DisciplineGetByIDRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.getByID(request, ContextToPb(ctx));

        const message = response.getDiscipline();
        if (message) {
            return PbToDiscipline(message);
        }
        return;
    },

    store: async (
        ctx: Context,
        organizationID: OrganizationID,
        name: string,
        description: string,
    ): Promise<Discipline | undefined> => {
        const request = new DisciplineStoreRequestMessage();
        request.setOrganizationId(EntityToUUID(organizationID));
        request.setName(name);
        request.setDescription(description);

        const response = await service.store(request, ContextToPb(ctx));

        const message = response.getDiscipline();
        if (message) {
            return PbToDiscipline(message);
        }
        return;
    },

    update: async (
        ctx: Context,
        id: DisciplineID,
        name: string,
        description: string,
    ): Promise<Discipline | undefined> => {
        const request = new DisciplineUpdateRequestMessage();
        request.setId(EntityToUUID(id));
        request.setName(name);
        request.setDescription(description);

        const response = await service.update(request, ContextToPb(ctx));

        const message = response.getDiscipline();
        if (message) {
            return PbToDiscipline(message);
        }
        return;
    },

    destroy: async (
        ctx: Context,
        id: DisciplineID,
    ): Promise<Discipline | undefined> => {
        const request = new DisciplineDestroyRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.destroy(request, ContextToPb(ctx));

        const message = response.getDiscipline();
        if (message) {
            return PbToDiscipline(message);
        }
        return;
    },
    restore: async (
        ctx: Context,
        id: DisciplineID,
    ): Promise<Discipline | undefined> => {
        const request = new DisciplineRestoreRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.restore(request, ContextToPb(ctx));

        const message = response.getDiscipline();
        if (message) {
            return PbToDiscipline(message);
        }
        return;
    },
};
