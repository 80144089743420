import { RouteRecordRaw } from "vue-router";
import { PageType } from "@app/pkg/router/pageType";
import { PageName } from "@app/pkg/router/pageName";
import { getSecondLevelProps } from "@app/pkg/router/props";
import UserAddView from "@users/add/UserAddView.vue";

export const usersAddRoute: RouteRecordRaw = {
    path: "/users/add",
    name: PageType.UserAdd,
    component: UserAddView,
    props: getSecondLevelProps(),
    meta: {
        breadcrumbs: [
            {
                name: PageName.Users,
                to: PageType.Users,
            },
        ],
    },
};
