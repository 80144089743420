import { Context } from "@app/entities/context";
import { Subscription } from "@app/entities/subscription";
import {
    DisciplineID,
    OrganizationID,
    OrganizationUserID,
    PaymentAccountID,
    SubscriptionID,
    SubscriptionTypeID,
} from "@app/entities/uuid";
import { removeUndefined } from "@app/services/arrays";
import {
    BaseEntityStorage,
    storeEntities,
    storeGetByID,
    storeGetMissedIDs,
} from "@app/stores/converters/repo";
import { useSubscriptionClient } from "@grpc/subscriptionClient";
import { defineStore } from "pinia";

type StoreFields = BaseEntityStorage<Subscription>;

export interface SubscriptionClient {
    store(
        ctx: Context,
        oUserID: OrganizationUserID,
        typeID: SubscriptionTypeID,
        disciplineIDs: DisciplineID[],
        start?: Date,
        expire?: Date,
        days?: number,
    ): Promise<Subscription | undefined>;

    update(
        ctx: Context,
        id: SubscriptionID,
        disciplineIDs: DisciplineID[],
        start: Date,
        expire: Date,
    ): Promise<Subscription | undefined>;

    loadByID(
        ctx: Context,
        id: SubscriptionID,
    ): Promise<Subscription | undefined>;

    loadByOrganizationID(
        ctx: Context,
        id: OrganizationUserID,
    ): Promise<Subscription[]>;

    loadByOrganizationUserID(
        ctx: Context,
        organizationUserID: OrganizationUserID,
    ): Promise<Subscription[]>;

    cancel(ctx: Context, id: SubscriptionID): Promise<Subscription | undefined>;

    open(ctx: Context, id: SubscriptionID): Promise<Subscription | undefined>;

    pay(
        ctx: Context,
        id: SubscriptionID,
        accountID: PaymentAccountID,
        sum: number,
        count: number,
    ): Promise<Subscription | undefined>;
}

const client: SubscriptionClient = useSubscriptionClient();

export const useSubscriptionRepo = defineStore("subscription", {
    state: () =>
        ({
            data: [],
            byId: {},
            byPage: {},
            currentPageId: undefined,
            lastUpdate: new Date(),
        }) as StoreFields,
    actions: {
        sync(entities: Subscription[]) {
            return storeEntities(this, entities);
        },

        async store(
            ctx: Context,
            oUserID: OrganizationUserID,
            typeID: SubscriptionTypeID,
            disciplineIDs: DisciplineID[],
            start?: Date,
            expire?: Date,
            days?: number,
        ): Promise<Subscription | undefined> {
            const model = await client.store(
                ctx,
                oUserID,
                typeID,
                disciplineIDs,
                start,
                expire,
                days,
            );
            if (model) {
                this.sync([model]);
                return model;
            }
            return;
        },

        async update(
            ctx: Context,
            id: SubscriptionID,
            disciplineIDs: DisciplineID[],
            start: Date,
            expire: Date,
        ): Promise<Subscription | undefined> {
            const model = await client.update(
                ctx,
                id,
                disciplineIDs,
                start,
                expire,
            );
            if (model) {
                this.sync([model]);
                return model;
            }
            return;
        },

        async loadByID(
            ctx: Context,
            id: SubscriptionID,
        ): Promise<Subscription | undefined> {
            const model = await client.loadByID(ctx, id);
            if (model) {
                this.sync([model]);
                return model;
            }
            return;
        },

        async loadByIDs(
            ctx: Context,
            entitiesIDs: SubscriptionID[],
        ): Promise<Subscription[]> {
            const waits: Promise<Subscription | undefined>[] = [];
            entitiesIDs.forEach((value) => {
                waits.push(this.loadByID(ctx, value));
            });
            const data = await Promise.all(waits);
            return removeUndefined(data);
        },

        async loadByOrganizationID(
            ctx: Context,
            organizationID: OrganizationID,
        ): Promise<Subscription[]> {
            const models = await client.loadByOrganizationID(
                ctx,
                organizationID,
            );
            if (models.length > 0) {
                this.sync(models);
                return models;
            }
            return [];
        },

        async loadByOrganizationUserID(
            ctx: Context,
            organizationID: OrganizationID,
        ): Promise<Subscription[]> {
            const models = await client.loadByOrganizationUserID(
                ctx,
                organizationID,
            );
            if (models.length > 0) {
                this.sync(models);
                return models;
            }
            return [];
        },

        async cancel(
            ctx: Context,
            id: SubscriptionID,
        ): Promise<Subscription | undefined> {
            const model = await client.cancel(ctx, id);
            if (model) {
                this.sync([model]);
                return model;
            }
            return;
        },

        async open(
            ctx: Context,
            id: SubscriptionID,
        ): Promise<Subscription | undefined> {
            const model = await client.open(ctx, id);
            if (model) {
                this.sync([model]);
                return model;
            }
            return;
        },

        async pay(
            ctx: Context,
            id: SubscriptionID,
            accountID: PaymentAccountID,
            sum: number,
            count: number,
        ): Promise<Subscription | undefined> {
            const model = await client.pay(ctx, id, accountID, sum, count);
            if (model) {
                this.sync([model]);
                return model;
            }
            return;
        },
    },
    getters: {
        All: (store): Subscription[] => store.data,
        getMissedIDs: (store) =>
            storeGetMissedIDs<Subscription, SubscriptionID>(store),
        active: (store): Subscription[] => {
            const now = new Date();
            return store.data.filter(
                (subscription) =>
                    subscription.expireDate && subscription.expireDate > now,
            );
        },
        getByID: (store) => storeGetByID<Subscription>(store),
        getByOrganizationUserID:
            (store: StoreFields) =>
            (organizationUserID: OrganizationUserID): Subscription[] => {
                return store.data
                    .filter(
                        (item) =>
                            item.organizationUserID.value ===
                            organizationUserID.value,
                    )
                    .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
            },
        getByFilter:
            (store: StoreFields) =>
            (
                organizationUserID: OrganizationUserID,
                disciplineID: DisciplineID,
                date: Date,
            ): Subscription[] => {
                return store.data.filter(
                    (subscription) =>
                        (!subscription.expireDate ||
                            subscription.expireDate > date) &&
                        subscription.organizationUserID.value ===
                            organizationUserID.value &&
                        !!subscription.disciplineIDs.find(
                            (id) => id.value === disciplineID.value,
                        ),
                );
            },
    },
});
