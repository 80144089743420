import { Account } from "@app/entities/balance/account";
import { PbToUUIDSure } from "@grpc/converters/uuid";
import { Account as AccountPb } from "proto-api/balance/account_pb";

export const PbToAccount = (resource: AccountPb): Account => {
    return {
        id: PbToUUIDSure(resource.getId()),
        balance: resource.getBalance(),
    };
};
