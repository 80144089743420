<template>
  <preloader-component
    :is-loading="isLoading"
    :type="PreloaderType.inline"
  >
    <div :class="`stats stats-vertical shadow m-0 mt-0 mb-1 p-1 block overflow-visible ${css.bg} ${css.textStyle}`">
      <div class="stat p-2">
        <div class="stat-figure w-8 text-center">
          <dropdown-link
            :hide-if-empty="false"
            :links="workoutLinks"
            type="link"
          >
            <ActionIcon
              :type="css.type"
              size="big"
            />
          </dropdown-link>
        </div>
        <div>
          <dropdown-link
            :hide-if-empty="false"
            :links="workoutLinks"
            type="link"
          >
            <div class="break-word">
              <slot :css="css">
                <div>
                  <b v-if="showDate">
                    {{
                      onlyDayMonth(
                        d,
                        model.dateTimeRange.left,
                      )
                    }},
                  </b>
                  <b v-if="showLocation && location">
                    {{ location.name }},
                  </b>
                  <b>{{
                    timeOfDate(model.dateTimeRange.left)
                  }}
                    -
                    {{
                      timeOfDate(
                        model.dateTimeRange.right,
                      )
                    }}</b>{{
                    trainer ? `, ${trainer?.user.name}` : ""
                  }}
                </div>
                <div
                  v-if="discipline"
                  class="stat-value text-2xl"
                >
                  {{ discipline.name }}
                </div>
                <div :class="`stat-desc ${css.textStyle}`">
                  {{ statusDescription }}
                </div>
              </slot>
            </div>
          </dropdown-link>
        </div>
      </div>
      <div
        v-if="workouts.length || userWorkoutsUseCase.isLoading"
        class="stat p-2 block"
      >
        <preloader-component
          :display="DisplayType.block"
          :is-loading="userWorkoutsUseCase.isLoading.value"
          :type="PreloaderType.progress"
        >
          <user-workout-component
            v-for="userWorkout of workoutsToDisplay"
            :key="EntityIDToString(userWorkout.id)"
            :user-workout-id="userWorkout.id"
            view-template="badge"
          />
        </preloader-component>
      </div>
    </div>
  </preloader-component>
</template>

<script lang="ts" setup>
import ActionIcon from "@app/components/ActionIcon.vue";
import DropdownLink from "@app/components/DropdownLink.vue";
import PreloaderComponent from "@app/components/PreloaderComponent.vue";
import { UserWorkout } from "@app/entities/userWorkout";
import { EntityIDToString } from "@app/entities/uuid";
import { Workout, workoutAfter } from "@app/entities/workout";
import { WorkoutStatus } from "@app/entities/workoutStatus";
import { PageName } from "@app/pkg/router/pageName";
import { PageType } from "@app/pkg/router/pageType";
import { pupUpRoute } from "@app/router/popups";
import { getStatusCss, StatusCss } from "@app/services/css";
import { onlyDayMonth, timeOfDate } from "@app/services/format";
import { useAuthViewData } from "@app/usecase/authUseCase";
import { useDisciplineViewData } from "@app/usecase/disciplineUseCase";
import { useLocationViewData } from "@app/usecase/locationUseCase";
import { useOrganizationUserViewData } from "@app/usecase/organizationUserUseCase";
import {
  userWorkoutsUseCase,
  useUserWorkoutViewData,
} from "@app/usecase/userWorkoutUseCase";
import { workoutsUseCase } from "@app/usecase/workoutsUseCase";
import { MakeAnAppointmentAction } from "@app/userActions/makeAnAppointment";
import { PreloaderType } from "@app/views/entities/preloaderType";
import UserWorkoutComponent from "@app/views/entityComponents/UserWorkoutComponent.vue";
import { DisplayType } from "@app/views/models";
import { actionLink, Link, LinkIcons } from "@app/views/models/links";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const locationView = useLocationViewData();
const route = useRoute();
const authView = useAuthViewData();
const user = authView.currentUser;

const disciplinesViewData = useDisciplineViewData();
const organizationUsersViewData = useOrganizationUserViewData();
const userWorkoutsViewData = useUserWorkoutViewData();

const { t, d } = useI18n();

const statusDescription = computed(() => {
  switch (true) {
    case props.model.status === WorkoutStatus.WorkoutStatusOpen &&
      props.model.placesLeft === 0:
      return t(
        `workoutStatus.noPlacesLeft`,
        props.model.status.toString(),
      );
    case props.model.status === WorkoutStatus.WorkoutStatusOpen &&
      props.model.placesLeft === 1:
      return t(
        `workoutStatus.onePlaceLeft`,
        props.model.status.toString(),
      );
    default:
      return t(
        `workoutStatus.${props.model.status}`,
        props.model.status.toString(),
      );
  }
});

export interface Props {
  model: Workout;
  showDate?: boolean;
  showLocation?: boolean;
}

const props = defineProps<Props>();

const emits = defineEmits<{
  (e: "on-update", value: Workout): void;
}>();

const isLoading = ref<boolean>(false);

const trainer = computed(() =>
  organizationUsersViewData.getByID(props.model.trainerID),
);

const location = computed(() => locationView.getByID(props.model.locationID));

const discipline = computed(() =>
  disciplinesViewData.getByID(props.model.disciplineID),
);

const workouts = computed(() =>
  userWorkoutsViewData.getByWorkoutID(props.model.id),
);

const workoutsToDisplay = computed(() =>
  workouts.value.filter((uWorkout) => {
    return (
      authView.isEmployee ||
      (authView.isAuth &&
        (uWorkout.status === WorkoutStatus.WorkoutStatusOpen ||
          uWorkout.status === WorkoutStatus.WorkoutStatusClosed))
    );
  }),
);

const myWorkout = computed(() =>
  workouts.value.find(
    (userWorkout: UserWorkout) =>
      !!user && userWorkout.organizationUserID.value === user.id.value,
  ),
);

const css = computed<StatusCss>(() => {
  return getStatusCss(props.model.status, myWorkout.value?.status, true);
});

const workoutLinks = computed(() => {
  let links: Link[] = [];
  if (props.model.status === WorkoutStatus.WorkoutStatusOpen) {
    links = [
      {
        text: t(PageName.SignUp),
        image: LinkIcons.userAdd,
        to: {
          name: PageType.SignUp,
        },
        disabled: authView.isAuth,
      },
      {
        text: t(PageName.Login),
        image: LinkIcons.user,
        to: {
          name: PageType.Login,
        },
        disabled: authView.isAuth,
      },
      {
        text: t("Conducted"),
        image: LinkIcons.checkbox,
        actionConfirm: "Are you sure you want to close this workout",
        action: actionLink(async () => {
          const response = await workoutsUseCase.close(
            props.model.id,
          );
          emits("on-update", response.workout);
        }, isLoading),
        disabled:
          !authView.isEmployee ||
          workoutAfter(props.model, new Date()),
      },
      (new MakeAnAppointmentAction({
        t,
        workout: props.model,
        oUserID: user?.id,
      })).getLink(),
      {
        text: t("Edit"),
        image: LinkIcons.pencil,
        to: pupUpRoute(route, PageType.WorkoutUpdate, {
          workoutId: props.model.id.value,
        }),
        disabled: !authView.isEmployee,
      },
      {
        text: t("Add user"),
        image: LinkIcons.userAdd,
        to: pupUpRoute(route, PageType.UserWorkoutAdd, {
          workoutId: props.model.id.value,
        }),
        disabled: !authView.isEmployee,
      },
      {
        text: t("Cancel"),
        image: LinkIcons.error,
        actionConfirm: t(
          "Are you sure you want to cancel this workout",
        ),
        action: actionLink(async () => {
          const response = await workoutsUseCase.cancel(
            props.model.id,
            true,
          );
          emits("on-update", response.workout);
        }, isLoading),
        disabled: !authView.isEmployee,
      },
    ];
  }

  return links;
});
</script>

<style scoped>
.stat {
  display: grid;
}

.stat.block {
  display: block;
}

.stat-value {
  white-space: pre-wrap;
}
</style>
