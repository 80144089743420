type nextFn = () => Promise<void>;
type prevFn = () => Promise<void>;

const touchEndMethod = async (
    touchEvent: Event,
    posXStart: number,
    posYStart: number,
    next: nextFn,
    prev: prevFn,
): Promise<void> => {
    if (!(touchEvent instanceof TouchEvent)) {
        return;
    }

    if (touchEvent.changedTouches.length !== 1) {
        // We only care if one finger is used
        return;
    }
    const posXEnd = touchEvent.changedTouches[0].clientX;
    const posYEnd = touchEvent.changedTouches[0].clientY;

    if (Math.abs(posYEnd - posYStart) < Math.abs(posXStart - posXEnd)) {
        if (posXStart < posXEnd) {
            await prev();
        } else if (posXStart > posXEnd) {
            await next();
        }
    }
};
export const touchStart = (next: nextFn, prev: prevFn) => {
    return async (touchEvent: TouchEvent): Promise<void> => {
        if (touchEvent.changedTouches.length !== 1) {
            // We only care if one finger is used
            return;
        }
        const posXStart = touchEvent.changedTouches[0].clientX;
        const posYStart = touchEvent.changedTouches[0].clientY;
        addEventListener(
            "touchend",
            async (touchEvent) =>
                await touchEndMethod(
                    touchEvent,
                    posXStart,
                    posYStart,
                    next,
                    prev,
                ),
            { once: true, passive: true },
        );
    };
};
