import { Context } from "@app/entities/context";
import * as grpcWeb from "grpc-web";

export const ContextToPb = (ctx: Context): grpcWeb.Metadata => {
    const metadata: grpcWeb.Metadata = {};
    if (ctx.token) {
        metadata.Authorization = `Bearer ${ctx.token}`;
    }
    return metadata;
};
