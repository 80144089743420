import { FileType as PbFileType } from "proto-api/file/file_type_pb";
import { FileType } from "@app/entities/fileType";

export const PbToFileType = (resource: PbFileType): FileType => {
    return resource.valueOf();
};

export const FileTypeToPb = (resource: FileType): PbFileType => {
    return resource.valueOf();
};
