import { ErrForbiddenMessage } from "@app/entities/errors";
import { filterUserWorkoutIDs } from "@app/entities/userWorkout";
import { AppError } from "@app/pkg/error/AppError";
import { PageType } from "@app/pkg/router/pageType";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { getPopupRoutes } from "@app/router/popups";
import { useWorkoutRepo } from "@app/stores/workoutRepo";
import { useAuthViewData } from "@app/usecase/authUseCase";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { organizationUserUseCase } from "@app/usecase/organizationUserUseCase";
import { paymentUseCase } from "@app/usecase/paymentUseCase";
import { subscriptionsUseCase } from "@app/usecase/subscriptionUseCase";
import { userWorkoutsUseCase } from "@app/usecase/userWorkoutUseCase";
import { workoutsUseCase } from "@app/usecase/workoutsUseCase";
import UsersView from "@users/index/UsersView.vue";
import {
    NavigationGuardWithThis,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";

export const UserIndexBeforeEnter: NavigationGuardWithThis<undefined> = async (
    to,
    from,
    next,
) => {
    const authView = useAuthViewData();
    if (!authView.isManager) {
        throw new AppError({
            message: ErrForbiddenMessage
        });
    }

    const workoutsRepo = useWorkoutRepo();

    const firstStep = await Promise.all([
        organizationUserUseCase.loadByOrganizationID(
            organizationUseCase.getOrganizationID(),
        ),
        subscriptionsUseCase
            .loadByOrganizationID(organizationUseCase.getOrganizationID())
            .then(async (models) => {
                await subscriptionsUseCase.sync(models);
                return models;
            }),
    ]);

    const sIDs = firstStep[1].map((s) => s.id);

    const secondStep = await Promise.all([
        userWorkoutsUseCase.loadBySubscriptionIDs(sIDs),
    ]);

    if (authView.isManager) {
        await paymentUseCase.loadTransactions(
            organizationUseCase.getOrganizationID(),
        );
    }

    const uWorkoutIDs = filterUserWorkoutIDs(secondStep[0]);
    const workoutIDS = workoutsRepo.getMissedIDs(uWorkoutIDs.workoutIDs);
    await workoutsUseCase.loadByIDs(workoutIDS);

    const props = getFirsLevelProps();
    // didn't find a better way
    to.params = {
        ...to.params,
        ...(props as unknown as RouteParams),
    };
    next();
};

export const usersIndexRoute: RouteRecordRaw = {
    path: "/users",
    name: PageType.Users,
    component: UsersView,
    beforeEnter: UserIndexBeforeEnter,
    props: getFirsLevelProps(),
    children: [...getPopupRoutes(PageType.Users, [])],
};
