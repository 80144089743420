<template>
  <page-component :title="t('Final step')">
    <p>
      {{ t("Please we need your contact info in case of emergency") }}.
      {{
        t("We cant do anything without your phone number and email")
      }}.<br>
      <b>!!!</b>
      {{
        t(
          "Only administrators will see your name, phone number and email",
        )
      }}
    </p>
    <user-update-form
      :organization-user="user"
      @on-success="onSuccess"
    />
  </page-component>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import PageComponent from "@app/components/PageComponent.vue";
import { OrganizationUser } from "@app/entities/organzationUser";
import UserUpdateForm from "@app/components/forms/UserUpdateForm.vue";
import { PageType } from "@app/pkg/router/pageType";
import { useI18n } from "vue-i18n";

interface Props {
    user: OrganizationUser;
}

const { t } = useI18n();
const router = useRouter();

defineProps<Props>();
const onSuccess = () => {
    router.push({
        name: PageType.Index,
    });
};
</script>

<style scoped></style>
