import { Context } from "@app/entities/context";
import { Account } from "@app/entities/payment/account";
import { AccountType } from "@app/entities/payment/accountType";
import { OrganizationID, PaymentAccountID } from "@app/entities/uuid";
import {
    BaseEntityStorage,
    storeEntities,
    storeGetByID,
    storeGetMissedIDs,
} from "@app/stores/converters/repo";
import { usePaymentClient } from "@grpc/paymentClient";
import { defineStore } from "pinia";

type StoreFields = BaseEntityStorage<Account>;

export interface PaymentAccountsClient {
    loadAccounts(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<Account[]>;

    storeAccount(
        ctx: Context,
        organizationID: OrganizationID,
        name: string,
        type: AccountType,
    ): Promise<Account | undefined>;

    updateAccount(
        ctx: Context,
        accountID: PaymentAccountID,
        name: string,
    ): Promise<Account | undefined>;
}

const client: PaymentAccountsClient = usePaymentClient();

export const usePaymentAccountsRepo = defineStore("paymentAccounts", {
    state: () =>
        ({
            data: [],
            byId: {},
        }) as StoreFields,
    actions: {
        sync(entities: Account[]) {
            return storeEntities(this, entities);
        },
        async loadByOrganizationID(
            ctx: Context,
            id: OrganizationID,
        ): Promise<Account[]> {
            const data = await client.loadAccounts(ctx, id);
            this.sync(data);
            return data;
        },
        async store(
            ctx: Context,
            organizationID: OrganizationID,
            name: string,
            type: AccountType,
        ): Promise<Account | undefined> {
            const data = await client.storeAccount(
                ctx,
                organizationID,
                name,
                type,
            );
            if (data) {
                this.sync([data]);
            }

            return data;
        },
        async update(
            ctx: Context,
            accountID: PaymentAccountID,
            name: string,
        ): Promise<Account | undefined> {
            const data = await client.updateAccount(ctx, accountID, name);
            if (data) {
                this.sync([data]);
            }

            return data;
        },
    },
    getters: {
        All: (store): Account[] => store.data,
        getByID: (store) => storeGetByID<Account>(store),
        getMissedIDs: (store) =>
            storeGetMissedIDs<Account, PaymentAccountID>(store),
    },
});
