<template>
  <input
    v-model="fieldRef.$model"
    :name="field.$path"
    type="date"
    :placeholder="placeholder"
    class="input input-bordered"
    :class="{
      'input-error text-red-500':
        field.$dirty && field.$silentErrors.length,
    }"
    @blur="field.$touch"
  >
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { BaseValidation } from "@vuelidate/core";

const props = defineProps<{
    field: BaseValidation;
    placeholder?: string;
}>();
const fieldRef = ref(props.field);
</script>

<style scoped></style>
