<template>
  <h1 class="text-xl p-2 text-center">
    Заработано денег
  </h1>
  <div>
    <canvas ref="income" />
  </div>
  <h1 class="text-xl p-2 text-center">
    Проведено занятий
  </h1>
  <div>
    <canvas ref="input" />
  </div>

  <listbox
    v-if="locations.length > 2"
    v-model="currentLocation"
    class="z-10"
  >
    <div class="relative mt-1 z-10">
      <ListboxButton
        class="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
      >
        <span class="block truncate">{{
          currentLocation.displayValue
        }}</span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <chevron-up-down-icon
            aria-hidden="true"
            class="h-5 w-5 text-gray-400"
          />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="location in locations"
            :key="location.value"
            v-slot="{ active, selected }"
            :value="location"
            as="template"
          >
            <li
              :class="[
                active
                  ? 'bg-sky-100 text-sky-900'
                  : 'text-sky-900',
                'relative cursor-default select-none py-2 pl-10 pr-4',
              ]"
            >
              <span
                :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate',
                ]"
              >{{ location.displayValue }}</span>
              <span
                v-if="selected"
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-sky-600"
              >
                <check-icon
                  aria-hidden="true"
                  class="h-5 w-5"
                />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </listbox>
  <div class="tabs font-mono">
    <a class="tab tab-lg tab-lifted p-0 tab-active leading-none">07<br>Вт</a>
    <a class="tab tab-sm tab-lifted w-2/12 p-0 leading-none h-10">08<br>Пн</a>
    <a class="tab tab-sm tab-lifted w-2/12 p-0 leading-none h-10">09<br>Ср</a>
    <a class="tab tab-sm tab-lifted w-2/12 p-0 leading-none h-10">10<br>Чт</a>
    <a class="tab tab-sm tab-lifted w-2/12 p-0 leading-none h-10">11<br>Пт</a>
    <a class="tab tab-sm tab-lifted w-2/12 p-0 leading-none h-10">12<br>Сб</a>
    <a class="tab tab-sm tab-lifted w-2/12 p-1 leading-none h-10">13<br>Вс</a>
  </div>
  <div class="space-y-2">
    <h1 class="text-xl font-bold text-center mt-2">
      Красная горка, 8 февраля
    </h1>
    <a
      class="stats shadow m-0 mt-0 block bg-primary/5"
      href="#!/"
    >
      <div class="stat p-4 text-primary">
        <div class="stat-figure">
          <svg
            class="inline-block w-8 h-8 stroke-current"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="stat-title">
          <b>18:00-19:30</b>, Виорика Венченто
        </div>
        <div class="stat-value text-2xl">Pole dance sport</div>
        <div class="stat-desc">
          Набор открыт, осталось несколько мест
        </div>
      </div>
    </a>

    <div class="stats shadow m-0 mt-0 block text-success bg-success/5">
      <div class="stat p-4">
        <div class="stat-figure">
          <svg
            class="inline-block w-8 h-8 stroke-current"
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 10V3L4 14h7v7l9-11h-7z"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
        </div>
        <div class="stat-title">
          <b>18:00-19:30</b>, Виорика Венченто
        </div>
        <a
          class="stat-value text-2xl"
          href="#!/"
        > Pole dance sport </a>
        <div class="stat-desc">
          Группа полностью набрана
        </div>
      </div>
    </div>

    <div class="stats shadow m-0 mt-0 block text-secondary bg-secondary/5">
      <div class="stat p-4">
        <div class="stat-figure">
          <svg
            class="inline-block w-8 h-8 stroke-current"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.412 15.655L9.75 21.75l3.745-4.012M9.257 13.5H3.75l2.659-2.849m2.048-2.194L14.25 2.25 12 10.5h8.25l-4.707 5.043M8.457 8.457L3 3m5.457 5.457l7.086 7.086m0 0L21 21"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="stat-title text-secondary">
          <b>18:00-19:30</b>, Виорика Венченто
        </div>
        <a
          class="stat-value text-2xl"
          href="#!/"
        > Pole dance sport </a>
        <div class="stat-desc">
          Тренеровка отменена
        </div>
      </div>
    </div>

    <div
      class="stats shadow m-0 mt-0 block text-secondary bg-secondary-content/5"
    >
      <div class="stat p-4">
        <div class="stat-figure">
          <svg
            class="inline-block w-8 h-8 stroke-current"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.412 15.655L9.75 21.75l3.745-4.012M9.257 13.5H3.75l2.659-2.849m2.048-2.194L14.25 2.25 12 10.5h8.25l-4.707 5.043M8.457 8.457L3 3m5.457 5.457l7.086 7.086m0 0L21 21"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="stat-title text-secondary">
          <b>18:00-19:30</b>, Виорика Венченто
        </div>
        <a
          class="stat-value text-2xl"
          href="#!/"
        > Pole dance sport </a>
        <div class="stat-desc">
          Набор в группу закрыт
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SelectOption } from "@app/views/models/form";
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/vue";
import CheckIcon from "@heroicons/vue/24/solid/CheckIcon";
import ChevronUpDownIcon from "@heroicons/vue/24/solid/ChevronUpDownIcon";
import Chart from "chart.js/auto";
import { computed, ComputedRef, onMounted, ref } from "vue";

const locations: ComputedRef<SelectOption<string>[]> = computed(() => {
    return [
        {
            displayValue: "All locations",
            value: "All locations",
        },
        {
            displayValue: "Test2 locations",
            value: "Test2",
        },
        {
            displayValue: "Test2 locations",
            value: "Test2",
        },
    ];
});

const currentLocation: ComputedRef<SelectOption<string>> = computed(
    () => locations.value[0],
);

// Charts
const income = ref();
const input = ref();

onMounted(() => {
    const i = input.value;
    if (i) {
        new Chart(i, {
            type: "bar",
            data: {
                labels: [
                    "October",
                    "November",
                    "December",
                    "January",
                    "February",
                    "March",
                    "April",
                ],
                datasets: [
                    {
                        type: "bar",
                        label: "Conducted",
                        data: [40, 38, 36, 40, 38, 36, 40],
                        borderColor: "#4ade80",
                        backgroundColor: "#4ade80",
                        order: 1,
                        yAxisID: "y",
                    },
                    {
                        type: "bar",
                        label: "Cancelled",
                        data: [0, 2, 4, 0, 2, 4, 0],
                        borderColor: "#9ca3af",
                        backgroundColor: "#9ca3af",
                        order: 2,
                        yAxisID: "y",
                    },
                    {
                        type: "bar",
                        label: "Open",
                        data: [0, 0, 0, 0, 0, 0, 10],
                        borderColor: "#60a5fa",
                        backgroundColor: "#60a5fa",
                        order: 3,
                        yAxisID: "y",
                    },
                ],
            },
            options: {
                responsive: true,
                scales: {
                    y: {
                        type: "linear",
                        display: true,
                        position: "right",
                        stacked: true,
                    },
                    x: {
                        stacked: true,
                    },
                },
            },
        });
    }

    const incomeVal = income.value;
    if (incomeVal) {
        new Chart(incomeVal, {
            type: "bar",
            data: {
                labels: [
                    "October",
                    "November",
                    "December",
                    "January",
                    "February",
                    "March",
                    "April",
                ],
                datasets: [
                    {
                        type: "bar",
                        label: "Карта",
                        data: [15000, 25000, 14000, 15000, 25000, 14000, 12000],
                        yAxisID: "y1",
                        order: 0,
                    },
                    {
                        type: "bar",
                        label: "Наличные",
                        data: [
                            185000, 140000, 120000, 175000, 185000, 140000,
                            120000,
                        ],
                        yAxisID: "y1",
                        order: 0,
                    },
                ],
            },
            options: {
                responsive: true,
                scales: {
                    y1: {
                        type: "linear",
                        display: true,
                        position: "right",

                        // grid line settings
                        grid: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },

                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value) {
                                return value + " Р.";
                            },
                        },
                    },
                    x: {
                        stacked: true,
                    },
                },
            },
        });
    }
});
</script>

<style scoped>
.tabs .tab {
    width: 14.28571428571429%;
}
</style>
