<template>
  <QuillEditor
    v-model:content="fieldRef.$model"
    theme="snow"
    content-type="html"
    class="textarea textarea-bordered"
    :class="{ 'text-red-500': field.$dirty && field.$silentErrors.length }"
    :placeholder="placeholder ? placeholder : ''"
    @blur="field.$touch"
  />
</template>

<script lang="ts" setup>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { BaseValidation } from "@vuelidate/core";
import { ref } from "vue";

const props = defineProps<{
    field: BaseValidation<string>;
    placeholder?: string;
}>();
const fieldRef = ref(props.field);
</script>

<style scoped></style>
