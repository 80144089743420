import {
    RouteLocationNormalizedLoaded,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";
import { PageType } from "@app/pkg/router/pageType";
import { AppBreadcrumbs } from "@app/router/models";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { LocationID, StringPossibleToEntityID } from "@app/entities/uuid";
import { Link, LinkIcons } from "@app/views/models/links";
import { pupUpRoute } from "@app/router/popups";
import { authUseCase, ENTITIES_LIST } from "@app/usecase/authUseCase";
import ScheduleAddView from "@app/views/popups/schedule-add/ScheduleAddView.vue";

type Props = {
    locationId: LocationID | undefined;
    day: string;
    dateFrom: Date;
    dateTo: Date;
};

export const getScheduleAddLink = (
    route: RouteLocationNormalizedLoaded,
    text: string,
): Link => {
    return {
        image: LinkIcons.add,
        text: text,
        to: pupUpRoute(route, PageType.ScheduleAdd, {
            ...route.params,
        }),
        disabled: !authUseCase.canEdit(ENTITIES_LIST.SUBSCRIPTION),
    };
};

export const scheduleAddRoute = (
    prefix: string,
    breadcrumbs: AppBreadcrumbs[],
): RouteRecordRaw => {
    return {
        path: "schedule-add",
        name: prefix + PageType.ScheduleAdd,
        meta: {
            breadcrumbs: breadcrumbs,
        },
        components: {
            popup: ScheduleAddView,
        },
        props: true,
        beforeEnter: async (to, from, next) => {
            const locationID = StringPossibleToEntityID(
                to.query.location_id as string,
            );

            let dateFrom = new Date();
            const day = to.params.day as string;
            if (day !== "") {
                dateFrom = new Date(day);
            }
            dateFrom.setHours(0, 0, 0, 0);

            const props = getFirsLevelProps<Props>();
            props.locationId = locationID;
            props.dateFrom = dateFrom;

            // didn't find a better way
            to.params = {
                ...to.params,
                ...(props as unknown as RouteParams),
            };
            next();
        },
    };
};
