import { TransactionStatus } from "@app/entities/payment/transactionStatus";
import { TransactionStatus as TransactionStatusPb } from "proto-api/payment/transaction_status_pb";

export const PbToTransactionStatus = (
    resource: TransactionStatusPb,
): TransactionStatus => {
    return resource.valueOf();
};

export const TransactionStatusToPb = (
    resource: TransactionStatus,
): TransactionStatusPb => {
    return resource.valueOf();
};
