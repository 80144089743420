import { nextTick } from "vue";
import { createI18n, I18n } from "vue-i18n";
import ru from "./locales/ru.json";
import en from "./locales/en.json";

export const SUPPORT_LOCALES = ["en", "ru"];
export type LocaleCode = "ru" | "en";
export const defaultLocale = import.meta.env.VITE_DEFAULT_LOCALE;

export const DATETIME_ONLY_DAY = "onlyDay";

/**
 * example: "Wed"
 */
export const DATETIME_WEEKDAY = "weekDay";

/**
 * example: "January 12"
 */
export const DATETIME_DAY_AND_MONTH = "dayAndMonth";
export const DATETIME_YEAR_MONTH = "YearAndMonth";
export const DATETIME_YEAR_MONTH_DAY = "YearAndMonthAndDay";
const dateTimeFormats: {
    [key: string]: { [key: string]: Intl.DateTimeFormatOptions };
} = {
    ru: {
        short: {
            day: "2-digit",
        },
        [DATETIME_ONLY_DAY]: {
            day: "2-digit",
        },
        [DATETIME_WEEKDAY]: {
            weekday: "short",
        },
        [DATETIME_DAY_AND_MONTH]: {
            month: "long",
            day: "numeric",
        },
        [DATETIME_YEAR_MONTH]: {
            month: "long",
            year: "numeric",
        },
        [DATETIME_YEAR_MONTH_DAY]: {
            day: "numeric",
            month: "long",
            year: "numeric",
        },
    },
    en: {
        short: {
            day: "2-digit",
        },
        [DATETIME_ONLY_DAY]: {
            day: "2-digit",
        },
        [DATETIME_WEEKDAY]: {
            weekday: "short",
        },
        [DATETIME_DAY_AND_MONTH]: {
            month: "long",
            day: "numeric",
        },
        [DATETIME_YEAR_MONTH]: {
            month: "long",
            year: "numeric",
        },
        [DATETIME_YEAR_MONTH_DAY]: {
            day: "numeric",
            month: "long",
            year: "numeric",
        },
    },
};

export const setI18nLanguage = (i18n: I18n, locale: LocaleCode) => {
    i18n.global.locale = locale;
    i18n.global.setDateTimeFormat(locale, dateTimeFormats[locale]);
    /**
     * NOTE:
     * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
     * The following is an example for axios.
     *
     * axios.defaults.headers.common['Accept-Language'] = locale
     */
    // document.querySelector("html").setAttribute("lang", locale);
};

export const loadLocaleMessages = async (i18n: I18n, locale: LocaleCode) => {
    // load locale messages with dynamic import
    const messages = await import(
        /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
    );

    // set locale and locale message
    i18n.global.setLocaleMessage(locale, messages.default);

    return nextTick();
};

export const setupI18n = (
    options: {
        locale: LocaleCode;
    } = { locale: defaultLocale },
) => {
    const i18n = createI18n({
        legacy: false, // you must set `false`, to use Composition API
        locale: defaultLocale, // set locale
        fallbackLocale: "en", // set fallback locale
        messages: {
            ru: ru,
            en: en,
        },
        pluralRules: {
            /**
             * @see https://vue-i18n.intlify.dev/guide/essentials/pluralization.html
             */
            ru: (choice, choicesLength) => {
                if (choice === 0) {
                    return 0;
                }

                const teen = choice > 10 && choice < 20;
                const endsWithOne = choice % 10 === 1;
                if (!teen && endsWithOne) {
                    return 1;
                }
                if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
                    return 2;
                }

                return choicesLength < 4 ? 2 : 3;
            },
        },
        flatJson: true,
    });
    setI18nLanguage(i18n, options.locale);
    loadLocaleMessages(i18n, options.locale).then();
    return i18n;
};

export const i18n = setupI18n();
