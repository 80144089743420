<template>
  <page-component
    :links="actionLinks"
    :title="t('Payments')"
  >
    <div class="overflow-x-auto">
      <table class="table table-xs table-pin-rows">
        <template
          v-for="row of rows"
          :key="row.title"
        >
          <thead>
            <tr>
              <td class="uppercase">
                {{ row.title }}
              </td>
              <td>Перевод</td>
              <td>Подтвердил</td>
              <td class="text-right">
                {{ row.sum }}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="r of row.rows"
              :key="r.createdAt.getTime()"
            >
              <th>{{ onlyDayMonth(d, r.createdAt) }}</th>
              <td>{{ r.sender }} -> {{ r.account }}</td>
              <td>{{ r.receiver }}</td>
              <td class="text-right">
                {{ r.sum }}
              </td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
  </page-component>
</template>

<script lang="ts" setup>
import PageComponent from "@app/components/PageComponent.vue";
import { DATETIME_YEAR_MONTH } from "@app/i18n/i18n";
import { PageName } from "@app/pkg/router/pageName";
import { PageType } from "@app/pkg/router/pageType";
import { onlyDayMonth } from "@app/services/format";
import { authUseCase, ENTITIES_LIST } from "@app/usecase/authUseCase";
import { useOrganizationUserViewData } from "@app/usecase/organizationUserUseCase";
import {
    useAccountsViewData,
    useTransactionsViewData,
} from "@app/usecase/paymentUseCase";
import { Link, LinkIcons } from "@app/views/models/links";
import { computed, ComputedRef } from "vue";
import { useI18n } from "vue-i18n";

type Row = {
    account?: string;
    sender: string;
    receiver: string;
    sum: number;
    createdAt: Date;
};
type Rows = {
    rows: Row[];
    title: string;
    date: Date;
    sum: number;
};
const { t, d } = useI18n();
const transactionsView = useTransactionsViewData();
const accountsView = useAccountsViewData();
const organizationUsersView = useOrganizationUserViewData();

const rows = computed(() => {
    const fields: Record<string, Rows> = {};

    let payments = transactionsView.All;
    payments = payments.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));

    payments.forEach((value) => {
        const sender = organizationUsersView.getByID(value.organizationUserID);
        const receiver = organizationUsersView.getByID(value.confirmedByID);

        const date = d(value.createdAt, DATETIME_YEAR_MONTH);
        if (!fields[date]) {
            fields[date] = {
                date: value.createdAt,
                rows: [],
                sum: 0,
                title: date,
            };
        }

        const account = accountsView.getByID(value.accountID);

        fields[date].rows.push({
            sender: sender ? sender.user.name : "",
            account: account ? account.name : "",
            receiver: receiver ? receiver.user.name : "",
            sum: value.amount,
            createdAt: value.createdAt,
        });
        fields[date].sum += value.amount;
    });

    const data = Object.values(fields).sort((a, b) =>
        a.date > b.date ? -1 : 1,
    );

    data.forEach((field) => {
        field.rows = field.rows.sort((a, b) =>
            a.createdAt > b.createdAt ? -1 : 1,
        );
    });

    return data;
});

const actionLinks: ComputedRef<Link[]> = computed(() => [
    {
        text: t("Organization"),
        image: LinkIcons.presentationChart,
        to: {
            name: PageType.OrganizationStatistic,
        },
        disabled: !authUseCase.canEdit(ENTITIES_LIST.ORGANIZATION),
    },
    {
        text: t(PageName.AccountAdd),
        image: LinkIcons.add,
        to: {
            name: PageType.AccountAdd,
        },
        disabled: !authUseCase.canEdit(ENTITIES_LIST.ORGANIZATION),
    },
]);
</script>

<style scoped></style>
