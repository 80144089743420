export const PbToEntities = <P, E>(
    data: P[] | undefined,
    converter: (data: P) => E,
): E[] => {
    const entities: E[] = [];
    if (!data) {
        return [];
    }
    if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            entities.push(converter(data[i]));
        }
    }
    return entities;
};
