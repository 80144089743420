import { WorkoutStatus } from "@app/entities/workoutStatus";
import { LinkIcons } from "@app/views/models/links";

export interface StatusCss {
    bg: string;
    type: LinkIcons;
    text: string;
    textStyle: string;
}

export const getStatusCss = (
    status: WorkoutStatus,
    userWorkoutStatus?: WorkoutStatus,
    isPaid?: boolean,
) => {
    switch (status) {
        case WorkoutStatus.WorkoutStatusClosed:
            return {
                bg: "bg-success/5",
                type: LinkIcons.bolt,
                text: status,
                textStyle: "text-success",
            };
        case WorkoutStatus.WorkoutStatusOpen:
            if (
                userWorkoutStatus &&
                userWorkoutStatus === WorkoutStatus.WorkoutStatusOpen
            ) {
                return {
                    bg: isPaid != false ? "" : "bg-warning/5",
                    type: LinkIcons.bolt,
                    text: "Scheduled",
                    textStyle:
                        isPaid != false ? "text-primary" : "text-warning",
                };
            } else {
                return {
                    bg: isPaid != false ? "" : "bg-warning/5",
                    type: LinkIcons.userAdd,
                    text: status,
                    textStyle:
                        isPaid != false ? "text-primary" : "text-warning",
                };
            }

        default:
            return {
                bg: "bg-secondary/5",
                type: LinkIcons.boltSlash,
                text: status,
                textStyle: "text-secondary",
            };
    }
};
