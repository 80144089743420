import { Context, WithBackground } from "@app/entities/context";
import { ErrUserNotFound } from "@app/entities/errors";
import { OrganizationUser } from "@app/entities/organzationUser";
import { UserRole } from "@app/entities/userRole";
import { UserWorkout } from "@app/entities/userWorkout";
import {
    EntityIDToString,
    LocationID,
    OrganizationID,
    OrganizationUserID,
    StringToEntityID,
    UserID,
} from "@app/entities/uuid";
import { LocaleCode } from "@app/i18n/i18n";
import { storageRepo } from "@app/pkg/storageRepo";
import { ServiceWorker } from "@app/services/service-worker";
import { AuthResponse, useAuthRepo } from "@app/stores/authRepo";
import { useOrganizationUsersRepo } from "@app/stores/organizationUserRepo";
import { userWorkoutsUseCase } from "@app/usecase/userWorkoutUseCase";
import { personalClient } from "@grpc/personalClient";
import * as Sentry from "@sentry/vue";
import { LocationQuery } from "vue-router";

export interface AuthRepo {
    forgotPassword(
        ctx: Context,
        organizationID: OrganizationID,
        email: string,
    ): Promise<void>;

    resetPassword(
        ctx: Context,
        organizationID: OrganizationID,
        email: string,
        newPassword: string,
        token: string,
    ): Promise<void>;

    loginByEmail(
        ctx: Context,
        organizationID: OrganizationID,
        email: string,
        password: string,
    ): Promise<AuthResponse>;

    loginByPhone(
        ctx: Context,
        organizationID: OrganizationID,
        phone: string,
        password: string,
    ): Promise<AuthResponse>;

    adminLogin(ctx: Context, userID: OrganizationUserID): Promise<AuthResponse>;

    update(
        ctx: Context,
        id: UserID,
        nickname: string,
        firstName: string,
        lastName: string,
        phone: string,
        email: string,
    ): Promise<void>;

    register(
        ctx: Context,
        organizationID: OrganizationID,
        nickname: string,
        firstName: string,
        lastName: string,
        phone: string,
        email: string,
        password: string,
    ): Promise<AuthResponse>;

    logout(): void;

    sendNotification(message: string): void;
}

const useRepo = (): AuthRepo => {
    return useAuthRepo();
};

// TODO
export const enum ENTITIES_LIST {
    DISCIPLINES,
    LOCATIONS,
    ORGANIZATION,
    ORGANIZATION_USER,
    SCHEDULE,
    SUBSCRIPTION,
    SUBSCRIPTION_TYPE,
    USER_WORKOUT,
    WORKOUT,
}

export const authUseCase = {
    async forgot(organizationID: OrganizationID, email: string): Promise<void> {
        const repo = useRepo();

        await repo.forgotPassword(
            storageRepo.withToken(WithBackground()),
            organizationID,
            email,
        );

        repo.sendNotification("We have emailed your password reset link!");
        return;
    },
    async resetPassword(
        organizationID: OrganizationID,
        email: string,
        password: string,
        token: string,
    ): Promise<void> {
        const repo = useRepo();
        await repo.resetPassword(
            storageRepo.withToken(WithBackground()),
            organizationID,
            email,
            password,
            token,
        );

        repo.sendNotification("Your password has been reset!");
        return;
    },

    async loginByEmail(
        organizationID: OrganizationID,
        email: string,
        password: string,
    ): Promise<void> {
        const repo = useRepo();

        const response = await repo.loginByEmail(
            storageRepo.withToken(WithBackground()),
            organizationID,
            email,
            password,
        );

        storageRepo.setToken(response.token);
        await this.sync();
        return;
    },

    async loginByPhone(
        organizationID: OrganizationID,
        phone: string,
        password: string,
    ): Promise<void> {
        const repo = useRepo();

        const response = await repo.loginByPhone(
            storageRepo.withToken(WithBackground()),
            organizationID,
            phone,
            password,
        );

        storageRepo.setToken(response.token);
        await this.sync();
        return;
    },

    async adminLogin(id: OrganizationUserID): Promise<void> {
        const repo = useRepo();

        const response = await repo.adminLogin(
            storageRepo.withToken(WithBackground()),
            id,
        );

        storageRepo.setToken(response.token);
        await this.sync();
        return;
    },

    async update(
        userID: UserID,
        nickname: string,
        firstName: string,
        lastName: string,
        phone: string,
        email: string,
    ): Promise<void> {
        const repo = useRepo();

        await repo.update(
            storageRepo.withToken(WithBackground()),
            userID,
            nickname,
            firstName,
            lastName,
            phone,
            email,
        );

        return;
    },

    async signUp(
        organizationID: OrganizationID,
        nickname: string,
        firstName: string,
        lastName: string,
        phone: string,
        email: string,
        password: string,
    ): Promise<void> {
        const repo = useRepo();

        const response = await repo.register(
            storageRepo.withToken(WithBackground()),
            organizationID,
            nickname,
            firstName,
            lastName,
            phone,
            email,
            password,
        );

        storageRepo.setToken(response.token);
        await this.sync();
        return;
    },
    logout() {
        const auth = useAuthRepo();
        auth.logout();
        storageRepo.logout();
    },
    currentUser() {
        const repo = useAuthViewData();
        const user = repo.currentUser;
        if (!user) {
            throw ErrUserNotFound;
        }
        return user;
    },
    canView(entity: ENTITIES_LIST) {
        const view = useAuthViewData();
        const oUser = view.currentUser;
        if (
            !!oUser &&
            (oUser.user.isAdmin || oUser.role !== UserRole.STUDENT)
        ) {
            return true;
        }
        switch (entity) {
            case ENTITIES_LIST.DISCIPLINES:
            case ENTITIES_LIST.LOCATIONS:
            case ENTITIES_LIST.ORGANIZATION:
            case ENTITIES_LIST.SCHEDULE:
            case ENTITIES_LIST.SUBSCRIPTION:
            case ENTITIES_LIST.SUBSCRIPTION_TYPE:
            case ENTITIES_LIST.WORKOUT:
                return true;
            default:
                return false;
        }
    },
    canEdit(entity: ENTITIES_LIST) {
        const view = useAuthViewData();
        const oUser = view.currentUser;
        if (!oUser) {
            return false;
        }
        if (oUser.user.isAdmin || oUser.role === UserRole.OWNER) {
            return true;
        }
        if (oUser.role === UserRole.MANAGER) {
            // manager can do everything except organization settings
            return true;
        }
        if (oUser.role === UserRole.TEACHER) {
            // manager can do everything except organization settings
            switch (entity) {
                case ENTITIES_LIST.ORGANIZATION_USER:
                case ENTITIES_LIST.USER_WORKOUT:
                case ENTITIES_LIST.SUBSCRIPTION:
                case ENTITIES_LIST.WORKOUT:
                    return true;
                default:
                    return false;
            }
        }
        if (oUser.role === UserRole.STUDENT) {
            // manager can do everything except organization settings
            return false;
        }
        return false;
    },

    async sync(): Promise<OrganizationUser | undefined> {
        const ctx = storageRepo.withToken(WithBackground());
        if (!ctx.token) {
            // we can't get auth data without token
            return;
        }
        const auth = useAuthRepo();
        const promises: Promise<unknown>[] = [];
        let oUser: OrganizationUser | undefined;
        promises.push(
            personalClient.info(ctx).then(async (data) => {
                if (data) {
                    auth.syncEventChannels(data.eventChannels);
                    await ServiceWorker.subscribe(data.eventChannels);
                }
            }),
        );
        promises.push(
            auth.info(ctx).then(async (response) => {
                oUser = response.organizationUser;
                const oUsers = useOrganizationUsersRepo();
                oUsers.sync([response.organizationUser]);
                Sentry.setUser({
                    id: EntityIDToString(response.organizationUser.id),
                    email: response.organizationUser.user.email,
                    username: response.organizationUser.user.name,
                    segment: response.organizationUser.role.toString(),
                });
                await userWorkoutsUseCase
                    .loadByOrganizationUserID(response.organizationUser.id)
                    .then((models) => {
                        userWorkoutsUseCase.sync(models);
                    });
            }),
        );
        await Promise.all(promises);
        return oUser;
    },
    syncFavoriteLocation(query: LocationQuery): LocationID | undefined {
        let locationID: LocationID | undefined = undefined;
        if (query.location_id) {
            locationID = StringToEntityID(query.location_id as string);
            return storageRepo.setFavoriteLocationID(locationID);
        }
        return storageRepo.getFavoriteLocationID();
    },
};

export interface AuthViewData {
    currentUser: OrganizationUser | undefined;
    currentLocale: LocaleCode;
    userName: string;
    isAdmin: boolean;
    isManager: boolean;
    isAuth: boolean;
    isOwner: boolean;
    isEmployee: boolean;
    hasPhone: boolean;
    hasEmail: boolean;
    hasToPassFinalStep: boolean;
    filterAccessedUserWorkouts: (models: UserWorkout[]) => UserWorkout[];
}

export const useAuthViewData = (): AuthViewData => {
    return useAuthRepo();
};
