import { Transaction } from "@app/entities/balance/transaction";
import { Context } from "@app/entities/context";
import { BalanceAccountID, PaymentTransactionID } from "@app/entities/uuid";
import { PbToTransaction } from "@grpc/converters/balance/transaction";
import { ContextToPb } from "@grpc/converters/context";
import { EntityToUUID } from "@grpc/converters/uuid";
import { TransactionServicePromiseClient } from "proto-api/balance/transaction_service_grpc_web_pb";
import { StoreTransactionRequest } from "proto-api/balance/transaction_service_pb";
import { App } from "vue";

let service: TransactionServicePromiseClient;

type Config = {
    service: TransactionServicePromiseClient;
};
export const initBalanceTransactionClient = (app: App, options: Config) => {
    service = options.service;
};

export const useBalanceTransactionClient = () => {
    return balanceTransactionClient;
};

const balanceTransactionClient = {
    storeTransaction: async (
        ctx: Context,
        fromAccountID: BalanceAccountID | undefined,
        toAccountID: BalanceAccountID | undefined,
        amount: number,
        paymentTransactionID: PaymentTransactionID | undefined,
    ): Promise<Transaction | undefined> => {
        const request = new StoreTransactionRequest();
        request.setFromAccountId(
            fromAccountID ? EntityToUUID(fromAccountID) : undefined,
        );
        request.setToAccountId(
            toAccountID ? EntityToUUID(toAccountID) : undefined,
        );
        request.setPaymentId(
            paymentTransactionID
                ? EntityToUUID(paymentTransactionID)
                : undefined,
        );
        request.setAmount(amount);

        const response = await service.storeTransaction(
            request,
            ContextToPb(ctx),
        );
        const account = response.getTransaction();
        if (account) {
            return PbToTransaction(account);
        } else {
            return undefined;
        }
    },
};
