<template>
  <page-component
    :title="
      t('{name} update', {
        name: organization.name,
      })
    "
  >
    <organization-update-form
      :organization="organization"
      @on-success="onSuccess"
    />
  </page-component>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import OrganizationUpdateForm from "@app/components/forms/OrganizationUpdateForm.vue";
import { useI18n } from "vue-i18n";
import PageComponent from "@app/components/PageComponent.vue";
import { Organization } from "@app/entities/organzation";

const { t } = useI18n();
const router = useRouter();

defineProps<{
    organization: Organization;
}>();

const onSuccess = () => {
    router.back();
};
</script>

<style scoped></style>
