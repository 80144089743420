<template>
  <popup-page-component :title="t('Workout add')">
    <workout-add-form
      :discipline-options="disciplineOptions"
      :location-options="locationOptions"
      :default-location-id="locationId"
      :schedule-id="scheduleId"
      :trainer-options="trainersOptions"
      :date="date"
      :location-id="selectedLocationId"
      :trainer-id="trainerID"
      :discipline-id="disciplineID"
      :students-count="studentsCount"
      :time-range="timeRange"
      @on-success="onSuccess"
    />
  </popup-page-component>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import WorkoutAddForm from "@app/components/forms/WorkoutAddForm.vue";
import PopupPageComponent from "@app/components/PopupPageComponent.vue";
import { computed } from "vue";
import { useDisciplineViewData } from "@app/usecase/disciplineUseCase";
import { useLocationViewData } from "@app/usecase/locationUseCase";
import { useOrganizationUserViewData } from "@app/usecase/organizationUserUseCase";
import { organizationUsersToOptions } from "@app/views/converters/organization_user";
import { locationsToOptions } from "@app/views/converters/location";
import { disciplinesToOptions } from "@app/views/converters/discipline";
import { LocationID, ScheduleID } from "@app/entities/uuid";
import { authUseCase } from "@app/usecase/authUseCase";
import { useScheduleViewData } from "@app/usecase/scheduleUseCase";
import { useI18n } from "vue-i18n";

interface Props {
    scheduleId?: ScheduleID;
    locationId?: LocationID;
    date: Date;
}

const { t } = useI18n();
const router = useRouter();

const props = defineProps<Props>();

const disciplinesStore = useDisciplineViewData();
const locationsStore = useLocationViewData();
const oUserViewData = useOrganizationUserViewData();
const scheduleViewData = useScheduleViewData();

const schedule = computed(() => {
    return props.scheduleId
        ? scheduleViewData.getByID(props.scheduleId)
        : undefined;
});

const selectedLocationId = computed(() => {
    if (props.locationId) {
        return props.locationId;
    } else if (schedule.value) {
        return schedule.value.locationID;
    }
    return undefined;
});
const trainerID = computed(() => {
    if (schedule.value) {
        return schedule.value.trainerID;
    } else {
        return authUseCase.currentUser().id;
    }
});
const disciplineID = computed(() => {
    return schedule.value ? schedule.value.disciplineID : undefined;
});
const studentsCount = computed(() => {
    return schedule.value ? schedule.value?.studentsCount : 0;
});
const timeRange = computed(() => {
    return schedule.value ? schedule.value.timeRange : undefined;
});
const trainersOptions = computed(() => {
    return organizationUsersToOptions(oUserViewData.trainers);
});
const locationOptions = computed(() => {
    return locationsToOptions(locationsStore.AllActive);
});
const disciplineOptions = computed(() => {
    return disciplinesToOptions(disciplinesStore.AllActive);
});

const onSuccess = () => {
    router.back();
};
</script>

<style scoped></style>
