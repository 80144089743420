<template>
  <form-component :form="vueForm">
    <div class="columns-1">
      <form-field
        :field="form.organization_user_id"
        :label="t('User')"
      >
        <select-box-field
          :field="form.organization_user_id"
          :options="usersOptions"
          @change="onChangeOrganizationUserID"
        />
      </form-field>
      <form-field
        :field="form.subscription_id"
        :label="t('Subscription')"
      >
        <select-box-field
          v-if="subscriptionOptions.length > 0"
          :field="form.subscription_id"
          :options="subscriptionOptions"
        />
        <p v-else>
          {{ t("User has no active subscriptions") }}
        </p>
      </form-field>
    </div>
    <submit-button />
  </form-component>
</template>

<script lang="ts" setup>
import FormComponent from "@app/components/FormComponent.vue";
import FormField from "@app/components/forms/fields/FormField.vue";
import SelectBoxField from "@app/components/forms/fields/SelectBoxField.vue";
import SubmitButton from "@app/components/forms/fields/SubmitButton.vue";
import { ErrWorkoutNotFound } from "@app/entities/errors";
import {
    EntityIDToString,
    StringToEntityID,
    WorkoutID,
} from "@app/entities/uuid";
import { appForm } from "@app/services/forms";
import { required } from "@app/services/validators";
import { useOrganizationUserViewData } from "@app/usecase/organizationUserUseCase";
import { subscriptionsUseCase } from "@app/usecase/subscriptionUseCase";
import { userWorkoutsUseCase } from "@app/usecase/userWorkoutUseCase";
import { useWorkoutViewData } from "@app/usecase/workoutsUseCase";
import { organizationUsersToOptions } from "@app/views/converters/organization_user";
import { subscriptionsToOptions } from "@app/views/converters/subscription";
import { SelectOption } from "@app/views/models/form";
import { ValidationArgs } from "@vuelidate/core";
import { computed, reactive } from "vue";
import { useI18n } from "vue-i18n";

type StoreUserWorkoutFormFields = {
    workout_id: WorkoutID;
    organization_user_id: string;
    subscription_id: string;
};

const oUsersView = useOrganizationUserViewData();
const workoutView = useWorkoutViewData();
const { t, d } = useI18n();

const props = defineProps<{
    workoutId: WorkoutID;
}>();

const emits = defineEmits<{
    (e: "on-success", value: void): void;
}>();

const rules: ValidationArgs<StoreUserWorkoutFormFields> = {
    workout_id: {
        required: required,
    },
    organization_user_id: {
        required: required,
    },
    subscription_id: {},
};

const workout = computed(() => {
    const model = workoutView.getByID(props.workoutId);
    if (!model) {
        throw ErrWorkoutNotFound;
    }
    return model;
});

const usersOptions = computed<SelectOption<string>[]>(() => {
    return organizationUsersToOptions(
        oUsersView.AllActive,
    );
});

const formFields = reactive<StoreUserWorkoutFormFields>({
    workout_id: props.workoutId,
    organization_user_id: usersOptions.value.length
        ? EntityIDToString(usersOptions.value[0])
        : "",
    subscription_id: "",
});

const subscriptionOptions = computed<SelectOption<string>[]>(() => {
    if (formFields.organization_user_id.length && workout.value) {
        return subscriptionsToOptions(
            subscriptionsUseCase.getForWorkoutID(
                StringToEntityID(formFields.organization_user_id),
                workout.value.disciplineID,
                workout.value.dateTimeRange.left,
            ),
            t,
            d,
        );
    }
    return [];
});

const onChangeOrganizationUserID = () => {
    const subscriptions = subscriptionsUseCase.getForWorkoutID(
        StringToEntityID(formFields.organization_user_id),
        workout.value.disciplineID,
        workout.value.dateTimeRange.left,
    );
    if (subscriptions.length) {
        formFields.subscription_id = EntityIDToString(subscriptions[0].id);
    } else {
        formFields.subscription_id = "";
    }
};
onChangeOrganizationUserID();

const vueForm = appForm<StoreUserWorkoutFormFields, void>(
    rules,
    formFields,
    async () => {
        const model = await userWorkoutsUseCase.store(
            formFields.workout_id,
            StringToEntityID(formFields.organization_user_id),
            formFields.subscription_id.length > 0
                ? StringToEntityID(formFields.subscription_id)
                : undefined,
        );
        emits("on-success", model);
    },
);
const form = vueForm.form;
</script>

<style scoped></style>
