<template>
  <form-component :form="vueForm">
    <div class="columns-1xs">
      <form-field
        :field="form.nickname"
        :label="t('Nickname')"
      >
        <text-input-field :field="form.nickname" />
      </form-field>
      <form-field
        :field="form.firstName"
        :label="t('First name')"
      >
        <text-input-field :field="form.firstName" />
      </form-field>
      <form-field
        :field="form.lastName"
        :label="t('Last name')"
      >
        <text-input-field :field="form.lastName" />
      </form-field>
      <form-field
        :field="form.email"
        :label="t('Email')"
      >
        <text-input-field
          type="email"
          :field="form.email"
        />
      </form-field>
      <form-field
        :field="form.phone"
        :label="t('Phone')"
      >
        <phone-input-field :field="form.phone" />
      </form-field>
    </div>
    <submit-button />
  </form-component>
</template>

<script lang="ts" setup>
import { reactive } from "vue";
import { ValidationArgs } from "@vuelidate/core";
import FormField from "@app/components/forms/fields/FormField.vue";
import SubmitButton from "@app/components/forms/fields/SubmitButton.vue";
import { appForm } from "@app/services/forms";
import FormComponent from "@app/components/FormComponent.vue";
import { OrganizationUser } from "@app/entities/organzationUser";
import { email, required } from "@app/services/validators";
import { authUseCase } from "@app/usecase/authUseCase";
import TextInputField from "@app/components/forms/fields/TextInputField.vue";
import PhoneInputField from "@app/components/forms/fields/PhoneInputField.vue";
import { useI18n } from "vue-i18n";

interface UpdateUserFormFields {
    nickname: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

const { t } = useI18n();
const props = defineProps<{
    organizationUser: OrganizationUser;
}>();

const emits = defineEmits<{
    (e: "on-success", value: void): void;
}>();

const formFields = reactive<UpdateUserFormFields>({
    nickname: props.organizationUser.user.nickname,
    firstName: props.organizationUser.user.firstName,
    lastName: props.organizationUser.user.lastName,
    email: props.organizationUser.user.email,
    phone: props.organizationUser.user.phone,
});
const rules: ValidationArgs<UpdateUserFormFields> = {
    nickname: { required },
    firstName: { required },
    lastName: { required },
    email: { required, email },
    phone: { required },
};

const vueForm = appForm<UpdateUserFormFields, void>(
    rules,
    formFields,
    async () => {
        await authUseCase.update(
            props.organizationUser.user.id,
            formFields.nickname,
            formFields.firstName,
            formFields.lastName,
            formFields.phone,
            formFields.email,
        );
        emits("on-success");
    },
);
const form = vueForm.form;
</script>

<style scoped></style>
