<template>
  <form-component :form="vueForm">
    <div class="columns-2xs">
      <form-field
        :field="form.subscription_type_id"
        :label="t('Subscription type')"
      >
        <select-box-field
          :field="form.subscription_type_id"
          :options="props.subscriptionTypeOptions"
          @change="onSubscriptionTypeChange"
        />
      </form-field>
      <form-field :field="form.is_open_date">
        <choose-field
          :label-left="t('Specific date')"
          :label-right="t('Open date')"
          :field="form.is_open_date"
        />
      </form-field>
      <form-field
        v-if="formFields.is_open_date"
        :field="form.days"
        :label="t('Days count')"
      >
        <text-input-field
          :field="form.days"
          type="number"
        />
      </form-field>
      <form-field
        v-if="!formFields.is_open_date"
        :field="form.start_date"
        :label="t('Start date')"
      >
        <date-input-field :field="form.start_date" />
      </form-field>
      <form-field
        v-if="!formFields.is_open_date"
        :field="form.expire_date"
        :label="t('Expire date')"
      >
        <date-input-field :field="form.expire_date" />
      </form-field>
      <form-field
        :field="form.disciplines"
        :label="t('Disciplines')"
      >
        <checkbox-field
          :field="form.disciplines"
          :options="props.disciplinesOptions"
        />
      </form-field>
      <form-field
        :field="form.workouts_count"
        :label="t('Workouts count')"
      >
        <text-input-field
          :field="form.workouts_count"
        />
      </form-field>
      <toggle-field
        :field="form.is_payment_confirmed"
        :label="t('Is payment confirmed')"
      />
      <form-field
        v-if="formFields.is_payment_confirmed === true"
        :field="form.payment_account_id"
        label="Payment account"
      >
        <select-box-field
          :field="form.payment_account_id"
          :options="props.paymentAccounts"
        />
      </form-field>
      <form-field
        v-if="formFields.is_payment_confirmed === true"
        :field="form.payment_sum"
        label="Payment sum"
      >
        <text-input-field
          :field="form.payment_sum"
          type="number"
        />
      </form-field>
    </div>
    <submit-button />
  </form-component>
</template>

<script lang="ts" setup>
import FormComponent from "@app/components/FormComponent.vue";
import CheckboxField from "@app/components/forms/fields/CheckboxField.vue";
import ChooseField from "@app/components/forms/fields/ChooseField.vue";
import DateInputField from "@app/components/forms/fields/DateInputField.vue";
import FormField from "@app/components/forms/fields/FormField.vue";
import SelectBoxField from "@app/components/forms/fields/SelectBoxField.vue";
import SubmitButton from "@app/components/forms/fields/SubmitButton.vue";
import TextInputField from "@app/components/forms/fields/TextInputField.vue";
import ToggleField from "@app/components/forms/fields/ToggleField.vue";
import { ErrSomethingWentWrong } from "@app/entities/errors";
import { OrganizationUser } from "@app/entities/organzationUser";
import { Subscription } from "@app/entities/subscription";
import {
    EntityIDsToString,
    EntityIDToString,
    StringsToEntityID,
    StringToEntityID,
    UserWorkoutID,
} from "@app/entities/uuid";
import { formatDateISO } from "@app/services/format";

import { appForm } from "@app/services/forms";
import { minValue, required, sameAsChecked } from "@app/services/validators";
import { useSubscriptionTypesViewData } from "@app/usecase/subscriptionTypeUseCase";
import { subscriptionsUseCase } from "@app/usecase/subscriptionUseCase";
import { userWorkoutsUseCase } from "@app/usecase/userWorkoutUseCase";
import { StringToDate } from "@app/views/converters/date";
import { SelectOption } from "@app/views/models/form";
import { ValidationArgs } from "@vuelidate/core";
import { numeric, requiredIf, or } from "@vuelidate/validators";
import { reactive } from "vue";
import { useI18n } from "vue-i18n";

interface StoreSubscriptionFormFields {
    subscription_type_id: string;
    user_id: string;
    workouts_count: number;
    start_date: string;
    expire_date: string;
    disciplines: string[];
    is_payment_confirmed: boolean;
    payment_account_id: string;
    payment_sum: number;
    is_open_date: boolean;
    days: number;
}

const { t } = useI18n();
const props = defineProps<{
    organizationUser: OrganizationUser;
    subscriptionTypeOptions: SelectOption<string>[];
    disciplinesOptions: SelectOption<string>[];
    paymentAccounts: SelectOption<string>[];
    userWorkoutId?: UserWorkoutID;
}>();

const emits = defineEmits<{
    (e: "on-success", value: Subscription): void;
}>();

const subscriptionTypesView = useSubscriptionTypesViewData();

const rules: ValidationArgs<StoreSubscriptionFormFields> = {
    user_id: {
        required: required,
    },
    subscription_type_id: {
        required: required,
    },
    disciplines: {
        required: required,
    },
    start_date: {
        requiredIf: requiredIf(() => {
            return !formFields.is_open_date;
        }),
    },
    expire_date: {
        requiredIf: requiredIf(() => {
            return !formFields.is_open_date;
        }),
    },
    is_payment_confirmed: {
        sameAs: sameAsChecked(true),
    },
    payment_account_id: {
        required: required,
    },
    payment_sum: {
        minValueRef: minValue(0),
        numeric: numeric,
        required: required,
    },
    workouts_count: {
        required: required,
        minValueRef: minValue(1),
        numeric: numeric,
    },
    is_open_date: {},
    days: {
        minValueRef: or(
            requiredIf(() => {
                return !formFields.is_open_date;
            }),
            minValue(1),
        ),
        numeric: numeric,
        requiredIf: requiredIf(() => {
            return formFields.is_open_date;
        }),
    },
};
const formFields = reactive<StoreSubscriptionFormFields>({
    user_id: EntityIDToString(props.organizationUser.id),
    subscription_type_id: props.subscriptionTypeOptions.length
        ? props.subscriptionTypeOptions[0].value
        : "",
    workouts_count: 8,
    expire_date: "",
    disciplines: props.disciplinesOptions
        .filter((value) => value.selected)
        .map((value) => value.value),
    start_date: "",
    is_payment_confirmed: false,
    payment_account_id: "",
    is_open_date: false,
    days: 0,
    payment_sum: 0,
});

const onSubscriptionTypeChange = () => {
    const subscriptionType = subscriptionTypesView.getByID(
        StringToEntityID(formFields.subscription_type_id),
    );

    if (subscriptionType) {
        formFields.disciplines = EntityIDsToString(
            subscriptionType.disciplineIDs,
        );
        formFields.workouts_count = subscriptionType.classes;
        formFields.days = subscriptionType.days;
        formFields.payment_sum = subscriptionType.price;

        const date = new Date();
        formFields.start_date = formatDateISO(date);
        date.setDate(date.getDate() + subscriptionType.days);
        formFields.expire_date = formatDateISO(date);
    }
};

const vueForm = appForm<StoreSubscriptionFormFields, void>(
    rules,
    formFields,
    async () => {
        let dateFrom: Date | undefined = undefined;
        let dateTo: Date | undefined = undefined;
        let days: number | undefined = undefined;

        if (formFields.is_open_date) {
            days = formFields.days;
        } else {
            dateFrom = StringToDate(formFields.start_date);

            dateTo = StringToDate(formFields.expire_date);
            dateTo.setDate(dateTo.getDate() + 1);
        }

        let subscription = await subscriptionsUseCase.store(
            StringToEntityID(formFields.user_id),
            StringToEntityID(formFields.subscription_type_id),
            StringsToEntityID(formFields.disciplines),
            dateFrom,
            dateTo,
            days,
        );
        if (!subscription) {
            throw ErrSomethingWentWrong;
        }

        subscription = await subscriptionsUseCase.pay(
            subscription.id,
            StringToEntityID(formFields.payment_account_id),
            formFields.payment_sum ? formFields.payment_sum : 0,
            formFields.workouts_count,
        );
        if (!subscription) {
            throw ErrSomethingWentWrong;
        }

        if (subscription && props.userWorkoutId) {
            await userWorkoutsUseCase.addToSubscription(
                props.userWorkoutId,
                subscription.id,
            );
        }

        emits("on-success", subscription);
    },
);
const form = vueForm.form;
onSubscriptionTypeChange();
</script>

<style scoped></style>
