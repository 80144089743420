import { TransactionStatus } from "@app/entities/payment/transactionStatus";
import {
    listIDsUnique,
    OrganizationID,
    OrganizationUserID,
    PaymentAccountID,
    PaymentTransactionID,
} from "@app/entities/uuid";

export type Transaction = {
    id: PaymentTransactionID;
    organizationID: OrganizationID;
    accountID: PaymentAccountID;
    confirmedByID: OrganizationUserID;
    organizationUserID: OrganizationUserID;
    status: TransactionStatus;
    amount: number;
    createdAt: Date;
    updatedAt: Date;
};

export const filterTransactionIDs = (
    models: Transaction[],
): {
    organizationUserIDs: OrganizationUserID[];
    accountIDs: PaymentAccountID[];
} => {
    return {
        organizationUserIDs: listIDsUnique(
            models
                .map((model) => model.organizationUserID)
                .concat(models.map((model) => model.confirmedByID)),
        ),
        accountIDs: models.map((model) => model.accountID),
    };
};
