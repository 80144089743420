import WorkoutAddView from "@app/views/popups/workout-add/WorkoutAddView.vue";
import { RouteParams, RouteRecordRaw } from "vue-router";
import { PageType } from "@app/pkg/router/pageType";
import { AppBreadcrumbs } from "@app/router/models";
import { ScheduleID, StringPossibleToEntityID } from "@app/entities/uuid";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { scheduleUseCase } from "@app/usecase/scheduleUseCase";

type Props = {
    scheduleId: ScheduleID;
    date: Date;
};
export const workoutAddRoute = (
    prefix: string,
    breadcrumbs: AppBreadcrumbs[],
): RouteRecordRaw => {
    return {
        path: "workouts/add/:scheduleId?",
        name: prefix + PageType.WorkoutAdd,
        components: {
            popup: WorkoutAddView,
        },
        meta: {
            breadcrumbs: breadcrumbs,
        },
        props: true,
        beforeEnter: async (to, from, next) => {
            const props = getFirsLevelProps<Props>();
            const scheduleID = StringPossibleToEntityID(
                to.params.scheduleId as string,
            );
            if (scheduleID) {
                await scheduleUseCase.loadByID(scheduleID);
                props.scheduleId = scheduleID;
            }

            let date = new Date();
            if (to.params.day) {
                date = new Date(to.params.day as string);
            }
            date.setHours(0, 0, 0, 0);
            props.date = date;

            // didn't find a better way
            to.params = {
                ...to.params,
                ...(props as unknown as RouteParams),
            };
            next();
        },
    };
};
