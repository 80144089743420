<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <preloader-component
    :error-message="errorMessage"
    :is-loading="routerLoadingState === RouterLoadingState.init"
    :type="PreloaderType.popup"
  >
    <default-layout v-if="authView.isAuth">
      <router-view />
    </default-layout>
    <public-layout v-else>
      <router-view />
    </public-layout>
  </preloader-component>
</template>

<script lang="ts" setup>
import PreloaderComponent from "@app/components/PreloaderComponent.vue";
import { RouterLoadingState, routerLoadingState } from "@app/router/index.js";
import { computed, onErrorCaptured, ref } from "vue";
import { i18n, setI18nLanguage } from "@app/i18n/i18n";
import { ServiceWorker } from "@app/services/service-worker";
import { authUseCase, useAuthViewData } from "@app/usecase/authUseCase";
import { PreloaderType } from "@app/views/entities/preloaderType";
import DefaultLayout from "@app/layouts/DefaultLayout.vue";
import PublicLayout from "@app/layouts/PublicLayout.vue";
import { useI18n } from "vue-i18n";
import { logError, translateError } from "@app/pkg/error/AppError";
import { isUnAuthorizedError } from "@grpc/error";
import { PageType } from "@app/pkg/router/pageType";
import { useRouter } from "vue-router";

// load locale messages
const authView = useAuthViewData();
computed(() => {
    setI18nLanguage(i18n, authView.currentLocale);
    return authView.currentLocale;
});

const { t } = useI18n();
const router = useRouter();

ServiceWorker.init();

const errorMessage = ref("");

defineExpose({
    errorMessage,
});

const logout = () => {
    authUseCase.logout();
    router.push({ name: PageType.Index });
};
router.onError((err) => {
    logError(err);
    if (isUnAuthorizedError(err)) {
        logout();
    } else {
        errorMessage.value = translateError(err, t, authView.currentUser);
    }
});
onErrorCaptured((err) => {
    logError(err);
    if (isUnAuthorizedError(err)) {
        logout();
    } else {
        errorMessage.value = translateError(err, t, authView.currentUser);
    }
    return false;
});
</script>
