import { SubscriptionServicePromiseClient } from "proto-api/organization/subscription_service_grpc_web_pb";
import { Context } from "@app/entities/context";
import {
    DisciplineID,
    OrganizationID,
    OrganizationUserID,
    PaymentAccountID,
    SubscriptionID,
    SubscriptionTypeID,
} from "@app/entities/uuid";
import { Subscription } from "@app/entities/subscription";
import {
    SubscriptionCancelRequestMessage,
    SubscriptionGetByIDRequestMessage,
    SubscriptionGetByOrganizationIDRequestMessage,
    SubscriptionGetByOrganizationUserIDRequestMessage,
    SubscriptionOpenRequestMessage,
    SubscriptionPayRequestMessage,
    SubscriptionStoreRequestMessage,
    SubscriptionUpdateRequestMessage,
} from "proto-api/organization/subscription_service_pb";
import { EntityIDsToUUID, EntityToUUID } from "@grpc/converters/uuid";
import { DateToPb } from "@grpc/converters/date";
import { ContextToPb } from "@grpc/converters/context";
import { PbToSubscription } from "@grpc/converters/subscription";
import { PbToEntities } from "@grpc/converters/entities";
import { App } from "vue";

let service: SubscriptionServicePromiseClient;

type Config = {
    service: SubscriptionServicePromiseClient;
};
export const initSubscriptionClient = (app: App, options: Config) => {
    service = options.service;
};

export const useSubscriptionClient = () => {
    return subscriptionClient;
};

const subscriptionClient = {
    async store(
        ctx: Context,
        oUserID: OrganizationUserID,
        typeID: SubscriptionTypeID,
        disciplineIDs: DisciplineID[],
        start?: Date,
        expire?: Date,
        days?: number,
    ): Promise<Subscription | undefined> {
        const request = new SubscriptionStoreRequestMessage();
        request.setOrganizationUserId(EntityToUUID(oUserID));
        request.setSubscriptionTypeId(EntityToUUID(typeID));
        request.setDisciplineIdsList(EntityIDsToUUID(disciplineIDs));
        if (days && days > 0) {
            request.setDays(days);
        } else {
            request.setStartDate(DateToPb(start));
            request.setExpireDate(DateToPb(expire));
        }

        const response = await service.store(request, ContextToPb(ctx));

        const subscription = response.getSubscription();
        if (subscription) {
            return PbToSubscription(subscription);
        }

        return;
    },

    async update(
        ctx: Context,
        id: SubscriptionID,
        disciplineIDs: DisciplineID[],
        start: Date,
        expire: Date,
    ): Promise<Subscription | undefined> {
        const request = new SubscriptionUpdateRequestMessage();
        request.setId(EntityToUUID(id));
        request.setDisciplineIdsList(EntityIDsToUUID(disciplineIDs));
        request.setStartDate(DateToPb(start));
        request.setExpireDate(DateToPb(expire));

        const response = await service.update(request, ContextToPb(ctx));

        const subscription = response.getSubscription();
        if (subscription) {
            return PbToSubscription(subscription);
        }

        return;
    },

    async loadByID(
        ctx: Context,
        id: SubscriptionID,
    ): Promise<Subscription | undefined> {
        const request = new SubscriptionGetByIDRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.getByID(request, ContextToPb(ctx));

        const subscription = response.getSubscription();
        if (subscription) {
            return PbToSubscription(subscription);
        }

        return;
    },

    async loadByOrganizationID(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<Subscription[]> {
        const request = new SubscriptionGetByOrganizationIDRequestMessage();
        request.setOrganizationId(EntityToUUID(organizationID));

        const response = await service.getByOrganizationID(
            request,
            ContextToPb(ctx),
        );

        const subscriptions = response.getSubscriptionsList();
        if (subscriptions) {
            return PbToEntities(subscriptions, PbToSubscription);
        }

        return [];
    },

    async loadByOrganizationUserID(
        ctx: Context,
        organizationUserID: OrganizationUserID,
    ): Promise<Subscription[]> {
        const request = new SubscriptionGetByOrganizationUserIDRequestMessage();
        request.setOrganizationUserId(EntityToUUID(organizationUserID));
        const response = await service.getByOrganizationUserID(
            request,
            ContextToPb(ctx),
        );
        const subscriptions = response.getSubscriptionsList();
        return PbToEntities(subscriptions, PbToSubscription);
    },

    async cancel(
        ctx: Context,
        id: SubscriptionID,
    ): Promise<Subscription | undefined> {
        const request = new SubscriptionCancelRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.cancel(request, ContextToPb(ctx));

        const subscription = response.getSubscription();
        if (subscription) {
            return PbToSubscription(subscription);
        }

        return;
    },

    async open(
        ctx: Context,
        id: SubscriptionID,
    ): Promise<Subscription | undefined> {
        const request = new SubscriptionOpenRequestMessage();
        request.setId(EntityToUUID(id));

        const response = await service.open(request, ContextToPb(ctx));

        const subscription = response.getSubscription();
        if (subscription) {
            return PbToSubscription(subscription);
        }

        return;
    },

    async pay(
        ctx: Context,
        id: SubscriptionID,
        accountID: PaymentAccountID,
        sum: number,
        count: number,
    ): Promise<Subscription | undefined> {
        const request = new SubscriptionPayRequestMessage();
        request.setId(EntityToUUID(id));
        request.setSum(sum);
        request.setPaymentAccountId(EntityToUUID(accountID));
        request.setWorkoutsCount(count);

        const response = await service.pay(request, ContextToPb(ctx));

        const subscription = response.getSubscription();
        if (subscription) {
            return PbToSubscription(subscription);
        }

        return;
    },
};
