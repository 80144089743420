<template>
  <popup-page-component :title="t('Workout add')">
    <choose-field
      :field="form.isNewUser"
      :label-left="t('Exist user')"
      :label-right="t('New user')"
    />
    <user-workout-add-form
      v-if="!formFields.isNewUser"
      :workout-id="workout.id"
      @on-success="onSuccess"
    />
    <user-add-form
      v-if="formFields.isNewUser"
      @on-success="onUserAdd"
    />
  </popup-page-component>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import UserWorkoutAddForm from "@app/components/forms/UserWorkoutAddForm.vue";
import { computed, reactive } from "vue";
import PopupPageComponent from "@app/components/PopupPageComponent.vue";
import { WorkoutID } from "@app/entities/uuid";
import { useWorkoutViewData } from "@app/usecase/workoutsUseCase";
import { ValidationArgs } from "@vuelidate/core";
import { required } from "@app/services/validators";
import { appForm } from "@app/services/forms";
import ChooseField from "@app/components/forms/fields/ChooseField.vue";
import UserAddForm from "@app/components/forms/UserAddForm.vue";
import { OrganizationUser } from "@app/entities/organzationUser";
import { userWorkoutsUseCase } from "@app/usecase/userWorkoutUseCase";
import { useI18n } from "vue-i18n";

type Props = {
    workoutId: WorkoutID;
};

type FormFields = {
    isNewUser: boolean;
};

const { t } = useI18n();
const rules: ValidationArgs<FormFields> = {
    isNewUser: {
        required,
    },
};
const formFields = reactive<FormFields>({
    isNewUser: false,
});
const vueForm = appForm<FormFields, void>(rules, formFields, async () => {});
const form = vueForm.form;

const workoutViewData = useWorkoutViewData();

const router = useRouter();

const props = defineProps<Props>();

const workout = computed(() => {
    const workout = workoutViewData.getByID(props.workoutId);
    if (!workout) {
        throw new Error("Workout not found");
    }
    return workout;
});

const onUserAdd = async (oUser: OrganizationUser) => {
    await userWorkoutsUseCase.store(props.workoutId, oUser.id, undefined);
    router.back();
};

const onSuccess = () => {
    router.back();
};
</script>

<style scoped></style>
