import { ErrForbiddenMessage } from "@app/entities/errors";
import { AppError } from "@app/pkg/error/AppError";
import { PageName } from "@app/pkg/router/pageName";
import { PageType } from "@app/pkg/router/pageType";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { getPopupRoutes } from "@app/router/popups";
import { useAuthViewData } from "@app/usecase/authUseCase";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { paymentUseCase } from "@app/usecase/paymentUseCase";
import AccountAddView from "@organizationView/payments/AccountAddView.vue";
import PaymentsView from "@organizationView/payments/PaymentsView.vue";
import {
    NavigationGuardWithThis,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";

export const PaymentsIndexViewBeforeEnter: NavigationGuardWithThis<
    undefined
> = async (to, from, next) => {
    const authView = useAuthViewData();

    const props = getFirsLevelProps<unknown>();
    if (!authView.isManager) {
        throw new AppError({
            message: ErrForbiddenMessage,
        });
    }

    await paymentUseCase.loadAccounts(organizationUseCase.getOrganizationID());
    await paymentUseCase
        .loadTransactions(organizationUseCase.getOrganizationID())
        .then(async (models) => {
            await paymentUseCase.sync(models);
        });

    // didn't find a better way
    to.params = {
        ...to.params,
        ...(props as unknown as RouteParams),
    };
    next();
};

export const paymentsIndexRoute: RouteRecordRaw = {
    path: "payments",
    meta: {
        breadcrumbs: [
            {
                name: PageName.Organization,
                to: PageType.OrganizationStatistic,
            },
            {
                name: PageName.Payments,
                to: PageType.Payments,
            },
        ],
    },
    children: [
        {
            path: "",
            name: PageType.Payments,
            component: PaymentsView,
            props: true,
            beforeEnter: PaymentsIndexViewBeforeEnter,
            children: getPopupRoutes(PageType.Payments, [
                {
                    name: PageName.Payments,
                    to: PageType.Payments,
                },
            ]),
        },
        {
            path: "account-add",
            name: PageType.AccountAdd,
            component: AccountAddView,
        },
    ],
};
