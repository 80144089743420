import {
    EventChannelType,
    EventChannelTypeBrowser,
} from "@app/entities/eventChannelType";
import { EventChannelTypeMessage } from "proto-api/organization/events_pb";

export const pbToEventChannelType = (
    payload: EventChannelTypeMessage,
): EventChannelType => {
    switch (payload) {
        case EventChannelTypeMessage.BROWSER:
            return EventChannelTypeBrowser;
        default:
            return EventChannelTypeBrowser;
    }
};
