<template>
  <div>
    <header
      v-if="title && title.length"
      :class="{
        shadow: hasPopup,
      }"
      class="bg-white py-0.5"
    >
      <div class="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8">
        <div
          :class="{
            'items-stretch space-y-4 mt-2 mb-2 max-w-4xl':
              withPadding,
          }"
        >
          <data-item
            :links="links && isPageLoading === false ? links : []"
            class="text-3xl text-primary"
          >
            <breadcrumbs-component
              v-if="hasPopup"
            />
            <h1 class="font-bold tracking-tight text-gray-900">
              {{ title }}
            </h1>
          </data-item>
        </div>
      </div>
    </header>
    <preloader-component
      :display="DisplayType.block"
      :error-message="pageErrorMessage"
      :is-loading="isPageLoading"
      :type="PreloaderType.inline"
    >
      <main class="h-full bg-white">
        <div class="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8">
          <div
            :class="{
              'items-stretch space-y-4 mt-2 mb-2 max-w-4xl pb-4':
                withPadding,
            }"
          >
            <error-boundary-component>
              <slot />
            </error-boundary-component>
          </div>
        </div>
      </main>
    </preloader-component>

    <!-- https://daisyui.com/components/modal/ -->
    <!-- Put this part before </body> tag -->
    <div
      v-if="hasPopup"
      :class="{
        'modal-open': isPopupOpen,
      }"
      class="modal p-0.5"
    >
      <div class="modal-box p-0.5 relative w-100 max-w-4xl">
        <label
          v-if="routerLoadingState !== RouterLoadingState.popupLoad"
          class="btn btn-sm btn-circle absolute right-2 top-2"
          for="modal"
          @click="goBack"
        >✕</label>
        <preloader-component
          :is-loading="
            routerLoadingState === RouterLoadingState.popupLoad
          "
          :type="PreloaderType.inline"
        >
          <router-view name="popup" />
        </preloader-component>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import DataItem from "./DataItem.vue";
import BreadcrumbsComponent from "@app/components/BreadcrumbsComponent.vue";
import { computed, ref } from "vue";
import { RouteLocationNormalizedLoaded, useRouter } from "vue-router";
import ErrorBoundaryComponent from "@app/components/ErrorBoundaryComponent.vue";
import PreloaderComponent from "@app/components/PreloaderComponent.vue";
import { RouterLoadingState, routerLoadingState } from "../router";
import { Link } from "@app/views/models/links";
import { PageData } from "@app/stores/converters/entity";
import { PreloaderType } from "@app/views/entities/preloaderType";
import { DisplayType } from "@app/views/models";

const router = useRouter();

const props = withDefaults(
    defineProps<{
        title?: string;
        withPadding?: boolean;
        links?: Link[];
        isLoading?: boolean;
        errorMessage?: string;
        hasPopup?: boolean;
        currentPage?: PageData<unknown> | undefined;
    }>(),
    {
        withPadding: true,
        title: "",
        links: undefined,
        isLoading: false,
        errorMessage: "",
        hasPopup: true,
        currentPage: undefined,
    },
);

const isPageLoading = computed<boolean>((): boolean => {
    return (
        props.isLoading ||
        routerLoadingState.value === RouterLoadingState.pageLoad
    );
});

const pageErrorMessage = computed<string>(() => {
    if (props.errorMessage.length) {
        return props.errorMessage;
    } else if (props.currentPage && props.currentPage.errorMessage.length) {
        return props.currentPage.errorMessage;
    } else {
        return "";
    }
});

const isPopupOpen = ref<boolean>(false);
const goBack = () => {
    isPopupOpen.value = false;
    router.back();
};

const checkPopup = (to: RouteLocationNormalizedLoaded) => {
    const isPopup = to.matched.find(
        (value) => value.components && value.components["popup"],
    );

    isPopupOpen.value = !!isPopup;
};
router.afterEach(checkPopup);
router.beforeEach(checkPopup);
checkPopup(router.currentRoute.value);
</script>

<style scoped></style>
