import { parseDateISO } from "@app/services/format";
import { StringToEntityID, UUID } from "@app/entities/uuid";
import { LocationQueryValue } from "vue-router";

export const parseDateFromQueryString = (
    value: LocationQueryValue | LocationQueryValue[],
    defaultValue: Date,
): Date => {
    let dateFrom = parseDateISO(value as string);
    if (!dateFrom) {
        dateFrom = defaultValue;
    }
    dateFrom.setHours(0, 0, 0, 0);
    return dateFrom;
};

export const parseUUIDFromQueryString = (
    value: LocationQueryValue | LocationQueryValue[],
): UUID | undefined => {
    return value ? StringToEntityID(value as string) : undefined;
};
