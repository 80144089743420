<template>
  <div class="text-center">
    <a
      :href="googleAuthUrl"
      class="m-2 inline-block rounded-full bg-primary p-2 uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
      data-te-ripple-color="light"
      data-te-ripple-init
    >
      <ActionIcon
        :type="LinkIcons.Google"
        size="medium"
      />
    </a>
    <a
      :href="vkAuthUrl"
      class="m-2 inline-block rounded-full bg-primary p-2 uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
      data-te-ripple-color="light"
      data-te-ripple-init
      type="button"
    >
      <ActionIcon
        :type="LinkIcons.VK"
        size="medium"
      />
    </a>
  </div>
</template>

<script setup lang="ts">
import ActionIcon from "@app/components/ActionIcon.vue";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { LinkIcons } from "@app/views/models/links";

const organization = organizationUseCase.currentOrganization();

const googleAuthUrl = `${import.meta.env.VITE_OAUTH_BASE_URL}/auth/${
    organization.id.value
}/google/login`;

const vkAuthUrl = `${import.meta.env.VITE_OAUTH_BASE_URL}/auth/${
    organization.id.value
}/vk/login`;
</script>

<style scoped></style>
