import { Organization } from "@app/entities/organzation";
import { PageName } from "@app/pkg/router/pageName";
import { PageType } from "@app/pkg/router/pageType";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import OrganizationInfoView from "@organizationView/info/OrganizationInfoView.vue";
import OrganizationUpdateView from "@organizationView/info/OrganizationUpdateView.vue";
import { RouteRecordRaw } from "vue-router";

type Props = {
    organization: Organization;
};

export const infoRoutes: RouteRecordRaw = {
    path: "info",
    children: [
        {
            path: "",
            name: PageType.OrganizationInfo,
            component: OrganizationInfoView,
            props: () => {
                const props = getFirsLevelProps<Props>();
                props.organization = organizationUseCase.currentOrganization();
                return props;
            },
        },
        {
            path: "edit",
            name: PageType.OrganizationEdit,
            component: OrganizationUpdateView,
            props: () => {
                const props = getFirsLevelProps<Props>();
                props.organization = organizationUseCase.currentOrganization();
                return props;
            },
            meta: {
                breadcrumbs: [
                    {
                        name: PageName.Organization,
                        to: PageType.OrganizationStatistic,
                    },
                    {
                        name: PageName.OrganizationInfo,
                        to: PageType.OrganizationInfo,
                    },
                ],
            },
        },
    ],
};
