import { defineStore } from "pinia";
import {
    BaseEntityStorage,
    storeEntities,
    storeGetByID,
    storeGetByIDs,
} from "@app/stores/converters/repo";
import { Schedule } from "@app/entities/schedule";
import {
    DisciplineID,
    LocationID,
    OrganizationID,
    OrganizationUserID,
    ScheduleID,
} from "@app/entities/uuid";
import { TimeRange } from "@app/entities/timeRange";
import { Context } from "@app/entities/context";
import { DateTimeRange } from "@app/entities/dateTimeRange";
import { scheduleClient } from "@grpc/scheduleClient";

type StoreFields = BaseEntityStorage<Schedule>;

export interface ScheduleClient {
    loadByOrganizationID(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<Schedule[]>;

    loadByID(ctx: Context, id: ScheduleID): Promise<Schedule | undefined>;

    store(
        ctx: Context,
        organizationID: OrganizationID,
        disciplineID: DisciplineID,
        locationID: LocationID,
        trainerID: OrganizationUserID,
        studentsCount: number,
        dateRange: Partial<DateTimeRange>,
        timeRange: TimeRange,
    ): Promise<Schedule | undefined>;

    update(
        ctx: Context,
        id: ScheduleID,
        disciplineID: DisciplineID,
        locationID: LocationID,
        trainerID: OrganizationUserID,
        studentsCount: number,
        dateRange: Partial<DateTimeRange>,
        timeRange: TimeRange,
    ): Promise<Schedule | undefined>;

    destroy(ctx: Context, id: ScheduleID): Promise<Schedule | undefined>;
}

const client: ScheduleClient = scheduleClient;

export const useScheduleRepo = defineStore("schedule", {
    state: () =>
        ({
            data: [],
            byId: {},
            byPage: {},
            currentPageId: undefined,
            lastUpdate: new Date(),
        }) as StoreFields,
    actions: {
        sync(entities: Schedule[]) {
            return storeEntities(this, entities);
        },

        async loadByOrganizationID(
            ctx: Context,
            organizationID: OrganizationID,
        ): Promise<Schedule[]> {
            const data = await client.loadByOrganizationID(ctx, organizationID);
            this.sync(data);
            return data;
        },

        async loadByID(
            ctx: Context,
            id: ScheduleID,
        ): Promise<Schedule | undefined> {
            const data = await client.loadByID(ctx, id);
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async store(
            ctx: Context,
            organizationID: OrganizationID,
            disciplineID: DisciplineID,
            locationID: LocationID,
            trainerID: OrganizationUserID,
            studentsCount: number,
            dateRange: Partial<DateTimeRange>,
            timeRange: TimeRange,
        ): Promise<Schedule | undefined> {
            const data = await client.store(
                ctx,
                organizationID,
                disciplineID,
                locationID,
                trainerID,
                studentsCount,
                dateRange,
                timeRange,
            );
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async update(
            ctx: Context,
            id: ScheduleID,
            disciplineID: DisciplineID,
            locationID: LocationID,
            trainerID: OrganizationUserID,
            studentsCount: number,
            dateRange: Partial<DateTimeRange>,
            timeRange: TimeRange,
        ): Promise<Schedule | undefined> {
            const data = await client.update(
                ctx,
                id,
                disciplineID,
                locationID,
                trainerID,
                studentsCount,
                dateRange,
                timeRange,
            );
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async destroy(
            ctx: Context,
            id: ScheduleID,
        ): Promise<Schedule | undefined> {
            const data = await client.destroy(ctx, id);
            if (data) {
                this.sync([data]);
            }
            return data;
        },
    },
    getters: {
        All: (store): Schedule[] => store.data,
        getByID: (store) => storeGetByID<Schedule>(store),
        getByIDs: (store) => storeGetByIDs<Schedule>(store),
        getByWeekday(store) {
            return (
                timeRange: TimeRange,
                locationID?: LocationID,
            ): Schedule[] => {
                return store.data
                    .filter(
                        (value) =>
                            !value.deletedAt &&
                            value.timeRange.left >= timeRange.left &&
                            value.timeRange.right < timeRange.right &&
                            (!locationID ||
                                locationID.value === value.locationID.value),
                    )
                    .sort((a, b) =>
                        a.timeRange.left > b.timeRange.left ? 1 : -1,
                    );
            };
        },
    },
});
