import {
    NavigationGuardWithThis,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";
import { PageType } from "@app/pkg/router/pageType";
import { PageName } from "@app/pkg/router/pageName";
import SchedulePlanView from "@pages/schedule/plan/SchedulePlanView.vue";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { DateTimeRange } from "@app/entities/dateTimeRange";
import { workoutsUseCase } from "@app/usecase/workoutsUseCase";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { workoutDraftsUseCase } from "@app/usecase/workoutDraftUseCase";

type Props = {
    dateRange: DateTimeRange;
};

export const SchedulePlanBeforeEnter: NavigationGuardWithThis<
    undefined
> = async (to, _from, next) => {
    const dateFrom = new Date();
    dateFrom.setHours(0, 0, 0, 0);
    const dateTo = new Date(dateFrom);
    dateTo.setMonth(dateTo.getMonth() + 1);

    const props = getFirsLevelProps<Props>();
    props.dateRange = {
        left: dateFrom,
        right: dateTo,
    };

    await workoutsUseCase.loadByOrganizationID(
        organizationUseCase.getOrganizationID(),
        props.dateRange,
    );
    workoutDraftsUseCase.updateData(props.dateRange, undefined);

    // didn't find a better way
    to.params = {
        ...to.params,
        ...(props as unknown as RouteParams),
    };

    next();
};

export const schedulePlanRoute: RouteRecordRaw = {
    path: "/schedule/plan",
    name: PageType.SchedulePlan,
    component: SchedulePlanView,
    props: true,
    beforeEnter: SchedulePlanBeforeEnter,
    meta: {
        breadcrumbs: [
            {
                name: PageName.Schedule,
                to: PageType.Schedule,
            },
            {
                name: "Schedule planning",
                to: PageType.SchedulePlan,
            },
        ],
    },
};
