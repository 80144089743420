<template>
  <label
    class="label cursor-pointer"
    :class="{
      'input-error text-red-500':
        field.$dirty && field.$silentErrors.length,
    }"
  >
    <input
      v-model="fieldRef.$model"
      type="checkbox"
      :value="true"
      :name="field.$path"
      class="toggle"
    >
    <span class="grow ml-2">{{ label }}</span>
  </label>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { BaseValidation } from "@vuelidate/core";

const props = defineProps<{
    field: BaseValidation<boolean>;
    label: string;
}>();
const fieldRef = ref(props.field);
</script>

<style scoped></style>
