<template>
  <page-component
    :links="actionLinks"
    :title="t('Schedule')"
  >
    <location-filter-form class="mb-2" />
    <weekday-navigation
      :active-date="activeDate"
      :left-date="leftDate"
      :right-date="rightDate"
      :route-name="PageType.Schedule"
      type="weekday"
    />
    <error-boundary-component
      v-for="entity of entities"
      :key="EntityIDToString(entity.id)"
    >
      <schedule-item-component
        :model="entity"
        :show-location="!locationId"
      />
    </error-boundary-component>
  </page-component>
</template>

<script lang="ts" setup>
import PageComponent from "@app/components/PageComponent.vue";
import { Link, LinkIcons } from "@app/views/models/links";
import { useI18n } from "vue-i18n";
import { computed, ComputedRef } from "vue";
import { onBeforeRouteUpdate, useRoute } from "vue-router";
import { authUseCase, ENTITIES_LIST } from "@app/usecase/authUseCase";
import { PageType } from "@app/pkg/router/pageType";
import WeekdayNavigation from "@app/components/WeekdayNavigation.vue";
import { EntityIDToString, LocationID } from "@app/entities/uuid";
import { TimeRange } from "@app/entities/timeRange";
import { ScheduleIndexBeforeEnter } from "@pages/schedule/index/route";
import { useScheduleViewData } from "@app/usecase/scheduleUseCase";
import ErrorBoundaryComponent from "@app/components/ErrorBoundaryComponent.vue";
import ScheduleItemComponent from "@app/views/entityComponents/ScheduleItemComponent.vue";
import { getScheduleAddLink } from "@app/views/popups/schedule-add/route";
import { parseDateISO } from "@app/services/format";
import LocationFilterForm from "@app/components/forms/LocationFilterForm.vue";

type Props = {
    day: string;
    timeRange: TimeRange;
    locationId?: LocationID;
};
const route = useRoute();
const { t } = useI18n();
const scheduleViewData = useScheduleViewData();
const props = defineProps<Props>();

const activeDate = computed(() => {
    const date = props.day ? parseDateISO(props.day) : undefined;
    return date ? date : new Date();
});
const leftDate = new Date();
leftDate.setHours(0, 0, 0, 0);
const rightDate = new Date(leftDate);
rightDate.setDate(rightDate.getDate() + 7);

const entities = computed(() => {
    return scheduleViewData.getByWeekday(props.timeRange, props.locationId);
});

const actionLinks: ComputedRef<Link[]> = computed(() => [
    {
        text: t("Workouts"),
        image: LinkIcons.trophy,
        to: {
            name: PageType.Workouts,
        },
        disabled: !authUseCase.canView(ENTITIES_LIST.WORKOUT),
    },
    {
        text: t("Workouts list"),
        image: LinkIcons.calendar,
        to: {
            name: PageType.WorkoutsList,
        },
        disabled: !authUseCase.canEdit(ENTITIES_LIST.WORKOUT),
    },
    {
        text: t("Schedule plan"),
        image: LinkIcons.presentationChart,
        to: {
            name: PageType.SchedulePlan,
        },
        disabled: !authUseCase.canEdit(ENTITIES_LIST.SCHEDULE),
    },
    getScheduleAddLink(route, t("Add workout")),
]);

onBeforeRouteUpdate(ScheduleIndexBeforeEnter);
</script>

<style scoped></style>
