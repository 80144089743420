import { Subscription } from "@app/entities/subscription";
import { pbToDateSure } from "@grpc/converters/date";
import { PbToUUIDSure } from "@grpc/converters/uuid";
import { SubscriptionMessage } from "proto-api/organization/subscription_pb";

export const PbToSubscription = (
    resource: SubscriptionMessage,
): Subscription => {
    const expireDate = resource.getExpireDate();

    const displayExpireDate = expireDate
        ? new Date(expireDate.toDate())
        : undefined;
    if (displayExpireDate) {
        displayExpireDate.setDate(displayExpireDate.getDate() - 1);
    }

    return {
        id: PbToUUIDSure(resource.getId()),
        organizationID: PbToUUIDSure(resource.getOrganizationId()),
        organizationUserID: PbToUUIDSure(resource.getOrganizationUserId()),
        subscriptionTypeID: PbToUUIDSure(resource.getSubscriptionTypeId()),
        balanceID: PbToUUIDSure(resource.getBalanceId()),
        disciplineIDs: resource
            .getDisciplineIdsList()
            .map((value) => PbToUUIDSure(value)),
        startDate: resource.getStartDate()?.toDate(),
        expireDate: resource.getExpireDate()?.toDate(),
        displayExpireDate: displayExpireDate,
        createdAt: pbToDateSure(resource.getCreatedAt()),
        updatedAt: pbToDateSure(resource.getUpdatedAt()),
    };
};
