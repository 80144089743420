import { defineStore } from "pinia";
import {
    sortByCreatedAt,
    storeEntities,
    storeGetByID,
} from "@app/stores/converters/repo";
import { UserWorkout } from "@app/entities/userWorkout";
import {
    OrganizationID,
    OrganizationUserID,
    PaymentAccountID,
    SubscriptionID,
    UserWorkoutID,
    WorkoutID,
} from "@app/entities/uuid";
import { Context } from "@app/entities/context";
import { BaseStoreFields } from "@app/stores/converters/entity";
import { useUserWorkoutClient } from "@grpc/userWorkoutClient";
import { WorkoutStatus } from "@app/entities/workoutStatus";

export interface UserWorkoutsClient {
    loadByID(ctx: Context, id: UserWorkoutID): Promise<UserWorkout | undefined>;

    loadByOrganizationID(
        ctx: Context,
        organizationID: OrganizationID,
        dateFrom: Date,
        dateTo: Date,
    ): Promise<UserWorkout[]>;

    loadByOrganizationUserID(
        ctx: Context,
        organizationUserID: OrganizationUserID,
    ): Promise<UserWorkout[]>;

    loadBySubscriptionIDs(
        ctx: Context,
        ids: SubscriptionID[],
    ): Promise<UserWorkout[]>;

    store(
        ctx: Context,
        workoutID: WorkoutID,
        organizationUserID: OrganizationUserID,
        subscriptionID?: SubscriptionID,
    ): Promise<UserWorkout | undefined>;

    addToSubscription(
        ctx: Context,
        workoutID: UserWorkoutID,
        subscriptionID: SubscriptionID,
    ): Promise<UserWorkout | undefined>;

    pay(
        ctx: Context,
        workoutID: UserWorkoutID,
        accountID: PaymentAccountID,
        sum: number,
    ): Promise<UserWorkout | undefined>;

    cancel(
        ctx: Context,
        workoutID: UserWorkoutID,
        keepBalance: boolean,
    ): Promise<UserWorkout | undefined>;

    open(
        ctx: Context,
        workoutID: UserWorkoutID,
    ): Promise<UserWorkout | undefined>;
}

type StoreFields = BaseStoreFields<UserWorkout>;

const defaultValues: StoreFields = {
    data: [],
    byId: {},
    byPage: {},
    currentPageId: undefined,
    lastUpdate: new Date(),
};

const client: UserWorkoutsClient = useUserWorkoutClient();

export const useUserWorkoutRepo = defineStore("userWorkout", {
    state: () => defaultValues,
    actions: {
        sync(entities: UserWorkout[]) {
            storeEntities(this, entities);
            sortByCreatedAt(this);
        },

        async loadByID(
            ctx: Context,
            id: UserWorkoutID,
        ): Promise<UserWorkout | undefined> {
            const userWorkout = await client.loadByID(ctx, id);
            if (userWorkout) {
                this.sync([userWorkout]);
            }
            return userWorkout;
        },

        async loadByOrganizationID(
            ctx: Context,
            organizationID: OrganizationID,
            dateFrom: Date,
            dateTo: Date,
        ): Promise<UserWorkout[]> {
            const userWorkouts = await client.loadByOrganizationID(
                ctx,
                organizationID,
                dateFrom,
                dateTo,
            );
            this.sync(userWorkouts);
            return userWorkouts;
        },
        async loadByOrganizationUserID(
            ctx: Context,
            organizationUserID: OrganizationUserID,
        ): Promise<UserWorkout[]> {
            const userWorkouts = await client.loadByOrganizationUserID(
                ctx,
                organizationUserID,
            );
            this.sync(userWorkouts);
            return userWorkouts;
        },
        async loadBySubscriptionIDs(
            ctx: Context,
            ids: SubscriptionID[],
        ): Promise<UserWorkout[]> {
            const userWorkouts = await client.loadBySubscriptionIDs(ctx, ids);
            this.sync(userWorkouts);
            return userWorkouts;
        },
        async store(
            ctx: Context,
            workoutID: WorkoutID,
            organizationUserID: OrganizationUserID,
            subscriptionID?: SubscriptionID,
        ): Promise<UserWorkout | undefined> {
            const userWorkout = await client.store(
                ctx,
                workoutID,
                organizationUserID,
                subscriptionID,
            );
            if (userWorkout) {
                this.sync([userWorkout]);
            }
            return userWorkout;
        },
        async open(
            ctx: Context,
            workoutID: UserWorkoutID,
        ): Promise<UserWorkout | undefined> {
            const userWorkout = await client.open(ctx, workoutID);
            if (userWorkout) {
                this.sync([userWorkout]);
            }
            return userWorkout;
        },
        async cancel(
            ctx: Context,
            workoutID: UserWorkoutID,
            keepBalance: boolean,
        ): Promise<UserWorkout | undefined> {
            const userWorkout = await client.cancel(
                ctx,
                workoutID,
                keepBalance,
            );
            if (userWorkout) {
                this.sync([userWorkout]);
            }
            return userWorkout;
        },
        async pay(
            ctx: Context,
            workoutID: UserWorkoutID,
            accountID: PaymentAccountID,
            sum: number,
        ): Promise<UserWorkout | undefined> {
            const userWorkout = await client.pay(
                ctx,
                workoutID,
                accountID,
                sum,
            );
            if (userWorkout) {
                this.sync([userWorkout]);
            }
            return userWorkout;
        },
        async addToSubscription(
            ctx: Context,
            workoutID: UserWorkoutID,
            subscriptionID: SubscriptionID,
        ): Promise<UserWorkout | undefined> {
            const userWorkout = await client.addToSubscription(
                ctx,
                workoutID,
                subscriptionID,
            );
            if (userWorkout) {
                this.sync([userWorkout]);
            }
            return userWorkout;
        },
    },
    getters: {
        getByID: (store) => storeGetByID<UserWorkout>(store),
        getWorkoutsByWorkoutId: (store) => {
            // const organizationUsersStore = useOrganizationUsersStore();
            return (workoutID: WorkoutID) => {
                return store.data.filter(
                    (value) => value.workoutID.value == workoutID.value,
                );
            };
        },
        getBySubscriptionID: (store) => {
            return (subscriptionID: SubscriptionID): UserWorkout[] => {
                return store.data.filter(
                    (value) =>
                        value.subscriptionID?.value == subscriptionID.value,
                );
            };
        },
        getOpenBySubscriptionID: (store) => {
            return (subscriptionID: SubscriptionID): UserWorkout[] => {
                return store.data.filter(
                    (value) =>
                        value.subscriptionID && value.subscriptionID.value == subscriptionID.value && value.status == WorkoutStatus.WorkoutStatusOpen,
                );
            };
        },
        getByWorkoutID: (store) => {
            return (workoutID: WorkoutID): UserWorkout[] => {
                return store.data.filter(
                    (value) => value.workoutID.value == workoutID.value,
                );
            };
        },
        getByOrganizationUserID: (store) => {
            return (organizationUserID: OrganizationUserID): UserWorkout[] => {
                return store.data.filter(
                    (value) =>
                        value.organizationUserID.value ==
                        organizationUserID.value,
                );
            };
        },
    },
});
