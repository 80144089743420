import { EntityFileID, OrganizationID, UUID } from "@app/entities/uuid";
import { EntityFile } from "@app/entities/entityFile";
import { useEntityFileRepo } from "@app/stores/entityFileRepo";
import { Context } from "@app/entities/context";
import { FileType } from "@app/entities/fileType";
import { storageRepo } from "@app/pkg/storageRepo";
import { ErrSomethingWentWrong } from "@app/entities/errors";

interface EntityFileRepo {
    loadByOrganizationID(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<EntityFile[]>;

    loadByID(ctx: Context, id: EntityFileID): Promise<EntityFile | undefined>;

    store(
        ctx: Context,
        entityID: UUID,
        entityType: FileType,
        data: string,
    ): Promise<EntityFile | undefined>;

    destroy(ctx: Context, id: EntityFileID): Promise<void>;
}

const useRepo = (): EntityFileRepo => {
    return useEntityFileRepo();
};

interface EntityFileUseCase {
    getByID(id: EntityFileID): Promise<EntityFile | undefined>;

    store(
        entityID: UUID,
        entityType: FileType,
        data: string,
    ): Promise<EntityFile>;

    destroy(id: EntityFileID): Promise<void>;

    syncImages(
        entityID: UUID,
        entityType: FileType,
        newImages: string[],
        deleteImages: UUID[],
    ): Promise<unknown>[];
}

export const entityFileUseCase: EntityFileUseCase = {
    async getByID(id: EntityFileID): Promise<EntityFile | undefined> {
        const repo = useRepo();
        const viewData = useEntityFilesData();
        let model = viewData.getByID(id);
        if (!model) {
            model = await repo.loadByID(storageRepo.withToken(), id);
        }
        return model;
    },
    async store(
        entityID: UUID,
        entityType: FileType,
        data: string,
    ): Promise<EntityFile> {
        const repo = useRepo();
        const model = await repo.store(
            storageRepo.withToken(),
            entityID,
            entityType,
            data,
        );
        if (!model) {
            throw ErrSomethingWentWrong;
        }
        return model;
    },
    async destroy(id: EntityFileID): Promise<void> {
        const repo = useRepo();
        await repo.destroy(storageRepo.withToken(), id);
    },
    syncImages(
        entityID: UUID,
        entityType: FileType,
        newImages: string[],
        deleteImages: UUID[],
    ): Promise<unknown>[] {
        const promises: Promise<unknown>[] = [];
        promises.push(
            ...newImages.map((value) =>
                this.store(entityID, entityType, value),
            ),
        );
        promises.push(
            ...deleteImages.map((value) => entityFileUseCase.destroy(value)),
        );
        return promises;
    },
};

export const useEntityFilesData = (): {
    getByID(id: EntityFileID): EntityFile | undefined;
    getByEntityID(entityID: UUID): EntityFile[];
    getByEntityType(entityID: UUID, entityType: FileType): EntityFile[];
    getOneByEntityType(
        entityID: UUID,
        entityType: FileType,
    ): EntityFile | undefined;
} => {
    return useEntityFileRepo();
};
