import * as uuid_pb from "proto-api/entity/uuid_pb";
import { UUID } from "@app/entities/uuid";

export const PbToUUID = <T>(
    resource: uuid_pb.UUID | undefined,
): T | undefined => {
    const val = resource?.getValue();
    if (val) {
        return { value: val } as T;
    }
    return undefined;
};

export const PbToUUIDSure = <T>(resource: uuid_pb.UUID | undefined): T => {
    return { value: resource ? resource.getValue() : "" } as T;
};

export const EntityToUUID = (uuid: UUID): uuid_pb.UUID => {
    const id = new uuid_pb.UUID();
    id.setValue(uuid.value);
    return id;
};

export const EntityIDsToUUID = (uuid: UUID[]): uuid_pb.UUID[] => {
    return uuid.map((value) => EntityToUUID(value));
};
