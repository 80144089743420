export const enum WorkoutStatus {
    WorkoutStatusOpen = "open",
    WorkoutStatusClosed = "closed",
    WorkoutStatusCancelled = "cancelled",
    WorkoutStatusDraft = "draft",
}

export const isFinalStatus = (s: WorkoutStatus): boolean => {
    return (
        s === WorkoutStatus.WorkoutStatusClosed ||
        s === WorkoutStatus.WorkoutStatusCancelled
    );
};
