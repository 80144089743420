export const enum PageName {
    Organization = "Organization",
    OrganizationInfo = "Info",
    Disciplines = "Disciplines",
    Locations = "Locations",
    Login = "Login",
    Users = "Users",
    SubscriptionTypes = "Subscription types",
    Workouts = "Workouts",
    Schedule = "Schedule",
    Index = "Welcome",
    Payments = "Payments",
    UserSubscriptions = "Subscriptions",
    UserWorkouts = "User's workouts",
    SignUp = "Sign up",
    AccountAdd = "Payment account add",
}
