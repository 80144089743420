import { RouteParams, RouteRecordRaw } from "vue-router";
import { PageType } from "@app/pkg/router/pageType";
import { AppBreadcrumbs } from "@app/router/models";
import UserWorkoutAddView from "@app/views/popups/user-workout-add/UserWorkoutAddView.vue";
import { workoutsUseCase } from "@app/usecase/workoutsUseCase";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { StringToEntityID, WorkoutID } from "@app/entities/uuid";
import { organizationUserUseCase } from "@app/usecase/organizationUserUseCase";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { subscriptionsUseCase } from "@app/usecase/subscriptionUseCase";

type Props = {
    workoutId: WorkoutID;
};
export const userWorkoutAddRoute = (
    prefix: string,
    breadcrumbs: AppBreadcrumbs[],
): RouteRecordRaw => {
    return {
        path: "workout/:workoutId/add-user",
        name: prefix + PageType.UserWorkoutAdd,
        meta: {
            breadcrumbs: breadcrumbs,
        },
        components: {
            popup: UserWorkoutAddView,
        },
        props: true,
        beforeEnter: async (to, from, next) => {
            const workoutID = StringToEntityID(to.params.workoutId as string);

            await workoutsUseCase.loadByID(workoutID);

            await organizationUserUseCase.loadByOrganizationID(
                organizationUseCase.getOrganizationID(),
            );

            await subscriptionsUseCase
                .loadByOrganizationID(organizationUseCase.getOrganizationID())
                .then(async (models) => {
                    await subscriptionsUseCase.sync(models);
                });

            const props = getFirsLevelProps<Props>();
            props.workoutId = workoutID;

            // didn't find a better way
            to.params = {
                ...to.params,
                ...(props as unknown as RouteParams),
            };
            next();
        },
    };
};
