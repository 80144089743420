import {
    LocationQueryRaw,
    RouteLocationRaw,
    RouteParamsRaw,
    RouteRecordRaw,
} from "vue-router";
import { AppBreadcrumbs } from "@app/router/models";
import { Route } from "@sentry/vue/build/types/router";
import { PageType } from "@app/pkg/router/pageType";
import { workoutAddRoute } from "@app/views/popups/workout-add/route";
import { userWorkoutAddRoute } from "@app/views/popups/user-workout-add/route";
import { subscriptionAddRoute } from "@app/views/popups/subscription-add/route";
import { subscriptionUpdateRoute } from "@app/views/popups/subsctiption-update/route";
import { scheduleAddRoute } from "@app/views/popups/schedule-add/route";
import { scheduleUpdateRoute } from "@app/views/popups/schedule-update/route";
import { workoutUpdateRoute } from "@app/views/popups/workout-update/route";
import { userWorkoutUpdateRoute } from "@app/views/popups/user-workout-update/route";

export const getPopupRoutes = (
    prefix: string,
    breadcrumbs: AppBreadcrumbs[],
): RouteRecordRaw[] => {
    prefix = prefix + "_";
    return [
        workoutUpdateRoute(prefix, breadcrumbs),
        scheduleUpdateRoute(prefix, breadcrumbs),
        scheduleAddRoute(prefix, breadcrumbs),
        subscriptionAddRoute(prefix, breadcrumbs),
        subscriptionUpdateRoute(prefix, breadcrumbs),
        workoutAddRoute(prefix, breadcrumbs),
        userWorkoutAddRoute(prefix, breadcrumbs),
        userWorkoutUpdateRoute(prefix, breadcrumbs),
    ];
};

export const pupUpRoute = (
    route: Route,
    name: PageType,
    params?: RouteParamsRaw,
    query?: LocationQueryRaw,
): RouteLocationRaw => {
    return {
        name: (route.name as string) + "_" + name,
        params: {
            ...route.params,
            ...params,
        },
        query: {
            ...route.query,
            ...query,
        },
    };
};
