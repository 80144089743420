<template>
  <page-component
    :is-loading="isLoading"
    :links="actionLinks"
    :title="t('My workouts')"
  >
    <div v-if="userWorkouts.length">
      <user-workout-component
        v-for="userWorkout in userWorkouts"
        :key="EntityIDToString(userWorkout.id)"
        :user-workout-id="userWorkout.id"
        view-template="stats"
      />
    </div>
    <p v-else>
      {{ t("You have no workouts yet") }}
    </p>
  </page-component>
</template>

<script lang="ts" setup>
import PageComponent from "@app/components/PageComponent.vue";
import { EntityIDToString } from "@app/entities/uuid";
import { PageType } from "@app/pkg/router/pageType";
import { authUseCase } from "@app/usecase/authUseCase";
import {
    userWorkoutsUseCase,
    useUserWorkoutViewData,
} from "@app/usecase/userWorkoutUseCase";
import UserWorkoutComponent from "@app/views/entityComponents/UserWorkoutComponent.vue";
import { Link, LinkIcons } from "@app/views/models/links";
import { computed, ComputedRef, ref } from "vue";
import { useI18n } from "vue-i18n";

const viewData = useUserWorkoutViewData();
const { t } = useI18n();

const user = authUseCase.currentUser();

const isLoading = ref<boolean>(false);

const userWorkouts = computed(() => {
    const data = viewData.getByOrganizationUserID(user.id);
    userWorkoutsUseCase.sortByWorkoutDate(data);
    return data;
});

const actionLinks: ComputedRef<Link[]> = computed(() => [
    {
        text: t("Personal"),
        image: LinkIcons.user,
        to: {
            name: PageType.Personal,
        },
        disabled: false,
    },
    {
        text: t("My subscriptions"),
        image: LinkIcons.trophy,
        to: {
            name: PageType.UserSubscriptions,
        },
        disabled: false,
    },
]);
</script>

<style scoped></style>
