<template>
  <template v-if="template == 'inline'">
    <h3
      class="text-xl pb-2 header"
      :class="{ 'line-through': !!discipline.deletedAt }"
    >
      {{ discipline.name }}
    </h3>
    <div v-if="!discipline.deletedAt">
      <div
        v-for="image of filesData.getByEntityID(discipline.id)"
        :key="EntityIDToString(image.id)"
      >
        <img
          :src="image.path"
          alt=""
          class="lg:max-w-sm max-w-full"
        >
      </div>
      <wysiwyg-content
        :content="discipline.description"
      />
    </div>
  </template>
  <template v-if="template == 'data-item'">
    <data-item
      :links="links"
      :model="discipline"
    >
      <h3
        class="text-xl pb-2 header"
        :class="{ 'line-through': !!discipline.deletedAt }"
      >
        {{ discipline.name }}
      </h3>
      <div v-if="!discipline.deletedAt">
        <div
          v-for="image of filesData.getByEntityID(discipline.id)"
          :key="EntityIDToString(image.id)"
        >
          <img
            :src="image.path"
            alt=""
            class="lg:max-w-sm max-w-full"
          >
        </div>
        <wysiwyg-content
          :content="discipline.description"
        />
      </div>
    </data-item>
  </template>
</template>

<script lang="ts" setup>
import { Discipline } from "@app/entities/discipline";
import { useEntityFilesData } from "@app/usecase/entityFileUseCase";
import { EntityIDToString } from "@app/entities/uuid";
import WysiwygContent from "@app/components/WysiwygContent.vue";
import { Link } from "@app/views/models/links";
import DataItem from "@app/components/DataItem.vue";

const filesData = useEntityFilesData();

interface Props {
    discipline: Discipline;
    template: "data-item" | "inline";
    links?: Link[];
}

withDefaults(defineProps<Props>(), {
    links: () => [],
});
</script>

<style scoped></style>
