import {
    RouteLocationNormalized,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { disciplineUseCase } from "@app/usecase/disciplineUseCase";
import { StringToEntityID } from "@app/entities/uuid";
import DisciplinesView from "@disciplinesView/DisciplinesView.vue";
import DisciplineAddView from "@disciplinesView/DisciplineAddView.vue";
import DisciplineUpdateView from "@disciplinesView/DisciplineUpdateView.vue";
import { PageType } from "@app/pkg/router/pageType";
import { PageName } from "@app/pkg/router/pageName";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { Discipline } from "@app/entities/discipline";

type Props = {
    discipline: Discipline;
};
export const disciplinesRoutes: RouteRecordRaw[] = [
    {
        path: "/disciplines",
        name: PageType.Disciplines,
        component: DisciplinesView,
        props: true,
        beforeEnter: async (to: RouteLocationNormalized, from, next) => {
            await disciplineUseCase.getByOrganizationID(
                organizationUseCase.getOrganizationID(),
            );

            const props = getFirsLevelProps();
            // didn't find a better way
            to.params = props as unknown as RouteParams;
            next();
        },
    },
    {
        path: "/disciplines/add",
        name: PageType.DisciplineAdd,
        component: DisciplineAddView,
        props: getFirsLevelProps(),
        meta: {
            breadcrumbs: [
                {
                    name: PageName.Disciplines,
                    to: PageType.Disciplines,
                },
            ],
        },
    },
    {
        path: "/disciplines/:id",
        name: PageType.DisciplineUpdate,
        component: DisciplineUpdateView,
        props: true,
        meta: {
            breadcrumbs: [
                {
                    name: PageName.Disciplines,
                    to: PageType.Disciplines,
                },
            ],
        },
        beforeEnter: async (to: RouteLocationNormalized, from, next) => {
            const discipline = await disciplineUseCase.getByID(
                StringToEntityID(to.params.id as string),
            );

            const props = getFirsLevelProps<Props>();
            props.discipline = discipline;

            // didn't find a better way
            to.params = {
                ...to.params,
                ...(props as unknown as RouteParams),
            };
            next();
        },
    },
];
