import * as entities_pb from "proto-api/organization/organization_pb";
import { Organization } from "@app/entities/organzation";
import { PbToUUID, PbToUUIDSure } from "@grpc/converters/uuid";

export const PbToOrganization = (
    resource: entities_pb.OrganizationMessage,
): Organization => {
    return {
        code: resource.getCode(),
        id: PbToUUIDSure(resource.getId()),
        logoSquareID: PbToUUID(resource.getLogoSquareId()),
        logoWideID: PbToUUID(resource.getLogoWideId()),
        name: resource.getName(),
        ownerID: PbToUUID(resource.getOwnerId()),
    };
};
