<template>
  <form-component :form="vueForm">
    <form-field
      v-if="
        props.subscriptionOptions &&
          props.subscriptionOptions.length > 0
      "
      :field="form.subscription_id"
      :label="t('Subscription')"
    >
      <select-box-field
        :field="form.subscription_id"
        :options="props.subscriptionOptions"
      />
    </form-field>
    <div v-else>
      <p>User has no subscriptions</p>
      <form-field
        v-if="!props.model.paymentID && !props.model.subscriptionID"
        :field="form.is_payment_confirmed"
        label="Payment"
      >
        <toggle-field
          :field="form.is_payment_confirmed"
          :label="t('Is payment confirmed')"
        />
        <form-field
          v-if="formFields.is_payment_confirmed === true"
          :field="form.payment_account_id"
          label="Payment account"
        >
          <select-box-field
            :field="form.payment_account_id"
            :options="props.paymentAccounts"
          />
        </form-field>
        <form-field
          v-if="formFields.is_payment_confirmed === true"
          :field="form.payment_sum"
          label="Payment sum"
        >
          <text-input-field
            :field="form.payment_sum"
            type="number"
          />
        </form-field>
      </form-field>
    </div>

    <submit-button />
  </form-component>
</template>

<script lang="ts" setup>
import { reactive } from "vue";
import { ValidationArgs } from "@vuelidate/core";
import { minValue } from "@app/services/validators";
import FormField from "@app/components/forms/fields/FormField.vue";
import SubmitButton from "@app/components/forms/fields/SubmitButton.vue";
import { appForm } from "@app/services/forms";
import SelectBoxField from "@app/components/forms/fields/SelectBoxField.vue";
import FormComponent from "@app/components/FormComponent.vue";
import ToggleField from "@app/components/forms/fields/ToggleField.vue";
import TextInputField from "@app/components/forms/fields/TextInputField.vue";
import { userWorkoutsUseCase } from "@app/usecase/userWorkoutUseCase";
import { UserWorkout } from "@app/entities/userWorkout";
import { StringToEntityID } from "@app/entities/uuid";
import { SelectOption } from "@app/views/models/form";
import { ErrSomethingWentWrong } from "@app/entities/errors";
import { useI18n } from "vue-i18n";

interface UpdateUserWorkoutFormFields {
    subscription_id: string;
    payment_account_id: string;
    payment_sum: number;
    is_payment_confirmed: boolean;
}

const { t } = useI18n();
const props = defineProps<{
    model: UserWorkout;
    paymentAccounts: SelectOption<string>[];
    subscriptionOptions: SelectOption<string>[];
}>();

const emits = defineEmits<{
    (e: "on-success", value: UserWorkout): void;
}>();

const rules: ValidationArgs<UpdateUserWorkoutFormFields> = {
    subscription_id: {},
    is_payment_confirmed: {},
    payment_account_id: {},
    payment_sum: {
        minValueRef: minValue(0),
    },
};

const formFields = reactive<UpdateUserWorkoutFormFields>({
    subscription_id: (props.subscriptionOptions.length
        ? props.subscriptionOptions[0].value
        : "") as string,
    payment_account_id: props.paymentAccounts.length
        ? props.paymentAccounts[0].value
        : "",
    payment_sum: 0,
    is_payment_confirmed: !!props.model.paymentID,
});

const vueForm = appForm<UpdateUserWorkoutFormFields, void>(
    rules,
    formFields,
    async () => {
        let model: UserWorkout | undefined = props.model;
        if (formFields.subscription_id) {
            model = await userWorkoutsUseCase.addToSubscription(
                props.model.id,
                StringToEntityID(formFields.subscription_id),
            );
        } else if (!model.paymentID && formFields.is_payment_confirmed) {
            model = await userWorkoutsUseCase.pay(
                props.model.id,
                StringToEntityID(formFields.payment_account_id),
                formFields.payment_sum,
            );
        }
        if (!model) {
            throw ErrSomethingWentWrong;
        }
        emits("on-success", model);
    },
);
const form = vueForm.form;
</script>

<style scoped></style>
