<template>
  <form-component :form="vueForm">
    <div class="columns-1xs">
      <form-field
        :field="form.role"
        :label="t('Role')"
      >
        <select-box-field
          :field="form.role"
          :options="possibleRolesOptions"
        />
      </form-field>
    </div>
    <submit-button />
  </form-component>
</template>

<script lang="ts" setup>
import { reactive } from "vue";
import { ValidationArgs } from "@vuelidate/core";
import FormField from "@app/components/forms/fields/FormField.vue";
import SelectBoxField from "@app/components/forms/fields/SelectBoxField.vue";
import SubmitButton from "@app/components/forms/fields/SubmitButton.vue";
import { appForm } from "@app/services/forms";
import FormComponent from "@app/components/FormComponent.vue";
import { UserRole } from "@app/entities/userRole";
import { organizationUserUseCase } from "@app/usecase/organizationUserUseCase";
import { OrganizationUser } from "@app/entities/organzationUser";
import {
    getAllRoles,
    getUserRoleValidator,
    UserRolesToOptions,
} from "@app/views/converters/userRole";
import { useI18n } from "vue-i18n";

interface UpdateUserFormFields {
    role: UserRole;
}

const { t } = useI18n();
const props = defineProps<{
    organizationUser: OrganizationUser;
}>();

const emits = defineEmits<{
    (e: "on-success", value: OrganizationUser): void;
}>();

const possibleRolesOptions = UserRolesToOptions();
const formFields = reactive<UpdateUserFormFields>({
    role: props.organizationUser.role,
});
const rules: ValidationArgs<UpdateUserFormFields> = {
    role: getUserRoleValidator(getAllRoles()),
};

const vueForm = appForm<UpdateUserFormFields, void>(
    rules,
    formFields,
    async () => {
        const model = await organizationUserUseCase.update(
            props.organizationUser.id,
            formFields.role,
        );
        emits("on-success", model);
    },
);
const form = vueForm.form;
</script>

<style scoped></style>
