import { Context } from "@app/entities/context";
import { Account } from "@app/entities/payment/account";
import { AccountType } from "@app/entities/payment/accountType";
import { Transaction } from "@app/entities/payment/transaction";
import {
    OrganizationID,
    PaymentAccountID,
    PaymentTransactionID,
} from "@app/entities/uuid";
import { ContextToPb } from "@grpc/converters/context";
import { PbToEntities } from "@grpc/converters/entities";
import { PbToAccount } from "@grpc/converters/payment/account";
import { AccountTypeToPb } from "@grpc/converters/payment/accountType";
import { PbToTransaction } from "@grpc/converters/payment/transaction";
import { EntityToUUID } from "@grpc/converters/uuid";
import { PaymentServicePromiseClient } from "proto-api/payment/service_grpc_web_pb";
import {
    GetAccountsRequest,
    GetTransactionsRequest,
    StoreAccountRequest,
    UpdateAccountRequest,
} from "proto-api/payment/service_pb";
import { App } from "vue";

let service: PaymentServicePromiseClient;

type Config = {
    service: PaymentServicePromiseClient;
};
export const initPaymentClient = (app: App, options: Config) => {
    service = options.service;
};

export const usePaymentClient = () => {
    return paymentClient;
};

const paymentClient = {
    loadAccounts: async (
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<Account[]> => {
        const request = new GetAccountsRequest();
        request.setOrganizationId(EntityToUUID(organizationID));
        const response = await service.getAccounts(request, ContextToPb(ctx));
        return PbToEntities(response.getAccountsList(), PbToAccount);
    },

    loadTransactions: async (
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<Transaction[]> => {
        const request = new GetTransactionsRequest();
        request.setOrganizationId(EntityToUUID(organizationID));
        const response = await service.getTransactions(
            request,
            ContextToPb(ctx),
        );
        return PbToEntities(response.getTransactionsList(), PbToTransaction);
    },

    loadTransactionsByIDs: async (
        ctx: Context,
        ids: PaymentTransactionID[],
    ): Promise<Transaction[]> => {
        // TODO
        console.log(ctx, ids);
        return Promise.resolve([]);
    },

    storeAccount: async (
        ctx: Context,
        organizationID: OrganizationID,
        name: string,
        type: AccountType,
    ): Promise<Account | undefined> => {
        const request = new StoreAccountRequest();
        request.setOrganizationId(EntityToUUID(organizationID));
        request.setName(name);
        request.setType(AccountTypeToPb(type));

        const response = await service.storeAccount(request, ContextToPb(ctx));

        const account = response.getAccount();
        if (account) {
            return PbToAccount(account);
        }
        return undefined;
    },

    updateAccount: async (
        ctx: Context,
        accountID: PaymentAccountID,
        name: string,
    ): Promise<Account | undefined> => {
        const request = new UpdateAccountRequest();
        request.setName(name);
        request.setAccountId(EntityToUUID(accountID));

        const response = await service.updateAccount(request, ContextToPb(ctx));

        const account = response.getAccount();
        if (account) {
            return PbToAccount(account);
        }
        return undefined;
    },
};
