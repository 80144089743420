import {
    BalanceAccountID,
    DisciplineID,
    listIDsUnique,
    OrganizationID,
    OrganizationUserID,
    SubscriptionID,
    SubscriptionTypeID,
} from "@app/entities/uuid";

export type Subscription = {
    id: SubscriptionID;
    organizationID: OrganizationID;
    organizationUserID: OrganizationUserID;
    subscriptionTypeID: SubscriptionTypeID;
    balanceID: BalanceAccountID;
    startDate?: Date;
    expireDate?: Date;
    displayExpireDate?: Date;
    createdAt: Date;
    updatedAt: Date;
    disciplineIDs: DisciplineID[];
};

export const filterSubscriptionIDs = (
    models: Subscription[],
): {
    ids: SubscriptionID[];
    organizationUserIDs: OrganizationUserID[];
    balanceIDs: BalanceAccountID[];
} => {
    return {
        ids: listIDsUnique(models.map((model) => model.id)),
        organizationUserIDs: listIDsUnique(
            models.map((model) => model.organizationUserID),
        ),
        balanceIDs: listIDsUnique(models.map((model) => model.balanceID)),
    };
};
